import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import Switch from "react-switch";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,
} from "../../../../../functions/Functions";

import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const Party = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  let {
    //party
    getParty,
    partyList,
    setPartyList,
    setPaginatedParty,
    partyForSearch,
    setPartyForSearch,
    //pagination
    dataPaginating
  } = useContext(UserContext);

  let { 
    orderTypeForSearch,
    deptTagList,
    paymentTypeList 
  } = useContext(RestaurantContext);

  let [orderType, setorderType] = useState([]);
  let [menu, setMenu] = useState([]);
  let [paymentType, setPaymentType] = useState([]);

  console.log("order type List", orderTypeForSearch);
  // States hook here
  //new customer
  let [newParty, setNewParty] = useState({
    name: "",
    email: "",
    phn_no: "",
    address: "",
    associateWith: [],
    associateWithMenu: [],
    associateWithPaymentType: [],
    ref_min:0,
    ref_max: 0,
    for_cc: false,
    receivableAmount: null,
    showHeader: false,
    showRefNo: false,
    due: null,
    edit: false,
    editSlug: null,
    uploading: false,
  });
  
  const handleSetOrderType = (ordertype) => {
    console.log("ordertype", ordertype);
    setNewParty({ ...newParty, ordertype });
    setorderType(ordertype);
  };

  const handleSetMenu = (menu) => {
    console.log("menu", menu);
    setNewParty({ ...newParty, menu });
    setMenu(menu);
  };

  const handleSetPaymentType = (paymentType) => {
    console.log("paymentType", paymentType);
    setNewParty({ ...newParty, paymentType });
    setPaymentType(paymentType);
  };

  //search result
  let [searchedParty, setSearchedParty] = useState({
    list: null,
    searched: false,
  });

  //useEffect == componentDidMount
  useEffect(() => {
  }, []);

  //set name, phn no hook
  const handleSetNewParty = (e) => {
    setNewParty({ ...newParty, [e.target.name]: e.target.value });
  };

  const handleShowHeader = () => {
    setNewParty({
      ...newParty,
      showHeader: !newParty.showHeader,
    });
  };
  const handleShowRefNo = () => {
    setNewParty({
      ...newParty,
      showRefNo: !newParty.showRefNo,
    });
  };
console.log("abcd44",newParty)
  const handleShowCallCenter = () => {
    setNewParty({
      ...newParty,
      for_cc : !newParty.for_cc,
    });
  };

  //Save New customer
  const handleSaveNewCustomer = (e) => {
    e.preventDefault();
    if(newParty.showRefNo && parseFloat(newParty.ref_min) < 1){
      toast.error("min length can't be less then 1");
      return false;
    }
    if(newParty.showRefNo && parseFloat(newParty.ref_max) < parseFloat(newParty.ref_min)){
      toast.error("max can't be less then min");
      return false;
    }
    setNewParty({
      ...newParty,
      uploading: true,
    });
    const customerUrl = BASE_URL + `/settings/new-party`;
    let formData = new FormData();
    formData.append("name", newParty.name);
    formData.append("phn_no", newParty?.phn_no ? newParty.phn_no : "");
    formData.append("email", newParty?.email ? newParty.email : "");
    formData.append("address", newParty?.address ? newParty?.address : "");

    // for order type
    let tempOrderTypeArray = [];
    newParty.ordertype.map((pItem) => {
      tempOrderTypeArray.push(pItem.id);
    });
    formData.append("associate_with", tempOrderTypeArray);

    // for memu
    let tempMenuArray = [];
    newParty.menu.map((pItem) => {
      tempMenuArray.push(pItem.id);
    });
    formData.append("associate_with_menu", tempMenuArray);

    // for payment type
    let tempPaymentTypeArray = [];
    newParty.paymentType.map((pItem) => {
      tempPaymentTypeArray.push(pItem.id);
    });
    formData.append("associate_with_payment_type", tempPaymentTypeArray);

    formData.append("receivable_amount", newParty?.receivableAmount ? newParty?.receivableAmount : "");
    formData.append("show_header", newParty.showHeader === false ? 0 : 1);
    formData.append("show_ref_no", newParty.showRefNo === false ? 0 : 1);
    formData.append("ref_min", newParty.ref_min);
    formData.append("ref_max", newParty.ref_max);
    formData.append("for_cc", newParty.for_cc === false ? 0 : 1);
 
    console.log("new party", newParty, " Fromdata ", formData);
    return axios
      .post(customerUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewParty({
          name: "",
          email: "",
          phn_no: "",
          address: "",
          ref_min:0,
          ref_max:0,
          for_cc: false,
          associateWith: [],
          associateWithMenu: [],
          associateWithPaymentType: [],
          receivableAmount: null,
          showHeader: false,
          showRefNo: false,
          edit: false,
          editSlug: null,
          uploading: false,
        });
        setorderType([]);
        setMenu([]);
        setPaymentType([]);
        setPartyList(res.data[0]);
        setPartyForSearch(res.data[1]);
        setLoading(false);
        toast.success(`${_t(t("Party has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewParty({
          ...newParty,
          uploading: false,
        });
        if (error && error.response.data.errors) {
          if (error.response.data.errors.phn_no) {
            error.response.data.errors.phn_no.forEach((item) => {
              if (item === "A party exists with this phone number") {
                toast.error(
                  `${_t(t("A party exists with this phone number"))}`,
                  {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  }
                );
              }
            });
          }
        }
      });
  };

  let selectedOrderTypes = [];
  let selectedMenus = [];
  let selectedPaymentList = [];

  //set edit true & values
  const handleSetEdit = (slug) => {
    let party = partyForSearch.filter((item) => {
      return item.slug === slug;
    });

    // For orderType
    const tempOrderTypeArray = JSON.parse(party[0].associate_with);
    console.log("Party On Edit", party, " tempOrderTypeArray ", tempOrderTypeArray);

    if (tempOrderTypeArray.length !== 0) {
      for (var orderTypeProperty of tempOrderTypeArray) {
        for (var i = 0; i < orderTypeForSearch.length; i++) {
          if (orderTypeForSearch[i].id == orderTypeProperty) {
            selectedOrderTypes.push(orderTypeForSearch[i]);
          }
        }
        setorderType(selectedOrderTypes);
      }
    } else {
      setorderType([]);
    }

    // For menu
    const tempMenuArray = JSON.parse(party[0].associate_with_menu);
    console.log("Party On Edit", party, " tempMenuArray ", tempMenuArray);

    if (tempMenuArray !== null && tempMenuArray.length !== 0) {
      for (var menuProperty of tempMenuArray) {
        for (var i = 0; i < deptTagList.data.length; i++) {
          if (deptTagList.data[i].id == menuProperty) {
            selectedMenus.push(deptTagList.data[i]);
          }
        }
        setMenu(selectedMenus);
      }
    } else {
      setMenu([]);
    }
    
    
    // For payment type
    const tempPaymentTypeArray = JSON.parse(party[0].associate_with_payment_type);
    console.log("Party On Edit", party, " tempPaymentTypeArray ", tempPaymentTypeArray);

    if (tempPaymentTypeArray !== null && tempPaymentTypeArray.length !== 0) {
      for (var paymentTypeProperty of tempPaymentTypeArray) {
        for (var i = 0; i < paymentTypeList.data.length; i++) {
          if (paymentTypeList.data[i].id == paymentTypeProperty) {
            selectedPaymentList.push(paymentTypeList.data[i]);
          }
        }
        console.log("selectedPaymentList", selectedPaymentList);
        setPaymentType(selectedPaymentList);
      }
    } else {
      setPaymentType([]);
    }
    setNewParty({
      ...newParty,
      name: party[0].name,
      email: party[0].email,
      phn_no: party[0].phn_no,
      address: party[0].address,
      associateWith: party[0].associate_with,
      associateWithMenu: party[0].associate_with_menu,
      associateWithPaymentType: party[0].associate_with_payment_type,
      receivableAmount: party[0].receivable_amount,
      ref_min: party[0].ref_min,
      ref_max: party[0].ref_max,
      for_cc: parseInt(party[0].for_cc) === 1 ? true : false,
      showHeader: parseInt(party[0].show_header) === 1 ? true : false,
      showRefNo: parseInt(party[0].show_ref_no) === 1 ? true : false,
      editSlug: party[0].slug,
      edit: true,
      ordertype: selectedOrderTypes,
      menu: selectedMenus,
      paymentType: selectedPaymentList,
    });
  };

  const handleUpdateStatus = (party) =>{
    console.log("abcd44",party)
    setLoading(true)
    let formData = {
      editSlug : party.slug,
      show_header : !parseInt(party.show_header) == true ? 1 : 0
    }
    const customerUrl = BASE_URL + `/settings/update-party-status`;
    return axios
    .post(customerUrl, formData, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((res) => {
     
      setPartyList(res.data[0]);
      setPartyForSearch(res.data[1]);
      setSearchedParty({
        ...searchedParty,
        list: res.data[1],
      });
      setLoading(false);
      toast.success(`${_t(t("Party has been updated"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    })
    .catch((error) => {
      setLoading(false);

      toast.error(
        `${_t(t("Please try again"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    });
  }

  //update customer
  const handleUpdateCustomer = (e) => {
    e.preventDefault();
    if(newParty.showRefNo && parseFloat(newParty.ref_min) < 1){
      toast.error("min length can't be less then 1");
      return false;
    }
    if(newParty.showRefNo && parseFloat(newParty.ref_max) < parseFloat(newParty.ref_min)){
      toast.error("max can't be less then min");
      return false;
    }
    setNewParty({
      ...newParty,
      uploading: true,
    });
    const customerUrl = BASE_URL + `/settings/update-party`;
    let formData = new FormData();
    formData.append("name", newParty.name);
    formData.append("phn_no", newParty.phn_no);
    formData.append("email", newParty.email);
    formData.append("address", newParty.address);
    
    // change work //
    let tempOrderTypeArray = [];
    newParty.ordertype.map((pItem) => {
      tempOrderTypeArray.push(pItem.id);
    });
    formData.append("associate_with", tempOrderTypeArray);

    // for memu
    let tempMenuArray = [];
    console.log("newParty", newParty);
    newParty.menu.map((pItem) => {
      tempMenuArray.push(pItem.id);
    });
    formData.append("associate_with_menu", tempMenuArray);

    // for payment type
    let tempPaymentTypeArray = [];
    newParty.paymentType.map((pItem) => {
      tempPaymentTypeArray.push(pItem.id);
    });
    formData.append("associate_with_payment_type", tempPaymentTypeArray);

    formData.append("receivable_amount", newParty.receivableAmount);
    formData.append("ref_min", newParty.ref_min);
    formData.append("ref_max", newParty.ref_max);
    formData.append("for_cc", newParty.for_cc === false ? 0 : 1);
    formData.append("show_header", newParty.showHeader === false ? 0 : 1);
    formData.append("show_ref_no", newParty.showRefNo === false ? 0 : 1);
    formData.append("editSlug", newParty.editSlug);
    return axios
      .post(customerUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewParty({
          name: "",
          email: "",
          phn_no: "",
          address: "",
          ref_min: 0,
          ref_max: 0,
          for_cc: false,
          associateWith: [],
          associateWithMenu: [],
          associateWithPaymentType: [],
          receivableAmount: null,
          showHeader: false,
          showRefNo: false,
          due: null,
          edit: false,
          editSlug: null,
          uploading: false,
        });
        setorderType([]);
        setPaymentType([]);
        setMenu([]);
        setPartyList(res.data[0]);
        setPartyForSearch(res.data[1]);
        setSearchedParty({
          ...searchedParty,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Party has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewParty({
          ...newParty,
          uploading: false,
        });
        if (error && error.response.data.errors) {
          if (error.response.data.errors.phn_no) {
            error.response.data.errors.phn_no.forEach((item) => {
              if (item === "A party exists with this phone number") {
                toast.error(
                  `${_t(t("A party exists with this phone number"))}`,
                  {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  }
                );
              }
            });
          }
        }
      });
  };

  //search customers here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedParty({ ...searchedParty, searched: false });
    } else {
      let searchedList = partyForSearch.filter((item) => {
        //name
        let lowerCaseItemName = item.name?.toLowerCase();

        //email
        let lowerCaseItemEmail =
          item.email !== null && item.email.toLowerCase();

        //phn no
        let lowerCaseItemPhnNo =
          item.phn_no !== null && item.phn_no.toLowerCase();

        //address
        let lowerCaseItemAddress =
          item.address !== null && item.address.toLowerCase();

        // //Associate with
        // let lowerCaseItemAssociateWith =
        //   item.associateWith !== null && item.associateWith.toLowerCase();

        //receivable amount
        let lowerCaseItemReceivableAmount =
          item.receivableAmount !== null && item.receivableAmount;

        // //show header
        // let lowerCaseItemShowHeader =
        //   item.showHeader !== null && item.showHeader.toLowerCase();

        return (
          lowerCaseItemName.includes(searchInput) ||
          (lowerCaseItemEmail && lowerCaseItemEmail.includes(searchInput)) ||
          (lowerCaseItemPhnNo && lowerCaseItemPhnNo.includes(searchInput)) ||
          (lowerCaseItemAddress &&
            lowerCaseItemAddress.includes(searchInput)) ||
          (lowerCaseItemReceivableAmount &&
            lowerCaseItemReceivableAmount.includes(searchInput))
        );
      });
      setSearchedParty({
        ...searchedParty,
        list: searchedList,
        searched: true,
      });
    }
  };

  //delete confirmation modal of waiter
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteCustomer(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete customer here
  const handleDeleteCustomer = (slug) => {
    setLoading(true);
    const customerUrl = BASE_URL + `/settings/delete-party/${slug}`;
    return axios
      .get(customerUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPartyList(res.data[0]);
        setPartyForSearch(res.data[1]);
        setSearchedParty({
          ...searchedParty,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Party has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  const handleFormStateEmpty = () => {
    setNewParty({
      name: "",
      email: "",
      phn_no: "",
      address: "",
      associateWith: [],
      associateWithMenu: [],
      associateWithPaymentType: [],
      receivableAmount: "",
      ref_min: 0,
      ref_max: 0,
      for_cc: false,
      showHeader: false,
      showRefNo: false,
      receivable_percentage: null,
      due: null,
      edit: false,
      editSlug: null,
      uploading: false,
    })
    setorderType([])
    setMenu([])
    setPaymentType([])
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Parties"))}</title>
      </Helmet>

      {/* Add modal */}
      <div className="modal fade" id="addCustomer" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newParty.edit
                    ? _t(t("Add new Party"))
                    : _t(t("Update Party"))}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newParty.uploading === false ? (
                <div key="fragment-customer-1">
                  <form
                    onSubmit={
                      !newParty.edit
                        ? handleSaveNewCustomer
                        : handleUpdateCustomer
                    }
                  >
                    <div>
                      <label htmlFor="name" className="form-label">
                        {_t(t("Name"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="e.g. Mr. John"
                        value={newParty.name || ""}
                        required
                        onChange={handleSetNewParty}
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="email" className="form-label">
                        {_t(t("Email"))}{" "}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        //required
                        placeholder="e.g. party@example.com"
                        value={newParty.email || ""}
                        onChange={handleSetNewParty}
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="phn_no" className="form-label">
                        {_t(t("Phone number"))}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phn_no"
                        name="phn_no"
                        placeholder="e.g. 01xxx xxx xxx"
                        value={newParty.phn_no || ""}
                        onChange={handleSetNewParty}
                      />
                    </div>

                    {/* <div className="mt-3">
                      <label htmlFor="due" className="form-label">
                        {_t(t("Previous due"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        min="0"
                        id="due"
                        name="due"
                        required
                        className="form-control"
                        onChange={handleSetNewParty}
                        value={newParty.due}
                        placeholder="Previous due in USD"
                        required
                      />
                    </div> */}

                    <div className="mt-3">
                      <label htmlFor="address" className="form-label">
                        {_t(t("Address"))}{" "}
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        //required
                        placeholder="Type customer address"
                        value={newParty.address || ""}
                        onChange={handleSetNewParty}
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="associate_with" className="form-label">
                        {_t(t("Associate with"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <Select
                        value={orderType}
                        options={orderTypeForSearch}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti={true}
                        maxMenuHeight="200px"
                        onChange={handleSetOrderType}
                        placeholder={
                          _t(t("Please select property groups")) + ".."
                        }
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="associate_with" className="form-label">
                        {_t(t("Menu"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <Select
                        value={menu}
                        options={deptTagList.data}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti={true}
                        maxMenuHeight="200px"
                        onChange={handleSetMenu}
                        placeholder={
                          _t(t("Please select property groups")) + ".."
                        }
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="associate_with" className="form-label">
                        {_t(t("Payment Type"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <Select
                        value={paymentType}
                        options={paymentTypeList.data}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti={true}
                        maxMenuHeight="200px"
                        onChange={handleSetPaymentType}
                        placeholder={
                          _t(t("Please select property groups")) + ".."
                        }
                      />
                    </div>

                    <div className="mt-3">
                      <label
                        htmlFor="receivable_percentage"
                        className="form-label"
                      >
                        {_t(t("Receivable percantage"))}{" "}
                      </label>
                      <input
                        type="number"
                        //step="0.01"
                        min="0"
                        id="receivableAmount"
                        name="receivableAmount"
                        className="form-control"
                        onChange={handleSetNewParty}
                        value={newParty.receivableAmount}
                        placeholder="Receivable percentage"
                      />
                    </div>
                    <div className="form-check mt-4">
                      <div className="row">
                        <div className="col-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="showHeader"
                            checked={newParty.showHeader}
                            onChange={handleShowHeader}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="showHeader"
                          >
                            {_t(t("Show Header?"))}
                          </label>
                        </div>
                        <div className="col-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="showRefNo"
                            checked={newParty.showRefNo}
                            onChange={handleShowRefNo}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="showRefNo"
                          >
                            {_t(t("Show Ref No?"))}
                          </label>
                        </div>
                        {
                          newParty.showRefNo &&(
                            <>
                            <div className="col-3 d-flex align-items-center ">
                            <label
                              className="form-check-label mr-2"
                              htmlFor="min"
                            >
                              {_t(t("min"))}
                            </label>
                            <input
                              type="number"
                              className="form-control mr-3"
                              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                              id="ref_min"
                              name ="ref_min"
                              value={newParty.ref_min || ""}
                              onChange={handleSetNewParty}
                              required
                            />
                           
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label
                              className="form-check-label mr-2"
                              htmlFor="max"
                            >
                              {_t(t("max"))}
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                              className="form-control mr-3"
                              id="ref_max"
                              name="ref_max"
                              value={newParty.ref_max || ""}
                              onChange={handleSetNewParty}
                              required
                            />
                           
                          </div>
                            </>
                          )
                        }
                      
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="row d-flex justify-content-end align-items-center">
                        <div className="col-6">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="for_cc"
                          checked={newParty.for_cc}
                          onChange={handleShowCallCenter}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="for_cc"
                        >
                          {_t(t("Apply for CC?"))}
                        </label>
                        </div>
                        <div className="col-3">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          >
                            {!newParty.edit ? _t(t("Save")) : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                            data-dismiss="modal"
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row d-flex justify-content-end align-items-center">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newParty.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {newParty.uploading === true || loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${dataPaginating && "loading"}`}></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedParty.searched
                                    ? _t(t("Party List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search customer */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add customer modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  data-toggle="modal"
                                  data-target="#addCustomer"
                                  onClick={() => {
                                    handleFormStateEmpty()
                                  }}
                                >
                                  {_t(t("add new"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("email"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Phn no"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Address"))}
                                </th>

                                {/* <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Associate with"))}
                                </th> */}

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Receivable Amount"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Show Header"))}
                                </th>

                                {/* <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Due"))}
                                </th> */}

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedParty.searched
                                ? [
                                    partyList && [
                                      partyList.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        partyList.data.map((item, index) => {
                                          console.log("List Item ", item);
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (partyList.current_page - 1) *
                                                    partyList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.name}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.email}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.phn_no ? (
                                                  <a
                                                    href={`tel:${item.phn_no}`}
                                                    rel="noopener noreferrer"
                                                  >
                                                    {item.phn_no}
                                                  </a>
                                                ) : (
                                                  "-"
                                                )}
                                              </td>
                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.address}
                                              </td>

                                              {/* <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.associate_with}
                                              </td> */}

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.receivable_amount}
                                              </td>

                                              <td className="sm-text text-capitalize align-middle text-center ">
                                                <Switch
                                                checked={item?.show_header === "1"}
                                                name="status"
                                                onChange={(e) => {
                                                  handleUpdateStatus(item)
                                                
                                                }}
                                                height={22}
                                                width={44}
                                                offColor="#ee5253"
                                                />
                                                {/*item.show_header === "1" ? (
                                                  <span
                                                    style={{
                                                      color: "Green",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    ON
                                                  </span>
                                                ) : (
                                                  <span
                                                    style={{
                                                      color: "Red",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    OFF
                                                  </span>
                                                  )*/}
                                              </td>

                                              {/* <td className="xsm-text align-middle text-center">
                                                {currencySymbolLeft()}
                                                {formatPrice(item.due_balance)}
                                                {currencySymbolRight()}
                                              </td> */}

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        setNewParty({
                                                          ...newParty,
                                                        });
                                                        handleSetEdit(
                                                          item.slug
                                                        );
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#addCustomer"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Edit"))}
                                                    </button>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.slug
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedParty && [
                                      searchedParty.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedParty.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (partyList.current_page -
                                                      1) *
                                                      partyList.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.email}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.phn_no ? (
                                                    <a
                                                      href={`tel:${item.phn_no}`}
                                                      rel="noopener noreferrer"
                                                    >
                                                      {item.phn_no}
                                                    </a>
                                                  ) : (
                                                    "-"
                                                  )}
                                                </td>
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.address}
                                                </td>

                                                {/* <td className="xsm-text align-middle text-center">
                                                  {currencySymbolLeft()}
                                                  {formatPrice(
                                                    item.due_balance
                                                  )}
                                                  {currencySymbolRight()}
                                                </td> */}
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.receivable_amount}
                                                </td>

                                                <td className="sm-text text-capitalize align-middle text-center ">
                                                  <Switch
                                                  checked={item?.show_header === "1"}
                                                  name="status"
                                                  onChange={(e) => {
                                                    handleUpdateStatus(item)
                                                  
                                                  }}
                                                  height={22}
                                                  width={44}
                                                  offColor="#ee5253"
                                                  />
                                                  {/*item.show_header === "1" ? (
                                                    <span
                                                      style={{
                                                        color: "Green",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      ON
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        color: "Red",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      OFF
                                                    </span>
                                                    )*/}
                                                </td>
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          setNewParty({
                                                            ...newParty,
                                                          });
                                                          handleSetEdit(
                                                            item.slug
                                                          );
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#addCustomer"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("Edit"))}
                                                      </button>

                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handleDeleteConfirmation(
                                                            item.slug
                                                          );
                                                        }}
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                        {_t(t("Delete"))}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {newParty.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedParty.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(partyList, setPaginatedParty)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(partyList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedParty({
                                      ...searchedParty,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedParty,
                                    partyForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default Party;
