import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import Moment from "react-moment";
import Select from "react-select";
import moment from 'moment';
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";


const StockConsumption = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let { loading, setLoading, dataPaginating, generalSettings } =
      useContext(SettingsContext);
    let { branchForSearch,ingredientItemList,ingredientItemForSearch } = useContext(RestaurantContext);
    const { partyForSearch, authUserInfo } = useContext(UserContext);
  
    let [newStockConsumptionReport, setNewStockConsumptionReport] = useState({
      branch:null,
      item:null,
    });
    
    //all data
    const [reportData, setReportData] = useState(null);
  
    const componentRef = useRef();
    //settle order
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [generatedReport, setGeneratedReport] = useState(false);
    const [siteName, setSiteName] = useState("");
    //useEffect == componentDidMount()
    const [branchSearch, setBranchSearch] = useState(null);
  
    useEffect(() => {
      setSiteName(getSystemSettings(generalSettings, "siteName"));
      if (authUserInfo.details.user_type === "staff") {
        setBranchSearch(
          branchForSearch.filter(
            (branch) => branch.id === authUserInfo.details.branch_id
          )
        );
      } else {
        setBranchSearch(branchForSearch);
      }
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }, [generalSettings]);

    const handleBranch = (branch) => {
    
        setNewStockConsumptionReport({
          ...newStockConsumptionReport,
          branch:branch,
        });
        // setBranch(branch);
      };

      const handleItem = (item) => {
    
        setNewStockConsumptionReport({
          ...newStockConsumptionReport,
          item:item,
        });
        // setBranch(branch);
      };
  
      //get BranchWise reports filter
  const handleBranchWiseReport = () => {
    if (newStockConsumptionReport?.branch?.id !== null && newStockConsumptionReport?.branch?.id !== undefined && startDate !== null && endDate !== null) {
      setLoading(true);
     
      var startdate = moment(startDate).format("YYYY-MM-DD");
      var enddate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-consumption-report";

      let formData = new FormData();
      formData.append("from_date", startdate);
      formData.append("to_date", enddate);
      formData.append("branch_id", newStockConsumptionReport?.branch?.id);
      formData.append("item_id", newStockConsumptionReport?.item ? newStockConsumptionReport?.item?.id : null);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setReportData(res.data[0]);
          setLoading(false);
          setGeneratedReport(true);
          componentRef.current.handleDownload();
          setNewStockConsumptionReport({
            branch:null,
            item:null,
          });
          setStartDate(null);
          setEndDate(null)
        })
        .catch((error) => {
          setNewStockConsumptionReport({
            branch:null,
            item:null,
          });
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, branch"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };
  return (
    <>
    <Helmet>
      <title>{_t(t("Stock Consumption Report"))}</title>
    </Helmet>
    <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table="table-to-xls"
      filename="Stock Consumption Report"
      sheet="sheet 1"
      buttonText="Sales Detail"
      ref={componentRef}
    />

    {/* Print Button */}
    <div className="d-none">
      <div>
        <style type="text/css" media="print">
          {
            "\
        @page { size: landscape; }\
      "
          }
        </style>
        <div className="fk-print">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {reportData !== null &&
                  reportData !== undefined &&
                  generatedReport === true && (
                    <>
                      <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                          <table
                            className="table table-bordered table-hover min-table-height mt-3"
                            id="table-to-xls"
                          >
                            <div className="page-break"></div>
                            <thead className="align-middle">
                              <tr style={{ border: "0px" }}>
                                <th colspan="12" style={{ border: "0px" }}>
                                  <div className="row">
                                    <div className="col-12">
                                      <h3 className="text-center mb-0">
                                        {siteName}
                                      </h3>
                                      <h3 className="text-center mt-0">
                                        Stock Consumption Report
                                      </h3>
                                      <p className="text-center">
                                        FROM&nbsp;
                                        <Moment format="LL">
                                          {startDate}
                                        </Moment>
                                      </p>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                              <tr>
                              <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                            {_t(t("Item Name"))}
                            </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Date"))}
                                </th>
                                
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Branch Name"))}
                                </th>
                                {/*<th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Item Name"))}
                                </th>*/}
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Unit"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Stock Qty"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Avg Rate"))}
                                </th>
                               
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Amount"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {console.log("309 ", reportData)}
                              {/* loop here*/}
                              { reportData &&
                                Object.keys(reportData).map(
                                  (group_name, index) => {
                                    let Amount = 0; 
                                    let stock_Qty = 0;
                                    
                                    return(
                                    <div key={index}>
                                  
                                    <tr>
                                    <th
                                      scope="row"
                                      className="lg-text text-capitalize align-middle text-center"
                                    >
                                      {group_name}
                                    </th>
                                  </tr>
                              {reportData[group_name].map((item, i) => {
                                 Amount += parseFloat(item.amount);
                                 stock_Qty += parseFloat(item.stock_qty);
                                
                                return (
                                  <tr>
                                  <td></td>
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center "
                                    >
                                      {i + 1}
                                    </td>
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                    <Moment format="LL">

                                      {item.document_date}
                                      </Moment>
                                    </td>
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {item.branch_name}
                                    </td>
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {item.unit_name}
                                    </td>
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {item.stock_qty}
                                    </td>
                                   {/* <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {item.item_name}
                                    </td>  */}
                                    
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {formatPrice(item.avg_rate)}
                                    </td>
                                  
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {formatPrice(item.amount)}
                                    </td>
                                  </tr>
                                );
                              })}
                               <tr>
                                <th
                                  scope="row"
                                  className="xsm-text text-capitalize align-middle text-center"
                                >
                                  Total
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            
                              <td
                              scope="row"
                              className="xsm-text text-capitalize align-middle text-center"
                              >
                                {formatPrice(stock_Qty)}
                              </td>
                              <td></td>
                              <td
                              scope="row"
                              className="xsm-text text-capitalize align-middle text-center"
                              >
                                {formatPrice(Amount)}
                              </td>
                               </tr>
                              </div>
                              );
                            }
                            )
                           }
                              {/*<tr>
                                <th className="sm-text text-capitalize align-middle text-center border-1 border"></th>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                  Total
                                </td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                  {formatPrice(stock_inn)}
                                </td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                  {formatPrice(stock_out)}
                                </td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                  {formatPrice(amount)}
                                </td>
                             </tr>*/}
                            </tbody>

                            <tfoot style={{ border: "0px" }}>
                              <tr style={{ border: "0px" }}>
                                <td
                                  colspan="7"
                                  className="text-center"
                                  style={{ border: "0px" }}
                                >
                                  <h5 className="mt-3">
                                    Powered by indolj.pk
                                  </h5>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ReportSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-2 mb-lg-0">
            <div className="t-bg-white p-1">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}

                  {/* Loading effect */}
                  {loading === true ? (
                    tableLoading()
                  ) : (
                   <>
                    <div key="smtp-form mt-2">
                      <div className="row gx-2 align-items-center t-pt-2 t-pb-2">
                        <div className="col-12 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Stock Consumption Report"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row gx-2 mt-2">
                        <div className="col-md-2 d-md-block mt-1">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control sm-text py-2 t-mb-2 mb-md-0"
                            placeholderText={_t(t("Start Date"))}
                            shouldCloseOnSelect={false}
                          />
                        </div>
                        <div className="col-md-2 d-md-block mt-1">
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control sm-text py-2 t-mb-2 mb-md-0"
                          placeholderText={_t(t("End Date"))}
                          shouldCloseOnSelect={false}
                        />
                      </div>

                        <div className="col-md-4 t-mb-15 mb-md-0 mt-1">
                          <Select
                            options={branchSearch && branchSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            //value={branch}
                            classNamePrefix="select"
                            className="sm-text "
                            onChange={handleBranch}
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select a branch")) + ".."}
                          />
                        </div>

                        <div className="col-md-4 t-mb-15 mb-md-0 mt-1">
                          <Select
                            options={ingredientItemForSearch && ingredientItemForSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            //value={branch}
                            classNamePrefix="select"
                            className="sm-text "
                            onChange={handleItem}
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select item")) + ".."}
                          />
                        </div>
                        
                      </div>
                      <div className="col-4 t-mb-15 mb-md-0 d-none d-md-block text-right mt-3 w-100">
                        <button
                        className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 w-25"
                        onClick={(e) => handleBranchWiseReport(e)}
                        >
                          Consumption Report
                        </button>
                      </div>
                    </div>

                   </>
                  )}
                </div>

              </div>
                
            </div>
            
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
  </>
  )
}

export default StockConsumption
