import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import { UserContext } from "../../../../contexts/User";

const CustomerOrderHistoryReport = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } =
    useContext(SettingsContext);
  let { branchForSearch, orderTypeList, paymentTypeForSearch } =
    useContext(RestaurantContext);
  const { foodForSearch, foodGroupForSearch } = useContext(FoodContext);
  const { partyForSearch } = useContext(UserContext);

  let [newCustomerOrderHistoryReport, setNewCustomerOrderHistoryReport] =
    useState(null);

  //all data
  const [reportData, setReportData] = useState(null);

  // paidMoney
  const [paidMoney, setPaidMoney] = useState(0);
  //return
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);
  const componentRef = useRef();
  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
  });

  const groupByArray = [
    { id: "Branch", name: "Branch" },
    { id: "Order Types", name: "Order Types" },
    { id: "Payment Types", name: "Payment Types" },
  ];
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [sumPrice, setSumPrice] = useState(0);
  const [sumTotalPrice, setSumTotalPrice] = useState(0);
  const [siteName, setSiteName] = useState("");
  //useEffect == componentDidMount()

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  //Print Report
  const ReportPrint = useRef();

  const handlePrintBtn = useReactToPrint({
    content: () => ReportPrint.current,
  });

  const handleBranch = (branch) => {
    setNewCustomerOrderHistoryReport({
      ...newCustomerOrderHistoryReport,
      branch,
    });
    // setBranch(branch);
  };
  const handleGroup = (group) => {
    setNewCustomerOrderHistoryReport({
      ...newCustomerOrderHistoryReport,
      group,
    });
    //setGroup(group);
  };
  const handleItem = (item) => {
    setNewCustomerOrderHistoryReport({
      ...newCustomerOrderHistoryReport,
      item,
    });
    //setItem(item);
  };
  const handleParty = (party) => {
    setNewCustomerOrderHistoryReport({
      ...newCustomerOrderHistoryReport,
      party,
    });
    //setParty(party);
  };
  const handleOrderTypes = (orderTypes) => {
    setNewCustomerOrderHistoryReport({
      ...newCustomerOrderHistoryReport,
      orderTypes,
    });
    //setOrderTypes(orderTypes);
  };
  const handlePaymentTypes = (paymentTypes) => {
    setNewCustomerOrderHistoryReport({
      ...newCustomerOrderHistoryReport,
      paymentTypes,
    });
    //setPaymentTypes(paymentTypes);
  };
  const handleGroupBy = (groupBy) => {
    setNewCustomerOrderHistoryReport({
      ...newCustomerOrderHistoryReport,
      groupBy,
    });
    //setGroupBy(groupBy);
  };

  //get BranchWise reports filter
  const getBranchWiseSelected = () => {
    setReportData(null);
    console.log(
      "newCustomerOrderHistoryReport ",
      newCustomerOrderHistoryReport
    );
    if (
      // newCustomerOrderHistoryReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null
    ) {
      setLoading(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-customer-order-report";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      console.log("formData ", formData);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("CustomerOrderHistoryReportResult", res);
          setReportData(res.data[0]);

          setLoading(false);
          console.log("Component ", componentRef);
          setGeneratedReport(true);
          componentRef.current.handleDownload();
          setNewCustomerOrderHistoryReport(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, to date and generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };
  let total_trans = 0;
  let total_discount = 0;
  let grand_sales = 0;

  return (
    <>
      <Helmet>
        <title>{_t(t("Customer Order History Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Customer Order History Report"
        sheet="sheet 1"
        buttonText="Sales Detail"
        ref={componentRef}
      />

      {/* Print Button */}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {"\
          @page { size: landscape; }\
        "}
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          {console.log("reportData", reportData)}
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="5" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Customer Order History Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Branch"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Phone"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Trans"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Discount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Sales"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {console.log("309 ", reportData)}
                                {/* loop here*/}
                                {Object.keys(reportData).map(
                                  (group_name, index) => {
                                    let discount = 0;
                                    let sales = 0;
                                    let trans = 0;
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-left"
                                          >
                                            {group_name}
                                          </th>
                                        </tr>
                                        {reportData[group_name].map(
                                          (item, i) => {
                                            discount += parseFloat(
                                              item.discount_amount
                                            );
                                            total_discount += parseFloat(
                                              item.discount_amount
                                            );
                                            sales += parseFloat(item.sales);
                                            grand_sales += parseFloat(
                                              item.sales
                                            );
                                            trans += parseFloat(item.trans);
                                            total_trans += parseFloat(
                                              item.trans
                                            );
                                            return (
                                              <tr>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.branch_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.customer_phn_no}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.trans}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.discount_amount}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.sales}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr>
                                          <th colspan="1"></th>
                                          <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                            Total
                                          </th>
                                          <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                            {formatPrice(trans)}
                                          </td>
                                          <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                            {formatPrice(discount)}
                                          </td>
                                          <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                            {formatPrice(sales)}
                                          </td>
                                        </tr>
                                      </div>
                                    );
                                  }
                                )}
                                <tr>
                                  <th colspan="1"></th>
                                  <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                    Grand Total
                                  </th>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(total_trans)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(total_discount)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(grand_sales)}
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Customer Order History Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          {/* <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={branchForSearch && branchForSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              //value={branch}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleBranch}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a branch")) + ".."}
                            />
                          </div> */}
                          {/* <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={foodGroupForSearch && foodGroupForSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              // value={group}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleGroup}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a food group")) + ".."}
                            />
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={foodForSearch && foodForSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              //value={item}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleItem}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a food items")) + ".."}
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={partyForSearch && partyForSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              //value={party}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleParty}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a party")) + ".."}
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={orderTypeList.data && orderTypeList.data}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              //value={orderTypes}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleOrderTypes}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a order types")) + ".."}
                            />
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={
                                paymentTypeForSearch && paymentTypeForSearch
                              }
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              //value={paymentTypes}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handlePaymentTypes}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(t("Select a payment type")) + ".."
                              }
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={groupByArray && groupByArray}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              //value={groupBy}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleGroupBy}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(t("Select a report group")) + ".."
                              }
                            />
                          </div> */}

                          {/* <div className="col-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2"
                              onClick={getBranchWiseSelected}
                            >
                              {_t(t("Generate Report"))}
                            </button>
                          </div> */}
                          <div className="col-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            {/* Action Button */}
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected(e)}
                            >
                              Print Customer Order History Report
                            </button>
                          </div>
                        </div>
                        <>
                          {/* <div className="row gx-2 justify-content-center t-pt-15">
                            <div className="col-12 mb-md-0">
                              <Chart
                                options={amountChart.options}
                                series={amountChart.series}
                                type="bar"
                                width="100%"
                                height="350px"
                              />
                            </div>
                          </div> */}

                          {/* {reportData !== null &&
                            reportData !== undefined &&
                            reportData.length > 0 && ( */}
                          {/* )} */}
                        </>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default CustomerOrderHistoryReport;
