import React, { useState, useContext, useEffect, useRef } from "react";
import Select from "react-select";
import ItemModal from "./ItemModal/ItemModal";
import { toast } from "react-toastify";
import moment from "moment";
import CustomerModal from "./ItemModal/CustomerModal";
import TableModal from "./ItemModal/TableModal";
import { Button } from "react-bootstrap";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { BASE_URL } from "../../../../../BaseUrl";
import { OrderPrint } from "./OrderPrint";
import PasscodeAuthenticator from "../PasscodeAuthenticator/PasscodeAuthenticator";
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ReactComponent as TriangleDown } from "../../../public/caret-down.svg";
import { ReactComponent as FP } from "../../../public/fp.svg";
//functions
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
  checkPermission,
  vatPercent,
  // restaurantMenuLink,
} from "../../../../../functions/Functions";

// custom styles
import "./pos.css";

import { NavLink, useParams, useHistory, useLocation } from "react-router-dom";
import SettleOrder from "./settleOrder/SettleOrder";
import Calculator from "../calc/Calculator";
import PosSelect from "./PosSelect";
import DropdownButton from "./DropdownButton";
import { FoodContext } from "../../../../../contexts/Food";
import {
  calculatePriceForCartItem,
  calculateSubTotal,
  createHash,
  getTax,
  getTaxedTotal,
  parseFoodItems,
  parseParties,
  getServiceCharges,
  getDiscount,
  calculatePriceForCartVariation,
  getInitialItemDiscount,
  getItemDiscount,
} from "./helpers";
import axios from "axios";
import CartItem from "./CartItem";
import "react-toastify/dist/ReactToastify.css";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import PosModal from "./ItemModal/PosModal";

gsap.registerPlugin(MotionPathPlugin);

function Pos() {
  // CONTEXTS
  const {
    //submitted orders
    // submittedOrders,
    // setWorkPeriodList,
    // workPeriodList,
    getRunningOrders,
    runningOrders,
    //work period
    workPeriodForSearch,
    setWorkPeriodListForSearch,
    workPeriodListForBranch,
    setWorkPeriodListForBranch,

    //branch
    branchForSearch,
    //table
    getTable,
    //dept-tag
    deptTagForSearch,
    //payment-type
    paymentTypeForSearch,
    getWorkPeriod,

    orderTypeList,
    //Scheme
    schemeForSearch,
    cardForSearch,
  } = useContext(RestaurantContext);

  const { loading, setLoading, generalSettings, showManageStock } =
    useContext(SettingsContext);

  const { authUserInfo, partyForSearch } = useContext(UserContext);

  const {
    //food
    foodForSearch,
    foodGroupForSearchForPOS,
    //food group
    //foodGroupForSearch,
    //setFoodGroupforSearch,
    getFoodGroup,
    getFoodGroupForPOS,
    foodForPOS,
    getOrderDetailsState,
    setGetOrderDetailsState,
    getOrderDetails,
    //property group
    propertyGroupForSearch,
    //food stock

    //food grop with menu
    getFoodGroupWithMenu,
    availableFoodGroups,
  } = useContext(FoodContext);

  const {
    //customer
    getCustomer,
    getWaiter,
    adminStaffForSearch,
    // getParty,
    // partyList,
  } = useContext(UserContext);

  const defaultImg = "https://menu.indolj.pk/upload/placholde-general2.png";

  const { t } = useTranslation();

  //edit order
  const { order_id } = useParams();
  const location = useLocation();

  const offline = (url) => {
    return !navigator.onLine && localStorage.getItem(url) != null;
  };
  let history = useHistory();
  const paymentOptions = ["Cash", "Card"];
  // STATES
  const [menu, setMenu] = useState({});
  const inputSearch = useRef();
  const itemAnimate = useRef(null);
  const flyStart = useRef(null);
  const animate = useRef(null);
  let order_hold = useRef(0);
  // overflow height style
  const scrollHeight = useRef();
  const taxHeight = useRef();
  const [heightScroll, setHeightScroll] = useState({
    actualHeight: "",
    overflowHeight: "",
  });
  const [taxScroll,setTaxScroll] = useState(null)
  const discountInput = useRef(true);
  const deliveryInput = useRef(true);

  //print bills
  const componentRef = useRef();
  const component2Ref = useRef();
  const cardModal = useRef();
  const con_Kot = useRef("");
  const staffSearch = useRef(false);
  const syncSettle = useRef(false);
  const commentFocus = useRef();

  //payment type for call center
  const [selectedPaymentTypecallCenter, setSelectedPaymentTypecallCenter] = useState("Cash");
  const [selectedSwitch, SetSelectedSwitch] = useState();
  const currentNavPill = (element) => {
    SetSelectedSwitch(element);
  };
  const [paymentType, setPaymentType] = useState("Cash");

  //online order counter
  const [onlineCounter, setOnlineCounter] = useState(0);
  const [foodPandaCounter, setFoodPandaCounter] = useState(0);
  const [readyOrderCounter, setReadyOrderCounter] = useState(0);

  //add comments
  const [specialInstruction, setSpecialInstruction] = useState(false);
  const [commentId, setCommentId] = useState();

  //customer api hit on cond
  const [customerApi, setCustomerApi] = useState(0);
  // muti address
  const [addressList, setAddressList] = useState([]);

  //checked variations
  //selected groups
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState([]);
  //checked properties
  //const [selectedProperties, setSelectedProperties] = useState([]);
  //print states
  const [foodGroupsToPrint, setFoodGroupsToPrint] = useState(null);

  //FBR Invoice Num
  const [fbrInvoiceNum, setFbrInvoiceNum] = useState(null);
  //FBR Invoice Nums
  const [srbInvoiceNum, setSrbInvoiceNum] = useState(null);
  const [userLock, setUserLock] = useState([]);
  const [kotPrintIdx, setKotPrintIdx] = useState(0);
  const [orderFoodGroups, setOrderFoodGroups] = useState(null);
  //card offer

  // edit delete states
  const [deleteReason, setDeleteReason] = useState("");
  const [addReasonModalShowing, setAddReasonModalShowing] = useState(null);
  const [deletedItemsOnEdit, setDeletedItemsOnEdit] = useState({});
  const [itemAdd, setItemAdd] = useState();
  const [editKot, setEditKot] = useState();
  const [editAdd, setEditAdd] = useState();
  //edit order state
  const [editOrder, setEditOrder] = useState(false);
  //print end
  // authenticate user
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [componentUnlock, setComponentUnlock] = useState("");
  //back btn modal
  const [backModal, setBackModal] = useState(false);
  //note rider btn modal
  const [noteRiderModal, setNoteRiderModal] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [selectedFoodItem, setSelectedFoodItem] = useState(null);
  const [selectedFoodItemId, setSelectedFoodItemId] = useState(null);
  //const [availableFoodGroups, setAvailableFoodGroups] = useState({});
  const [activeFoodGroup, setActiveFoodGroup] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [availableDeliveryTypes, setAvailableDeliveryTypes] = useState([]);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState();
  const [availableDeliveryParties, setAvailableDeliveryParties] = useState({});
  const [selectedParty, setSelectedParty] = useState();
  const [totalsettle, setTotalSettle] = useState(0);
  const [theVatSettle, setTheVatSettle] = useState(0);
  const [theVat, setTheVat] = useState(0);
  const [newOrder, setNewOrder] = useState(null);
  const [showSettle, setShowSettle] = useState(false);
  const [calculatorMenu, setCalculatorMenu] = useState(false);
  const [modal9Show, setModal9Show] = React.useState(false);
  const [modal10Show, setModal10Show] = React.useState(false);
  //cart calculation states
  const [cartItems, setCartItems] = useState({});
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [taxType, setTaxType] = useState("");
  const [selectedKot, setSelectedKot] = useState([]);
  const [paidMoney, setPaidMoney] = useState(0);
  const [deptCommission, setDeptCommission] = useState(0);
  const [newSettings, setNewSettings] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isSettle, setIsSettle] = useState(0);
  const [theTax, setTheTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [serviceCharges, setServiceCharges] = useState(0);
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);
 // const [deliveryTypeList, setDeliveryTypeList] = useState([]);
 // const [filteredDept, setFilteredDept] = useState(null);
  const [paymentTypeAvailable, setPaymentTypeAvailable] =
    useState(paymentTypeForSearch);
  const [activeItemInOrder, setActiveItemInOrder] = useState(null);
  const [tempSchemeForSearch, setTempSchemeForSearch] = useState(null);
  const [schemeForSearchObj, setSchemeForSearchObj] = useState(null);
  const [tempQty, setTempQty] = useState(0);
  const [selectGroup, setSelectGroup] = useState({});
  //table popup
  const [waiterSelected, setwaiterSelected] = useState(null);
  const [guestSelected, setGuestSelected] = useState(1);
  const [selectTable, setSelectTable] = useState({});
  const [tableId, setTableId] = useState(null);
  // customer popup
  const [customer, setCustomer] = useState({
    name:"",
    email:"",
    phn_no:"",
    address:"",
    ref_no:"",
    is_confirm: false,
  })
  //end
  const [pickedProperties, setPickedProperties] = useState({});
  const [searchedFoodItem, setSearchedFoodItem] = useState({
    searched: false,
    list: null,
  });

  // for bogo offers
  const [bogo, setBogo] = useState(0);
 // cash cheque pay in payout
  const [cashDetails, setCashDetails] = useState({
    work_period_id: null,
    branch_id: null,
    branch_name: null,
    party_id: null,
    party_name: null,
    payment_type: null,
    cheque_no: 0,
    cheque_date: null,
    payment_status: null,
    remarks: null,
    amount: 0,
  });
  // order details
  const [orderDetails, setOrderDetails] = useState({
    branch: null,
    customer: null,
    table: null,
    waiter: null,
    dept_tag: null,
    card_number: "",
    payment_type: null,
    payment_amount: null,
    total_guest: 1,
    table_id: null,
    table_name: null,
    newCustomer: true,
    service_amount: 0,
    note_to_rider:"",
    newCustomerInfo: {
      name: "",
      email: "",
      number: "",
      address: "",
      ref_no: "",
    },
    token: null,
    serviceCharge: 0,
    is_reservation: 0,
    discount: 0,
    scheme: {
      scheme_id: null,
      scheme_name: "",
      cap_amount: 0,
      discount_amount: null,
      open_discount: null,
      discount_type: "",
      how_much_discount: null,
    },
    orderTypeDetails: {
      orderTypeId: null,
      orderTypeName: "",
    },
    partyDetails: {
      partyId: null,
      partyName: "",
      partyPercent: null,
    },
  });

  // State hooks here
  const [foodItem, setFoodItem] = useState({
    foodGroup: null,
    items: null,
    selectedItem: null,
    variations: null,
    properties: null,
  });
  // payment type tax modal
  const [paymentTypeModalState, setPaymentTypeModalState] = useState({
    showModal: false,
    taxCalSelect: false,
    subTotal: 0,
    vat: 0,
    vatAmount: 0,
    serviceCharge: 0,
    serviceChargeAmount: 0,
    deliveryCharges: 0,
    discount: 0,
    totalPayable: 0,
    payment_type_name: "",
  });
  const [availableDeptTags, setAvailableDeptTags] = useState([]);
  const [selectedDeptTag, setSelectedDeptTag] = useState();
  console.log("kot1", orderDetails, newOrder);

  useEffect(() => {
    getRunningOrders();
    
  }, []);

  useEffect(() => {
    let heightScroll = scrollHeight.current?.offsetHeight;
    let actualHeight = scrollHeight.current?.scrollHeight;
    setHeightScroll({
      overflowHeight: actualHeight,
      actualHeight: heightScroll,
    });
  }, [editOrder, runningOrders]);

  useEffect(() => {
    let details = selectedSwitch?.getBoundingClientRect();
    const root = document.querySelector(":root");
    root.style.setProperty("--delivery-switcher-width", details?.width + "px");
    root.style.setProperty(
      "--delivery-switcher-height",
      details?.height + "px"
    );
    root.style.setProperty(
      "--delivery-switcher-left",
      selectedSwitch?.offsetLeft + "px"
    );
  }, [paymentType, selectedSwitch]);

  // PACKAGES SETTINGS OBJECT
  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };

  const emptyCardEdit = () => {

    setCartItems({});
    setTheVat(0);
    setTheTax(0);
    setDiscount(0);
    setServiceCharges(0);
    setSelectedParty();
    setSelectedDeliveryType();
    setCustomer({
      name:"",
      email:"",
      phn_no:"",
      address:"",
      ref_no:"",
      is_confirm: false,
    });
    setSelectTable({});
    setwaiterSelected(null);
    setUserLock([]);
    setGuestSelected(1);
    setOrderDetails({
      ...orderDetails,
      token: null,
      orderTypeDetails: {
        orderTypeId: null,
        orderTypeName: "",
      },
      partyDetails: {
        partyId: null,
        partyName: "",
        partyPercent: null,
      },
      customer: null,
      card_number: "",
      note_to_rider:"",
      newCustomerInfo: {
        name: "",
        email: "",
        number: "",
        address: "",
        ref_no: "",
      },
      serviceCharge: 0,
      discount: 0,
      scheme: {
        scheme_id: null,
        scheme_name: "",
        cap_amount: 0,
        discount_amount: null,
        open_discount: null,
        discount_type: "",
        how_much_discount: null,
      },
      total_guest: 1,
      is_reservation: 0,
      table: null,
      waiter: null,
      table_id: null,
      table_name: null,
    });
    discountInput.current = true;
    setSearchedFoodItem({
      searched: false,
      list: null,
    });
    setSearchInput("");
    setEditOrder(false);
    if (inputSearch.current !== undefined) inputSearch.current.value = "";
  }

  useEffect(() => {
    emptyCardEdit();
    if(!order_id && orderDetails?.branch){
      setTheTax(orderDetails.branch.branch_tax)
    }
    if (
      order_id &&
      (authUserInfo?.details?.user_type === "admin" ||
        authUserInfo?.details?.user_type === "superAdmin")
    ) {
      setSelectedBranch(null);
      setNewSettings({
        vat: null,
        workPeriod: null,
      });
    }
    

    if (authUserInfo.permissions !== null) {
      if (checkPermission(authUserInfo.permissions, "Order edit")) {
        if (order_id) {
          getOrderDetails(order_id).then((data) => {
            setTimeout(function () {
              partyForSearch && setStatesOnEditOrder(data);
              // axiosForItemGroupByDept(data);
            }, 500);

            handleEditOrder(data);
          });
        }
      }
    }
  }, [order_id, authUserInfo, partyForSearch]);

  useEffect(() => {
   

    // const deliveryTypes = [];
    // let orderTypeListData = [];
    // let parties = [];
    // for (const key in orderTypeList && orderTypeList.data) {
    //   if (orderTypeList.data[key].status == "On") {
    //     orderTypeListData = orderTypeList.data[key];
    //     for (const key in partyForSearch) {
    //       // if(partyForSearch[key].show_header){
    //       // console.log("partyyyyyyy: ", partyForSearch[key].show_header)
    //       if (
    //         partyForSearch[key].associate_with.includes(orderTypeListData.id) &&
    //         partyForSearch[key].show_header == "1"
    //       ) {
    //         parties.push(partyForSearch[key]);
    //       }
    //       // }
    //     }
    //     orderTypeListData["parties"] = parties;
    //     parties = [];
    //     deliveryTypes.push(orderTypeListData);
    //   }
    // }
    // setDeliveryTypeList(deliveryTypes);

    let theDefaultMenu =
      deptTagForSearch &&
      deptTagForSearch.find((menu) => {
        return menu.is_default_menu;
      });

    if (!order_id) {
      theDefaultMenu &&
        setOrderDetails({
          ...orderDetails,
          dept_tag: theDefaultMenu,
        });
    }

    if (authUserInfo.details) {
      let theBranch =
        branchForSearch &&
        branchForSearch.find((branch) => {
          return parseInt(authUserInfo.details.branch_id) === branch.id;
        });

      //set work period
      //let theWorkPeriod = null;
      let theWorkPeriod = newSettings?.workPeriod;
      if (theBranch !== undefined && theBranch !== null) {
        theWorkPeriod =
         workPeriodListForBranch &&
         workPeriodListForBranch.find((tempWorkPeriod) => {
            return (theBranch.id === parseInt(tempWorkPeriod.branch_id)) && tempWorkPeriod.ended_at === null;;
          });

        //filter it with ended_at === null;
        // theWorkPeriod =
        //   theWorkPeriod &&
        //   theWorkPeriod.find((endAtNullItem) => {
        //     return endAtNullItem.ended_at === null;
        //   });
        setTimeout(() => {
          // set branch for staff
          setOrderDetails({
            ...orderDetails,
            branch: theBranch,
          });
          setTheTax(theBranch.branch_tax);
          setCashDetails({
            ...cashDetails,
            branch_id: theBranch && theBranch.id,
            branch_name: theBranch && theBranch?.name,
            work_period_id: theWorkPeriod && theWorkPeriod?.id,
          });
        }, 30);
      }
      // call center if already branch
      if (authUserInfo?.details?.user_type === "call_center") {
        theBranch && setSelectedBranch(theBranch);
      }
      //set work period here

      setNewSettings({
        ...newSettings,
        //vat: generalSettings && getSystemSettings(generalSettings, "type_vat"),
        vat: theTax !== 0 ? theTax : theBranch?.branch_tax,
        workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
      });
    } else {
      // user type admin
      let theBranch =
        branchForSearch &&
        branchForSearch.find((branch) => {
          return parseInt(authUserInfo.details.branch_id) === branch.id;
        });
      // call center if already branch
      if (authUserInfo?.details?.user_type === "call_center") {
        theBranch && setSelectedBranch(theBranch);
      }
      setNewSettings({
        ...newSettings,
        vat:
          theTax !== 0
            ? theTax
            : theBranch?.branch_tax === undefined
            ? 0
            : theBranch.branch_tax,
        // vat: 10,
      });
    }

  }, [
    authUserInfo,
    generalSettings,
    // foodGroupForSearch,
    foodGroupForSearchForPOS,
    orderTypeList,
  ]);

   // empty card 
  const emptyCardOnChange = () =>{
     // cant empty card if card has item on edit
  if (order_id) {
    if(!buttonDisabled && cartItems && Object.keys(cartItems)?.length > 0){
      toast.error("Please complete the current order");
    }
  } else {
    setOrderDetails({
      ...orderDetails,
      orderTypeDetails: {
        orderTypeId: selectedDeliveryType ? selectedDeliveryType.id : null,
        orderTypeName: selectedDeliveryType ? selectedDeliveryType.name : "",
      },
      partyDetails: {
        partyId: selectedParty ? selectedParty.id : null,
        partyName: selectedParty ? selectedParty.name : "",
        partyPercent: selectedParty ? selectedParty.receivable_amount : "",
        partyRefNo: selectedParty ? selectedParty.show_ref_no : "",
      },
      note_to_rider:"",
      card_number:"",
      token: null,
      newCustomerInfo: {
        name: "",
        email: "",
        number: "",
        address: "",
        ref_no: "",
      },
      table: null,
      waiter: null,
      total_guest: 1,
      is_reservation: 0,
      table_id: null,
      table_name: null,
      serviceCharge: 0,
      discount: 0,
      scheme: {
        scheme_id: null,
        scheme_name: "",
        cap_amount: 0,
        discount_amount: null,
        open_discount: null,
        discount_type: "",
        how_much_discount: null,
      },
    });
    setTheVat(0);
    setCartSubTotal(0);
    setServiceCharges(0);
    setDiscount(0);
    setwaiterSelected(null);
    setCartItems({});
    setCustomer({
      name:"",
      email:"",
      phn_no:"",
      address:"",
      ref_no:"",
      is_confirm: false,
    });
    setSelectTable({});
    setGuestSelected(1);
    setBogo(0);
    discountInput.current = true;
  }
  setSearchedFoodItem({
    searched: false,
    list: null,
  });
  setSearchInput("");
  if (inputSearch.current !== undefined) inputSearch.current.value = "";
  }

  useEffect(() => {
    //set branch tax
    if (orderDetails.branch) setTheTax(orderDetails.branch.branch_tax);
    else setTheTax(0);
    // empty card
    emptyCardOnChange()
      
    if (!order_id) {
      setSelectedDeliveryType();
      setSelectedParty();
    }
  }, [selectedBranch]);

  // const getTableWithStatus1 = () => {
  //   const pendingUrl = BASE_URL + "/settings/get-table-status";
  //   return axios
  //     .get(pendingUrl, {
  //       headers: { Authorization: `Bearer ${getCookie()}` },
  //     })
  //     .then((res) => {
  //       let tempTables = res.data.filter((eachTable) => {
  //         //let isReserved = !order_id ? eachTable.status === "0" : 1;
  //         return (
  //           !order_id &&
  //           parseInt(eachTable.branch_id) === orderDetails.branch.id &&
  //           eachTable.status === "0"
  //         );
  //       });
  //       setGetTable(tempTables);
  //     })
  //     .catch((err) => {});
  // };

  useEffect(() => {
    if (schemeForSearch && schemeForSearch.length) {
      const obj = {};
      schemeForSearch.map((scheme) => {
        obj[parseInt(scheme.id)] = scheme;
      });
      setSchemeForSearchObj(obj);
    }
  }, [schemeForSearch]);

  //get current scheme obj for scheme
  const SchemeSearch = useRef();
  SchemeSearch.current = schemeForSearchObj;
  // set states on editorder
  function setStatesOnEditOrder(data) {
    console.log("Editdata : ", data);
    // handleSetGroupAndItem(
    //   foodGroupForSearchForPOS && foodGroupForSearchForPOS[0],
    //   data.dept_tag
    // );

    setGetOrderDetailsState(data);
    //cal vat percent order
    let tempVat = parseFloat(data.theVat);
    let vatPercentage = vatPercent(parseFloat(data.subTotal), tempVat);

    setNewSettings({
      ...newSettings,
      vat: vatPercentage,
      workPeriod: data.workPeriod,
    });

    const branchFull =
      branchForSearch && branchForSearch.find(({ id }) => id == data.branch.id);
    //set vat percent
    let vat_Percent = !isNaN(vatPercentage)
      ? parseFloat(vatPercentage)
      : branchFull.branch_tax;
    setTheTax(vat_Percent);
    // add one extra field in formdata if card offer
    setOrderDetails({
      branch: {
        ...data.branch,
        ...(branchFull || {}),
      },
      discount: data.discount,
      card_number: data.card_number,
      customer: Object.keys(data.customer).length > 0 ? data.customer : null,
      orderTypeDetails: data.orderTypeDetails,
      partyDetails: data.partyDetails,
      note_to_rider: orderDetails.note_to_rider,
      table: data?.table.length !== 0 ? data.table : null,
      waiter: data?.waiter.length !== 0 ? data?.waiter : null,
      dept_tag: data.dept_tag,
      dept_commission: data.dept_commission,
      payment_type: data.payment_type.length !== 0 ? data.payment_type : null,
      payment_amount: data.payment_amount
        ? parseFloat(data.payment_amount)
        : data.payment_amount,
      total_guest: parseInt(data.total_guest),
      is_reservation: parseInt(data?.is_reservation),
      table_id: data?.table_id ? JSON.parse(data.table_id) : data?.table_id,
      table_name: data?.table_name,
      newCustomer: data.newCustomer,
      newCustomerInfo:
        Object.keys(data.newCustomerInfo).length > 0
          ? data.newCustomerInfo
          : null,
      token: data.token,
      serviceCharge: parseFloat(data.serviceCharge),
      service_amount: data.service_amount,
      discount_amount: data.discount_amount,
      scheme: data.scheme.id !== null ? {
        ...data.scheme,
        cap_amount: parseFloat(data.scheme.cap_amount),
        discount_amount: parseFloat(data.scheme.discount_amount),
      } : data.scheme,
        // (data.scheme.card_required === 1 || data.scheme.card_required === "1")
        //   ? (!data.scheme || !data.scheme?.scheme_id) && data.discount_amount
        //     ? {
        //         ...data.scheme,
        //         discount_amount: data.discount_amount,
        //         cap_amount: parseFloat(data.scheme.cap_amount),
        //       }
        //     : {
        //         ...data.scheme,
        //         cap_amount: data.scheme?.cap_amount
        //           ? parseFloat(data.scheme?.cap_amount)
        //           : 0,
        //       }
        //   : (!data.scheme || !data.scheme?.scheme_id) && data.discount_amount
        //   ? { ...data.scheme, discount_amount: data.discount_amount }
         // : data.scheme,
    });
    if(data.scheme.open_discount){
      setDiscount(data.scheme.discount_amount)
      discountInput.current = false;
    }else{
      discountInput.current = true;
    }
   
    setDeliveryCharges(data.serviceCharge);
    let schemeObj = SchemeSearch.current;
    // filter scheme manual
    const appliedScheme =
      data.scheme && data.scheme.scheme_id && schemeObj
        ? schemeObj[parseInt(data.scheme.scheme_id)]
        : null;
    // set data on cartItems
    const item = data.orderItems;
    for (const key in item) {
      const card = {
        ...item[key].item,
        price: item[key].item.price
          ? item[key].item.price
          : item[key].variation.food_with_variation_price,
      };
      const selectedCard = {
        ...item[key],
        item: card,
      };
      if (
        !selectedCard.discount &&
        parseFloat(selectedCard.item_discount) > 0
      ) {
        if(appliedScheme && appliedScheme.card_required){
          selectedCard.discount = data.scheme.how_much_discount
          ? parseFloat(data.scheme.how_much_discount)
          : 0;
        }else if (appliedScheme) {
          selectedCard.discount = appliedScheme.how_much_discount
            ? parseFloat(appliedScheme.how_much_discount)
            : 0;
        }else if (selectedCard.scheme_id) {
          const appliedSchemeDefault = schemeObj
            ? schemeObj[parseInt(selectedCard.scheme_id)]
            : 0;
          selectedCard.discount = appliedSchemeDefault?.how_much_discount
            ? parseFloat(appliedSchemeDefault.how_much_discount)
            : 0;
        }
      }
      let cardProperties = {};
      for (const index in selectedCard.properties) {
        const Properties = selectedCard.properties[index];
        let Propertygroup = data?.selected_property_group_ids
          ? data?.selected_property_group_ids[key][index]
          : Properties.item.property_group_id.replace(/[\[\]']+/g, "");
        const propertyId = Properties.item.id;
        cardProperties[Propertygroup] = cardProperties[Propertygroup]
          ? {
              ...cardProperties[Propertygroup],
              [propertyId]: { ...Properties },
            }
          : { [propertyId]: Properties };
      }
      let items;
      let itemObj = {
        item_discount: parseFloat(selectedCard.item_discount),
        after_tax_price: parseFloat(selectedCard.after_tax_price),
        discount: parseFloat(selectedCard.discount),
      }
      if (card.has_property === "1" && card.has_variation === "0") {
        items = {
          ...selectedCard,
          ...itemObj,
          variation: null,
          properties: cardProperties ? cardProperties : null,
        };
      } else if (card.has_property === "1") {
        items = {
          ...selectedCard,
          ...itemObj,
          properties: cardProperties ? cardProperties : null,
        };
      } else if (card.has_variation === "0") {
        items = {
          ...selectedCard,
          ...itemObj,
          variation: null,
        };
      } else {
        items = {
          ...selectedCard,
          ...itemObj,
        };
      }
      const orderItem = [];
      orderItem.push({
        ...items,
      });
      const hashId = createHash(items);
      items &&
      setEditKot((curr) => {
        const newData = {
          ...curr,
          [hashId]: {
            ...items,
            new: false,
          },
        };
        return newData;
      });
        setTimeout(() => {
          setCartItems((curr, edit = false) => {
            const newData = {
              ...curr,
              [hashId]: {
                ...items,
                new: false,
                seq: Object.keys(cartItems)?.length + 1,
              },
            };
            if (edit) newData[items.item.id] = item;
            return newData;
          });
        }, 50);
    }

    if (
      history.location &&
      history.location !== "" &&
      (history.location?.search === "?waiter=edit" ||
        history.location?.search === "?table=edit_transfer" ||
        history.location?.search === "?table=edit_merge")
    ) {
      setModal10Show(true);
    }
    setButtonDisabled(false);
  }

  // //axios request for submit
  // const axiosForItemGroupByDept = (orderData) => {
  //   const pendingUrl = BASE_URL + "/settings/get-food-group-with-menu";
  //   return axios
  //     .get(pendingUrl, {
  //       headers: { Authorization: `Bearer ${getCookie()}` },
  //     })
  //     .then((res) => {
  //       // save data to local
  //       setAvailableFoodGroups(res.data);
  //       setDataToLocal(pendingUrl, res.data);

  //       if (order_id) {
  //         //set New Food Group Menu Wise
  //         //let tempFoodGroup = res.data[orderData.dept_tag.id];
  //         // setShowFilterFoodGroupsByDept(tempFoodGroup);

  //         // // setInitialFoodItem();
  //         handleFilterFoodGroup(orderData.dept_tag);
  //         //handleSetGroupAndItem(tempFoodGroup[0], orderData.dept_tag);
  //       }
  //     })
  //     .catch((err) => {
  //       if (offline(pendingUrl)) {
  //         let parseRes = getDataFromLocal(pendingUrl);
  //         setAvailableFoodGroups(parseRes);
  //       }
  //     });
  // };

  // const handleSetGroupAndItem = (groupItem, deptTag) => {
  //   let tempItems =
  //     foodForPOS &&
  //     foodForPOS.filter((tempItem) => {
  //       let $ifExits = tempItem.food_groups.includes(groupItem.id);
  //       let ifExits2 = tempItem.menu_ids.includes(
  //         deptTag ? deptTag.id : orderDetails?.dept_tag.id
  //       );
  //       if ($ifExits === true && ifExits2 === true) {
  //         return parseInt(groupItem.id) === groupItem.id;
  //       }
  //     });
  //   if (tempItems && tempItems.length > 0) {
  //     setFoodItem({
  //       ...foodItem,
  //       foodGroup: groupItem,
  //       items: tempItems,
  //       selectedItem: tempItems && tempItems[0],
  //       variations:
  //         tempItems && parseInt(tempItems[0].has_variation) === 1
  //           ? tempItems[0].variations
  //           : null,
  //       properties:
  //         tempItems && parseInt(tempItems[0].has_property) === 1
  //           ? tempItems[0].properties
  //           : null,
  //     });
  //   } else {
  //     setFoodItem({
  //       foodGroup: groupItem,
  //       items: null,
  //       selectedItem: null,
  //       variations: null,
  //       properties: null,
  //     });
  //   }
  // };

  //dynamic style
  const style = {
    logo: {
      backgroundColor:
        generalSettings &&
        getSystemSettings(generalSettings, "type_background"),
      backgroundImage:
        generalSettings &&
        `url(${getSystemSettings(generalSettings, "type_logo")})`,
    },
    currency: {
      backgroundColor:
        generalSettings && getSystemSettings(generalSettings, "type_clock"),
      color:
        generalSettings && getSystemSettings(generalSettings, "type_color"),
    },
  };

  useEffect(() => {
    const timeOut = getSystemSettings(generalSettings, "order_time") ? (parseInt(getSystemSettings(generalSettings, "order_time")) * 60 * 1000) : 120000;
   
    const interval = setInterval(() => {
      if (
        getSystemSettings(generalSettings, "online_order_status") === "1" &&
        getSystemSettings(generalSettings, "online_order_status") !== undefined
      ) {
        const pendingUrl = BASE_URL + "/website/pending-order";
        return axios
          .get(pendingUrl, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            if (res.data.length) {
              const [online, ready,foodPanda] = res.data;
              localStorage.setItem("online_order", online);
              let played = false;
              if (authUserInfo?.details?.user_type === "call_center")
                played = true;
              let order = document.getElementById("myAudioPhoneRing");
              setOnlineCounter((currOnline) => {
                if (online !== currOnline) {
                  if (!played) {
                    order.play();
                    played = true;
                  }
                  return online;
                }
                online > 0 && order.play();
                return currOnline;
              });
              setFoodPandaCounter((currFoodOnline) => {
                if (foodPanda !== currFoodOnline) {
                  if (!played) {
                    order.play();
                    played = true;
                  }
                  return foodPanda;
                }
                foodPanda > 0 && order.play();
                return currFoodOnline;
              });
              setReadyOrderCounter((currReady) => {
                if (ready !== currReady) {
                  if (!played) {
                    order.play();
                    played = true;
                  }
                  return ready;
                }
                ready > 0 && order.play();
                return currReady;
              });
            }
          })
          .catch((err) => {});
      }

    }, 120000);
    return () => {
      clearInterval(interval);
    };
  }, [getSystemSettings(generalSettings, "online_order_status")]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
      
  //     let api =  authUserInfo.details.user_type === "call_center"
  //                ? "/settings/new-callcenter-order"
  //                : "/settings/new-order";

  //     let url = BASE_URL + api;
  //     let pendingOrder = JSON.parse(localStorage.getItem("orderplaced"));
  //     if (navigator.onLine && pendingOrder && pendingOrder?.length > 0) {
  //       setLoading(true);
  //       syncLocalOrders(url);
  //     }
  //   }, 9000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // validate addon Qty //

  const handleEditOrder = (data) => {
    console.log("ar-log data", data);
    setButtonDisabled(true);
   // setSelectedProperties(data?.selected_properties_ids);
    setSelectedPropertyGroup(data?.selected_property_group_ids);
    const deptTagId = data.dept_tag.id;
    if ((deptTagId !== null || deptTagId !== undefined) && deptTagForSearch) {
      const dept_tag = deptTagForSearch?.find((dept) => {
        return dept.id === deptTagId;
      });
      dept_tag && setSelectedDeptTag(dept_tag);
    }

    const ordertype = data.orderTypeDetails.orderTypeId;
    if ((ordertype !== null || ordertype !== undefined) && orderTypeList) {
      // const orderTypeStatus = orderTypeList.data.find(
      //   (order) => order.status === "On"
      // );
      const deliveryType = orderTypeList.data.find((delivery) => {
        return delivery.id === ordertype && delivery.status === "On";
      });
      setSelectedDeliveryType(deliveryType);
    }
    const partyId = data.partyDetails.partyId;
    if ((partyId !== null || partyId !== undefined) && partyForSearch) {
      const partyType = partyForSearch?.find((party) => {
        return party.id === partyId;
      });
      partyType && setSelectedParty(partyType);
    }
    const branchFull =
      branchForSearch && branchForSearch.find(({ id }) => id == data.branch.id);
    handleSetBranchOnEdit(branchFull);
    //handleFilterDept(data.branch.id, data.orderTypeDetails.orderTypeId);
  };

  useEffect(() => {
    // when partyForSearch is recieved from context and
    // sets all the party types whose show header is '1' and filters them according to the ids it is
    // associated with
    if (
      partyForSearch &&
      partyForSearch.length &&
      !Object.keys(availableDeliveryParties).length
    ) {
      const parties = parseParties(partyForSearch);
      setAvailableDeliveryParties(parties);
    }
  }, [partyForSearch]);

  useEffect(() => {
    // when orderTypeList is recieved from context and
    // sets all the deliver types whose status is 'on'
    if (
      orderTypeList &&
      orderTypeList.data &&
      orderTypeList.data.length &&
      !availableDeliveryTypes.length
    ) {
      const filteredDeliveryTypes = orderTypeList.data.filter(
        (order) => order.status === 'On'
      );
      if (filteredDeliveryTypes.length > 0) {
        setAvailableDeliveryTypes(filteredDeliveryTypes);
      }
      // setAvailableDeliveryTypes(
      //   orderTypeList.data.filter((order) => order.status === "On")
      // );
    }
  }, [orderTypeList]);

  const handleFilterDept = (branch_id = null, order_type = null, type=null) =>{
    if (deptTagForSearch && orderDetails.branch && branch_id) {
      let deptartmentTag;
      if(type && type.match(/order(.*)Type/i) && branch_id){
        deptartmentTag = deptTagForSearch?.filter((deptTag) => {
          return (
            JSON.parse(deptTag.branch_id).includes(branch_id) &&
            JSON.parse(deptTag.associate_with).includes(order_type)
          );
        });
      }else if(order_type && branch_id){
         deptartmentTag = deptTagForSearch.filter((deptTag) => {
          return (
            JSON.parse(deptTag.branch_id).includes(branch_id) &&
             JSON.parse(order_type).includes(deptTag.id)
          );
        });
      }else if(branch_id){
          deptartmentTag = deptTagForSearch.filter((deptTag) => {
          return (
            JSON.parse(deptTag.branch_id).includes(branch_id)
          );
        });
      }
      const deptartmentTag1 = deptartmentTag?.find(
        (deptTag) => deptTag.is_default_menu === 1
      );
      const finalDeptTag = deptartmentTag1
        ? deptartmentTag1
        : deptartmentTag[0];
      setAvailableDeptTags(deptartmentTag);
      !buttonDisabled && setSelectedDeptTag(finalDeptTag);
    } else {
      const deptartmentTag = deptTagForSearch && deptTagForSearch?.filter(
        (deptTag) => deptTag.is_default_menu === 1
      );
      deptartmentTag && setAvailableDeptTags(deptartmentTag);
      !buttonDisabled &&
        setSelectedDeptTag(deptartmentTag && deptartmentTag[0]);
    }
  }

  useEffect(() => {
    // once delivery type is set, sets all the available dept tags which are associated with the delivery type
    // or if delivery type is not set, only branch is, sets all the available dept tags which are default
    // selects the first dept tag as a default
    // console.log(orderDetails);
    if (deptTagForSearch && deptTagForSearch.length) {
      if (
        selectedDeliveryType &&
        selectedDeliveryType.id &&
        orderDetails.branch
      ) {
         handleFilterDept(orderDetails.branch.id,selectedDeliveryType.id,"order type")

        setTempSchemeForSearch(
          schemeForSearch && schemeForSearch.length
            ? schemeForSearch.filter((scheme) => {
                return (
                  JSON.parse(scheme.branch_id).includes(
                    orderDetails.branch.id
                  ) &&
                  JSON.parse(scheme.order_type_id).includes(
                    selectedDeliveryType.id
                  ) &&
                  scheme.status == "1" &&
                  scheme.scheme_type == "manual_apply"
                );
              })
            : []
        );
      }
    }

    // when delivery type is changed(if selectedParty exists, it does after delivery type is set), sets party to null
    if (selectedDeliveryType && selectedParty) setSelectedParty(null);
    const deliveryChargesLock =  parseInt(getSystemSettings(generalSettings, "delivery_charges_lock"));
    let deliveryCharges = parseFloat(getSystemSettings(generalSettings, "delivery_charges"));
    // set delivery charges if applicable
    if (selectedDeliveryType && selectedDeliveryType.name.match(/delivery/i)) {
      if (deliveryChargesLock === 0
      ) {
        deliveryInput.current = false;
        setDeliveryCharges(deliveryCharges);
      } else {
        deliveryInput.current = true;
        setDeliveryCharges(deliveryCharges);
      }
    } else {
      deliveryInput.current = true;
      setDeliveryCharges(0);
    }
    // cant empty card if card has item on edit
    emptyCardOnChange()
    if (
      selectedDeliveryType && selectedDeliveryType.name.match(/dine(.*)in/i)
    ) {
      getTable();
      getWaiter();
    }
  }, [deptTagForSearch, selectedDeliveryType]);

  useEffect(() => {
     if(orderDetails.branch && selectedParty){
      handleFilterDept(orderDetails.branch?.id,selectedParty?.associate_with_menu)
     }else if(!order_id && orderDetails.branch){
      handleFilterDept(orderDetails.branch?.id); 
     }
     
     if (selectedParty && selectedParty?.show_header === "1") {
      
      if(!order_id){
        const staffWithCallCenter = adminStaffForSearch && adminStaffForSearch.find(staff => staff.user_type === "call_center");
        if (staffWithCallCenter) {
          staffSearch.current = true;
        }

        if (
          staffSearch.current &&
          authUserInfo.details.user_type === "staff" &&
          selectedParty?.for_cc
        ) {
          toast.error(`${_t(t("Invalid party for Staff User"))} ` + selectedParty?.name, toastObj);
          setSelectedParty(null);
        }

        if (
          authUserInfo.details.user_type === "call_center" &&
          !selectedParty?.for_cc
        ) {
          toast.error(`${_t(t("Invalid party for Call Center"))} ` + selectedParty?.name, toastObj);
          setSelectedParty(null)
        }
      }
      if (selectedParty?.associate_with_payment_type !== null) {
        let tempAvailablePaymentType =
          paymentTypeForSearch &&
          paymentTypeForSearch.filter((paymentType) => {
            return JSON.parse(selectedParty?.associate_with_payment_type).includes(
              paymentType.id
            );
          });
        setPaymentTypeAvailable(tempAvailablePaymentType);
      }
     
    }
    if(selectedParty){
       // cant empty card if card has item on edit
       emptyCardOnChange()
    }
      /// open table pop auto if dine in
    if( selectedParty && selectedDeliveryType.name.match(/dine(.*)in/i) &&
      parseInt(getSystemSettings(generalSettings, "table_waiter")) === 1 && !order_id ){
        setModal10Show(true);
      }else if(selectedParty && selectedDeliveryType.name.match(/delivery/i) && !order_id){
        setModal9Show(true)
      }

      if (
        order_id &&
        schemeForSearch &&
        selectedDeliveryType &&
        orderDetails?.branch
      ) {
        setTempSchemeForSearch(
          schemeForSearch && schemeForSearch.length
            ? schemeForSearch.filter((scheme) => {
                return (
                  JSON.parse(scheme.branch_id).includes(orderDetails.branch.id) &&
                  JSON.parse(scheme.order_type_id).includes(
                    selectedDeliveryType.id
                  ) &&
                  scheme.status == "1" &&
                  scheme.scheme_type == "manual_apply"
                );
              })
            : []
        );
      }

  }, [selectedParty, orderDetails?.branch]);


  useEffect(() => {
    // parses and sets the menu when all food items are available
    if (foodForPOS && foodForPOS.length && !Object.keys(menu).length) {
      setMenu(parseFoodItems(foodForPOS));
    }
  }, [foodForPOS]);

  //set search if group change
  // useEffect(() => {
  //   setSearchedFoodItem({
  //     searched: false,
  //     list: null,
  //   });
  //   setSearchInput("");

  //   if (inputSearch.current !== undefined) inputSearch.current.value = "";
  // }, [activeFoodGroup]);

  //set food group
  useEffect(() => {
    if (
      !selectedDeptTag ||
      !selectedDeptTag.id ||
      !Object.keys(availableFoodGroups).length
    )
      return;
    availableFoodGroups[selectedDeptTag?.id] &&
      setActiveFoodGroup(availableFoodGroups[selectedDeptTag.id][0]?.id);

    let tempCommission = 0;
    if (selectedDeptTag && selectedDeptTag) {
      tempCommission = selectedDeptTag.commission;
      setDeptCommission(tempCommission);
    }
    // cant empty card if card has item on edit
    emptyCardOnChange()
  }, [selectedDeptTag]);

  useEffect(() => {
    if (
      !selectedDeptTag ||
      !selectedDeptTag.id ||
      !Object.keys(availableFoodGroups).length
    )
      return;
    availableFoodGroups[selectedDeptTag?.id] &&
      setActiveFoodGroup(availableFoodGroups[selectedDeptTag.id][0]?.id);
  }, [availableFoodGroups, orderDetails?.branch]);

  useEffect(() => {
    // cal tax subtotal and total
    const Vat = getTax(theTax, cartSubTotal);
    let serviceCharge = 0;
    let isServiceCharge = orderDetails?.branch && orderDetails.branch?.service_fees &&
    orderDetails?.total_guest >= orderDetails.branch.service_fees_person && selectedDeliveryType.name.match(/dine(.*)in/i)
    if (
      isServiceCharge
    ) {
      const subtotalTax = parseFloat(
        (parseFloat(cartSubTotal) + parseFloat(Vat)).toFixed(2)
      );
      serviceCharge = getServiceCharges(
        subtotalTax,
        orderDetails.branch.service_fees
      );
    }
    setServiceCharges(serviceCharge);
    let delivery = deliveryCharges ? parseFloat(deliveryCharges) : 0;
    const carttotal =
      getTaxedTotal(calculateSubTotal(cartItems), theTax, taxType) +
      delivery +
      parseFloat(serviceCharge) -
      discount;
    setCartTotal(carttotal.toFixed(2));

  }, [
    cartSubTotal,
    theVat,
    orderDetails?.total_guest,
    discount,
    deliveryCharges,
  ]);

  const Cartanimation = () =>{
   
    if(cartItems[itemAnimate.current] ){
      let blink =  document.getElementById("cart-animate");
      if(animate.current === true){
        const parentPos = flyStart.current?.getBoundingClientRect();
         let rect = cartItems[itemAnimate.current].item.image; 
         const childPos = blink?.getBoundingClientRect();
         let childY = childPos.top > 410 ? 410 : childPos.top;
         const distanceX = (childPos.left) - parentPos.left;
         const distanceY = (childY - 7) - parentPos.top;
         const virtualDiv = document.createElement('div');
         virtualDiv.style.position = 'absolute';
         virtualDiv.style.top = `${parentPos.top}px`;
         virtualDiv.style.left = `${parentPos.left}px`;
         virtualDiv.style.width = `${parentPos.width}px`;
         virtualDiv.style.height = `${parentPos.height}px`;
        const image = new Image();
        image.src = rect; // Replace with the path to your image
        image.style.height = '50px';
        //image.style.width = '50%';
        image.style.borderRadius = '50%';
        virtualDiv.appendChild(image);
        document.body.appendChild(virtualDiv);
         gsap.to(virtualDiv, {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: -35, y: -30 },
              { x: `${distanceX}px`, y: `${distanceY}px` },
            ],
            curviness: 1.5,
          },
          duration: .7,
         });
  
         setTimeout(() => {        
           blink.classList.add("blink");
         }, 200);
        // gsap.to(virtualDiv, { x: distanceX, y: distanceY, duration: .8 });
        
        setTimeout(() => {
          blink.classList.remove("blink");
          document.body.removeChild(virtualDiv);
          gsap.set(flyStart.current, { x: 0, y: 0 });
         }, 600);
       }else{

        if(animate.current == "add"){
          blink.classList.add("blink");
        }else if(animate.current == "del"){
          blink.classList.add("delBlink");
        }  

       setTimeout(() => {
         if(animate.current == "add"){
           blink.classList.remove("blink");
          }else if(animate.current == "del"){
            blink.classList.remove("delBlink");
          }
        }, 350);
       }
      }
     }

  useEffect(() => {
    // whenever cart items change, update all cart totals
    let dis = 0;
    if (!Object.keys(cartItems).length) {
      setCartSubTotal(0);
      setCartTotal(0);
      setTheVat(0);
      setDiscount(0);
    } else {
      const total = calculateSubTotal(cartItems);
      let subtotal = getBeforeTaxPrice(total);
      dis = Object.values(cartItems).reduce(
        (total, item) => item.item_discount + total,
        0
      );
      setCartSubTotal(getBeforeTaxPrice(total).toFixed(2));
      setTheVat(getTax(theTax, subtotal).toFixed(2));

      if(orderDetails.scheme?.cap_amount && orderDetails.scheme.cap_amount > 0 && orderDetails.scheme.cap_amount < dis){
        dis = orderDetails.scheme.cap_amount;
      }

      let discountPercent = orderDetails.scheme?.how_much_discount;

      // meezan card discount
        // let subtotalValue = parseFloat(subtotal?.toFixed(2));
        // let discountAmount = ((subtotalValue / 100) * parseFloat(orderDetails.discount))?.toFixed(2);
        
      //   if (
      //     (orderDetails?.capAmountMet === false || orderDetails?.capAmountMet === true) && orderDetails.discount
      //     ) {
      //   let capAmount = orderDetails.scheme?.cap_amount;
      //   let capAmountMet = false;
      //   dis = discountAmount;
      //   if (
      //     capAmount > 0 &&
      //     capAmount < parseFloat(dis)
      //   ) {
      //     setDiscount(capAmount);
      //     capAmountMet = true
      //   } else {
      //     setDiscount(dis);
      //   }
      //   setOrderDetails({
      //     ...orderDetails,
      //     capAmountMet: capAmountMet,
      //     scheme: {
      //       ...orderDetails.scheme,
      //       discount_amount: dis,
      //     },
      //   });
      // } else {
       
      // }
      if(!orderDetails.scheme.open_discount){
        setDiscount(dis.toFixed(2));
      }
      setOrderDetails({
        ...orderDetails,
        discount: orderDetails.scheme && discountPercent ? orderDetails.scheme.discount_type?.toLowerCase() === "fixed"
            ? 0 : discountPercent
            : orderDetails.discount,
        scheme: {
          ...orderDetails.scheme,
          discount_amount: orderDetails.scheme.open_discount ? discount : dis.toFixed(2),
        },
      });
    }

    //order items set
    const orderItem = [];
    for (const itemHash in cartItems) {
      const item = cartItems[itemHash];
      // parse properties for 'new order' state
      const pickedProperty = [];
      if (item.hasOwnProperty("properties") && item.properties) {
        const items =
          item.properties[
            Object.values(item.properties).forEach((itm) => {
              for (const key in itm) {
                pickedProperty.push({
                  item: itm[key].item,
                  quantity: itm[key].quantity,
                });
              }
            })
          ];
      }
      
      // discount
      // dis += parseFloat(item.item_discount);
      // for bogo offers

      if (
        (bogo === 0 && item.discount_type === "bogo" && (parseInt(discount) === 0 || parseInt(dis) === 0))
      ) {
        if((order_id && item.offer_type === "bogo") || !order_id){
          setBogo(parseFloat(getBeforeTaxPrice(calculatePriceForCartVariation(item)).toFixed(2)));
          setCartItems((curr) => {
            const newData = {
              ...curr,
              [itemHash]: {
                ...item,
                offer_type: order_id && item.offer_type === "bogo" ? "bogo" : !order_id && "bogo",
              },
            };
            return newData;
          });
        }else{
          orderItem.push({
            ...item,
            properties: pickedProperty,
            print_status: "new",
            ...((item.offer_type === "bogo" && { offer_type: "bogo",}))
          });
        }
      } else {
          orderItem.push({
            ...item,
            properties: pickedProperty,
            print_status: "new",
            ...((item.offer_type === "bogo" && { offer_type: "bogo",}))
          });
      }
    }
    setNewOrder(orderItem);

    // check if current item qty > previous on edit
    if (order_id) {
      let finalItem = [];
      for (const keys in cartItems) {
        const item = cartItems[keys];

        for (const key in editKot) {
          const kot = editKot[key];
          const isKotItem =  (keys === key && item.item.has_property === kot.item.has_property &&
                           item.item.has_variation === kot.item.has_variation);
          if (
            isKotItem && item.quantity > kot.quantity
          ) {
            finalItem.push({
              ...item,
              quantity: item.quantity - kot.quantity,
            });
          } else if (
            isKotItem && item.quantity === kot.quantity
          ) {
            const tempDeletion = deletedItemsOnEdit[key]
              ? delete deletedItemsOnEdit[key]
              : void 0;
          }
        }
      }

      setEditAdd(finalItem);
    }
  }, [cartItems]);

  useEffect(() => {
    if (!generalSettings || !generalSettings.length) return;
    setTaxType(getSystemSettings(generalSettings, "tax_type"));
  }, [generalSettings]);

  //switcher for call center payment
  const handlePaymentType = (payment_type) => {
    if (payment_type == "Cash") {
      setPaymentType("Cash");
      setSelectedPaymentTypecallCenter("Cash");
    } else {
      setPaymentType(payment_type);
      setSelectedPaymentTypecallCenter(payment_type);
    }
  };

  // HELPERS
  const handleCloseItemModal = () => {
    setPickedProperties({});
    setTempQty(0);
    setShowModal(false);
    setSelectGroup({});
  };
  const handleShowItemModal = () => setShowModal(true);

  const handleFoodItem = (foodItem) =>{
    if (foodItem.has_property === "1" || foodItem.has_variation === "1") {
      setSelectedFoodItem({ item: foodItem });
      setSelectedFoodItemId(foodItem.id);
      (foodItem.properties || foodItem.variations[0]) &&
        handleShowItemModal();
    } else {
      // quickAddToCart
      addToCart(foodItem.id, { item: foodItem });
    }
  }

  const handleFoodItemClicked = (foodItem) => {
    if (validateIfCanAddToCart()) return;
    const tableValidation =  selectedDeliveryType && selectedDeliveryType.name.match(/dine(.*)in/i) &&
        parseInt(getSystemSettings(generalSettings, "table_waiter")) === 1
    const customerValidation =  order_id || (customer.ref_no?.length !== undefined &&
      customer.ref_no?.length !== 0 && customer.ref_no?.length  >= selectedParty.ref_min &&
      customer.ref_no?.length <= selectedParty.ref_max && customer.is_confirm)
    // if del is food panda then show customer popup
    const tableSelected = orderDetails.table && (orderDetails.table !== null || orderDetails.table !== undefined);
    const isFoodPandaParty =  selectedParty && parseInt(selectedParty.show_ref_no) === 1 &&
                               selectedParty.name.match(/food(.*)panda/i);  
                                                      
    if (
      tableValidation
    ) {
      if (
        tableSelected
      ) {
        handleFoodItem(foodItem)
      } else {
        setModal10Show(true);
      }
    } else if (
      isFoodPandaParty
    ) {
      if (
        customer.name !== "" &&
        customer.name !== undefined &&
        customerValidation
      ) {
        handleFoodItem(foodItem)
      } else {
        setModal9Show(true);
      }
    } else if (selectedParty && parseInt(selectedParty.show_ref_no) === 1) {
      if (
        customerValidation
      ) {
        handleFoodItem(foodItem)
      } else {
        setModal9Show(true);
      }
    } else {
      handleFoodItem(foodItem)
    }
    // check if item has properties or variations
  };

  const checkScheme = (item) => {
    if (orderDetails.scheme?.scheme_id) {
     if(!orderDetails.scheme?.open_discount){
        const schemeToApply =
          schemeForSearchObj[parseInt(orderDetails.scheme.scheme_id)];
        if (schemeToApply.item_type === "Group") {
          const hasScheme = JSON.parse(schemeToApply.groups_id).some((element) => item.item.food_groups.includes(element));
          if (!hasScheme)
            return false;
        } else if (schemeToApply.item_type === "Item") {
          if (!JSON.parse(schemeToApply.items_id).includes(item.item.id))
            return false;
        }
        return schemeToApply;
     }
    } else {
      const schemeToApply = schemeForSearch.find((scheme) => {
        if (!scheme.branch_id.includes(orderDetails.branch.id)) return false;
        if (!scheme.order_type_id.includes(selectedDeliveryType.id))
          return false;
        if (scheme.status !== "1") return false;
        if (scheme.scheme_type !== "enable_by_default") return false;
        if (scheme.item_type === "Group") {
          const hasScheme = JSON.parse(scheme.groups_id).some((element) => item.item.food_groups.includes(element));
          if (!hasScheme)
          return false;
          // if (!JSON.parse(scheme.groups_id).includes(activeFoodGroup))
        } else if (scheme.item_type === "Item") {
          if (!JSON.parse(scheme.items_id).includes(item.item.id)) return false;
        }

        const currDate = moment(new Date(), "DD/MM/YYYY HH:mm");
        const startDate = moment(scheme.start_date).format("LLL");
        const endDate = moment(scheme.end_date).format("LLL");
        const day = moment(currDate).format("ddd");
     
        const scheduling = JSON.parse(scheme.schedule);
        if(scheduling?.length === 0 && !currDate.isBetween(startDate, endDate)){
          return false;
        }else if(!currDate.isBetween(startDate, endDate) || (scheduling?.length > 0 && !scheduling.includes(day))){
          return false;
        }
       // if (!currDate.isBetween(startDate, endDate)) return false;
        return true;
      });
      return schemeToApply;
    }
  };
  const validateIfCanAddToCart = () => {
    let error = "";
    if (!selectedParty || !selectedParty.id) error = "party";
    if (!selectedDeliveryType || !selectedDeliveryType.id)
      error = "delivery type";
    if (error) {
      toast.error(`Please select ${error} first.`);
    }

    return Boolean(error);
  };

  const handleNewItem = (item,id,hashId,edit,editOrder) =>{
    itemAnimate.current = hashId;
    if (cartItems[hashId]) {
      let schemeToApply = checkScheme(item);
      if(schemeToApply?.card_required){
        schemeToApply = {
          ...schemeToApply,
          how_much_discount: orderDetails.scheme.how_much_discount
        }
      }
    if (
      bogo > 0 &&
      orderDetails.scheme.discount_type === "bogo" &&
      parseInt(orderDetails.scheme.discount_amount) === 0
    ) {
      if (getBeforeTaxPrice(calculatePriceForCartItem(item, 1)) > bogo) {
        toast.error("You can't select item more than bogo price");
      } else {
        setCartItems((curr) => {
          const afterTaxPrice = parseFloat(
                    getBeforeTaxPrice(
                      calculatePriceForCartItem(curr[hashId], 1)
                    ).toFixed(2)
                  );
          const newData = {
            ...curr,
            [hashId + "x"]: {
              ...item,
              quantity: 1,
              after_tax_price: afterTaxPrice,
              item_discount: getBeforeTaxPrice(
                calculatePriceForCartItem(curr[hashId], 1)
              ),
              discount_type: schemeToApply
                ? schemeToApply.discount_type
                : "",
              scheme_id: schemeToApply ? schemeToApply.id : "",
              offer_type: "bogo",
              comments: "",
              seq: Object.keys(cartItems)?.length + 1,
            },
          };
          if (edit) newData[id] = item;
          return newData;
        });
      }
    } else {
      // add cart item already exist
      setCartItems((curr) => {
        const afterTaxPrice = parseFloat(
          getBeforeTaxPrice(
            calculatePriceForCartItem(curr[hashId], curr[hashId].quantity + 1)
          ).toFixed(2)
        );
        const newData = {
          ...curr,
          [hashId]: {
            ...curr[hashId],
            quantity: curr[hashId].quantity + 1,
            after_tax_price: afterTaxPrice,
            item_discount:
              bogo !== 0 && orderDetails.scheme.discount_type === "bogo"
                ? curr[hashId]?.item_discount
                  ? curr[hashId].item_discount
                  : 0
                : getItemDiscount(
                    afterTaxPrice,
                    curr[hashId],
                    curr[hashId].quantity + 1
                  ),
            discount_type: schemeToApply
              ? schemeToApply.discount_type
              : "",
            scheme_id: schemeToApply ? schemeToApply.id : "",
          },
        };
        if (edit) newData[id] = item;
        return newData;
      });
    }
   }else{
    //new cart item
     let schemeToApply = checkScheme(item);
     if(schemeToApply?.card_required){
      schemeToApply = {
        ...schemeToApply,
        how_much_discount: orderDetails.scheme.how_much_discount
      }
    }
     const afterTaxPrice = parseFloat(
        getBeforeTaxPrice(calculatePriceForCartItem(item)).toFixed(2)
      );
          if (
            bogo > 0 &&
            orderDetails.scheme.discount_type === "bogo" &&
            parseInt(orderDetails.scheme.discount_amount) === 0
          ) {
            if (afterTaxPrice > bogo) {
              toast.error("You can't select item more than bogo price");
            } else {
              setCartItems((curr) => {
                const newData = {
                  ...curr,
                  [hashId + "x"]: {
                    ...item,
                    quantity: 1,
                    after_tax_price: afterTaxPrice,
                    new: editOrder ? true : false,
                    discount: schemeToApply
                      ? parseFloat(schemeToApply.how_much_discount)
                      : 0,
                    item_discount: afterTaxPrice,
                    discount_type: schemeToApply
                      ? schemeToApply.discount_type
                      : "",
                    comments: "",
                    scheme_id: schemeToApply ? schemeToApply.id : "",
                    offer_type: "bogo",
                    seq: Object.keys(cartItems)?.length + 1,
                  },
                };
                if (edit) delete newData[id];
                return newData;
              });
            }
          } else {
            setCartItems((curr) => {
              const newData = {
                ...curr,
                [hashId]: {
                  ...item,
                  quantity: 1,
                  after_tax_price: afterTaxPrice,
                  new: editOrder ? true : false,
                  discount: schemeToApply
                    ? parseFloat(schemeToApply.how_much_discount)
                    : 0,
                  item_discount:
                    bogo !== 0 && orderDetails.scheme.discount_type === "bogo"
                      ? curr[hashId]?.item_discount
                        ? curr[hashId].item_discount
                        : 0
                      : schemeToApply
                      ? getInitialItemDiscount(afterTaxPrice, schemeToApply)
                      : 0,
                  discount_type: schemeToApply
                    ? schemeToApply.discount_type
                    : "",
                  comments: "",
                  scheme_id: schemeToApply ? schemeToApply.id : "",
                  seq: Object.keys(cartItems)?.length + 1,
                },
              };
              if (edit) delete newData[id];
              return newData;
            });
          }
      }
  }

  const addToCart = (
    id,
    item,
    edit = false,
    properties,
    editOrder = order_id
  ) => {
    console.log("[POS LOG]", id, item, edit, editOrder);

    //  - Validate
    //  - Check scheme
    //  - Check stock
    //  - Add To Cart

    // token add
    if (!order_id) {
      tokenNumber();
    }
    //  Item has properties/variations
    //valid condition on pickes item
    let checkValid;
    let groupName = "";
    let propKey = [];
    const piked = item.item.property_groups_validation;
    const valid = [];
    // check property if required
    for (const key in piked) {
      const items = piked[key];
      if (items.required === 1) {
        if (Object.keys(item.properties).includes(key)) {
          valid.push(key);
        }
        propKey.push(key);
        groupName +=
          properties && properties[JSON.parse(key)]?.details.name + ". ";
        //checkValid = valid.includes(key);
        // if (items.limit > 0) {
        // }
      }
    }
    checkValid = JSON.stringify(valid) === JSON.stringify(propKey);

    if (
      checkValid === true ||
      checkValid === undefined ||
      checkValid === null
    ) {
      if (item.item.has_property === "1" || item.item.has_variation === "1") {
        const hashId = createHash(item);
        if (
          order_id &&
          deletedItemsOnEdit[hashId] &&
          Object.keys(deletedItemsOnEdit).includes(hashId)
        ) {
          const deleteItem = deletedItemsOnEdit[hashId];
          setDeletedItemsOnEdit((curr) => ({
            ...curr,
            [hashId]: {
              ...deleteItem,
              quantity: deletedItemsOnEdit[hashId].quantity - 1,
            },
          }));
        }
        if (cartItems[hashId]) {
          handleNewItem(item,id,hashId,edit,editOrder);
        } else {
          // new item
          handleNewItem(item,id,hashId,edit,editOrder);
        }
      }
      //  Item can be added to cart immediately
      else {
        //  Cart has Item
            if (
          order_id &&
          deletedItemsOnEdit[item.item.id] &&
          Object.keys(deletedItemsOnEdit).includes(item.item.id)
        ) {
          const deleteItem = deletedItemsOnEdit[item.item.id];
          setDeletedItemsOnEdit((curr) => ({
            ...curr,
            [item.item.id]: {
              ...deleteItem,
              quantity: deletedItemsOnEdit[item.item.id].quantity - 1,
            },
          }));
        }
        if (cartItems[item.item.id]) {
          handleNewItem(item,id,item.item.id,edit,editOrder);
        
        }
        //  Cart does not have item
        else {
          handleNewItem(item,id,item.item.id,edit,editOrder);
         
        }
      }

      setShowModal(false);
    } else {
      toast.error(
        "You have to pick atleast one item from required " + groupName
      );
      setShowModal(true);
    }
  };

  const removeScheme = () => {
    console.log("orderDetails", orderDetails);
    // if cap_amount had been met, discount amount to add
    // to get original cost is the cap_amount itself
    // const dis = !orderDetails?.capAmountMet
    //   ? (cartSubTotal / 100) * parseFloat(orderDetails.discount)
    //   : parseFloat(orderDetails.discount);
    // setCartTotal(cartTotal + dis);
    setDiscount(0);
    setOrderDetails((curr) =>{
      const newData = {
      ...curr,
      discount: 0,
      card_number: "",
      scheme: {
        scheme_id: null,
        scheme_name: "",
        cap_amount: 0,
        discount_amount: null,
        open_discount: null,
        discount_type: "",
        how_much_discount: null,
      },
    }
    return newData
    });
    //setSchemeForSearchObj(null);
  };
  // meezan card offer
  const handleCardSubmit = (e) => {
    if (orderDetails.card_number === "") {
      toast.error(`${_t(t("Please enter the card number"))}`, toastObj);
    } else if (orderDetails.card_number.length > 16) {
      toast.error(
        `${_t(t("The card number should not be longer than 16 digits"))}`,
        toastObj
      );
    } else if (orderDetails.card_number.length < 8) {
      toast.error(
        `${_t(t("The card number should not be shorter than 8 digits"))}`,
        toastObj
      );
    } else {
      const cardsInfoIds = JSON.parse(
        schemeForSearchObj[parseInt(orderDetails.scheme.scheme_id)]
          ?.card_info_ids
      );

      const applicableIbans = [];
      if (cardsInfoIds && cardsInfoIds.length) {
        for (const cardInfo of cardForSearch) {
          if (cardsInfoIds.includes(cardInfo.id))
            applicableIbans.push(cardInfo);
        }
        if (applicableIbans.length) {
          for (const applicableIban of applicableIbans) {
            if (
              orderDetails.card_number.startsWith(applicableIban.iban_number)
            ) {
              handleSetOffer(
                schemeForSearchObj[parseInt(orderDetails.scheme.scheme_id)],
                applicableIban
              );
              cardModal.current.classList.remove("showing");
              return;
            }
          }
          toast.error(`${_t(t("Offer is not valid on your card."))}`, toastObj);
          removeScheme();
        }
      } else {
        toast.error(`${_t(t("Offer is not valid."))}`, toastObj);
        removeScheme();
      }
      cardModal.current.classList.remove("showing");
    }
  };

  const handleSetOffer = (scheme, cardInfo = null) => {
    const currDate = moment(new Date(), "DD/MM/YYYY HH:mm");
    const startDate = moment(scheme.start_date).format("LLL");
    const endDate = moment(scheme.end_date).format("LLL");
    const day = moment(currDate).format("ddd");
    const scheduling = JSON.parse(scheme.schedule)
    
    if(scheduling?.length === 0 && !currDate.isBetween(startDate, endDate)){
      toast.error("Offer is not valid", toastObj);
      return false;
    }else if(!currDate.isBetween(startDate, endDate) || (scheduling?.length > 0 && !scheduling.includes(day))){
      toast.error("Offer is not valid", toastObj);
      return false;
    }
   
    itemAnimate.current = null;
    // offer bogo
    if (scheme.discount_type === "bogo" && Object.keys(cartItems).length > 0) {
      setCartItems((currCartItems) => {
        let bogoOffer = true;
        const newData = { ...currCartItems };
        Object.keys(currCartItems).reverse().forEach((index)=>{
           const item = currCartItems[index];        
           let valid = true;
           if (scheme.item_type === "Group") {
             //if (!JSON.parse(scheme.groups_id).includes(item.item.food_groups[0]))
             if(!JSON.parse(scheme.groups_id).some((element) => item.item.food_groups.includes(element)))
               valid = false;
           } else if (scheme.item_type === "Item") {
             if (!JSON.parse(scheme.items_id).includes(item.item.id))
               valid = false;
           }
           
           if (valid && bogoOffer) {
             newData[index] = {
               ...item,
               item_discount: getInitialItemDiscount(
                 item.after_tax_price,
                 scheme,
               ),
               scheme_id: scheme.id,
               discount: parseFloat(scheme.how_much_discount),
               discount_type: scheme.discount_type,
               offer_type: "bogo",
             };
             bogoOffer = false;
           } else {
             newData[index] = {
               ...item,
               item_discount: 0,
               discount: 0,
               discount_type: "",
               scheme_id: "",
             };
           }
        });
        return newData;
      });
    } else {
      let discountPercent = scheme
      if(cardInfo !== null){
         discountPercent = {
          ...scheme,
          how_much_discount : cardInfo.discount
          ? parseFloat(cardInfo.discount)
          : orderDetails.discount,
        }
      }
     
      setCartItems((currCartItems) => {
        const newData = {};
        for (const hash in currCartItems) {
          const item = currCartItems[hash];
          let valid = true;
          if (scheme.item_type === "Group") {
            if (
              !JSON.parse(scheme.groups_id).some((element) => item.item.food_groups.includes(element))
            )
              valid = false;
          } else if (scheme.item_type === "Item") {
            if (!JSON.parse(scheme.items_id).includes(item.item.id))
              valid = false;
          }
          if (valid) {
            newData[hash] = {
              ...item,
              item_discount: getInitialItemDiscount(
                item.after_tax_price,
                discountPercent,
                item.quantity
              ),
              scheme_id: scheme.id,
              discount: parseFloat(discountPercent.how_much_discount),
              discount_type: scheme.discount_type,
            };
            delete newData[hash].offer_type;
          } else {
            newData[hash] = {
              ...item,
              item_discount: 0,
              discount: 0,
              discount_type: "",
              scheme_id: "",
            };
            delete newData[hash].offer_type;
          }
        }
        return newData;
      });
    }
    
    if(scheme.open_discount){
      setDiscount("")
      discountInput.current = false;
    }else{
      discountInput.current = true;
    }
    // in case of card and discount exceeding cap_amount
    // the cap amount is the new discount amount
    // (adding key 'capAmountMet' to orderDetails object to remove discount easily)
    // let capAmountMet = false;
  
    // let dis = 0;
    // let capAmount = 0;
    // if (cardInfo !== null) {
    //   const cartSubTotalValue = parseFloat(cartSubTotal);
    //   dis = ((cartSubTotalValue / 100) * cardDiscount).toFixed(2);
    //   capAmount = parseFloat(scheme.cap_amount);
    //   if (
    //     scheme.card_required &&
    //     capAmount > 0 &&
    //     capAmount < parseFloat(dis)
    //   ) {
    //     capAmountMet = true;
    //     dis = capAmount;
    //     capAmount = parseFloat(scheme.cap_amount);
    //   } else {
    //     dis = ((cartSubTotalValue / 100) * cardDiscount).toFixed(2);
    //   }
    //   setDiscount(dis);
    //   discount =  cardInfo.discount ? cardDiscount : orderDetails.discount;
      
    //   setOrderDetails((curr) => ({
    //     ...curr,
    //     discount: discount,
    //     capAmountMet: capAmountMet,
    //     scheme: {
    //       scheme_id: scheme.id,
    //       scheme_name: scheme.name,
    //       cap_amount: 500,
    //       discount_type: scheme.discount_type,
    //       open_discount: scheme.open_discount,
    //       how_much_discount: cardInfo.discount
    //         ? parseFloat(cardInfo.discount)
    //         : orderDetails.discount,
    //       discount_amount: dis,
    //     },
    //   }));
    // } else {
      // if (scheme) {
      //   if (scheme.discount_type.match(/fixed/i)) {
      //     discount = 0;
      //   } else {
      //     discount = scheme.how_much_discount ? parseFloat(scheme.how_much_discount) : orderDetails.discount;
      //   }
      // }   
      // setOrderDetails((curr) => {
      //   const {capAmountMet,...current} = curr
      //   const newData = {
      //   ...curr,
      //   discount:
      //     scheme && scheme?.how_much_discount
      //       ? scheme?.discount_type.toLowerCase() === "fixed"
      //         ? 0
      //         : parseFloat(scheme.how_much_discount)
      //       : orderDetails.discount,
      //   card_number: "",
      //   scheme: {
      //     scheme_id: scheme.id,
      //     scheme_name: scheme.name,
      //     cap_amount: 500,
      //     discount_type: scheme.discount_type,
      //     open_discount: scheme.open_discount,
      //     how_much_discount: parseFloat(scheme.how_much_discount),
      //   },
      // }
      // return newData;
      // });
    // }

    let discount = 0;
    if (scheme) {
      if (scheme.discount_type.match(/fixed/i)) {
        discount = 0;
      } else if(cardInfo !== null) {
        const cardDiscount = parseFloat(cardInfo.discount);
        discount =  cardDiscount ? cardDiscount : orderDetails.discount;
      }else{
        discount = scheme.how_much_discount ? parseFloat(scheme.how_much_discount) : orderDetails.discount;
      }
    }

    let howMuchDis = parseFloat(scheme.how_much_discount);
    if(cardInfo !== null){
      howMuchDis = cardInfo.discount
      ? parseFloat(cardInfo.discount)
      : orderDetails.discount;
    }
          
    setOrderDetails((curr) => {
      const newData = {
      ...curr,
      discount: discount,
      card_number: cardInfo !== null ? orderDetails.card_number : "",
      scheme: {
        scheme_id: scheme.id,
        scheme_name: scheme.name,
        cap_amount: parseFloat(scheme.cap_amount),
        discount_type: scheme.discount_type,
        open_discount: scheme.open_discount,
        how_much_discount: howMuchDis,
      },
     }
     return newData;
    });

    if (scheme.card_required && !scheme?.discount) {
      cardModal.current.classList += " showing";
      return;
     }
     setBogo(0);
    };

  const editCartItem = (itemId) => {
    const itemObj = cartItems[itemId];
    if (
      itemObj.item.has_property === "1" ||
      itemObj.item.has_variation === "1"
    ) {
      setSelectedFoodItem(itemObj);
      setSelectedFoodItemId(itemId);
      setShowModal(true);
    }
  };

  const emptyCard = () => {

    emptyCardEdit();
    setBackModal(false);
    if (order_id) {
      history.push("/dashboard/pos");
    }
  };

  const removeComments = (itemId) => {
    animate.current = false;

    const newObj = { ...cartItems };
    const addComment = newObj[itemId];

    const finalComment = {
      ...newObj,
      [itemId]: {
        ...addComment,
        comments: "",
      },
    };
    setCartItems(finalComment);
    setSpecialInstruction(false);
  };

  const addComments = (e, itemId) => {
    animate.current = false;

    const newObj = { ...cartItems };
    const addComment = newObj[itemId];

    const finalComment = {
      ...newObj,
      [itemId]: {
        ...addComment,
        comments: e.target.value,
      },
    };
    setCartItems(finalComment);
    // setSpecialInstruction(false);
  };

  const deleteCartItem = (itemId, deleteMade) => {
    const newObj = { ...cartItems };

    const bogoDltItem = newObj[itemId].offer_type === "bogo" && newObj[itemId].item_discount === 0;
    const bogoDlt = () =>{
      for (let key in cartItems) {
        if (
          cartItems[key].offer_type === "bogo" &&
          bogo >=
            getBeforeTaxPrice(calculatePriceForCartVariation(cartItems[key], 1))
        ) {
          delete newObj[key];
          setBogo(0);
        }
      }
    }

    if (order_id) {
      const deletedItemFromEdit = newObj[itemId];
      const pickedPropertyDelete = [];
      if (
        deletedItemFromEdit.hasOwnProperty("properties") &&
        deletedItemFromEdit.properties
      ) {
        const items1 =
          deletedItemFromEdit.properties[
            Object.values(deletedItemFromEdit.properties).forEach((itm) => {
              for (const key in itm) {
                pickedPropertyDelete.push(itm[key]);
              }
            })
          ];
      }

      if (newObj[itemId].new === false) {
        if (deletedItemFromEdit) {
          const updateItem = {
            ...deletedItemFromEdit,
            reason: deleteReason,
            status: deleteMade ? "made" : "unmade",
            print_status: "deleted",
            properties: pickedPropertyDelete ? pickedPropertyDelete : null,
          }
          // const existingDeletedItem = Object.keys(deletedItemsOnEdit).includes(itemId);
          const existingItemAdd = itemAdd && itemAdd[itemId];
          if (deletedItemsOnEdit[itemId]) {
            setDeletedItemsOnEdit((curr) => ({
              ...curr,
              [itemId]: {
                ...updateItem,
                quantity:
                  deletedItemsOnEdit[itemId].quantity +
                  deletedItemFromEdit.quantity,
              },
            }));
            // reset
            setDeleteReason("");
          } else if (
            existingItemAdd
          ) {
            setDeletedItemsOnEdit((curr) => ({
              ...curr,
              [itemId]: {
                ...updateItem,
                quantity:
                  deletedItemFromEdit.quantity - itemAdd[itemId].quantity,
              },
            }));
            // reset
            setDeleteReason("");
          } else {
            setDeletedItemsOnEdit((curr) => ({
              ...curr,
              [itemId]: {
                ...updateItem,
              },
            }));
            // reset
            setDeleteReason("");
          }
        }
      }
      if (
        bogoDltItem &&
        !newObj[itemId].new
      ) {
        for (let key in cartItems) {
          if (
            cartItems[key].offer_type === "bogo" &&
            bogo >=
            getBeforeTaxPrice(
              calculatePriceForCartVariation(cartItems[key], 1)
              )
              ) {
            if (newObj[itemId] !== cartItems[key]) {
              setDeletedItemsOnEdit((curr) => ({
                ...curr,
                [key]: {
                  ...cartItems[key],
                  reason: deleteReason,
                  status: deleteMade ? "made" : "unmade",
                  print_status: "deleted",
                  properties: pickedPropertyDelete
                    ? pickedPropertyDelete
                    : null,
                },
              }));
            }
            delete newObj[key];
            setBogo(0);
          }
        }
      } else {
        if (
          newObj[itemId].new &&
          bogoDltItem
        ) {
          bogoDlt()
        }
      }
    }

    // delete bogo offer
    if (
      !order_id &&
      bogoDltItem
    ) {
      bogoDlt()
    } else {
      delete newObj[itemId];
    }

    setCartItems(newObj);
  };
  // - minus functionality
  const deleteOneCartItem = (itemId, status) => {
    const newObj = { ...cartItems };
    const deleteItem = newObj[itemId];
    const afterTaxPrice = parseFloat(
      getBeforeTaxPrice(
        calculatePriceForCartItem(deleteItem, deleteItem.quantity - 1)
      ).toFixed(2)
    );
    const finalObj = {
      ...newObj,
      [itemId]: {
        ...deleteItem,
        after_tax_price: afterTaxPrice,
        item_discount: getItemDiscount(
          afterTaxPrice,
          deleteItem,
          deleteItem.quantity - 1
        ),
        quantity: deleteItem.quantity - 1,
      },
    };
    if (order_id) {
      const pickedPropertyDelete = [];
      if (deleteItem.hasOwnProperty("properties") && deleteItem.properties) {
        const items1 =
          deleteItem.properties[
            Object.values(deleteItem.properties).forEach((itm) => {
              for (const key in itm) {
                pickedPropertyDelete.push(itm[key]);
              }
            })
          ];
      }
      if (newObj[itemId].new === false) {
        const updatedItem = {
          ...deleteItem,
          reason:  deletedItemsOnEdit[itemId] ? deletedItemsOnEdit[itemId].reason : deleteReason,
          status: status ? "made" : "unmade",
          print_status: "deleted",
          properties: pickedPropertyDelete ? pickedPropertyDelete : null,
          
        }
        const deleteQty = deletedItemsOnEdit[itemId] ? deletedItemsOnEdit[itemId].quantity + 1 : 1;
         if (
          itemAdd &&
          itemAdd[itemId]
        ) {
          setDeletedItemsOnEdit((curr) => ({
            ...curr,
          }));
        } else {
          setDeletedItemsOnEdit((curr) => ({
            ...curr,
            [itemId]: {
              ...updatedItem,
              quantity: deleteQty,
            },
          }));
        }
        // reset
        setDeleteReason("");
      }
    }
    setCartItems(finalObj);
  };

  const getBeforeTaxPrice = (price) => {
    if (taxType === "inclusive") {
      return price / (1 + theTax / 100);
    }
    return price;
  };

  const getFormattedPrice = (price) => {
    return parseFloat(price).toFixed(2);
  };

  // tax cal on change tax type
  const handleTaxOnPaymentTypeChange = (payment_type) => {
    console.log("handleTaxOnPaymentTypeChange", payment_type);
    let paymentTypeTax = orderDetails.branch.payment_tax[payment_type.name];
    // if payment type tax exists
    if (paymentTypeTax) {
      paymentTypeTax = parseFloat(paymentTypeTax);
      const newState = {
        subTotal: cartSubTotal,
        vat: paymentTypeTax,
        vatAmount: paymentTypeTax > 0  ? cartSubTotal ? cartSubTotal * (paymentTypeTax / 100) : 0 : theVat,
        serviceCharge : orderDetails.branch.service_fees,
        serviceChargeAmount: serviceCharges,
        deliveryCharges: deliveryCharges,
        discount: orderDetails.scheme?.discount_amount
          ? orderDetails.scheme.discount_amount
          : 0,
        totalPayable:
          cartTotal - theVat + cartSubTotal * (paymentTypeTax / 100),
        showModal: true,
        payment_type_name: payment_type.name,
        taxCalSelect:false
      };
      setPaymentTypeModalState(newState);
    }else{
      const newState = {
        subTotal: cartSubTotal,
        vat: orderDetails.branch.branch_tax,
        vatAmount: theVat,
        serviceCharge : orderDetails.branch.service_fees,
        serviceChargeAmount: serviceCharges,
        deliveryCharges: deliveryCharges,
        discount: orderDetails.scheme?.discount_amount
          ? orderDetails.scheme.discount_amount
          : 0,
        totalPayable: cartTotal,
        showModal: true,
        payment_type_name: payment_type.name,
        taxCalSelect:false
      };
      setPaymentTypeModalState(newState); 
    }
  }; 

  //settle button
  const handleSettleOrderButton = (e) => {
    setTotalSettle(cartTotal);
    setTheVatSettle(theVat);
    setPaidMoney(cartTotal)
    if (newOrder && newOrder.length > 0) {
      if (orderDetails && selectedDeptTag !== null) {
        if (
          orderDetails.orderTypeDetails === undefined ||
          orderDetails.orderTypeDetails === null
        ) {
          toast.error("Please select order type", toastObj);
        } else {
          setShowSettle(true);
        }
      } else {
        toast.error("Please select a department tag", toastObj);
      }
    } else {
      toast.error("Please add items in order first", toastObj);
    }
  };

  const synclocalAllOrders = async () => {
    setButtonDisabled(true);
    const url = authUserInfo.details.user_type === "call_center"
    ? "/settings/new-callcenter-order"
    : "/settings/new-order";
    const submitUrl = BASE_URL + url;
    const settleUrl = BASE_URL + "/settings/settle-order"
    let local = JSON.parse(localStorage.getItem("orderplaced"));
    if (local != null) {
       for (let i = 0; i < local.length; i++) {
         (function (i) {
           setTimeout(async () => {
            await new Promise(resolve => setTimeout(resolve, i * 4000));
            try {
              await createOrUpdateOrder(submitUrl, local[i]);
            } catch (error) {
              console.error("Error:", error);
            }
            
          }, 4000);
        })(i);
      }
      await new Promise(resolve => setTimeout(resolve, 4000));
      localStorage.removeItem("orderplaced");
      toast.success("Local submit order has been resorted",toastObj)
    }
  
    let localSettle = JSON.parse(localStorage.getItem("ordersettle"));
    if (localSettle != null) {
      syncSettle.current = true
      for (let i = 0; i < localSettle.length; i++) {
        (function (i) {
          setTimeout(async () => {
            await new Promise(resolve => setTimeout(resolve, i * 4000));
            try {
              await createOrUpdateOrder(settleUrl, localSettle[i]);
            } catch (error) {
              console.error("Error:", error);
            }
            
          }, 6000);
        })(i);
      }
      await new Promise(resolve => setTimeout(resolve, 5000));
      syncSettle.current = false
      localStorage.removeItem("ordersettle");
      toast.success("Local settle order has been resorted",toastObj)
    }
    setButtonDisabled(false);
  };

  //sync order of local
  const syncLocalOrders = async (url) => {
   if(!order_id){
    let local = JSON.parse(localStorage.getItem("orderplaced"));
    if (local != null) {
      for (let i = 0; i < local.length; i++) {
        (function (i) {
          setTimeout(async () => {
            await new Promise(resolve => setTimeout(resolve, i * 3000));
            try {
              await createOrUpdateOrder(url, local[i]);
            } catch (error) {
              console.error("Error:", error);
            }
            
          }, 7000);
        })(i);
      }
      await new Promise(resolve => setTimeout(resolve, 3000));
      localStorage.removeItem("orderplaced");
    }
   }
  };

  //call after successful order submit and settle
  const handleOrderSubmitSuccessful = () => {
    const partyName = selectedParty.name.match(/food(.*)panda/i) ? "Food Panda" : ""
    setSelectedBranch(selectedBranch);
    setSelectedDeliveryType(selectedDeliveryType);
    setSelectedParty(selectedParty);
    setNewOrder([]);
    setFoodGroupsToPrint({});
    setSelectTable({});
    setKotPrintIdx(0);
    setSelectedKot({});
    setActiveItemInOrder(null);
    setSelectedPropertyGroup([]);
    setSrbInvoiceNum(null);
    setFbrInvoiceNum(null);
    setCartSubTotal(0);
    setTheVat(0);
    setCartTotal(0);
    setReturnMoneyUsd(0);
    setPaidMoney(0);
    setIsSettle(0);
    setBogo(0);
    setwaiterSelected(null);
    setGuestSelected(1);
    setServiceCharges(0);
    setDiscount(0);
    setOrderDetails({
      branch: orderDetails.branch,
      customer: null,
      table: null,
      waiter: null,
      dept_tag: orderDetails.dept_tag,
      payment_type: null,
      card_number: "",
      payment_amount: null,
      total_guest: 1,
      is_reservation: 0,
      table_id: null,
      table_name: null,
      discount_voucher: "",
      newCustomer: true,
      note_to_rider:"",
      newCustomerInfo: {
        name: partyName,
        email: "",
        number: "",
        address: "",
        ref_no: "",
      },
      scheme:{
        scheme_id: null,
        scheme_name: "",
        cap_amount: 0,
        discount_amount: null,
        open_discount: null,
        discount_type: "",
        how_much_discount: null,
      },
      orderTypeDetails: orderDetails.orderTypeDetails,
      partyDetails: orderDetails.partyDetails,
      token: null,
      serviceCharge: 0,
      discount: 0,
      service_amount: 0,
    });
    setAddressList([]);
    setCartItems({});
    order_hold.current = 0;
    setShowSettle(false);
    setSearchInput("");
    if (inputSearch.current !== undefined) inputSearch.current.value = "";
    if (navigator.onLine) {
      toast.success(`${_t(t("Order has been taken"))}`, toastObj);
    }
    if (selectedDeliveryType && selectedDeliveryType.name.match(/delivery/i)) {
      // if (
      //   parseInt(
      //     getSystemSettings(generalSettings, "delivery_charges_lock")
      //   ) === 0
      // ) {
      //   deliveryInput.current = false;
      //   setDeliveryCharges(
      //     parseFloat(getSystemSettings(generalSettings, "delivery_charges"))
      //   );
      // } else {
      //   deliveryInput.current = true;
      // }
      setDeliveryCharges(
        parseFloat(getSystemSettings(generalSettings, "delivery_charges"))
      );
    } else {
      deliveryInput.current = true;
      setDeliveryCharges(0);
    }
    setCustomer({
      ...customer,
      name: partyName,
      email:"",
      phn_no:"",
      address:"",
      ref_no:"",
      is_confirm: false,
    })
    setShowSettle(false);
    setLoading(false);
    setUserLock([]);

    if (
      selectedDeliveryType &&
      selectedDeliveryType.name.match(/dine(.*)in/i)
    ) {
      getTable();
      getWaiter();
    }
    //set token
    if(navigator.onLine){
      localStorage.setItem("order_id", newSettings.workPeriod.token);
    }else{
      if (localStorage.getItem("order_id")) {
        localStorage.setItem(
          "order_id",
          parseInt(localStorage.getItem("order_id")) + 1
          );
        }else{
          localStorage.setItem("order_id", newSettings.workPeriod.token);
        }
    }

    if(authUserInfo.details.user_type === "call_center"){
      setPaymentType("Cash");
      setSelectedPaymentTypecallCenter("Cash");
    }
    discountInput.current = true;
    //sound
    if (!order_id) {
      if (getSystemSettings(generalSettings, "play_sound") === "1") {
        let beep = document.getElementById("myAudio");
        beep.play();
      }
    } else {
      history.push("/dashboard/pos/submitted");
    }
    setButtonDisabled(false);
  };

  const isLocked = (type = "") => {
    console.log("typetype", type, userLock);
    // getSystemSettings(generalSettings, "offers_lock_status") == 1 &&
    return authUserInfo.details.user_type === "staff" &&
      authUserInfo.details.user_passcode &&
      !userLock.includes(type)
      ? true
      : false;
  };

  // handle auth user
  const authenticateStaffUser = (componentType) => {
    console.log("componentType", componentType, "userLock", userLock);
    if (!userLock.includes(componentType)) {
      setShowPasswordPopup(true);
      setComponentUnlock(componentType);
    }
  };
  const hanleCancelAuthenticate = () => {
    setShowPasswordPopup(false);
    setComponentUnlock("");
  };
  const onSuccessAuthenticationStaff = () => {
    setShowPasswordPopup(false);
    setUserLock([...userLock, componentUnlock]);
  };

  //after order submit or settle
  //All Receipt Control from this handle
  const getCurrentSelectedKots = useRef();
  getCurrentSelectedKots.current = selectedKot;
  const getCurrentKotPrintIndex = useRef();
  getCurrentKotPrintIndex.current = kotPrintIdx;
  const getCurrentOrderFoodGroups = useRef();
  getCurrentOrderFoodGroups.current = orderFoodGroups;

  const handleRemoveItemFromOrderListOnEdit = (deleteMade, index) => {
    setAddReasonModalShowing(null);
    deleteCartItem(index, deleteMade);
  };

  const handleOrderItemQtyOnEdit = (id, status, action, activeitem) => {
    if (!(id in deletedItemsOnEdit) && status === null)
      setAddReasonModalShowing({
        action: "decrease",
        params: [action, activeitem, id],
      });
    else deleteOneCartItem(id, status);
  };

  const addReasonOnSubmit = (status) => {
    if (!addReasonModalShowing || !addReasonModalShowing.action) return;
    if (!deleteReason) {
      toast.error("Reason cannot be empty.", toastObj);
      return;
    }
    if (addReasonModalShowing.action === "remove") {
      setAddReasonModalShowing(null);
      return handleRemoveItemFromOrderListOnEdit(
        status,
        ...addReasonModalShowing.params
      );
    }
    if (addReasonModalShowing.action === "decrease") {
      setAddReasonModalShowing(null);
      return handleOrderItemQtyOnEdit(...addReasonModalShowing.params, status);
    }
  };
  // kot for kitchen
  const handleKotItems = () => {
    const kotObj = { ...selectedKot };
    const groups = {};
    if (foodGroupForSearchForPOS) {
      for (const group of foodGroupForSearchForPOS &&
        foodGroupForSearchForPOS) {
        groups[group.id] = group;
      }
    }
    let orderItems = [...newOrder];
    // add  new and deleted items on edit
    if (order_id) {
      const addedItems = [];
      orderItems.forEach((item) => {
        if (item.new && item.new) {
          addedItems.push({
            ...item,
            print_status: "added",
          });
        }
      });
      orderItems = [
        ...Object.values(deletedItemsOnEdit),
        ...addedItems,
        ...Object.values(itemAdd),
      ];
    }
    //kotObj[orderDetails.branch.consolidated_ip] = { ...orderItems };
    orderItems.forEach((obj) => {
      const kotKey = obj.item.kot
        ? obj.item.kot === "No"
          ? obj.item.kot
          : obj.item.kot_ip
        : "No";
      const group = groups[obj.item.food_groups[0]];
      const groupKey = group ? group.name : "None";
      const consolidatedKot = orderDetails.branch.consolidated_kot === "true";
      const branchKot = orderDetails.branch.consolidated_ip;

      // branch wise consolidated kot
      if(consolidatedKot){
        if(
          kotObj.hasOwnProperty(branchKot) &&
          kotObj[branchKot] &&
          Object.keys(kotObj[branchKot])?.length
        ){
          if (
            kotObj[branchKot].hasOwnProperty(groupKey) &&
            kotObj[branchKot][groupKey] &&
            kotObj[branchKot][groupKey]?.length
          ){
            kotObj[branchKot] = {
              ...kotObj[branchKot],
              [groupKey]: [...kotObj[branchKot][groupKey], { ...obj }],
            };
          }else{
            kotObj[branchKot] = {
              ...kotObj[branchKot],
              [groupKey]: [{ ...obj }],
           }
          }          
         }else{
            kotObj[branchKot] = {
              ...kotObj[branchKot],
              [groupKey]: [{ ...obj }],
            };
         }
      }
  // item wise kot
      if (
        kotObj.hasOwnProperty(kotKey) &&
        kotObj[kotKey] &&
        Object.keys(kotObj[kotKey])?.length
      ) {
        if (
          kotObj[kotKey].hasOwnProperty(groupKey) &&
          kotObj[kotKey][groupKey] &&
          kotObj[kotKey][groupKey]?.length
        ){
          kotObj[kotKey] = {
            ...kotObj[kotKey],
            [groupKey]: [...kotObj[kotKey][groupKey], { ...obj }],
          };
         
        }else{
          kotObj[kotKey] = {
            ...kotObj[kotKey],
            [groupKey]: [{ ...obj }],
          };
        }
      } else {
        kotObj[kotKey] = {
          [groupKey]: [{ ...obj }],
        };
      }
    });
    
    setSelectedKot(kotObj);
    return kotObj;
  };

  //for Customer & kithcen
  const handlePrintAll = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
        let kots = getCurrentSelectedKots.current;
        if (kots && Object.keys(kots)?.length) {
          setNextKotPrint();
        } else {
          handleOrderSubmitSuccessful();
        }
      } else {
        handleOrderSubmitSuccessful();
      }
    },
  });

  /* KOT PRINTING FUNCTION */
  const setNextKotPrint = () => {
    let currentKotIdx = getCurrentKotPrintIndex.current;
    let allKots = getCurrentSelectedKots.current;
    setKotPrintIdx((curr) => curr + 1);
    if (currentKotIdx === Object.keys(allKots).length) {
      handleOrderSubmitSuccessful();
    } else {
      const newNewOrder = Object.values(allKots)[currentKotIdx];
      if(Object.keys(allKots)[currentKotIdx] == orderDetails.branch.consolidated_ip){
        con_Kot.current = "Consolidated Kot"
      }else{
        con_Kot.current = ""
      }
      // let groups;
      // setOrderFoodGroups(curr => {
      //   groups = [...curr]
      //   return curr
      // })
      // setFoodGroupsToPrint(groups.filter(group => (
      //   newNewOrder.find(order => (order.item.food_groups.includes(group.id)))
      // )))
      setFoodGroupsToPrint(newNewOrder);
      handlePrintKitchen2();
    }
  };
  const handlePrintKitchen2 = useReactToPrint({
    content: () => component2Ref.current,
    onAfterPrint: () => {
      setNextKotPrint();
    },
  });

  const handleReceiptPrint = () => {
    switch (getSystemSettings(generalSettings, "print_pos_setting")) {
      case "Customer":
        handlePrintCustomer();
        break;

      case "Kitchen":
        setNextKotPrint();
        break;

      default:
        handlePrintAll();
        break;
    }
  };

  //for Customer
  const handlePrintCustomer = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      handleOrderSubmitSuccessful();
    },
  });

  const axiosRequest = () => {
    let api = order_id
      ? "/settings/order-update"
      : authUserInfo.details.user_type === "call_center"
      ? "/settings/new-callcenter-order"
      : "/settings/new-order";

    let url = BASE_URL + api;
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    let formData = getOrderObject(localCurrency);
    setLoading(true);
    if (navigator.onLine) {
      syncLocalOrders(url);
    }

    createOrUpdateOrder(url, formData);
  };

  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  };

  const getDataFromLocal = (url) => {
    return JSON.parse(localStorage.getItem(url));
  };

  // save order to local when offline
  const saveOrderToLocal = (formData) => {
    formData.local = true;
    let localData = getDataFromLocal("orderplaced");
    if (localData != null) {
      localData.push(formData);
      setDataToLocal("orderplaced", localData);
    } else {
      let data = [];
      data.push(formData);
      setDataToLocal("orderplaced", data);
    }
    setLoading(false);
  };
  // form data for direct print to printer
  const formDataReturn = () => {
    return {
      site_name: getSystemSettings(generalSettings, "siteName"),
      branch: orderDetails.branch,
      type_print_heading: getSystemSettings(
        generalSettings,
        "type_print_heading"
      ),
      party_name:
        orderDetails.orderTypeDetails.orderTypeName +
        "-" +
        orderDetails.partyDetails.partyName,
      guest: orderDetails && orderDetails.total_guest,
      //is_reservation: orderDetails.is_reservation,
      isSettle: isSettle,
      customer: orderDetails.customer,
      table: orderDetails.table,
      waiter: orderDetails.waiter,
      dept_tag: selectedDeptTag,
      payment_type: orderDetails.payment_type,
      payment_amount: orderDetails.payment_amount,
      card_number: orderDetails.card_number,
      newCustomer:
        orderDetails.newCustomer || !orderDetails.customer?.id ? 1 : 0,
      newCustomerInfo: orderDetails.newCustomerInfo,
      token: orderDetails.token,
      total_guest: orderDetails.total_guest,
      table_id: orderDetails.table_id,
      table_name: orderDetails.table_name,
      discount_voucher: orderDetails.discount_voucher,
      //orderItems: newOrder,
      orderItems:
        !Object.keys(selectedKot).length === 0 ? selectedKot : handleKotItems(),
      serviceCharge: deliveryCharges,
      discount: discount,
      scheme: orderDetails.scheme,
      subTotal: cartSubTotal,
      totalPayable: isSettle === 1 && totalsettle > 0 ? totalsettle : cartTotal,
      paidMoney: paidMoney,
      theVat: theVat,
      dept_commission: deptCommission,
      workPeriod: newSettings.workPeriod,
      order_id: order_id ? order_id : null,
      orderTypeDetails: orderDetails.orderTypeDetails,
      partyDetails: orderDetails.partyDetails,
      orderFoodGroups: orderFoodGroups,
      selectedPropertyGroup: selectedPropertyGroup,
      propertyGroupForSearch: propertyGroupForSearch,
      RefNo:
        orderDetails &&
        orderDetails.orderTypeDetails &&
        orderDetails.partyDetails.partyName +
          " - ref no :" +
          orderDetails.newCustomerInfo?.ref_no,
      return_amount: returnMoneyUsd,
      print_footer: getSystemSettings(generalSettings, "type_print_footer"),
      user_name:
        authUserInfo && authUserInfo.details && authUserInfo.details.name,
      fbr_num: fbrInvoiceNum,
      srb_num: srbInvoiceNum,
    };
  };

  const handleLocalPrint = (local = 0) => {
    let formData = formDataReturn();
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("http://localhost:8081/example-app/api/test", formData, axiosConfig)
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
        if (order_id) {
          history.push("/dashboard/pos/submitted");
          setButtonDisabled(false);
        } else handleOrderSubmitSuccessful();
      })
      .catch((err) => {
        if (!local) {
          if (authUserInfo.details.user_type === "call_center") {
            setNextKotPrint();
          } else {
            handleReceiptPrint();
          }
        } else if (order_id) {
          history.push("/dashboard/pos/submitted");
          setButtonDisabled(false);
        }
      });
  };

  const createOrUpdateOrder = (url, formData) => {
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(async (res) => {
        if (formData.local) {
          await handleCreateOrUpdateOrder(res, formData.local);
        } else {
          handleCreateOrUpdateOrder(res);
        }
        setLoading(false);
        //setButtonDisabled(false);
        //toast.success("Order has been submitted successfully.", toastObj);
      })
      .catch(async (error) => {
        if (!navigator.onLine) {
          saveOrderToLocal(formData);
          setLoading(false);
          let newRes = {};
          // let workPeriod = await getWorkPeriod();
          let cust = await getCustomer();
          let cus = [];
          let workperiod = []
          workperiod.push(workPeriodListForBranch);
          cus.push(cust?.data);
          newRes.data = workperiod;
          handleCreateOrUpdateOrder(newRes);
          toast.success(`${_t(_t("Order Saved To Local"))}`, toastObj);
          //setButtonDisabled(false);
        } else {
          setFoodGroupsToPrint({});
          setKotPrintIdx(0);
          setSelectedKot({});
          let data = [];
          data.push(workPeriodListForBranch);
          //set work period
          let theWorkPeriod = null;
          if (
            orderDetails.branch !== undefined &&
            orderDetails.branch !== null &&
            authUserInfo.details.user_type !== "staff"
          ) {
            theWorkPeriod =
              data[0] &&
              data[0].find((tempWorkPeriod) => {
                return (
                  (orderDetails.branch.id === parseInt(tempWorkPeriod.branch_id)) && tempWorkPeriod.ended_at === null
                );
              });
          } else {
            theWorkPeriod = data && data[0][0];
          }
          setLoading(false);
          setTimeout(() => {
            setNewSettings({
              ...newSettings,
              vat: orderDetails.branch.branch_tax,
              workPeriod:
              theWorkPeriod !== undefined ? theWorkPeriod : null,
            });
          }, 1000);
          setButtonDisabled(false);
          toast.error(`${_t(_t("Please try again"))}`, toastObj);
        }
      });
  };

  const handleCreateOrUpdateOrder = (res, local = false) => {
    if(syncSettle.current === true){
      setIsSettle(1);
      setFbrInvoiceNum(res.data[2]);
      setSrbInvoiceNum(res.data[3]);
    }else{
      setIsSettle(0);
    }
    
    if (res.data !== "ended") {
      if(navigator.onLine && local == false && !order_id){
        setOrderDetails({
          ...orderDetails,
          token : {
            ...orderDetails.token,
            id: res.data[1],
          }
        })
      }
      //getFoodGroup();
      getFoodGroupForPOS();
      // setWorkPeriodList(res.data[0][0]);
      setWorkPeriodListForBranch(res.data[0]);
      // print on submit
      if (authUserInfo.details.user_type !== "call_center" && !order_hold.current) {
        res.data[0] && handleLocalPrint(local);
      } else {
        if (
          getSystemSettings(generalSettings, "print_callcenter_kot") === "1" && !order_hold.current
        ) {
          res.data[0] && handleLocalPrint(local);
        } else if(local == false){
          res.data[0] && handleOrderSubmitSuccessful();
        }
      }

      //set work period
      let theWorkPeriod = null;
      if (
        orderDetails.branch !== undefined &&
        orderDetails.branch !== null &&
        authUserInfo.details.user_type !== "staff"
      ) {
        theWorkPeriod =
          res.data[0] &&
          res.data[0].find((tempWorkPeriod) => {
            return (
              orderDetails.branch.id === parseInt(tempWorkPeriod.branch_id)
            );
          });
      } else {
        theWorkPeriod = res.data[0][0];
      }
      // setOrderDetails({
      //   ...orderDetails,
      //   branch: orderDetails.branch,
      // });
      //set work period here
      setNewSettings({
        ...newSettings,
        vat: orderDetails.branch.branch_tax,
        workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
      });

      setTheTax(orderDetails.branch.branch_tax);
      // customer api hit if customer change
      if (customerApi && res.data[0]) {
        getCustomer();
      }

      getRunningOrders();
      setLoading(false);
    } else {
      toast.error(`${_t(_t("Please restart the work period"))}`, toastObj);
      setLoading(false);
      setButtonDisabled(false);
    }
  };

  const tokenNumber = () => {
    if (!orderDetails.token?.id || typeof(orderDetails.token) !== "object") {
      let tokenTemp;

      if (localStorage.getItem("order_id")) {
        tokenTemp = parseInt(localStorage.getItem("order_id")) + 1;
      
      } else {
        tokenTemp = newSettings.workPeriod.token;
      }

      // if(navigator.onLine){
      //   tokenTemp = newSettings.workPeriod.token;
      // }else{
      //   if(localStorage.getItem("order_id")){
      //   tokenTemp = parseInt(localStorage.getItem("order_id")) + 1;
      //   }else{
      //     tokenTemp = newSettings.workPeriod.token;
      //   }
      // }
     let userWiseToken = getSystemSettings(generalSettings, "user_wise_token") == 1;
      setOrderDetails({
        //set token here on first order item add,
        ...orderDetails,
        token: {
          time: new Date().getTime(),
          id: userWiseToken ? (
            authUserInfo?.details &&
            authUserInfo.details?.name?.charAt(0).toUpperCase() +
              authUserInfo.details?.id +
              "-" +
              tokenTemp) : tokenTemp, //token#
          prefix : userWiseToken ? ( authUserInfo?.details && authUserInfo.details?.name?.charAt(0).toUpperCase() +
                    authUserInfo.details?.id + "-" ) : "",
        },
      });

     } 
    //else {
    //   setOrderDetails({
    //     ...orderDetails,
    //     token: orderDetails.token,
    //   });
    // }
  };

  const handleSubmitOrder = (e) => {
    e.preventDefault();
   
    // const staffWithCallCenter = adminStaffForSearch && adminStaffForSearch.find(staff => staff.user_type === "call_center");
    // if (staffWithCallCenter) {
    //   staffSearch.current = true;
    // }
      if (
        staffSearch.current &&
        authUserInfo.details.user_type === "staff" &&
        selectedParty?.for_cc
      ) {
        toast.error(`${_t(t("Invalid party for staff user"))}`, toastObj);
        return false;
      }
      if (
        authUserInfo.details.user_type === "call_center" &&
        !selectedParty?.for_cc
      ) {
        toast.error(`${_t(t("Invalid party for call center"))}`, toastObj);
        return false;
      }
   
    if (!orderDetails.token?.id || typeof(orderDetails.token) !== "object") {
      tokenNumber();
      return false;
    }

    
    if (newOrder && newOrder.length > 0) {
         if (
        orderDetails.orderTypeDetails === undefined ||
        orderDetails.orderTypeDetails === null
      ) {
        toast.error(`${_t(_t("Submit order to select order type"))}`, toastObj);
        return false;
      }

      if (
        selectedDeliveryType?.name?.match(/dine(.*)in/i) &&
        parseInt(getSystemSettings(generalSettings, "table_waiter")) === 1 &&
        (orderDetails?.table_id === null ||
          (orderDetails?.table_id && orderDetails.table_id?.length === 0))
      ) {
        toast.error(`${_t(t("Please confirm table first"))}`, toastObj);
        setModal10Show(true);
        return false;
      }

      if (
        // authUserInfo.details.user_type === "call_center" &&
        selectedParty?.name.match(/food(.*)panda/i) &&
        parseInt(selectedParty.show_ref_no) === 1 &&
        customer.ref_no?.length < selectedParty.ref_min &&
        customer.is_confirm
      ) {
        toast.error(
          `${_t(t(`Please enter ref no between ${selectedParty.ref_min}-${selectedParty.ref_max} digits`))}`,
          toastObj
        );
        return false;
      }

      if (isNaN(cartTotal)) {
        toast.error(`${_t(_t("Total amount can't be NaN"))}`, toastObj);
        return false;
      }

      if (paidMoney !== 0) {
        toast.error(
          `${_t(
            _t("Settle order to add payments, or remove payment's amount")
          )}`,
          toastObj
        );
      } else {
        setButtonDisabled(true);
        axiosRequest();
      }
    } else {
      toast.error(`${_t(_t("Please add items in order list"))}`, toastObj);
      setButtonDisabled(false);
    }
  };

  const getOrderObject = (localCurrency) => {
    // add one extra field in formdata if callcenter
    let partyFinal = {
      partyId: selectedParty && selectedParty.id,
      partyName: selectedParty && selectedParty.name,
      partyPercent: selectedParty && selectedParty.receivable_amount,
      partyRefNo: selectedParty && selectedParty.show_ref_no,
    };

    let orderTypeFinal = {
      orderTypeId: selectedDeliveryType && selectedDeliveryType.id,
      orderTypeName: selectedDeliveryType && selectedDeliveryType.name,
    };

    if (
      (!orderTypeFinal && !orderTypeFinal?.orderTypeId) ||
      (!partyFinal && !partyFinal?.partyId)
    ) {
      let error = "";
      if (
        !selectedParty ||
        !selectedParty.id ||
        (!partyFinal && !partyFinal.partyId)
      )
        error = "party";
      if (
        !selectedDeliveryType ||
        !selectedDeliveryType.id ||
        (!orderTypeFinal && !orderTypeFinal?.orderTypeId)
      )
        error = "delivery type";
      if (error) {
        toast.error(`Please select ${error} first.`);
        return false;
      }
    }

    let formData = {
      branch: orderDetails.branch,
      customer: orderDetails.customer,
      table: orderDetails.table,
      waiter: orderDetails.waiter,
      dept_tag: selectedDeptTag,
      card_number: orderDetails.card_number,
      ...(authUserInfo.details.user_type === "call_center" && {
          payment_method: paymentType,
          foodPanda_ref_no : customer.ref_no,
         }
      ),
      payment_type: orderDetails.payment_type,
      payment_amount: orderDetails.payment_amount,
      newCustomer:
        orderDetails.newCustomer ||
        (orderDetails.customer && !orderDetails.customer?.id)
          ? 1
          : 0,
      newCustomerInfo: orderDetails.newCustomerInfo,
      note_to_rider: orderDetails.note_to_rider,
      token: orderDetails.token,
      total_guest: orderDetails.total_guest,
      //is_reservation: orderDetails.is_reservation,
      table_id: orderDetails.table_id,
      table_name: orderDetails.table_name,
      discount: orderDetails.discount,
      orderItems: newOrder,
      ...(order_id && {delete_items: Object.values(deletedItemsOnEdit)}),
      serviceCharge: deliveryCharges,
      is_hold: order_hold.current ? 1 : 0,
      scheme: orderDetails.scheme
        ? {
            ...orderDetails.scheme,
            scheme_id:
              orderDetails?.scheme && orderDetails.scheme.scheme_id
                ? orderDetails.scheme.scheme_id
                : null,
            discount_amount: discount,
          }
        : null,
      subTotal: cartSubTotal,
      totalPayable: cartTotal,
      paidMoney: paidMoney,
      theVat: theVat,
      tax_percent: theTax > 0 ? theTax : orderDetails.branch.branch_tax,
      service_amount: serviceCharges,
      dept_commission: deptCommission,
      localCurrency: localCurrency,
      workPeriod: newSettings.workPeriod,
      order_id: order_id ? order_id : null,
      orderTypeDetails: orderTypeFinal?.orderTypeId
        ? orderTypeFinal
        : orderDetails.orderTypeDetails,
      partyDetails: partyFinal?.partyId
        ? partyFinal
        : orderDetails.partyDetails,
    };
    handleKotItems();
    return formData;

    // newOrder.map((key, value) => {
    //   // console.log("2866items", value, key);
    //   selectedKot[key.item.kot] = key;
    //   setSelectedKot(selectedKot);
    // });
    // console.log("2866items", selectedKot);
  };

  //set branch on edit
  const handleSetBranchOnEdit = (branch) => {
    setLoading(true);
    setOrderDetails({
      ...orderDetails,
      branch: branch,
    });

    setSelectedBranch(branch);
    setLoading(false);
  };

  const handleSetBranch = (branch) => {
    setLoading(true);

    // set work period according to branch for admin on branch change
    let theWorkPeriod = null;
    theWorkPeriod =
     workPeriodListForBranch &&
     workPeriodListForBranch.find((tempWorkPeriod) => {
        return (branch.id === parseInt(tempWorkPeriod.branch_id)) && tempWorkPeriod.ended_at === null;
      });

    // let selectedBranch = null;
    // selectedBranch = Array.from(theWorkPeriod).find((item) => {
    //   return branch.id === parseInt(item?.branch_id);
    // });
    // console.log("grop2222",selectedBranch,theWorkPeriod)
    setCashDetails({
      ...cashDetails,
      branch_id: branch.id,
      branch_name: branch?.name,
      work_period_id: branch?.id,
    });
    setNewSettings({
      ...newSettings,
      workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
      vat: branch.branch_tax,
    });

    setOrderDetails({
      ...orderDetails,
      branch: branch,
    });
    setSelectedBranch(branch);

    setCartItems({});
    setTheVat(0);
    setLoading(false);
  };

  //search food here
  // const inputsearch = useRef();
  // inputsearch.current = searchInput;
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    let searchinput = e.target.value;
    if (searchinput.length === 0) {
      setSearchedFoodItem({ list: null, searched: false });
    } else {
      const deptFood =
        foodForPOS &&
        foodForPOS?.filter((item) => {
          let lowerCaseItemName = item.name.toLowerCase();
          return (
            lowerCaseItemName.includes(searchinput.toLowerCase()) &&
            JSON.parse(item.menu_ids).includes(selectedDeptTag?.id) && 
            item.food_group_status
          );
        });
      // for (const key in menu[selectedDeptTag?.id]) {
      //   deptFood.push(...menu[selectedDeptTag?.id][key]);
      // }
      // let searchedList = deptFood && deptFood.filter((item) => {
      //   let lowerCaseItemName = item.name.toLowerCase();
      //   return lowerCaseItemName.includes(searchinput);
      // });

      setSearchedFoodItem({
        ...searchedFoodItem,
        list: deptFood,
        searched: true,
      });
    }
  };

  //show price of each item in order list without discount
  // const showPriceOfEachOrderItemWithoutDiscount = (itemIndex) => {
  //   if (newOrder) {
  //     let price = 0;
  //     let dis = 0;
  //     let inclusive_vat = 0;
  //     let after_tax_price = 0;
  //     let orderItem = newOrder[itemIndex];
  //     //check price * quantity (variation price / item price)
  //     if (parseInt(orderItem.item.has_variation) === 1) {
  //       price = parseFloat(orderItem.variation.food_with_variation_price);
  //     } else {
  //       price = parseFloat(orderItem.item.price);
  //     }
  //     //calculate total price of properties
  //     if (orderItem.properties) {
  //       if (orderItem.properties.length > 0) {
  //         orderItem.properties.map((getEachItemPrice) => {
  //           let totalPropertyPrice =
  //             parseFloat(getEachItemPrice.item.extra_price) *
  //             getEachItemPrice.quantity;
  //           price = price + totalPropertyPrice;
  //         });
  //       }
  //     }

  //     if (getSystemSettings(generalSettings, "tax_type") === "inclusive") {
  //       if (newSettings) {
  //         inclusive_vat = (parseFloat(newSettings.vat) + 100) / 100;
  //         after_tax_price = price / inclusive_vat;
  //         inclusive_vat = (price - after_tax_price) * orderItem.quantity;
  //         price = after_tax_price;
  //       }
  //     }
  //     let formattedPrice = formatPrice(price * orderItem.quantity);
  //     return formattedPrice;
  //   }
  // };

  //show price of each item in print
  // const showPriceOfEachOrderItemPrint = (itemIndex) => {
  //   if (newOrder) {
  //     let price = 0;
  //     let dis = 0;
  //     let dis_amount = 0;

  //     let orderItem = newOrder[itemIndex];

  //     if (orderItem.discount !== undefined) {
  //       dis = parseFloat(orderItem.discount);
  //     } else {
  //       dis = 0;
  //     }

  //     //check price * quantity (variation price / item price)
  //     if (parseInt(orderItem.item.has_variation) === 1) {
  //       const tempPrice = parseFloat(
  //         orderItem.variation.food_with_variation_price
  //       );
  //       price = getFormattedPrice(getBeforeTaxPrice(tempPrice));
  //     } else {
  //       const temprice = parseFloat(orderItem.item.price);
  //       price = getFormattedPrice(getBeforeTaxPrice(temprice));
  //     }

  //     let formattedPrice = 0;
  //     if (orderItem.discount_type === "percentage") {
  //       dis_amount = (price / 100) * dis;
  //       formattedPrice = formatPrice(
  //         price * orderItem.quantity - dis_amount * orderItem.quantity
  //       );
  //     } else {
  //       formattedPrice = formatPrice(
  //         price * orderItem.quantity - dis * orderItem.quantity
  //       );
  //     }

  //     //let formattedPrice = formatPrice(price * orderItem.quantity - 10);
  //     return formattedPrice;
  //   }
  // };

  return (
    <>
      <div
        className={`pos--main-container bg-white row m-0 p-0
        ${!showSettle ? "" : "d-none"}
      `}
      >
        {/* category sidebar */}
        <div className="category-box mt-0 bg-white col col-2 d-flex flex-column px-0 py-2 mh-inherit shadow">
          <PosSelect
            isSearchable={false}
            options={availableDeptTags && availableDeptTags}
            value={selectedDeptTag}
            getOptionLabel={(a) => a.name}
            getOptionValue={(a) => a.name}
            placeholder="Select Menu"
            className="mt-2 mb-2 mx-2"
            onChange={(e) => {
              if (order_id) {
                toast.error(
                  "Can't change type on edit complete the current order"
                );
              } else {
                setSelectedDeptTag(e);
              }
            }}
          />
          <div className="pos--left-inner-addon mx-2">
            <input
              type="search"
              className="form-control"
              placeholder="Search"
              width="100"
              ref={inputSearch}
              value={searchInput}
              onChange={(e) => handleSearch(e)}
            />
            <i
              className="fa fa-search search pt-2"
              onClick={() => {
                setSearchedFoodItem({ list: null, searched: false });
                setSearchInput("");
                if (inputSearch.current !== undefined)
                  inputSearch.current.value = "";
              }}
            ></i>
          </div>
          <div className="flex-grow-1" data-simplebar>
            <div className="hover_category mb-4">
              <div className="pos--category-nav d-flex flex-column  ">
                {selectedDeptTag && Object.keys(availableFoodGroups).length ? (
                  availableFoodGroups[selectedDeptTag.id]?.map((foodGroup) => (
                    <span
                      key={foodGroup.id}
                      className={`pos--category-nav-item d-flex font-weight-bold rounded-05 ${
                        activeFoodGroup === foodGroup.id ? "active" : ""
                      }`}
                      onClick={() => setActiveFoodGroup(foodGroup.id)}
                    >
                      <div>{foodGroup.name}</div>
                      {activeFoodGroup === foodGroup.id ? (
                        <div>
                          <i class="fa fa-arrow-right ml-1"></i>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </span>
                  ))
                ) : (
                  <span></span>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* main content */}

        <div className="col mh-inherit mt-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="pos--options-container mt-2 mb-2 pt-3 ml-3  col">
              <NavLink
                className="pos--btn btn mr-1 bg-success text-white "
                to={"/dashboard/pos/online-orders"}
              >
                Online Order
                {onlineCounter > 0 && (
                  <span className=" pl-1 rounded-md special-category">
                    ({onlineCounter})
                  </span>
                )}
              </NavLink>
              {availableDeliveryTypes.length
                ? availableDeliveryTypes.map((deliveryType) => (
                    <DropdownButton
                      value={deliveryType}
                      isSelected={deliveryType.id === selectedDeliveryType?.id}
                      setSelected={setSelectedDeliveryType}
                      getLabel={(dt) => dt.name}
                      options={availableDeliveryParties[deliveryType.id]}
                      selectedSubItem={selectedParty}
                      setSelectedSubItem={setSelectedParty}
                      getSubItemLabel={(p) => p.name}
                    />
                  ))
                : null}
              {authUserInfo.details.user_type !== "call_center" && (
                <>
                  <NavLink
                    className="pos--btn btn mr-1 bg-success  text-white"
                    to={"/dashboard/pos/settled"}
                  >
                    Settled
                  </NavLink>

                  <div className="d-inline-block btn-group">
                    <NavLink
                      className={`dropdwn--btn btn text-white bg-success text-decoration-none ${
                        runningOrders && runningOrders?.length > 0
                          ? ""
                          : "mr-2 rounded-05"
                      }`}
                      to={"/dashboard/pos/submitted"}
                    >
                      Running Orders
                      {readyOrderCounter > 0 && (
                        <span className=" pl-1 rounded-md special-category">
                          ({readyOrderCounter})
                        </span>
                      )}
                    </NavLink>
                    {runningOrders && runningOrders?.length > 0 && (
                      <button
                        className="dropdwn1--btn  btn bg-success text-white mr-1"
                        onClick={() => setEditOrder(!editOrder)}
                      >
                        <i className="fa-sharp fa fa-caret-down ml-2 lg-text " />
                      </button>
                    )}
                    {editOrder && (
                      <div
                        className="pos--dropdown-list-edit"
                        ref={scrollHeight}
                      >
                        {runningOrders?.map((item, index) => (
                          <div
                            key={index}
                            className={`editOrderPos d-flex align-items-center justify-content-between mb-2 ml-2 ${
                              heightScroll?.actualHeight >=
                              heightScroll?.overflowHeight
                                ? "mr-2"
                                : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                authUserInfo.permissions !== null &&
                                checkPermission(
                                  authUserInfo.permissions,
                                  "Order edit"
                                )
                              ) {
                                history.push("/dashboard/epos/" + item?.id);
                              }
                            }}
                          >
                            <div className="p-1" style={{ width: "80%" }}>
                              <div className="editDetail">
                                #
                                {item.token?.id}
                                {item && item.table_name !== "-" &&(
                                  <span className="pl-0"> | Table : {item.table_name}</span>
                                )}
                              </div>
                              <div className="editDetail">
                              {
                                item.order_type_name +
                                " - " +
                                item.party_name
                                }
                              </div>
                              <div className="editOrderId mt-1">
                                {item?.branch_name}
                              </div>
                            </div>
                            <div className="editIcon">
                              {authUserInfo.permissions !== null &&
                              checkPermission(
                                authUserInfo.permissions,
                                "Order edit"
                              ) ? (
                                <NavLink
                                  refresh="true"
                                  title="Edit Order"
                                  to={"/dashboard/epos/" + item?.id}
                                  className="mr-2"
                                  style={{
                                    borderRadius: "2px",
                                  }}
                                >
                                  <span>
                                    <i className="fa fa-pencil"></i>
                                  </span>
                                </NavLink>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}

              <CustomerModal
                show={modal9Show}
                onHide={() => setModal9Show(false)}
                modal9Show={modal9Show}
                setModal9Show={setModal9Show}
                setOrderDetails={setOrderDetails}
                orderDetails={orderDetails}
                selectedParty={selectedParty}
                setCustomerApi={setCustomerApi}
                setAddressList={setAddressList}
                addressList={addressList}
                customer={customer}
                setCustomer={setCustomer}
              />
              <Button
                className="pos--btn btn bg2-success xlg-text pos--icon-btn text-white border-0 mr-1"
                onClick={() =>
                  selectedParty
                    ? setModal9Show(true)
                    : toast.error("Please select party type")
                }
              >
                <i className="fa fa-user-plus new-btn"></i>
              </Button>
              {authUserInfo.details.user_type !== "call_center" && (
                <span>
                  {parseInt(
                    getSystemSettings(generalSettings, "table_waiter")
                  ) === 1 ? (
                    selectedDeliveryType &&
                    selectedDeliveryType.name.match(/dine(.*)in/i) &&
                    parseInt(
                      getSystemSettings(generalSettings, "table_waiter")
                    ) === 1 ? (
                      <Button
                        className="pos--btn btn mr-1 bg2-success xlg-text pos--icon-btn text-white border-0"
                        onClick={() =>
                          selectedDeliveryType &&
                          selectedDeliveryType.name.match(/dine(.*)in/i)
                            ? setModal10Show(true)
                            : toast.error("Please select Dine In ")
                        }
                      >
                        <i className="fa fa-address-card new-btn"></i>
                      </Button>
                    ) : (
                      <span></span>
                    )
                  ) : (
                    <span></span>
                  )}
                </span>
              )}
              {
                parseInt(getSystemSettings(generalSettings, "foodPanda_integration")) === 1 && (
                <NavLink 
                ref={taxHeight}
                className="btn foodPanda-btn text-white mr-1"
                to={"/dashboard/pos/foodpanda"}
                >
                <span className="d-flex align-items-center">
                 <FP  className="foodPandabtn mr-1"/>
                  Food Panda 
                  {foodPandaCounter > 0 && (
                    <span className=" pl-1 rounded-md special-category">
                      ({foodPandaCounter})
                    </span>
                  )}
                </span>
                 {/* <img className="ml-1" style={{height:"1.2rem", borderRadius:"2px"}} src={FPRider}  alt=""/>*/}
                 
                </NavLink>
                )
              }

              <TableModal
                show={modal10Show}
                onHide={() => setModal10Show(false)}
                modal10Show={modal10Show}
                setModal10Show={setModal10Show}
                orderDetails={orderDetails}
                setOrderDetails={setOrderDetails}
                selectedDeliveryType={selectedDeliveryType}
                selectedParty={selectedParty}
                selectedDeptTag={selectedDeptTag}
                selectTable={selectTable}
                setSelectTable={setSelectTable}
                tableId={tableId}
                setTableId={setTableId}
                waiterSelected={waiterSelected}
                setwaiterSelected={setwaiterSelected}
                guestSelected={guestSelected}
                setGuestSelected={setGuestSelected}
                buttonDisabled={buttonDisabled}
              />
            </div>
            {/* Branch select Dropdown*/}
            <div className="col-md-2 mr-3 mt-2 mb-2 pt-3">
              {authUserInfo.details &&
                authUserInfo.details.user_type !== "staff" && (
                  <Select
                    options={branchForSearch && branchForSearch}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={selectedBranch}
                    onChange={(branch) => handleSetBranch(branch)}
                    classNamePrefix="Branch"
                    className="sm-text"
                    maxMenuHeight="200px"
                    placeholder="Branch"
                    styles={{
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none",
                      }),
                      control: (provided) => ({
                        ...provided,

                        borderRadius: ".5rem",
                        borderColor: "#27ccaa",
                        maxHeight: "2rem",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: "#27ccaa",
                      }),
                    }}
                  />
                )}
              {authUserInfo.details &&
                authUserInfo.details.user_type === "staff" && (
                  <div className="user_branch_name  text-right">
                    {orderDetails?.branch?.name}{" "}
                  </div>
                )}
            </div>
          </div>

          {/* main container */}
          <div className="row pos--main-content-wrapper">
            <div
              className="col d-flex flex-row pt-1 p-4 pr-4 menu-left"
              data-simplebar
            >
              <div className="pos--menu-container row m-0 flex-1">
                <div className="col flex-grow-1 mh-100 p-1">
                  <div className="pos--menu-items-conatiner row m-0 flex-wrap">
                  {!searchedFoodItem.searched
                    ? menu &&
                    orderDetails.branch &&
                        selectedDeptTag &&
                        activeFoodGroup &&
                        menu[selectedDeptTag.id] &&
                        menu[selectedDeptTag.id][activeFoodGroup]
                        ? menu[selectedDeptTag.id][activeFoodGroup]?.map(
                            (foodItem,index) => (
                              <>
                              <div
                              ref={flyStart}
                              key={foodItem.id}
                              title={foodItem?.item_description}
                              className="font-weight-bold pos--menu-item rounded-05 mr-2 mb-3"
                              onClick={(e) =>{
                                animate.current = true;
                                flyStart.current = e.target;
                                if(!buttonDisabled){
                                  handleFoodItemClicked(foodItem,e)
                                }
                               }}
                              >
                              
                              <figure className="position-relative w-100">
                                  <img
                                    
                                    src={
                                      foodItem.image
                                        ? foodItem.image
                                        : defaultImg
                                    }
                                    alt=""
                                    className="img-fluid item-img"
                                    loading="lazy"
                                  />
                                  <figcaption className="item-name text-center px-1">
                                    {foodItem.name}
                                  </figcaption>
                                </figure>
                              </div>
                              </>
                            )
                          )
                        : null
                      : menu &&
                        orderDetails.branch &&
                        selectedDeptTag &&
                        activeFoodGroup &&
                        menu[selectedDeptTag.id] &&
                        menu[selectedDeptTag.id][activeFoodGroup]
                      ? searchedFoodItem.list?.map((foodItem) => (
                          <div
                            ref={flyStart}
                           title={foodItem?.item_description}
                            key={foodItem.id}
                            className="font-weight-bold pos--menu-item rounded-05  mr-2 mb-3"
                            onClick={(e) =>{
                              animate.current = true;
                              flyStart.current = e.target
                              if(!buttonDisabled){
                               handleFoodItemClicked(foodItem)
                              }
                            }}
                          >
                         
                            <figure className="position-relative w-100">
                              <img
                                src={
                                  foodItem.image ? foodItem.image : defaultImg
                                }
                                alt=""
                                className="img-fluid item-img"
                                loading="lazy"
                              />
                              <figcaption className="item-name text-center mt-2 px-2">
                                {foodItem.name}
                              </figcaption>
                            </figure>
                          </div>
                        ))
                      : null}
                    <ItemModal
                      closeModal={handleCloseItemModal}
                      showModal={showModal}
                      pickedProperties={pickedProperties}
                      setPickedProperties={setPickedProperties}
                      itemData={selectedFoodItem}
                      itemId={selectedFoodItemId}
                      tempQty={tempQty}
                      setTempQty={setTempQty}
                      addToCart={addToCart}
                      selectGroup={selectGroup}
                      setSelectGroup={setSelectGroup}
                      buttonDisabled={buttonDisabled}
                    />
                  </div>
                </div>
              </div>
            </div>
            {newSettings && newSettings.workPeriod === null && (
              <div className="fk-overlay">
                <div className="fk-left-overlay__content text-center m-auto">
                  <h6
                    className={`text-primary text-uppercase ${
                      authUserInfo.details &&
                      authUserInfo.details.user_type !== "staff" &&
                      "mb-0"
                    }`}
                  >
                    {authUserInfo.details &&
                    authUserInfo.details.user_type !== "staff"
                      ? _t(_t("Select branch to active POS"))
                      : _t(_t("start workperiod"))}
                  </h6>
                  <div className="m-auto col-8 mt-3">
                    {authUserInfo.details &&
                      authUserInfo.details.user_type !== "staff" && (
                        <Select
                          options={branchForSearch && branchForSearch}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={selectedBranch}
                          onChange={(branch) => handleSetBranch(branch)}
                          classNamePrefix="Branch"
                          className="sm-text"
                          maxMenuHeight="200px"
                          placeholder="Branch"
                          styles={{
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: ".5rem",
                              borderColor: "#27ccaa",
                              maxHeight: "2rem",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "#27ccaa",
                            }),
                          }}
                        />
                      )}
                  </div>
                  {authUserInfo.details &&
                    authUserInfo.details.user_type !== "staff" && (
                      <>
                        <h4 className="mt-2 text-uppercase xsm-text mb-2">
                          {_t(_t("Start workperiod if it is not started"))}
                        </h4>
                      </>
                    )}
                  <NavLink
                    to="/dashboard/work-periods"
                    className="t-heading-font btn btn-success btn-sm text-uppercase sm-text"
                  >
                    {_t(_t("Start Your Work Period"))}
                  </NavLink>
                </div>
              </div>
            )}

            <div className="col-5 pt-2 py-5 pr-4 bill-right">
              <div className="pt-1 pos--bill-container rounded-05 w-80 d-flex flex-column pb-2">
                <div className="p-2 cart-1">
                  <h6 className="p-2 mb-0 font-weight-bold border-bottom d-flex justify-content-between">
                    {selectedDeliveryType &&
                      `${
                        selectedDeliveryType
                          ? selectedDeliveryType.name + " "
                          : ""
                      } - `}
                    {selectedParty ? selectedParty.name : ""}
                    <h6
                      className="m-0 ml-2 text-capitalize"
                      style={{ fontSize: "1rem" }}
                    >
                      {orderDetails.token && orderDetails
                        ? getSystemSettings(generalSettings, "token_text").slice(0,5) + " # " + orderDetails.token.id
                        : ""}
                    </h6>
                  </h6>
                </div>

                <div className="flex-grow-1 container">
                  <div className="px-1 d-flex flex-row justify-content-between border-bottom bill-list">
                    <span className="text-capitalize sm-text d-inline-block fw-normal col-5">
                      <h7>Food Item</h7>
                    </span>

                    <span className="text-capitalize sm-text d-inline-block col-3 text-center">
                      <h7>QTY</h7>
                    </span>
                    <span className="mr-2 text-capitalize sm-text d-inline-block col-3 text-right">
                      <h7>Price</h7>
                    </span>
                  </div>
                  {/* <div className="simplebar-scrollbar"> */}
                  <div className="items-wrap ">
                    {cartItems && Object.keys(cartItems).length
                      ? Object.keys(cartItems)
                          .sort((a, b) =>
                            cartItems[a].seq < cartItems[b].seq ? 1 : -1
                          )
                          .map((itemHash) => {
                            return(
                            <div key= {itemHash} id={itemAnimate.current == itemHash && "cart-animate"} className="cart-item-wrapper"
                            >
                            <CartItem
                              itemId={itemHash}
                              animate={animate}
                              itemAnimate={itemAnimate}
                              animation={Cartanimation}
                              item={cartItems[itemHash]}
                              deleteItem={deleteCartItem}
                              editItem={editCartItem}
                              addToCart={addToCart}
                              deleteOneCartItem={deleteOneCartItem}
                              commentFocus={commentFocus}
                              setAddReasonModalShowing={
                                setAddReasonModalShowing
                              }
                              orderScheme =  {orderDetails.scheme}
                              deletedItemsOnEdit = {deletedItemsOnEdit}
                              cartItems={cartItems}
                              commentId={commentId}
                              setCommentId={setCommentId}
                              specialInstruction={specialInstruction}
                              setSpecialInstruction={setSpecialInstruction}
                              addComments={addComments}
                              removeComments={removeComments}
                              userLock={userLock}
                              editAdd={editAdd}
                              itemAdd={itemAdd}
                              setItemAdd={setItemAdd}
                              authenticateStaffUser={authenticateStaffUser}
                              isLocked={isLocked}
                              getPrice={(p) =>
                                getFormattedPrice(
                                  p.after_tax_price ==
                                    getBeforeTaxPrice(
                                      calculatePriceForCartItem(p)
                                    )
                                    ? p.after_tax_price
                                    : getBeforeTaxPrice(
                                        calculatePriceForCartItem(p)
                                      )
                                )
                              }
                            />
                            </div>
                          )})
                      : null}
                  </div>
                </div>
                {/* </div> */}

                <div className="col-12 p-0 m-0 t mt-10">
                  <div className="">
                    <div className="container 2">
                      <div className="row  border-bottom">
                        {/*Subtotal*/}
                        <div className="col-md-6">
                          <div className=" d-flex flex-row justify-content-between p-2 px-1">
                            <span className="text-capitalize sm-text d-inline-block total-bill">
                              SubTotal
                            </span>
                            <span className="text-capitalize sm-text d-inline-block font-weight-bold text-right mr-3">
                             
                                {currencySymbolLeft()}
                                {getFormattedPrice(cartSubTotal)}
                                {currencySymbolRight()}
                           
                            </span>
                          </div>
                        </div>
                        {/*Tax*/}
                        <div className="col-md-6">
                          <div className="pt-2 d-flex flex-row justify-content-evenly p-2 mr-2">
                            <span className="text-capitalize sm-text d-inline-block col-5 mr-1 position-relative">
                              Tax
                              <span className=" text-capitalize sm-text text-center font-weight-bold text-right ">
                                ({theTax && theTax + "%"})
                              </span>
                               {
                                parseInt(
                                  getSystemSettings(
                                    generalSettings,
                                    "payment_type_tax_calculation"
                                  )
                                ) === 1 && (
                                  <TriangleDown  className="dashboard_card_down cursor-pointer" onClick={()=> setPaymentTypeModalState({
                                    ...paymentTypeModalState,
                                    taxCalSelect : !paymentTypeModalState.taxCalSelect
                                  })}/>
                                )
                                }
                              {paymentTypeModalState.taxCalSelect === true && (
                              <div className={`taxSelect 
                                ${taxScroll?.actualHeight >=
                                taxScroll?.overflowHeight ? 
                                "pr-1" : ""}`}
                              > 
                              {
                               paymentTypeAvailable &&
                               paymentTypeAvailable?.map((groupItem, groupIndex)=>{
                                 return (
                                   <span className="m-0 p-0" key={groupIndex}  onClick={() => {
                                    setPaymentTypeModalState({
                                      ...paymentTypeModalState,
                                      taxCalSelect : !paymentTypeModalState.taxCalSelect
                                    });
                                    handleTaxOnPaymentTypeChange(groupItem)
                                   }}>
                                    <li className={`list-unstyled dashboard_border p-1 cursor-pointer ${groupIndex == (paymentTypeAvailable?.length - 1) ? "mb-0" : "mb-1"}`}>{groupItem.name}</li>
                                   </span>
                                 )
                               })
                              }
                             </div>
                             )}
                            </span>
                            <span className="text-capitalize sm-text d-inline-block font-weight-bold col-6 text-right ml-4">
                              <>
                                {theTax &&
                                  currencySymbolLeft() +
                                    theVat +
                                    currencySymbolRight()}
                              </>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Input tags */}
                    <div className="input_fields d-flex flex-row flex-wrap align-items-center justify-content-between my-2 mx-1 px-2">
                      <div className="col-md-4 my-1 mx-1 d-flex align-items-center">
                        <label className="sm-text col-6 font-weight-bold mr-1">
                          Del Charge:
                        </label>
                        <input
                          disabled={deliveryInput.current}
                          class="form-control cartInput rounded-25"
                          type="number"
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-","ArrowUp","ArrowDown","ArrowLeft", "ArrowRight"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onWheel={(e) => e.target.blur()}  
                          min="0"
                          value={deliveryCharges}
                          onChange={(e) => setDeliveryCharges(e.target.value)}
                        />
                      </div>
                      <div className="col my-1 mx-1">
                        <span className="sm-text text-center d-flex flex-row">
                          <span className=" font-weight-bold mr-1">
                            S.C(
                            {orderDetails?.branch &&
                              orderDetails.branch?.service_fees}
                            %):
                          </span>
                          <span> RS: {serviceCharges.toFixed(2)}</span>
                        </span>
                      </div>
                      <div className="col-md-4 my-1 mx-1 d-flex  align-items-center ">
                        <label className="sm-text col-5 font-weight-bold mr-1 text-center">
                          Dis:
                        </label>
                        <input
                          disabled={newOrder?.length === 0 || discountInput.current }
                          class="form-control cartInput rounded-25 "
                          type="number"
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-","ArrowUp","ArrowDown","ArrowLeft", "ArrowRight"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onWheel={(e) => e.target.blur()}  
                          min="0"
                          placeholder="Discount"
                          value={discount}
                          onChange={(e) => {
                            setOrderDetails({
                              ...orderDetails,
                              scheme:{
                                ...orderDetails.scheme,
                                discount_amount: e.target.value
                              }
                            });
                            setDiscount(e.target.value)}}
                        />
                      </div>
                    </div>
                    <div className="mt-1 container">
                      <div className="row justify-content-between mb-1">
                        <div className="col-1 d-flex align-items-center">
                          {isLocked("offer_cp") &&
                          parseInt(
                            getSystemSettings(
                              generalSettings,
                              "offers_lock_status"
                            )
                          ) === 1 ? (
                            <div
                              className="cursor-pointer mx-1 d-flex align-items-center"
                              onClick={(e) => authenticateStaffUser("offer_cp")}
                            >
                              <i className="fa fa-lock lock_icon"></i>
                            </div>
                          ) : (
                            <div className="text-success mx-1 d-flex align-items-center">
                              <i className="las la-lock-open lock_icon"></i>
                            </div>
                          )}
                        </div>
                        <div className="col-5 ">
                          {tempSchemeForSearch !== null &&
                          tempSchemeForSearch?.length !== 0 ? (
                            <span className="position-relative offer-tip">
                              <span
                                className={`${
                                  isLocked("offer_cp") &&
                                  parseInt(
                                    getSystemSettings(
                                      generalSettings,
                                      "offers_lock_status"
                                    )
                                  ) === 1
                                    ? "tool-tip"
                                    : "disable-tip"
                                }`}
                              >
                                please unlock offer
                              </span>
                              <Select
                                isDisabled={
                                  (isLocked("offer_cp") &&
                                  parseInt(
                                    getSystemSettings(
                                      generalSettings,
                                      "offers_lock_status"
                                    )
                                  ) === 1) || buttonDisabled
                                }
                                isSearchable={true}
                                options={tempSchemeForSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={
                                  orderDetails.scheme?.scheme_id
                                    ? schemeForSearchObj[
                                        orderDetails.scheme?.scheme_id
                                      ]
                                    : null
                                }
                                onChange={(offer) => handleSetOffer(offer)}
                                classNamePrefix="Offers"
                                className="sm-text rounded-25 cursor-pointer"
                                menuPlacement="top"
                                placeholder="Offers"
                                styles={{
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    borderRadius: ".3rem",
                                    borderColor: "#27ccaa",
                                    minHeight: "1rem",
                                    maxHeight: "2rem",
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    fontSize: "1rem",
                                    color: "#27ccaa",
                                    padding: "0 8px 0 0",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0 8px",
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                  }),
                                  inputContainer: (provided) => ({
                                    ...provided,
                                  }),
                                }}
                              />
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </div>
                        <div className="col-6 d-flex justify-content-end print-cancel-row align-items-center">
                          <span className="mr-4 font-weight-bold bill-total">
                            Total Bill:
                          </span>
                          <span className="font-weight-bold mr-1 bill-total">
                            <>
                              {currencySymbolLeft()}
                              {cartTotal}
                              {currencySymbolRight()}
                            </>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="col-7 row">
                          <div className="mr-1 col-6">
                            {/* <button
                              type="button"
                              className="w-100 btn btn-success xsm-text text-uppercase col-3"
                            >
                              Print Bill
                            </button> */}
                          </div>

                          {/* <div className="col-4 m-0 p-0 cancel-btn">
                            <button
                              type="button"
                              className="w-100 btn btn-success2 xsm-text text-uppercase col-6"
                              onClick={emptyCard}
                            >
                              cancel
                            </button>
                          </div> */}
                        </div>
                      </div>

                      <div className="bill-last-row row mt-2 mb-3 ">
                        <div className="col-7 d-flex">
                          {authUserInfo.details.user_type === "call_center" && (
                            <>
                              <div
                                aria-label="payment Type"
                                className="indolj-switcher-container mx-2"
                              >
                                {paymentOptions.map((paymentOption) =>
                                  selectedPaymentTypecallCenter == paymentOption ? (
                                    <div
                                      className={`switch ${
                                        paymentType == paymentOption
                                          ? "active-switch"
                                          : ""
                                      }`}
                                      key={paymentOption}
                                      ref={(e) =>
                                        paymentType == paymentOption
                                          ? currentNavPill(e)
                                          : null
                                      }
                                      onClick={() =>
                                        handlePaymentType(paymentOption)
                                      }
                                    >
                                      {paymentOption}
                                    </div>
                                  ) : (
                                    <div
                                      className={`switch ${
                                        paymentType == paymentOption
                                          ? "active-switch"
                                          : ""
                                      }`}
                                      key={paymentOption}
                                      ref={(e) =>
                                        paymentType == paymentOption
                                          ? currentNavPill(e)
                                          : null
                                      }
                                      onClick={() =>
                                        handlePaymentType(paymentOption)
                                      }
                                    >
                                      {paymentOption}
                                    </div>
                                  )
                                )}
                                <div className="driveActive"></div>
                              </div>
                            </>
                          )}
                           <div className=" ml-1 fk-calculator-container calculator-btn ">
                             {
                              /*
                              <button
                                className="fa fa-calculator btn btn-info text-uppercase w-100 h-1000 rounded-25"
                                onClick={() => setCalculatorMenu(!calculatorMenu)}
                              ></button>
                              <div
                                className={`calculator ${
                                  calculatorMenu && "active"
                                }`}
                              >
                                <Calculator />
                              </div>
                            */
                             }  
                                <button
                                  title="Rider Note"
                                  disabled={buttonDisabled || (selectedDeliveryType &&
                                    selectedDeliveryType?.name?.match(
                                      /dine(.*)in/i
                                    ))}
                                  className=" btn btn-primary xsm-text text-uppercase rounded-25 d-flex align-items-center py-1 px-2"
                                  onClick={(e) =>
                                    newOrder && newOrder.length > 0
                                      ? setNoteRiderModal(true)
                                      : ""
                                  }
                                >
                                 <img src="/assets/img/writing.png" style={{height:"1.25rem"}}/>
                                </button>
                              <div>
                              </div>
                            </div>

                          {/*authUserInfo.details.user_type !== "call_center" && (
                            <div className=" m-0 p-0 cash d-flex justify-content-start">
                              <button
                                disabled={buttonDisabled}
                                className="btn btn-info sm-text text-uppercase font-weight-bold rounded-25"
                                type="button"
                                data-toggle="modal"
                                data-target={`${
                                  orderDetails.branch && newSettings?.workPeriod
                                    ? "#cashModal"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  !orderDetails.branch &&
                                  toast.error(
                                    `${_t(t("You have to select a branch"))}`,
                                    toastObj
                                  )
                                }
                              >
                                cash{" "}
                              </button>
                            </div>
                              )*/}
                          <div className="ml-1 d-flex justify-content-start">
                            <button
                              disabled={buttonDisabled}
                              className=" btn btn-success xsm-text text-uppercase rounded-25"
                              onClick={(e) =>
                                newOrder && newOrder.length > 0
                                  ? setBackModal(true)
                                  : ""
                              }
                            >
                              Back
                            </button>
                          </div>
                          {authUserInfo.permissions !== null &&
                            checkPermission(
                              authUserInfo.permissions,
                              "Submit"
                            ) && authUserInfo.details.user_type !== "call_center" ? (
                              <button
                                disabled={buttonDisabled}
                                type="button"
                                className="w-15 ml-1 btn btn-danger xsm-text d-flex align-items-center justify-content-center text-uppercase col-3 rounded-25 font-weight-bold hover-effect"
                                onClick={(e) => {
                                  order_hold.current = 1;
                                  !loading && handleSubmitOrder(e)}}
                              >
                                {!loading ?
                                     _t(_t("hold"))
                                  :  _t(_t("Please wait"))}
                              </button>
                            ) : (
                              ""
                            )}
                            {
                              navigator.onLine && (
                                <button
                                disabled={buttonDisabled}
                                type="button"
                                style={{width:"2.9rem"}}
                                className=" ml-1 btn btn-secondary xsm-text d-flex align-items-center justify-content-center text-uppercase col-2 rounded-25 font-weight-bold hover-effect mr-1"
                                onClick={(e) => {
                                  !loading && synclocalAllOrders(e)}}
                                  >
                                  Sync
                                </button>
                              )
                            }
                        </div>
                        <div className="col-5 d-flex justify-content-end bill-submit">
                        
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Settle"
                          ) ? (
                            !order_id &&
                            authUserInfo.details.user_type !== "call_center" ? (
                              <button
                                disabled={
                                  (selectedDeliveryType &&
                                    selectedDeliveryType?.name?.match(
                                      /dine(.*)in/i
                                    ) &&
                                    parseInt(
                                      getSystemSettings(
                                        generalSettings,
                                        "table_waiter"
                                      )
                                    ) === 1) ||
                                  buttonDisabled
                                }
                                type="button"
                                className="mr-1 w-15 btn btn-success3 d-flex align-items-center justify-content-center xsm-text text-uppercase col-6 rounded-25 font-weight-bold hover-effect"
                                onClick={handleSettleOrderButton}
                              >
                                {!loading ? "settle" : "Please wait"}
                              </button>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                         
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Submit"
                          ) ? (
                            <button
                              disabled={buttonDisabled}
                              type="button"
                              className="w-15 btn btn-primary xsm-text d-flex align-items-center justify-content-center text-uppercase col-6 rounded-25 font-weight-bold hover-effect mr-1"
                              onClick={!loading && handleSubmitOrder}
                            >
                              {!loading
                                ? _t(_t("submit"))
                                :  _t(_t("Please wait"))}
                            </button>
                          ) : (
                            ""
                          )}
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SettleOrder
        showSettle={showSettle}
        setShowSettle={setShowSettle}
        selectedDeptTag={selectedDeptTag}
        selectedParty={selectedParty}
        selectedDeliveryType={selectedDeliveryType}
        setSelectedDeliveryType={setSelectedDeliveryType}
        buttonDisabled={buttonDisabled}
        setButtonDisabled={setButtonDisabled}
        newOrder={newOrder}
        cartItems={cartItems}
        setCartItems={setCartItems}
        cartTotal={cartTotal}
        selectedBranch={selectedBranch}
        orderDetails={orderDetails}
        setOrderDetails={setOrderDetails}
        deliveryCharges={deliveryCharges}
        customerApi={customerApi}
        setActiveItemInOrder={setActiveItemInOrder}
        customer={customer}
        activeItemInOrder={activeItemInOrder}
        showPriceOfEachOrderItem={(p) =>
          getFormattedPrice(
            p.after_tax_price
              ? p.after_tax_price
              : getBeforeTaxPrice(calculatePriceForCartItem(p))
          )
        }
        theVatSettle={theVatSettle}
        setTheVatSettle={setTheVatSettle}
        setPaidMoney={setPaidMoney}
        setNewSettings={setNewSettings}
        newSettings={newSettings}
        totalsettle={totalsettle}
        setTotalSettle={setTotalSettle}
        paidMoney={paidMoney}
        serviceCharges={serviceCharges}
        foodItem={foodItem}
        setFoodItem={setFoodItem}
        cartSubTotal={cartSubTotal}
        discount={discount}
        deptCommission={deptCommission}
        tokenNumber={tokenNumber}
        theVat={theVat}
        theTax={theTax}
        taxType={taxType}
        setTheVat={setTheVat}
        returnMoneyUsd={returnMoneyUsd}
        setReturnMoneyUsd={setReturnMoneyUsd}
        setIsSettle={setIsSettle}
        getPrice={(p) => getFormattedPrice(getBeforeTaxPrice(p))}
        handleLocalPrint={handleLocalPrint}
        paymentTypeAvailable={paymentTypeAvailable}
        setFbrInvoiceNum={setFbrInvoiceNum}
        setSrbInvoiceNum={setSrbInvoiceNum}
        staffSearch={staffSearch}
      />

      <OrderPrint
        newOrder={newOrder}
        setNewOrder={setNewOrder}
        foodGroupsToPrint={foodGroupsToPrint}
        cartSubTotal={cartSubTotal}
        cartTotal={cartTotal}
        newSettings={newSettings}
        con_Kot={con_Kot}
        selectedPropertyGroup={selectedPropertyGroup}
        totalSettle={totalsettle}
        theVat={theVat}
        theVatSettle={theVatSettle}
        setReturnMoneyUsd={setReturnMoneyUsd}
        setPaidMoney={setPaidMoney}
        paidMoney={paidMoney}
        orderDetails={orderDetails}
        taxType={taxType}
        returnMoneyUsd={returnMoneyUsd}
        isSettle={isSettle}
        srbInvoiceNum={srbInvoiceNum}
        fbrInvoiceNum={fbrInvoiceNum}
        setFbrInvoiceNum={setFbrInvoiceNum}
        setSrbInvoiceNum={setSrbInvoiceNum}
        serviceCharges={serviceCharges}
        deliveryCharges={deliveryCharges}
        selectedDeliveryType={selectedDeliveryType}
        selectedParty={selectedParty}
        selectedDeptTag={selectedDeptTag}
        customerPrint={componentRef}
        component2Ref={component2Ref}
        setKotPrintIdx={setKotPrintIdx}
      />
    
      {/* Authenticate Modal */}
      {showPasswordPopup ? (
        <PasscodeAuthenticator
          correctPasscode={authUserInfo.details.user_passcode}
          setComponentUnlock={setComponentUnlock}
          thisComponent={componentUnlock}
          onSuccess={onSuccessAuthenticationStaff}
          show={showPasswordPopup}
          hanleCancelAuthenticate={hanleCancelAuthenticate}
        />
      ) : null}

      <PosModal
      handleCardSubmit={handleCardSubmit}
      setOrderDetails={setOrderDetails}
      orderDetails={orderDetails}
      cartTotal={cartTotal}
      setCartTotal={setCartTotal}
      cartSubTotal={cartSubTotal}
      setDiscount={setDiscount}
      cardModal={cardModal}
      addReasonOnSubmit={addReasonOnSubmit}
      setAddReasonModalShowing={setAddReasonModalShowing}
      addReasonModalShowing={addReasonModalShowing}
      deleteReason={deleteReason}
      setDeleteReason={setDeleteReason}
      backModal={backModal}
      setBackModal={setBackModal}
      noteRiderModal={noteRiderModal}
      setNoteRiderModal={setNoteRiderModal}
      emptyCard={emptyCard}
      setButtonDisabled={setButtonDisabled}
      cashDetails={cashDetails}
      setCashDetails={setCashDetails}
      setPaymentTypeModalState={setPaymentTypeModalState}
      paymentTypeModalState={paymentTypeModalState}
      />
      {/* beep sound */}
      <audio id="myAudio">
        <source src="/assets/beep/beep.mp3" type="audio/mpeg" />
      </audio>

      <audio id="myAudioPhoneRing">
        <source src="/assets/beep/notify.mp3" type="audio/mpeg" />
      </audio>

      <audio id="myAudioNew">
        <source src="/assets/beep/beep2.mp3" type="audio/mpeg" />
      </audio>

    </>
  );
}

export default Pos;
