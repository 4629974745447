import React, { useState, useEffect } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

//functions
import { getCookie } from "../functions/Functions";

//3rd party packages

// creating context api
const FoodContext = React.createContext();

const FoodProvider = ({ children }) => {
  // State hooks  here
  //loading
  const [loading, setLoading] = useState(false);
  const [dataPaginating, setDataPaginating] = useState(false);

  //website
  const [foodListWeb, setFoodListWeb] = useState(null);
  const [foodStockWeb, setFoodStockWeb] = useState(null);
  const [foodGroupWeb, setFoodGroupWeb] = useState(null);
  const [propertyGroupWeb, setPropertyGroupWeb] = useState(null);
  const [workPeriodWeb, setWorkPeriodWeb] = useState(null);
  const [onlineOrdersCustomer, setOnlineOrdersCustomer] = useState(null);

  //pos page online orders
  const [onlineOrdersAdmin, setOnlineOrdersAdmin] = useState(null);
  const [onlineOrdersAdminForSearch, setOnlineOrdersAdminForSearch] =
    useState(null);
    
      //pos page food panda orders
  const [foodPandaOrders, setFoodPandaOrders] = useState(null);
  const [foodPandaOrdersForSearch, setFoodPandaOrdersForSearch] =
    useState(null);
  //foods
  const [foodList, setFoodList] = useState(null);
  const [foodForSearch, setFoodForSearch] = useState(null);

  //foods for pos
  const [foodForPOS, setFoodForPOS] = useState(null);

  //food stock
  const [foodItemStock, setFoodItemStock] = useState(null);
  const [foodPurchaseHistory, setFoodPurchaseHistory] = useState(null);
  const [foodPurchaseHistoryForSearch, setFoodPurchaseHistoryForSearch] =
    useState(null);

  //food group
  const [foodGroupList, setFoodGroupList] = useState(null);
  const [foodGroupForSearch, setFoodGroupforSearch] = useState(null);
  const [foodGroupForSearchForPOS, setFoodGroupforSearchForPOS] =
    useState(null);

  //food unit
  const [foodUnitList, setFoodUnitList] = useState(null);
  const [foodUnitForSearch, setFoodUnitforSearch] = useState(null);

  //variation
  const [variationList, setVariationList] = useState(null);
  const [variationForSearch, setVariationForSearch] = useState(null);

  //property Group
  const [propertyGroupList, setPropertyGroupList] = useState(null);
  const [propertyGroupForSearch, setPropertyGroupForSearch] = useState(null);

  //property Item
  const [propertyItemList, setPropertyItemList] = useState(null);
  const [propertyItemForSearch, setPropertyItemForSearch] = useState(null);
  const [propertyItemGroup, setPropertyItemGroup] = useState(null);

  //stock
  let [items, setItems] = useState(null);
  let [theGroups, setTheGroups] = useState(null);
  let [itemsFood, setItemsFood] = useState(null);
  let [theGroupsFood, setTheGroupsFood] = useState(null);

  //food grops menu
  const [availableFoodGroups, setAvailableFoodGroups] = useState({});

  const [getOrderDetailsState, setGetOrderDetailsState] = useState(null);

  // localStorage helper
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  };

  // localStorage helper
  const getDataFromLocal = (url) => {
    return JSON.parse(localStorage.getItem(url));
  };

  //check if offline helper
  const offline = (url) => {
    return !navigator.onLine && localStorage.getItem(url) != null;
  };

  //useEffect- to get data on render
  useEffect(() => {
    //call- unauthenticated
    getFoodWeb();
    //call if authenticated
    if (getCookie() !== undefined || offline(BASE_URL + "/auth/user")) {
      getFood();
      getFoodItemsForPOS();
      getFoodGroup();
      getFoodGroupForPOS();
      getFoodUnit();
      getVariation();
      getPropertyGroup();
      getFoodGroupWithMenu();
    }
  }, []);

  // Helper
  const setIngredientsStock = (res) => {
    setItems(res.data[0]);
    setTheGroups(res.data[1]);
    setLoading(false);
  };

  //stock  // Priority ->
  const getIngredientStock = async (id) => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-closing-items/" + id;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setIngredientsStock(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setIngredientsStock(parseRes);
      }
    }
  };

  // Helper
  const setFoodsStockReport = (res) => {
    setItemsFood(res.data[0]);
    setTheGroupsFood(res.data[1]);
    setLoading(false);
  };

  // Priority ->
  const getFoodStockReport = async (id) => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-closing-items-food/" + id;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setFoodsStockReport(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setFoodsStockReport(parseRes);
      }
    }
  };

  // Helper
  const setFoodsWeb = (res) => {
    setFoodListWeb(res.data[0]);
    setFoodGroupWeb(res.data[1]);
    setPropertyGroupWeb(res.data[2]);
    setWorkPeriodWeb(res.data[3]);
    setFoodStockWeb(res.data[[4]]);
  };

  //website
  //get foods web  // Priority ->
  const getFoodWeb = async () => {
    setLoading(true);
    const foodItemWebUrl = BASE_URL + "/website/home";
    try {
      const res = await axios.get(foodItemWebUrl);
      setFoodsWeb(res);
      setDataToLocal(foodItemWebUrl, res);
    } catch (error) {
      if (offline(foodItemWebUrl)) {
        let parseRes = getDataFromLocal(foodItemWebUrl);
        setFoodsWeb(parseRes);
      }
    }
  };

  // Helper
  const setOnlineOrderCustomer = (res) => {
    setOnlineOrdersCustomer(res.data[1]);
    setLoading(false);
  };

  //get online orders customer  // Priority ->
  const getOnlineOrdersCustomer = async () => {
    setLoading(true);
    const ordersUrl = BASE_URL + "/website/online-orders/customer";
    try {
      const res = await axios.get(ordersUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setOnlineOrderCustomer(res);
      setDataToLocal(ordersUrl, res);
    } catch (error) {
      if (offline(ordersUrl)) {
        let parseRes = getDataFromLocal(ordersUrl);
        setOnlineOrderCustomer(parseRes);
      }
    }
  };

  // Helper
  const setOlineOrderAdmin = (res) => {
    setOnlineOrdersAdmin(res.data[0]);
    setOnlineOrdersAdminForSearch(res.data[1]);
    setLoading(false);
  };

  //get online orders  // Priority ->
  const getOnlineOrders = async () => {
    setLoading(true);
    const ordersUrl = BASE_URL + "/website/online-orders";
    try {
      const res = await axios.get(ordersUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setOlineOrderAdmin(res);
      setDataToLocal(ordersUrl, res);
    } catch (error) {
      if (offline(ordersUrl)) {
        let parseRes = getDataFromLocal(ordersUrl);
        setOlineOrderAdmin(parseRes);
      }
    }
  };

  // Helper
  const setPaginatedOnlineOrder = (res) => {
    setOnlineOrdersAdmin(res.data[0]);
    setOnlineOrdersAdminForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated online orders // Priority ->
  const setPaginatedOnlineOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/website/online-orders?page=" + pageNo;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setPaginatedOnlineOrder(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedOnlineOrder(parseRes);
      }
    }
  };

    // Helper
    const setFoodPandaOrder= (res) => {
      setFoodPandaOrders(res.data[0]);
      setFoodPandaOrdersForSearch(res.data[1]);
      setLoading(false);
    };
  
    //get food panda orders  // Priority ->
    const getFoodPandaOrders = async () => {
      setLoading(true);
      const ordersUrl = BASE_URL + "/website/online-orders-fp";
      try {
        const res = await axios.get(ordersUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setFoodPandaOrder(res);
        setDataToLocal(ordersUrl, res);
      } catch (error) {
        if (offline(ordersUrl)) {
          let parseRes = getDataFromLocal(ordersUrl);
          setFoodPandaOrder(parseRes);
        }
      }
    };
  
    // Helper
    const setPaginatedFoodPandaOrder = (res) => {
      setFoodPandaOrders(res.data[0]);
      setFoodPandaOrdersForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    // get paginated food panda orders // Priority ->
    const setPaginatedPandaOrders = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/website/online-orders-fp?page=" + pageNo;
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedFoodPandaOrder(res);
        setDataToLocal(url, res);
      } catch (error) {
        if (offline(url)) {
          let parseRes = getDataFromLocal(url);
          setPaginatedFoodPandaOrder(parseRes);
        }
      }
    };

  // Helper
  const setFoods = (res) => {
    setFoodList(res.data[0]);
    setFoodForSearch(res.data[1]);
    setLoading(false)
  };

  //get foods  // Priority ->
  const getFood = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-food-item";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setFoods(res);
      //setDataToLocal(url, res);
    } catch (error) {
        if (offline(url)) {
          let parseRes = getDataFromLocal(url);
          //setFoods(parseRes);
        }
    }
  };

  // Helper
  const setPaginatedFoods = (res) => {
    setFoodList(res.data[0]);
    setFoodForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated food  // Priority ->
  const setPaginatedFood = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-food-item?page=" + pageNo;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setPaginatedFoods(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedFoods(parseRes);
      }
    }
  };

  // get food groups food items
  const getFoodGroupWithMenu = () => {
    const url = BASE_URL + "/settings/get-food-group-with-menu";
    axios
      .get(url, { headers: { Authorization: `Bearer ${getCookie()}` } })
      .then((res) => {
        setAvailableFoodGroups(res.data);
        setDataToLocal(url, res);
        // save to ls
      })
      .catch((err) => {
        // pwa work
        if (offline(url)) {
          let parseRes = getDataFromLocal(url);
          setAvailableFoodGroups(parseRes.data);
        }
      });
  };
  //get food items for POS  // Priority ->
  const getFoodItemsForPOS = async (toSetLoading = false) => {
    if (!toSetLoading) setLoading(true);
    const url = BASE_URL + "/settings/get-enabled-item";
    const res = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodForPOS(res.data[1]);
        setDataToLocal(url, res);
      })
      .catch((error) => {
        if (offline(url)) {
          let parseRes = getDataFromLocal(url);
          setFoodForPOS(parseRes.data[1]);
          // setFoods(parseRes.data[1]);
        }
      });
  };

  // Helper
  const setFoodsGroup = (res) => {
    setFoodGroupList(res.data[0]);
    setFoodGroupforSearch(res.data[1]);
    setFoodItemStock(res.data[2]);
    setLoading(false);
  };

  //get food groups  // Priority
  const getFoodGroup = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-food-group";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodsGroup(res);
        setDataToLocal(url, res);
      })
      .catch((error) => {
        if (offline(url)) {
          let parseRes = getDataFromLocal(url);
          setFoodsGroup(parseRes);
        }
      });
  };

  const getFoodGroupForPOS = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-food-group_status";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodGroupforSearchForPOS(res.data[1]);
        setDataToLocal(url, res);
        setLoading(false);
      })
      .catch((error) => {
        if (offline(url)) {
          let parseRes = getDataFromLocal(url);
          setFoodGroupforSearchForPOS(parseRes.data[1]);
        }
      });
  };

  // Helper
  const setPaginatedFoodsGroup = (res) => {
    setFoodGroupList(res.data[0]);
    setFoodGroupforSearch(res.data[1]);
    setFoodItemStock(res.data[2]);
    setDataPaginating(false);
  };

  // get paginated food groups  // Priority ->
  const setPaginatedFoodGroup = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-food-group?page=" + pageNo;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setPaginatedFoodsGroup(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedFoodsGroup(parseRes);
      }
    }
  };

  //Helper
  const setFoodsUnit = (res) => {
    setFoodUnitList(res.data[0]);
    setFoodUnitforSearch(res.data[1]);
    setLoading(false);
  };

  //get food units  // Priority ->
  const getFoodUnit = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-food-unit";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setFoodsUnit(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setFoodsUnit(parseRes);
      }
    }
  };

  // Helper
  const setPaginatedFoodsUnit = (res) => {
    setFoodUnitList(res.data[0]);
    setFoodUnitforSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated food units // Priority ->
  const setPaginatedFoodUnit = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-food-unit?page=" + pageNo;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setPaginatedFoodsUnit(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedFoodsUnit(parseRes);
      }
    }
  };

  // Helper
  const setVariationsList = (res) => {
    setVariationList(res.data[0]);
    setVariationForSearch(res.data[1]);
    setLoading(false);
  };

  //get variations  //Priority ->
  const getVariation = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-variation";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setVariationsList(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setVariationsList(parseRes);
      }
    }
  };

  // Helper
  const setPaginatedVariations = (res) => {
    setVariationList(res.data[0]);
    setVariationForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated variations  // Priority ->
  const setPaginatedVariation = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-variation?page=" + pageNo;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setPaginatedVariations(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedVariations(parseRes);
      }
    }
  };

  // Helper
  const setPropertyGroups = (res) => {
    setPropertyGroupList(res.data[0]);
    setPropertyGroupForSearch(res.data[1]);
    setLoading(false);
  };

  //get property group  // Priority ->
  const getPropertyGroup = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-property-group";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setPropertyGroups(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPropertyGroups(parseRes);
      }
    }
  };

  // Helper
  const setPaginatedPropertiesGroup = (res) => {
    setPropertyGroupList(res.data[0]);
    setPropertyGroupForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated property group  // Priority ->
  const setPaginatedPropertyGroup = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-property-group?page=" + pageNo;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setPaginatedPropertiesGroup(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedPropertiesGroup(parseRes);
      }
    }
  };

  // Helper
  const setPropertyItems = (res) => {
    setPropertyItemList(res.data[0]);
    setPropertyItemForSearch(res.data[0]);
    setPropertyItemGroup(res.data[1]);
    setLoading(false);
  };

  //get property item  // Priority ->
  const getPropertyItem = async (slug) => {
    setLoading(true);
    //const propertyItemUrl = BASE_URL + "/settings/get-property-item/" + slug;
    //Change fahad //
    const propertyItemUrl = BASE_URL + "/settings/get-property-item/" + slug;
    try {
      const res = await axios.get(propertyItemUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setPropertyItems(res);
      setDataToLocal(propertyItemUrl, res);
    } catch (error) {
      if (offline(propertyItemUrl)) {
        let parseRes = getDataFromLocal(propertyItemUrl);
        setPropertyItems(parseRes);
      }
    }
  };

  // Helper
  const setFoodsPurchase = (res) => {
    setFoodPurchaseHistory(res.data[0]);
    setFoodPurchaseHistoryForSearch(res.data[1]);
    setLoading(false);
  };

  //get food purchases  // Priority ->
  const getFoodPurchase = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-food_purchase";
    try {
      const res = await axios.get(url);
      setDataToLocal(url, res);
      setFoodsPurchase(res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setFoodsPurchase(parseRes);
      }
    }
  };

  // Helper
  const setPaginatedFoodsPurchase = (res) => {
    setFoodPurchaseHistory(res.data[0]);
    setFoodPurchaseHistoryForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated purchases  // Priority ->
  const setPaginatedFoodPurchase = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-food_purchase?page=" + pageNo;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setPaginatedFoodsPurchase(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedFoodsPurchase(parseRes);
      }
    }
  };

  //Helper
  const setGetOrderDetails = (res) => {
    setGetOrderDetailsState(res.data.data[0]);
  };

  //Get Order Detail  // Priority ->
  async function getOrderDetails(id) {
    const url = BASE_URL + "/settings/get-order/" + id;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(async (res) => {
        console.log("indexOrder", res);
        setGetOrderDetails(res);
        return Promise.resolve(res.data.data[0]);
        setDataToLocal(url, res);
      })
      .catch(async (error) => {
        if (offline(url)) {
          let parseRes = getDataFromLocal(url);
          setGetOrderDetails(parseRes);
          return Promise.resolve(parseRes.data.data[0]);
        }
      });
  }

  return (
    <FoodContext.Provider
      value={{
        // common
        loading,
        setLoading,

        //ingredient stock
        getIngredientStock,
        items,
        setItems,
        theGroups,
        setTheGroups,

        //food stock
        getFoodStockReport,
        itemsFood,
        setItemsFood,
        theGroupsFood,
        setTheGroupsFood,

        //website
        getFoodWeb,
        foodGroupWeb,
        foodListWeb,
        setFoodListWeb,
        foodStockWeb,
        setFoodStockWeb,
        propertyGroupWeb,
        setPropertyGroupWeb,
        workPeriodWeb,
        onlineOrdersAdmin,
        setOnlineOrdersAdmin,
        onlineOrdersAdminForSearch,
        setOnlineOrdersAdminForSearch,
        getOnlineOrders,
        setPaginatedOnlineOrders,
        getOnlineOrdersCustomer,
        onlineOrdersCustomer,
        setOnlineOrdersCustomer,

        //food panda
        getFoodPandaOrders,
        setPaginatedPandaOrders,
        setFoodPandaOrders,
        setFoodPandaOrdersForSearch,
        foodPandaOrders,
        foodPandaOrdersForSearch,

        //food
        getFood,
        foodList,
        setFoodList,
        setPaginatedFood,
        foodForSearch,
        setFoodForSearch,

        //food for POS
        getFoodItemsForPOS,
        setFoodForPOS,
        foodForPOS,

        //food purchases
        getFoodPurchase,
        foodPurchaseHistory,
        setFoodPurchaseHistory,
        setPaginatedFoodPurchase,
        foodPurchaseHistoryForSearch,
        setFoodPurchaseHistoryForSearch,

        //stock
        foodItemStock,
        //food group
        getFoodGroup,
        foodGroupList,
        setFoodGroupList,
        setPaginatedFoodGroup,
        foodGroupForSearch,
        setFoodGroupforSearch,
        getFoodGroupForPOS,
        foodGroupForSearchForPOS,
        setFoodGroupforSearchForPOS,

        //food units
        getFoodUnit,
        foodUnitList,
        setFoodUnitList,
        setPaginatedFoodUnit,
        foodUnitForSearch,
        setFoodUnitforSearch,

        //variation
        getVariation,
        variationList,
        setVariationList,
        setPaginatedVariation,
        variationForSearch,
        setVariationForSearch,

        //property group
        getPropertyGroup,
        propertyGroupList,
        setPropertyGroupList,
        setPaginatedPropertyGroup,
        propertyGroupForSearch,
        setPropertyGroupForSearch,

        //property Item
        getPropertyItem,
        propertyItemList,
        setPropertyItemList,
        propertyItemForSearch,
        setPropertyItemForSearch,
        propertyItemGroup,
        setPropertyItemGroup,

        //pagination
        dataPaginating,
        setDataPaginating,

        getOrderDetailsState,
        setGetOrderDetailsState,
        getOrderDetails,

        //food group menu
        availableFoodGroups,
        setAvailableFoodGroups,
        getFoodGroupWithMenu,
      }}
    >
      {children}
    </FoodContext.Provider>
  );
};

export { FoodContext, FoodProvider };
