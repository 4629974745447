import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { data } from "jquery";

const MenuTagCrud = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  let {
    //dept-tag
    getDeptTag,
    deptTagList,
    setDeptTagList,
    setPaginatedDeptTag,
    deptTagForSearch,
    setDeptTagForSearch,

    branchForSearch,
    orderTypeForSearch,

    //pagination
    dataPaginating,
  } = useContext(RestaurantContext);

  // States hook here
  //new dept-tag
  let [newDeptTag, setNewDeptTag] = useState({
    name: "",
    commission: 0,
    edit: false,
    editSlug: null,
    uploading: false,
    branch_id: null,
    branch_name: null,
  });

  //search result
  let [searchedDeptTag, setSearchedDeptTag] = useState({
    list: null,
    searched: false,
  });

  const [duplicateModalStates, setDuplicateModalStates] = useState({
    open: false,
    to: null,
    from: null,
    prefix: null,
    uploading: false
  })

  let [associateWith, setAssociateWith] = useState([]);
  let [branch, setBranch] = useState(null);
  let [defaultMenu, setDefaultMenu] = useState(null);
  //useEffect == componentDidMount
  useEffect(() => {
    let getMenuId = deptTagList.data.filter((item) => {
      return parseInt(item.is_default_menu) === 1;
    });
    setDefaultMenu(getMenuId[0]?.id);
  }, []);

  //set name hook
  const handleSetNewDeptTag = (e) => {
    setNewDeptTag({ ...newDeptTag, [e.target.name]: e.target.value });
  };

  const openDuplicatModal = (id) => {
    setDuplicateModalStates({
      ...duplicateModalStates,
      open: true,
      from: id,
    })
  }

  const handleDuplicateModalSubmit = (e) => {
    if (!duplicateModalStates.to) {
      toast.error('You must select a menu to duplicate.',{
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else if(!duplicateModalStates.prefix){
      toast.error('Please type prefix to duplicate.',{
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      console.log('ar-log trying')
      setDuplicateModalStates({
        ...duplicateModalStates,
        uploading: true
      })
      handleDuplicateAxios(duplicateModalStates.from, duplicateModalStates.to, duplicateModalStates.prefix)
    }
  }

  const handleDuplicateAxios = (from, to, prefix) => {
    console.log('ar-log trying 2')
    const duplicateMenuUrl = BASE_URL + `/settings/duplicate-menu`;
    const formData = new FormData()
    formData.append('from', from)
    formData.append('to', to)
    formData.append('prefix',prefix)
    return axios
      .post(duplicateMenuUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res && res.data && res.data.code === 1) {
          toast.success('Items duplicated successfully!', {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          toast.error(res.data.message, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      })
      .catch((err) => {
        toast.error('Please try again.',{
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .finally(() => {
        setDuplicateModalStates({
          open: false,
          from: null,
          to: null,
          prefix: null,
          uploading: false
        })
      })
  }

  //Save New paymentType
  const handleSaveNewDeptTag = (e) => {
    e.preventDefault();
    setNewDeptTag({
      ...newDeptTag,
      uploading: true,
    });

    const foodGroupUrl = BASE_URL + `/settings/new-dept-tag`;
    let formData = new FormData();
    formData.append("name", newDeptTag.name);
    formData.append("commission", newDeptTag.commission);
    //formData.append("branch_id", newDeptTag.branch_id);
    formData.append("branch_name", newDeptTag.branch_name);

    let tempbranch = [];
    branch.map((Item) => {
      tempbranch.push(Item.id);
    });
    formData.append("branch_id", tempbranch);

    let tempArray = [];
    associateWith.map((Item) => {
      tempArray.push(Item.id);
    });
    formData.append("associate_with", tempArray);

    return axios
      .post(foodGroupUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewDeptTag({
          name: "",
          commission: 0,
          edit: false,
          editSlug: null,
          uploading: false,
        });
        setAssociateWith([]);
        setBranch(null);
        setDeptTagList(res.data[0]);
        setDeptTagForSearch(res.data[1]);
        setSearchedDeptTag({
          ...searchedDeptTag,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Menu has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewDeptTag({
          ...newDeptTag,
          uploading: false,
        });
        if (error.response.data.errors) {
          if (error.response.data.errors.name) {
            error.response.data.errors.name.forEach((item) => {
              if (item === "A menu already exists with this name") {
                toast.error(
                  `${_t(t("A menu already exists with this name"))}`,
                  {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  }
                );
              }
            });
          } else {
            toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        }
      });
  };

  //set edit true & values
  const handleSetEdit = (slug) => {
    let paymentType = deptTagForSearch.filter((item) => {
      return item.slug === slug;
    });
    console.log("paymentType[0]", deptTagForSearch);
    let selectOptionsProprttyGroup = [];
    let selectOptionsBranch = [];
    const propertyArray = JSON.parse(paymentType[0].associate_with);
    const branchArray = JSON.parse(paymentType[0].branch_id);
    console.log(
      "paymentType On Edit",
      paymentType,
      " assiative ",
      propertyArray
    );
    if (propertyArray.length !== 0) {
      for (var property of propertyArray) {
        for (var i = 0; i < orderTypeForSearch.length; i++) {
          if (orderTypeForSearch[i].id == property) {
            selectOptionsProprttyGroup.push(orderTypeForSearch[i]);
          }
        }
        setAssociateWith(selectOptionsProprttyGroup);
      }
    } else {
      setAssociateWith([]);
    }

    if (branchArray.length !== 0) {
      for (var property of branchArray) {
        for (var i = 0; i < branchForSearch.length; i++) {
          if (branchForSearch[i].id == property) {
            selectOptionsBranch.push(branchForSearch[i]);
          }
        }
        setBranch(selectOptionsBranch);
      }
    } else {
      setBranch([]);
    }

    const branch_id = parseInt(paymentType[0].branch_id);
    if (branch_id) {
      let selectedBranch = branchForSearch.filter((item) => {
        return item.id === branch_id;
      });
      setBranch(selectedBranch);
    }

    setNewDeptTag({
      ...newDeptTag,
      name: paymentType[0].name,
      commission: paymentType[0].commission,
      input_key: paymentType[0].input_key,
      editSlug: paymentType[0].slug,
      branch_id: paymentType[0].branch_id,
      branch_name: paymentType[0].branch_name,
      edit: true,
    });
  };

  //update food group
  const handleUpdateDeptTag = (e) => {
    e.preventDefault();
    setNewDeptTag({
      ...newDeptTag,
      uploading: true,
    });
    const foodGroupUrl = BASE_URL + `/settings/update-dept-tag`;
    let formData = new FormData();
    formData.append("name", newDeptTag.name);
    formData.append("commission", newDeptTag.commission);
    formData.append("editSlug", newDeptTag.editSlug);
    // formData.append("branch_id", newDeptTag.branch_id);
    formData.append("branch_name", newDeptTag.branch_name);

    // for Branch Multi
    let tempbranch = [];
    branch.map((Item) => {
      tempbranch.push(Item.id);
    });
    formData.append("branch_id", tempbranch);
    //for Associate_with
    let tempArray = [];
    associateWith.map((Item) => {
      tempArray.push(Item.id);
    });
    formData.append("associate_with", tempArray);

    return axios
      .post(foodGroupUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewDeptTag({
          name: "",
          commission: 0,
          edit: false,
          editSlug: null,
          uploading: false,
        });
        setAssociateWith([]);
        setBranch(null);
        setDeptTagList(res.data[0]);
        setDeptTagForSearch(res.data[1]);
        setSearchedDeptTag({
          ...searchedDeptTag,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Menu has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewDeptTag({
          ...newDeptTag,
          uploading: false,
        });
        if (error.response.data.errors) {
          if (error.response.data.errors.name) {
            error.response.data.errors.name.forEach((item) => {
              if (item === "A Menu already exists with this name") {
                toast.error(
                  `${_t(t("A Menu already exists with this name"))}`,
                  {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  }
                );
              }
            });
          } else {
            toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        }
      });
  };

  //search food group here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedDeptTag({ ...searchedDeptTag, searched: false });
    } else {
      let searchedList = deptTagForSearch.filter((item) => {
        let lowerCaseItemName = item.name?.toLowerCase();
        return lowerCaseItemName && lowerCaseItemName.includes(searchInput);
      });
      setSearchedDeptTag({
        ...searchedDeptTag,
        list: searchedList,
        searched: true,
      });
    }
  };

  //delete confirmation modal of paymentType
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteDeptTag(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete paymentType here
  const handleDeleteDeptTag = (slug) => {
    setLoading(true);
    const foodGroupUrl = BASE_URL + `/settings/delete-dept-tag/${slug}`;
    return axios
      .get(foodGroupUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDeptTagList(res.data[0]);
        setDeptTagForSearch(res.data[1]);
        setSearchedDeptTag({
          ...searchedDeptTag,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Menu has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  const handleSetAssociate = (accosiateOption) => {
    console.log("accosiateOption", accosiateOption);
    // setNewParty({ ...newParty, accosiateOption });
    setAssociateWith(accosiateOption);
  };

  const handleSetBranch = (branch) => {
    console.log("branch", branch);
    // setNewParty({ ...newParty, branch });
    setNewDeptTag({
      ...newDeptTag,
      branch_id: branch.id,
      branch_name: branch.name,
    });
    setBranch(branch);
  };

  const handleDefaultMenu = (menu_id) => {
    setDefaultMenu(menu_id);

    const foodGroupUrl = BASE_URL + `/settings/update-default-menu`;

    let formData = new FormData();

    formData.append("id", menu_id);

    return axios
      .post(foodGroupUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLoading(false);
        toast.success(
          `${_t(t("The menu has been set successfully on by default"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Menu"))}</title>
      </Helmet>

      {/* Add modal */}
      <div className="modal fade" id="addPaymentType" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newDeptTag.edit
                    ? _t(t("Add new menu"))
                    : _t(t("Update menu"))}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newDeptTag.uploading === false ? (
                <div key="fragment-dept-tag-1">
                  <form
                    onSubmit={
                      !newDeptTag.edit
                        ? handleSaveNewDeptTag
                        : handleUpdateDeptTag
                    }
                  >
                    <div>
                      <label htmlFor="name" className="form-label">
                        {_t(t("Menu Name"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="e.g. Regular Menu, Foodpanda Menu, Bykea Menu etc"
                        value={newDeptTag.name || ""}
                        required
                        onChange={handleSetNewDeptTag}
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="associate" className="form-label">
                        {_t(t("Associate With"))}
                        <small className="text-danger">*</small>
                      </label>
                      <Select
                        value={associateWith}
                        options={orderTypeForSearch}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti={true}
                        maxMenuHeight="200px"
                        onChange={handleSetAssociate}
                        placeholder={
                          _t(t("Please select associate menu")) + ".."
                        }
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="branch" className="form-label">
                        {_t(t("Branch"))}
                        <small className="text-danger">*</small>
                      </label>
                      <Select
                        value={branch}
                        options={branchForSearch}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti={true}
                        maxMenuHeight="200px"
                        onChange={handleSetBranch}
                        placeholder={_t(t("Please select branch")) + ".."}
                      />
                    </div>
                   {/*
                    <div className="mt-3">
                      <label htmlFor="commission" className="form-label">
                        {_t(t("Commission"))}
                        {"( % )"}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="number"
                        step="0.001"
                        className="form-control"
                        id="commission"
                        name="commission"
                        min="0"
                        placeholder="e.g. 10, insert 0 (zero) for no commission"
                        value={newDeptTag.commission || ""}
                        required
                        onChange={handleSetNewDeptTag}
                      />
                      </div>*/}

                    <div className="mt-4">
                      <div className="row d-flex justify-content-end">
                        <div className="col-3">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          >
                            {!newDeptTag.edit ? _t(t("Save")) : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                            data-dismiss="modal"
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newDeptTag.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* Duplicate modal */}
      <div className={`modal fade ${duplicateModalStates.open ? 'showing' : ''}`} id="duplicate" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {_t(t("Duplicate menu"))}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault()
                  setDuplicateModalStates({
                    open: false,
                    to: null,
                    from: null,
                    prefix: null,
                    uploading: false
                  })
                }}
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {duplicateModalStates.uploading === false ? (
                <div key="fragment-dept-tag-1">
                  <form
                    onSubmit={handleDuplicateModalSubmit}
                  >
                    <div className="mt-3">
                      <label htmlFor="" className="form-label">
                        {_t(t("Menu To Duplicate To"))}
                        <small className="text-danger">*</small>
                      </label>
                      <Select
                        value={
                          deptTagList.data && deptTagList.data.length && duplicateModalStates.to
                          ? deptTagList.data.find(o => o.id===duplicateModalStates.to)
                          : null
                        }
                        options={
                          deptTagList.data && deptTagList.data.length
                          ? deptTagList.data.filter(o => o.id!==duplicateModalStates.from)
                          : []
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        maxMenuHeight="200px"
                        onChange={(e) => {
                          setDuplicateModalStates({
                            ...duplicateModalStates,
                            to: e.id,
                          })
                        }}
                        placeholder={
                          _t(t("Please select menu to duplicate to")) + ".."
                        }
                      />
                    </div>
                    <div className="mt-2">
                    <label htmlFor="prefix" className="form-label">
                      {_t(t("Prefix Menu"))}
                      <small className="text-danger">*</small>
                    </label>
                    <input
                      type="text"
                      id="prefix"
                      name="prefix"
                      className="form-control"
                      value={duplicateModalStates.prefix || ""}
                      onChange={(e) => {
                        setDuplicateModalStates({
                          ...duplicateModalStates,
                          prefix: e.target.value,
                        })
                      }}
                      required
                      placeholder="eg: -, Fp, ."
                    />
                  </div>
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-6">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                            onClick={(e) => {
                              e.preventDefault()
                              handleDuplicateModalSubmit()
                            }}
                          >
                            {_t(t("Duplicate"))}
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                            data-dismiss="modal"
                            onClick={(e) => {
                              e.preventDefault()
                              setDuplicateModalStates({
                                open: false,
                                to: null,
                                from: null,
                                prefix: null,
                                uploading: false
                              })
                            }}
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!_t(t("Duplicate"))}
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                          onClick={(e) => {
                            e.preventDefault()
                            setDuplicateModalStates({
                              open: false,
                              to: null,
                              from: null,
                              prefix: null,
                              uploading: false
                            })
                          }}
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {newDeptTag.uploading === true || loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${dataPaginating && "loading"}`}></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedDeptTag.searched
                                    ? _t(t("Menu List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  data-toggle="modal"
                                  data-target="#addPaymentType"
                                  onClick={() => {
                                    setNewDeptTag({
                                      ...newDeptTag,
                                      edit: false,
                                      uploading: false,
                                    });
                                  }}
                                >
                                  {_t(t("add new"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Default"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedDeptTag.searched
                                ? [
                                    deptTagList && [
                                      deptTagList.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        deptTagList.data.map((item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (deptTagList.current_page -
                                                    1) *
                                                    deptTagList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {/* {item.commission} */}
                                                <label className="mx-checkbox d-inline-block mr-3">
                                                  <input
                                                    checked={
                                                      defaultMenu === item.id
                                                    }
                                                    type="radio"
                                                    value="Cash"
                                                    className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0"
                                                    name="is_default_menu"
                                                    onChange={() => {
                                                      handleDefaultMenu(
                                                        item.id
                                                      );
                                                    }}
                                                  />
                                                  <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text"></span>
                                                </label>
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() =>
                                                        handleSetEdit(item.slug)
                                                      }
                                                      data-toggle="modal"
                                                      data-target="#addPaymentType"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Edit"))}
                                                    </button>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() =>
                                                        openDuplicatModal(item.id)
                                                      }
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-copy"></i>
                                                      </span>
                                                      {_t(t("Duplicate"))}
                                                    </button>


                                                    {/* <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.slug
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button> */}
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedDeptTag && [
                                      searchedDeptTag.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedDeptTag.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (deptTagList.current_page -
                                                      1) *
                                                      deptTagList.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {/* {item.commission} */}
                                                  <label className="mx-checkbox d-inline-block mr-3">
                                                    <input
                                                      type="radio"
                                                      value="Cash"
                                                      className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0"
                                                      name="is_default_menu"
                                                      onChange={() => {
                                                        handleDefaultMenu(
                                                          item.id
                                                        );
                                                      }}
                                                    />
                                                    <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text"></span>
                                                  </label>
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() =>
                                                          handleSetEdit(
                                                            item.slug
                                                          )
                                                        }
                                                        data-toggle="modal"
                                                        data-target="#addPaymentType"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("Edit"))}
                                                      </button>

                                                      <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() =>
                                                        openDuplicatModal(item.id)
                                                      }
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-copy"></i>
                                                      </span>
                                                      {_t(t("Duplicate"))}
                                                    </button>

                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handleDeleteConfirmation(
                                                            item.slug
                                                          );
                                                        }}
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                        {_t(t("Delete"))}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {newDeptTag.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedDeptTag.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(deptTagList, setPaginatedDeptTag)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(deptTagList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedDeptTag({
                                      ...searchedDeptTag,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedDeptTag,
                                    deptTagForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default MenuTagCrud;
