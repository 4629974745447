import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

const CallCenterReport = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let { loading, setLoading, dataPaginating, generalSettings } =
      useContext(SettingsContext);
    let { branchForSearch } =
      useContext(RestaurantContext);
    const { authUserInfo } = useContext(UserContext);
  
    let [newCallCenterReport, setNewCallCenterReport] = useState(null);
  
    //all data
    const [reportData, setReportData] = useState(null);
    const [zReportData, setZReportData] = useState(null);
  
    const componentRef = useRef();
    const componentRefThermal = useRef();
    //settle order
    // const [checkOrderDetails, setCheckOrderDetails] = useState({
    //   item: null,
    //   settle: false,
    //   uploading: false,
    //   payment_type: null,
    //   payment_amount: null,
    // });
  
    // const groupByArray = [
    //   { id: "Branch", name: "Branch" },
    //   { id: "Order Types", name: "Order Types" },
    //   { id: "Payment Types", name: "Payment Types" },
    // ];
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [generatedReport, setGeneratedReport] = useState(false);
    // const [sumPrice, setSumPrice] = useState(0);
    // const [sumTotalPrice, setSumTotalPrice] = useState(0);
    const [siteName, setSiteName] = useState("");
  
    //useEffect == componentDidMount()
    const [branchSearch, setBranchSearch] = useState(null);
  
    useEffect(() => {
      setSiteName(getSystemSettings(generalSettings, "siteName"));
      if (authUserInfo.details.user_type === "staff") {
        setBranchSearch(
          branchForSearch.filter(
            (branch) => branch.id === authUserInfo.details.branch_id
          )
        );
      } else {
        setBranchSearch(branchForSearch);
      }
  
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }, [generalSettings]);
  
    const handleBranch = (branch) => {
      setNewCallCenterReport({ ...newCallCenterReport, branch });
      // setBranch(branch);
    };
    // const handleGroup = (group) => {
    //   setNewCallCenterReport({ ...newCallCenterReport, group });
    //   //setGroup(group);
    // };
    // const handleItem = (item) => {
    //   setNewCallCenterReport({ ...newCallCenterReport, item });
    //   //setItem(item);
    // };
    // const handleParty = (party) => {
    //   setNewCallCenterReport({ ...newCallCenterReport, party });
    //   //setParty(party);
    // };
    // const handleOrderTypes = (orderTypes) => {
    //   setNewCallCenterReport({ ...newCallCenterReport, orderTypes });
    //   //setOrderTypes(orderTypes);
    // };
    // const handlePaymentTypes = (paymentTypes) => {
    //   setNewCallCenterReport({ ...newCallCenterReport, paymentTypes });
    //   //setPaymentTypes(paymentTypes);
    // };
    // const handleGroupBy = (groupBy) => {
    //   setNewCallCenterReport({ ...newCallCenterReport, groupBy });
    //   //setGroupBy(groupBy);
    // };
  
    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      setReportData(null);
      console.log(
        "newCallCenterReport ",
        newCallCenterReport,
        "branchcheck",
        newCallCenterReport?.branch?.id
      );
      if (
        authUserInfo.details.user_type === "staff" &&
        newCallCenterReport?.branch?.id === undefined
      ) {
        toast.error(`${_t(t("Please select Branch to generate report"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        return;
      }
  
      if (
        // newCallCenterReport?.branch?.id !== null &&
        startDate !== null &&
        endDate !== null
        // newCallCenterReport?.groupBy?.id
      ) {
        setLoading(true);
        var fromDate = moment(startDate).format("YYYY-MM-DD");
        var toDate =  moment(endDate).format("YYYY-MM-DD");
  
        const url = BASE_URL + "/settings/get-call-center-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("branch_id", newCallCenterReport?.branch?.id);
        // formData.append("order_type_id", newCallCenterReport?.orderTypes?.id);
        // formData.append("party_id", newCallCenterReport?.party?.id);
        // formData.append(
        //   "payment_type_name",
        //   newCallCenterReport?.paymentTypes?.name
        // );
        formData.append("group_by", "Branch");
  
        // console.log("formData ", formData);
  
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            console.log("CallCenterReportResult", res);
            setReportData(res.data[0]);
  
            setLoading(false);
            console.log("Component ", componentRef);
            setGeneratedReport(true);
            componentRef.current.handleDownload();
            setNewCallCenterReport(null);
          })
          .catch((error) => {
            setLoading(false);
            toast.error(
              `${_t(
                t(
                  "Please try again"
                )
              )}`,
              {
                position: "bottom-center",
                closeButton: false,
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              }
            );
          });
      } else {
        toast.error(
          `${_t(
            t(
              "Please select from date, to date and branch to generate report"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };
  
    // const thermalPrint = useReactToPrint({
    //   content: () => componentRefThermal.current,
    // });
  
     //get BranchWise reports filter
    //  const getThermalSelected = () => {
    //   setReportData(null);
      
    //   if (
    //     authUserInfo.details.user_type === "staff" &&
    //     newCallCenterReport?.branch?.id === undefined
    //   ) {
    //     toast.error(`${_t(t("Please select Branch to generate report"))}`, {
    //       position: "bottom-center",
    //       closeButton: false,
    //       autoClose: 10000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       className: "text-center toast-notification",
    //     });
    //     return;
    //   }
  
    //   if (
    //     // newCallCenterReport?.branch?.id !== null &&
    //     startDate !== null &&
    //     endDate !== null &&
    //     newCallCenterReport?.groupBy?.id
    //   ) {
    //     setLoading(true);
    //     var fromDate = moment(startDate).format("YYYY-MM-DD");
    //     var toDate =  moment(endDate).format("YYYY-MM-DD");
  
    //     const url = BASE_URL + "/settings/get-boc-sales-report";
    //     let formData = new FormData();
    //     formData.append("fromDate", fromDate);
    //     formData.append("toDate", toDate);
    //     formData.append("branch_id", newCallCenterReport?.branch?.id);
    //     formData.append("order_type_id", newCallCenterReport?.orderTypes?.id);
    //     formData.append("party_id", newCallCenterReport?.party?.id);
    //     formData.append(
    //       "payment_type_name",
    //       newCallCenterReport?.paymentTypes?.name
    //     );
    //     formData.append("group_by", newCallCenterReport?.groupBy?.id);
  
    //     console.log("formData ", formData);
  
    //     return axios
    //       .post(url, formData, {
    //         headers: { Authorization: `Bearer ${getCookie()}` },
    //       })
    //       .then((res) => {
    //         console.log("abcd44",res.data[0])
    //         setZReportData(res.data[0])
    //         setLoading(false);
    //         thermalPrint()
    //       })
    //       .catch((error) => {
    //         setLoading(false);
    //       });
    //   } else {
    //     toast.error(
    //       `${_t(
    //         t(
    //           "Please select from date, to date, branch and report group to generate report"
    //         )
    //       )}`,
    //       {
    //         position: "bottom-center",
    //         closeButton: false,
    //         autoClose: 10000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         className: "text-center toast-notification",
    //       }
    //     );
    //   }
    // };
    
    let total_price = 0;
    let grand_total = 0;
    let tax_total = 0;
    let g_tax = 0 ;
    let g_discount = 0
    let g_delCharges = 0;
    let g_serCharges = 0;
  
    ///print totals
    let salesAvg = 0;
    let salesAmount = 0;
    let salesCount = 0;
    let disAvg = 0;
    let disAmount = 0;
    let disCount = 0;
    let tenderAvg = 0;
    let tenderAmount = 0;
    let tenderCount = 0;
    let saleTypeAvg = 0;
    let saleTypeAmount = 0;
    let saleTypeCount = 0;
    let deliveryAvg = 0;
    let deliveryAmount = 0;
    let deliveryCount = 0;
    let paymentModeAvg = 0;
    let paymentModeAmount = 0;
    let paymentModeCount = 0;
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Call Center Report"))}</title>
        </Helmet>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xls"
          filename="Call Center Report"
          sheet="sheet 1"
          buttonText="Sales Detail"
          ref={componentRef}
        /> 
       
        {/* Print Button */}
        <div className="d-none">
          <div>
          {
            /*  
    
            <style type="text/css" media="print">
              {
                "\
            @page { size: landscape; }\
          "
              }
            </style>
            */}
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {reportData !== null && reportData &&
                      reportData !== undefined &&
                      generatedReport === true && (
                        <>
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                            {console.log("reportData", reportData)}
                            <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                              <table
                                className="table table-bordered table-hover min-table-height mt-3"
                                id="table-to-xls"
                              >
                                <div className="page-break"></div>
                                <thead className="align-middle">
                                  <tr style={{ border: "0px" }}>
                                    <th colspan="26" style={{ border: "0px" }}>
                                      <div className="row">
                                        <div className="col-12">
                                          <h3 className="text-center mb-0">
                                            {siteName}
                                          </h3>
                                          <h3 className="text-center mt-0">
                                            Call Center Report
                                          </h3>
                                          <p className="text-center">
                                            FROM&nbsp;
                                            <Moment format="LL">
                                              {startDate}
                                            </Moment>
                                            &nbsp;TO&nbsp;
                                            <Moment format="LL">{endDate}</Moment>
                                          </p>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    ></th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("S/L"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Date"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Token"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Punching Time"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Dispatch Time"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Branch Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Order Type"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Party Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Customer Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Rider Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Ref No"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Payment Type"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Scheme"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Amount"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Discount"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Discount Claimable"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Discount Unclaimable"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Vat"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Delivery Charges"))}
                                    </th>
                                    <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Service Charges"))}
                                  </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Total"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("User Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Status"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Reason"))}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="align-middle">
                                  {console.log("309 ", reportData)}
                                  {/* loop here*/}
                                  {Object.keys(reportData).map(
                                    (group_name, index) => {
                                      let sum = 0;
                                      let g_total = 0;
                                      let discount = 0;
                                      let delCharges = 0;
                                      let serCharges = 0;
                                      tax_total = 0;
                                      return (
                                        <div key={index}>
                                          <tr>
                                            <th
                                              scope="row"
                                              className="lg-text text-capitalize align-middle text-center"
                                            >
                                              {group_name}
                                            </th>
                                          </tr>
                                          {reportData[group_name].map(
                                            (item, i) => {
                                              sum += parseFloat(item.order_bill);
                                              total_price += parseFloat(
                                                item.order_bill
                                              );
                                              g_total += parseFloat(
                                                item.total_payable
                                              );
                                              grand_total += parseFloat(
                                                item.total_payable
                                              );
                                              tax_total += parseFloat(item.vat);
                                              g_tax += parseFloat(item.vat)
                                              g_discount += parseFloat(item.discount_amount)
                                              g_delCharges += parseFloat(item.delivery_charges);
                                              g_serCharges += item.service_amount ? parseFloat(item.service_amount) : 0;
                                              discount += parseFloat(item.discount_amount);
                                              delCharges += parseFloat(item.delivery_charges);
                                              serCharges = parseFloat(item.service_amount);
  
                                              return (
                                                <tr>
                                                  <td></td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center "
                                                  >
                                                    {i + 1}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    <Moment format="LL">
                                                      {item.created_at}
                                                    </Moment>
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.token.id}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.punching_time}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.ready_time}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.branch_name}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.order_type_name}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.party_name}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.customer_name}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.delivery_boy_name}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.ref_no}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.bill_distribution !==
                                                    null
                                                      ? item.bill_distribution[0]
                                                          .payment_type
                                                      : ""}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.scheme_name}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.order_bill}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.discount_amount}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.claimable_amount}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.unclaimable_amount}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.vat}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.delivery_charges}
                                                  </td>
                                                  <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.service_amount}
                                                </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.total_payable}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.user_name}
                                                  </td>
                                                  <td class="xsm-text text-capitalize align-middle text-center">
                                                  {parseInt(
                                                      item.is_cancelled
                                                    ) === 0 ? (
                                                      [
                                                        parseInt(
                                                          item.is_ready
                                                        ) === 1 &&
                                                        parseInt(
                                                          item.is_refund
                                                        ) === 0 && parseInt(item.is_foc) === 0 ? (
                                                          <span class="btn btn-transparent btn-success xsm-text text-capitalize px-4">
                                                            {_t(t("Ready"))}
                                                          </span>
                                                        ) : parseInt(
                                                            item.is_refund
                                                          ) === 1 && parseInt(item.is_foc) === 0 ? (
                                                          <span class="btn btn-transparent btn-secondary xsm-text text-capitalize">
                                                            {_t(t("Refund"))}
                                                          </span>
                                                        ) : 
                                                        parseInt(item.is_foc) === 1 ?(
                                                          <span class="btn btn-transparent btn-danger xsm-text text-capitalize">
                                                          {_t(t("Free of cost"))}
                                                          </span>
                                                        ) :
                                                        (
                                                          <span class="btn btn-transparent btn-secondary xsm-text text-capitalize">
                                                            {_t(t("processing"))}
                                                          </span>
                                                        ),
                                                      ]
                                                    ) : (
                                                      <span class="btn btn-transparent btn-primary xsm-text text-capitalize px-3">
                                                        {_t(t("Cancelled"))}
                                                      </span>
                                                    )}
                                                  </td>
                                                  <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {item.reason ? item.reason : "-"}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                          <tr>
                                            <th colspan="13"></th>
                                            <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                              Total
                                            </th>
                                            <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                              {formatPrice(sum)}
                                            </td>
                                            <td>{discount}</td>
                                            <td></td>
                                            <td></td>
                                            <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                              {formatPrice(tax_total)}
                                            </td>
                                            <td>{delCharges}</td>
                                            <td>{serCharges}</td>
                                            <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                              {formatPrice(g_total)}
                                            </td>
                                            {/*
                                            <td>After Tax Price</td>
                                          <td>{formatPrice(parseFloat(sum) + parseFloat(tax_total))}</td>*/}
                                          </tr>
                                        </div>
                                      );
                                    }
                                  )}
                                  {
                                    <>
                                    <tr>
                                      <th colspan="13"></th>
                                      <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                        Grand Total
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(total_price)}
                                      </td>
                                      <td>{g_discount}</td>
                                      <td></td>
                                      <td></td>
                                      <td>{formatPrice(g_tax)}</td>
                                      <td>{g_delCharges}</td>
                                      <td>{g_serCharges}</td>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(grand_total)}
                                      </td>
                                    </tr>
                                    <tr>
                                    <th colspan="13"></th>
                                    <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                    </th>
                                    <td className="sm-text align-middle text-center">
                                      Gross Amount
                                    </td>
                                    <td className="sm-text align-middle text-center">Discount</td>
                                    <td></td>
                                    <td></td>
                                    <td className="sm-text align-middle text-center">Vat</td>
                                    <td className="sm-text align-middle text-center">D.C</td>
                                    <td className="sm-text align-middle text-center">S.C</td>
                                    <td className="sm-text align-middle text-center">
                                      Total
                                    </td>
                                  </tr>
                                  </>
                                  }
                                </tbody>
                                <tfoot style={{ border: "0px" }}>
                                  <tr style={{ border: "0px" }}>
                                    <td
                                      colspan="7"
                                      className="text-center"
                                      style={{ border: "0px" }}
                                    >
                                      <h5 className="mt-3">
                                        Powered by indolj.pk
                                      </h5>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ReportSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading <div className={`${dataPaginating && "loading"}`}></div>*/}
                      
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      {/* spin loading ends */}
  
                      {/* Loading effect 
                      {loading === true ? (
                        tableLoading()
                      ) : (
                        */}
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-12 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Call Center Report"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-2">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("From date"))}
                                shouldCloseOnSelect={true}
                              />
                            </div>
                            <div className="col-2">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("To date"))}
                                shouldCloseOnSelect={true}
                              />
                            </div>
                            <div className="col-4">
                              <Select
                                options={branchSearch && branchSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                //value={branch}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleBranch}                                
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select a branch")) + ".."}
                              />
                            </div>
                            <div className="col-2">
                            <button
                                  className="btn btn-block btn-primary text-uppercase sm-text px-2 py-2 rounded"
                                  onClick={(e) => getBranchWiseSelected(e)}
                                >
                                  Print Report
                                </button>
                            </div>
                          </div>                                                    
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
}

export default CallCenterReport;