import React, { useEffect, useContext, useState } from "react";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";

const UpdateSettings = () => {
  const { t } = useTranslation();
  let {
    loading,
    setLoading,
    generalSettings,
    dataPaginating,
  } = useContext(SettingsContext);

  const [updateSettings, setUpdateSettings] = useState({
    action: "tax",
    db_name: "",
    secret_key: null,
    query: null,
    table:null,
    column_name: null,
    text: "",
    price: "",
    tax:""
  });

  const handleChange = (e) => {
    setUpdateSettings({ ...updateSettings, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = updateSettings.text.replace(/\n/g, '');
    let formData = {
      // action: updateSettings.action, 
      // db_name: updateSettings.name,
      // secret_key: updateSettings.secret_key,
      // query: updateSettings.query,
      // table:updateSettings.table,
      // column_name: updateSettings.column_name,
      action: updateSettings.action,
      price: updateSettings.action === "tax" ? updateSettings.tax : updateSettings.price,
      text: payload,
    };
    setLoading(true);
    // if (updateSettings.secret_key !== null && updateSettings.query !== null && updateSettings.action!=="") {
    //   if(updateSettings.action === "create" || (updateSettings.action === "alter" && updateSettings.table !==null && updateSettings.column_name !==null)){
          if (updateSettings.secret_key === "@indolj123#@") {
            const url = BASE_URL + "/settings/correction";
            return axios
              .post(url, formData, {
                headers: { Authorization: `Bearer ${getCookie()}` },
              })
              .then((res) => {
                setUpdateSettings({
                  action:"tax",
                  db_name: "",
                  secret_key: updateSettings.secret_key,
                  query: null,
                  table:null,
                  column_name: null,
                  text: "",
                  price:"",
                  tax:""
                });
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                toast.error(`${_t(t("Please try again"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
              });
              }else{
                setLoading(false);
                toast.error(`${_t(t("Incorrect credentidal"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
              }
           
      //   } else {
      //     setLoading(false);
      //     toast.error(`${_t(t("please fill table and column"))}`, {
      //       position: "bottom-center",
      //       autoClose: 10000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       className: "text-center toast-notification",
      //     });
        
      //   }
      // } else {
      //   setLoading(false);
      //   toast.error(`${_t(t("Please fill required fields"))}`, {
      //     position: "bottom-center",
      //     autoClose: 10000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     className: "text-center toast-notification",
      //   });
      // }
   };

  return (
    <>
      <Helmet>
        <title>{_t(t("Update Settings"))}</title>
      </Helmet>
      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div className="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0 ">
                            <ul className="t-list mb-3 fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Update Settings"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 sm-text my-2"
                            style={{ width: "97%" }}
                            onSubmit={handleSubmit}
                          >
                            <div className="col-12">
                            <div className="form-group mt-3">
                            <div className="d-inline mx-1 mr-4">
                              <input
                                type="radio"
                                className="form-check-input pointer-cursor"
                                id="action"
                                name="action"
                                value="tax"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                checked={
                                  updateSettings.action === "tax" ? 1 : 0
                                }
                              />
                              <label
                                className="form-radio-label pointer-cursor ml-2 mr-4"
                                htmlFor="action"
                              >
                                {_t(t("Tax"))}
                              </label>
                              <input
                                type="radio"
                                className="form-check-input pointer-cursor"
                                id="action_price"
                                name="action"
                                value="price"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                checked={
                                  updateSettings.action === "price" ? 1 : 0
                                }
                              />
                              <label
                                className="form-radio-label pointer-cursor ml-2"
                                htmlFor="action_price"
                              >
                                {_t(t("Item Price"))}
                              </label>
                            </div>
                            { updateSettings.action === "alter" && (
                              <div className="d-flex align-items-center mt-3 d-none">
                              <div className="mr-4" style={{width:"49.5%"}}>
                                  <input
                                    type="text"
                                    className="form-control "
                                    id="table"
                                    name="table"
                                    onChange={handleChange}
                                    value={
                                      updateSettings.table
                                        ? updateSettings.table
                                        : ""
                                    }
                                    placeholder="table"
                                  />
                                </div>
                                <div className="ml-1" style={{width:"49.5%"}}>
                                  <input
                                    type="text"
                                    className="form-control "
                                    id="column"
                                    name="column_name"
                                    onChange={handleChange}
                                    value={
                                      updateSettings.column_name
                                        ? updateSettings.column_name
                                        : ""
                                    }
                                    placeholder="column name"
                                  />
                                </div>
                                </div>
                            )
                            }
                            { updateSettings.action === "price" ? (
                              <div className="mt-3 ">
                                  <input
                                    type="number"
                                    className="form-control "
                                    step="0.001"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    min="0"
                                    id="price"
                                    name="price"
                                    onChange={handleChange}
                                    value={
                                      updateSettings.price
                                        ? updateSettings.price
                                        : ""
                                    }
                                    required
                                    placeholder="price"
                                  />                                
                                </div>
                             ) : (
                              <div className="mt-3">
                                <input
                                  type="number"
                                  className="form-control "
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  step="0.001"
                                  min="0"
                                  id="tax"
                                  name="tax"
                                  onChange={handleChange}
                                  value={
                                    updateSettings.tax
                                      ? updateSettings.tax
                                      : ""
                                  }
                                  required
                                  placeholder="tax"
                                />                                
                               </div>
                               )
                              }
                                <div className="mb-3 mt-3">
                                  <input
                                    type="text"
                                    className="form-control "
                                    id="secret_key"
                                    name="secret_key"
                                    onChange={handleChange}
                                    value={
                                      updateSettings.secret_key
                                        ? updateSettings.secret_key
                                        : ""
                                    }
                                    required
                                    placeholder="secret key"
                                  />
                                </div>
                                <div className="mb-3 d-none">
                                  <input
                                    type="text"
                                    className="form-control "
                                    id="query"
                                    name="query"
                                    onChange={handleChange}
                                    value={
                                      updateSettings.query
                                        ? updateSettings.query
                                        : ""
                                    }
                                    placeholder="query"
                                  />
                                </div>
                                <div className="mb-3 d-none">
                                  <input
                                    type="text"
                                    className="form-control "
                                    id="db_name"
                                    name="db_name"
                                    onChange={handleChange}
                                    value={updateSettings.db_name}
                                    placeholder="db name"
                                  />
                                </div>
                                <div className="mb-3">
                                  <textarea
                                    type="text"
                                    className="form-control "
                                    id="text"
                                    name="text"
                                    rows="5" 
                                    onChange={handleChange}
                                    value={updateSettings.text}
                                    placeholder="text"
                                    required
                                  >
                                  </textarea>
                                </div>
                              </div>
                            </div>
                            <div className="form-group mt-4 pb-2">
                              <div className="col-lg-12">
                                <button
                                  className="btn btn-primary px-5 rounded d-flex justify-content-end ml-auto"
                                  type="submit"
                                >
                                  {_t(t("Save"))}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UpdateSettings;
