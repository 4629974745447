
import React, { useState, useEffect,useContext, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Select, { createFilter } from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../../../../BaseUrl";
import axios from "axios";
import {
  _t,
  getCookie,
  // restaurantMenuLink,
} from "../../../../../../functions/Functions";
import {UserContext} from "../../../../../../contexts/User"

const CustomerModal = ({
  modal9Show,
  setModal9Show,
  setOrderDetails,
  orderDetails,
  selectedParty,
  customer,
  setCustomer,
  setCustomerApi,
  setAddressList,
  addressList,
}) => {
  const {
    //waiter
    customerForSearch,
   
  } = useContext(UserContext);
  const nameInput = useRef(false);
  //const [customers, setCustomers] = useState();
  const duplicateRef = useRef(null);
  const [showCustomer,setShowCustomer] = useState(false)
  const { order_id } = useParams();

  const customerFilter = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: option => `${option.label} ${ option.value}`,
    trim: true,
  }

  useEffect(() => {
    // search customer acc to branch
    // if (orderDetails.branch) {
    //   let tempCustomers =
    //     customerForSearch !== null &&
    //     customerForSearch.filter((eachCustomer) => {
    //       return parseInt(eachCustomer.branch_id) === orderDetails?.branch.id;
    //     });
    //   setCustomers(tempCustomers);
    // }
    // set name and input disable on food panda
    if (
        (selectedParty &&
        selectedParty?.show_ref_no === 1) &&
        (selectedParty.name.match(/food(.*)panda/i))
    ) {
      nameInput.current = true;
      setTimeout(() => {  
        setCustomer({
          ...customer,
          name:"Food Panda"
        }) 
      },10);
    } else {
      setCustomer({
        ...customer,
        name:""
      })
      nameInput.current = false;
    }
  
    if (order_id) {
      if(orderDetails.customer){
        setCustomer({
          ...customer,
          name: orderDetails.customer?.name,
          email: orderDetails.customer?.email,
          phn_no: orderDetails.customer?.phn_no,
          address: orderDetails.customer?.address,
          ref_no: orderDetails.customer?.ref_no,
        });
      }
     
    }
  }, [selectedParty,orderDetails?.branch]);
 
  // submit customer info
  const handleCustomer = async(e) => {
    e.preventDefault();
   
    const customerValidation =  order_id || (customer.ref_no?.length !== undefined &&
      customer.ref_no?.length !== 0 && customer.ref_no?.length  >= selectedParty.ref_min &&
      customer.ref_no?.length <= selectedParty.ref_max);
      const customerInfo = {
        name: customer.name ? customer.name : "",
        email: customer.email ? customer.email : "",
        number: customer.phn_no ? customer.phn_no : "",
        address: customer.address ? customer.address : "",
        ref_no: customer.ref_no ? customer.ref_no : "",
      };

      const customerOnEdit = {
        ...orderDetails.customer,
        ...customer,
        name: customer.name ? customer.name :"",
        email: customer.email ? customer.email : "",
        address: customer.address ? customer.address : "",
        ...((!orderDetails.customer?.id) ? {number: customer.phn_no ? customer.phn_no : ""} :  {phn_no: customer.phn_no ? customer.phn_no :""}),
        ref_no: customer.ref_no ? customer.ref_no : ""
      }

      const setCustomerInfo = {
        ...orderDetails,
        newCustomerInfo: customerInfo,
      }

      const setCustomers = {
        ...orderDetails,
        customer: customerOnEdit,
      }
      
    const updateCustomerInfo = () => {
      if (order_id) {
        setOrderDetails(setCustomers);
      } else {
        setOrderDetails(setCustomerInfo);
      }
      setModal9Show(false);
    };

    const updateCustomerFoodPanda = () =>{

      if (order_id) {
        setOrderDetails(setCustomers);
        setModal9Show(false);
      } else {
        let dupRef = duplicateRef.current;
        if(dupRef === "exist"){
          setCustomer({
            ...customer,
            ref_no: "",
            is_confirm: false,
          });
          setOrderDetails(setCustomerInfo);
          toast.error("Ref no is already exist!");
        }else{
          setOrderDetails(setCustomerInfo);
          setModal9Show(false);
        }
      }
    }
                                
    if (
        selectedParty &&
        parseInt(selectedParty.show_ref_no) === 1 &&
        (selectedParty.name.match(/food(.*)panda/i))
    ) {
      //duplicate ref no api
      const refUrl = BASE_URL + "/settings/check-duplicate-ref-no/" + customer.ref_no;
      await axios
      .get(refUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        duplicateRef.current = res.data;
      })
      .catch((err)=>console.log(err));

      if (
        (customer.name !== "" &&
        customerValidation)
      ) {
       
        updateCustomerFoodPanda()
       
      } else {
        setCustomer({
          ...customer,
          is_confirm: false,
        })
        toast.error(`Please fill name & ref no between ${selectedParty.ref_min}-${selectedParty.ref_max} digits.`);
      }
    }else if(
      selectedParty && parseInt(selectedParty.show_ref_no) === 1
    ){
      if (
        customerValidation
      ) {
        updateCustomerInfo()
      } else {
        setCustomer({
          ...customer,
          is_confirm: false,
        })
        toast.error(`Please fill ref no between ${selectedParty.ref_min}-${selectedParty.ref_max} digits.`);
      }
    }
     else {
      updateCustomerInfo();
    }
  };

  // const handleAddress=(e)=>{
  //   e.preventDefault();
  //   let addres= [...addressList, {
  //     id: addressList.length +1,
  //     address:multiAddress,
  //   }]
  //   setAddressList(addres);
  //   setAddress(multiAddress);
  //   setMultiAddress("");
  // }

  // const handleDeleteAddress = (adress,index) =>{
  //    let add = addressList?.filter((address) => address.id !== adress.id);
  //    setAddressList(add)
  // }
  
  //search customer
  const customerSearch = (customers) => {
    setCustomer({
      ...customers,
      name: customers.name,
      email: customers.email ? customers.email : "",
      phn_no: customers.phn_no ? customers.phn_no : "",
      ref_no:"",
    });

    if(customers.address_book.length > 1 ){
      setShowCustomer(true);
      setAddressList(customers.address_book);
      setCustomer({
        ...customers,
        name: customers.name,
        email: customers.email ? customers.email : "",
        phn_no: customers.phn_no ? customers.phn_no : "",
        address: customers.address_book.length ? customers.address_book[0].address : "",
        ref_no: "",
      });
    }else{
      setShowCustomer(false);
      setCustomer({
        ...customers,
        name: customers.name,
        email: customers.email ? customers.email : "",
        phn_no: customers.phn_no ? customers.phn_no : "",
        address: customers.address ? customers.address : "",
        ref_no:"",
      });
      setAddressList([]);
    }
    setCustomerApi(0)
  };
  // multi addres pop up
  const addressChange =(e)=>{
    setCustomer({
      ...customer,
      address: e.target.value
    })
    setCustomerApi(0);
  }

  return (
    <>
    <Modal
      show={modal9Show}
      onHide={() => {
        if(selectedParty && parseInt(selectedParty.show_ref_no) === 1 && 
           customer.is_confirm === false){
          toast.error("Please press confirm button");
        }
        setModal9Show(false)
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
     
    >
      <Modal.Header closeButton className="customer-header">
        <Modal.Title className="customer-title" id="contained-modal-title-vcenter">
          {/* Modal Header */}
          <div className="container-fluid mt-1">
            <h4 className="fw-bold mb-0 input-field" id="exampleModalLabel">
              Search Customer
            </h4>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Modal Body */}
        <div className="modal-body pt-0">
          <div className="container-fluid" style={{ paddingLeft: "0px" }}>
            <div className="row">
              <div className="col-6 mr-2">
                <label
                  for="recipient-name"
                  className="col-form-label fw-bold d-flex justify-content-start"
                >
                  By Name / By Phone
                </label>
                <Select
                  isDisabled={selectedParty && selectedParty.name?.match(/food(.*)panda/i)}
                  options={customerForSearch}
                  components={makeAnimated()}
                  getOptionLabel={(option) => option.name_phone}
                  getOptionValue={(option) => option.id}
                  classNamePrefix="select"
                  filterOption={createFilter(customerFilter)}
                  className="xsm-text"
                  onChange={customerSearch}
                  maxMenuHeight="200px"
                  placeholder="Customer Search"
                />
              </div>
              {
                addressList && addressList.length > 0 &&
                <div className="col-3 d-flex align-items-end mb-1">
                  <button className="btn btn-sm btn-primary" onClick={()=>setShowCustomer(true)}>Multi Address</button>
                </div>
              }
            </div>
            <h4 className="d-flex justify-content-start fw-bold mb-1 mt-3">
              Add Customer
            </h4>
            <form 
            onSubmit={handleCustomer}
            >
              <div className="row">
                <div className="col-6">
                  <label
                    for="recipient-name"
                    className="col-form-label fw-bold d-flex justify-content-start p-0"
                  >
                    Name
                  </label>
                  <input
                    disabled={nameInput.current}
                    type="text"
                    className="form-control mb-2 customer-input"
                    id="recipient-name"
                    placeholder="Name"
                    onChange={(e) =>{
                      setCustomerApi(1);
                      setCustomer({
                        ...customer,
                        name: e.target.value
                      });
                    }}
                    value={customer.name}
                  />
                </div>
                <div className="col-6">
                  <label
                    for="recipient-name"
                    className="col-form-label fw-bold d-flex justify-content-start p-0"
                  >
                    Phone
                  </label>
                  <input
                    type="number"
                    className="form-control customer-input"
                    onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                    onWheel={(e) => e.target.blur()}  
                    id="recipient-name"
                    placeholder="0304***"
                    onChange={(e) => {
                      setCustomerApi(1);
                      setCustomer({
                        ...customer,
                        phn_no: e.target.value
                      });
                    } }
                    value={customer.phn_no}
                  />
                </div>
                </div>
                <div className="row">
                
                <div className="col-6">
                  <label
                    for="recipient-name"
                    className="col-form-label fw-bold d-flex justify-content-start p-0"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control customer-input"
                    id="recipient-name"
                    placeholder="Email"
                    onChange={(e) =>  setCustomer({
                      ...customer,
                      email: e.target.value
                    })}
                    value={customer.email || ""}
                  />
                </div>
                <div className="col-6">
                  <label
                    for="recipient-name"
                    className="col-form-label fw-bold d-flex justify-content-start p-0"
                  >
                    Ref No
                  </label>
                  <input
                    // disabled={order_id}
                    type="text"
                    className="form-control customer-input"
                    id="recipient-name"
                    placeholder="Ref No"
                    onChange={(e) =>  setCustomer({
                      ...customer,
                      ref_no: e.target.value,
                    })}
                    value={customer.ref_no}
                    minLength={
                      selectedParty &&
                      selectedParty.show_ref_no === 1 &&
                      selectedParty.ref_min > 0 && selectedParty.ref_min
                    }
                    maxLength={
                      selectedParty &&
                      selectedParty.show_ref_no === 1 &&
                      selectedParty.ref_max > 0 ? selectedParty.ref_max : 20
                      }
                      />
                      </div>
                      {/* address */}
                      <div className="row mt-2 pr-0">
                      <label
                        for="recipient-name"
                        className="col-form-label fw-bold d-flex justify-content-start pt-0 pb-0"
                      >
                        Delivery Address
                      </label>
                
                    <div className="col-12 pr-0">
                    <input
                      type="text"
                      className="form-control customer-input"
                      id="recipient-name"
                      placeholder="Address"
                      onChange={(e) =>{
                        setCustomerApi(1);
                        setCustomer({
                          ...customer,
                          address: e.target.value
                        });
                      } }
                      value={customer.address}
                    />
                  </div>
                </div>
              </div>
              <Modal.Footer
              style={{
                borderTop: "none",
                justifyContent: "space-between",
                display: "block",
                marginBottom:"-15px",
                paddingRight:"0"
              }}
            >
              {/* Modal Footer */}
              <div className="modal-footer" style={{ borderTop: "none",paddingBottom:"0",paddingRight:"0" }}>
                <div className="container-fluid " style={{ padding: "0px" }}>
                  <div className="row d-flex justify-content-end">
                    <div className="col-md-3 mb-1">
                      <button
                        type="submit"
                        className="btn w-100 fw-bold rounded-25"
                        style={{
                          backgroundColor: "#0a8d6e",
                          color: "#fff",
                          borderRadius: "0.5rem",
                        }}
                        onClick={()=>{
                          setCustomer({
                            ...customer,
                            is_confirm: true,
                          })
                        }}
                      >
                        CONFIRM
                      </button>
                    </div>
                    {
                      /*
                    <div className="col-md-3 mb-1">
                      <button
                        type="button"
                        className="btn w-100 fw-bold rounded-25"
                        style={{
                          backgroundColor: "#939393",
                          color: "#fff",
                          borderRadius: "0.5rem",
                        }}
                        onClick={() => setModal9Show(false)}
                      >
                        CANCEL
                      </button>
                    </div>
                      */}
                  </div>
                </div>
              </div>
            </Modal.Footer>
            </form>
          </div>
        </div>
      </Modal.Body>
     
    </Modal>
      {/*multi address Modal*/}
      <div
        className={`modal fade ${
          showCustomer !== false ? "showing" : ""
        }`}
        id="customerAddress"
        aria-hidden="true"
        style={{
          zIndex:14999
        }}
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content"></div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setShowCustomer(false);
                }}
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form onSubmit={(e) => e.preventDefault()}>
                  <label htmlFor="name" className="form-label w-100 p-0" style={{fontSize:"1.2rem",fontWeight:"600"}}>
                     Multiple Address
                  </label>
                 <div className="modal-scroll">
                    {
                      addressList && addressList?.map((adress,index)=>{
                        return(
                          <label key={index} className="d-flex align-items-center add-container cursor-pointer">
                          <input
                          type="radio"
                          id={adress.address}
                          name="multiAddress"
                          value={adress.address}
                          defaultChecked={index === 0}
                          onChange={addressChange}
                          />
                          <span className="ml-2 d-flex align-items-center">
                          {adress.address}
                          </span>
                          </label>
                        )
                      })
                    }
                    
                  </div>

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="submit"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                          onClick={(e) => {
                          setShowCustomer(false);
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                          onClick={(e) => {
                            setShowCustomer(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  );
};

export default CustomerModal;
