import React, { useContext, useState } from "react";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import { _t, getCookie, modalLoading, tableLoading, showingData, pagination, paginationLoading } from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

import { BASE_URL } from "../../../../../BaseUrl";
import axios from "axios";


//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";

const CardsCrud = () => {
  // 3rd party hooks
  const { t } = useTranslation();

  // contexts
  const {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);
  const {
    cardList,
    setCardList,
    cardForSearch,
    setCardForSearch,
    setPaginatedCard,
  } = useContext(RestaurantContext);

  // states
  const [newCard, setNewCard] = useState({
    bank_name: "",
    iban_number: "",
    discount: "",
    slug: "",
    edit: false,
    uploading: false,
  })
  const [searchedCards, setSearchedCards] = useState({
    list: [],
    searched: false,
  });

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    if (searchTerm) {
      const newList =
        cardForSearch && cardForSearch.length
          ? cardForSearch.filter((card) => {
              if (!card || !Object.keys(card).length) return false;
              if (
                card.discount &&
                card.discount.toLowerCase().includes(searchTerm)
              )
                return true;
              if (
                card.bank_name &&
                card.bank_name.toLowerCase().includes(searchTerm)
              )
                return true;
              if (
                card.iban_number &&
                card.iban_number.toLowerCase().includes(searchTerm)
              )
                return true;
              return false;
            })
          : [];
      setSearchedCards({
        list: [...newList],
        searched: true,
      });
    } else {
      setSearchedCards({
        list: [],
        searched: false,
      });
    }
  };

  useEffect(() => console.log('[ar] cardForSearch', cardForSearch), [cardForSearch])
  useEffect(() => console.log('[ar] cardList', cardList), [cardList])

  // const foodGroupUrl = BASE_URL + `/settings/new-card-info`;
  // let formData = new FormData();
  // formData.append("bank_name", "Meezan")
  // formData.append("discount", "10")
  // formData.append("iban_number", "10%")
  // axios.post(foodGroupUrl, formData, {
  //   headers: { Authorization: `Bearer ${getCookie()}` },
  // }).then(res => {
  //   toast.success(`${_t(t("Card has been added"))}`, {
  //     position: "bottom-center",
  //     autoClose: 10000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     className: "text-center toast-notification",
  //   });
  // })

  // state helpers
  const handleSetEdit = (card) => {
    setNewCard({
      bank_name: card.bank_name,
      iban_number: card.iban_number,
      discount: card.discount,
      slug: card.slug,
      edit: true,
      uploading: false,
    })
  }

  const handleSetNewCard = (e) => {
    e.persist()
    setNewCard(curr => ({
      ...curr,
      [e.target.name]: e.target.value
    }))
  }

  const clearNewCard = () => {
    setNewCard({
      bank_name: "",
      iban_number: "",
      discount: "",
      slug: "",
      edit: false,
      uploading: false,
    })
  }

  const setNewData = (res) => {
    setCardList(res.data[0]);
    setCardForSearch(res.data[1]);
    setSearchedCards({
      searched: false,
      list: [],
    });
    setLoading(false);
  }

  const apiFailedToast = () => {
    setLoading(false);
    toast.error(`${_t(t("Please try again"))}`, {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  }


  // CREATE / UPDATE
  const handleSaveCard = (e) => {
    setLoading(true);
    setNewCard({
      ...newCard,
      uploading:true
    })
    e.preventDefault()
    const createUrl = BASE_URL + '/settings/new-card-info'
    const updateUrl = BASE_URL + '/settings/update-card-info'

    const edit = newCard.edit

    if (
      !newCard.bank_name ||
      !newCard.discount ||
      !newCard.iban_number
    ) {
      toast.error(`${_t(t("Please fill all the fields"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return
    }
    const formData = new FormData();
    formData.append("bank_name", newCard.bank_name)
    formData.append("discount", newCard.discount)
    formData.append("iban_number", newCard.iban_number)
    if (edit) formData.append("slug", newCard.slug)
    axios.post(
      (edit ? updateUrl : createUrl),
      formData,
      { headers: { Authorization: `Bearer ${getCookie()}` }, }
    ).then(res => {
      setNewData(res)
      toast.success(`${_t(t(`Card has been ${edit ? "updated" : "saved"}.`))}.`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      setLoading(false);
      setNewCard({
        ...newCard,
        uploading:false
      })
      document.getElementById('close-btn').click();
    })
    .catch(err => {
      setLoading(false);
      setNewCard({
        ...newCard,
        uploading:false
      })
      apiFailedToast()
    })
    .finally(clearNewCard);
  }

  // DELETE
  const handleDeleteConfirmation = (card) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary rounded"
                onClick={() => {
                  handleDeleteCard(card.slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  }
  const handleDeleteCard = async (slug) => {
    setLoading(true);
    const deleteUrl = BASE_URL + `/settings/delete-card-info/${slug}`
    return axios.get(
      deleteUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(res => {
        setNewData(res)
        toast.success(`${_t(t("Card Information has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => apiFailedToast())
      .finally(clearNewCard);
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Card Setting"))}</title>
      </Helmet>

      {/* add modal */}
      <div className="modal fade" id="addCard" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newCard.edit
                    ? _t(t("Add IBAN Number"))
                    : _t(t("Update IBAN Number"))}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {!newCard.uploading ?
                <div key="fragment-card">
                  <form
                    onSubmit={handleSaveCard}
                  >
                    <div className="">
                      <label htmlFor="bank_name" className="form-label">
                        {_t(t("Bank Name"))}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="bank_name"
                        id="bank_name"
                        placeholder="e.g. Meezan, Al Falah, HBL"
                        value={newCard.bank_name ? newCard.bank_name : ''}
                        required
                        onChange={handleSetNewCard}
                      />
                    </div>
                    <div className="mt-4">
                      <label htmlFor="iban_number" className="form-label">
                        {_t(t("IBAN Number"))}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="iban_number"
                        id="iban_number"
                        placeholder="e.g. 123456..."
                        value={newCard.iban_number ? newCard.iban_number : ''}
                        required
                        onChange={handleSetNewCard}
                      />
                    </div>
                    <div className="mt-4">
                      <label htmlFor="discount" className="form-label">
                        {_t(t("Discount In Percentage"))}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        name="discount"
                        id="discount"
                        placeholder="e.g. 15"
                        value={newCard.discount ? newCard.discount : ''}
                        required
                        onChange={handleSetNewCard}
                      />
                    </div>
                    <div className="row mt-4 d-flex justify-content-end">
                      <div className="col-3">
                        <button
                          type="submit"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          // onClick={(e) => {
                          //   e.preventDefault();
                          // }}
                        >
                          {!newCard.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                          onClick={clearNewCard}
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                :
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row d-flex justify-content-end">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          disabled
                        >
                          {!newCard.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                          disabled
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>

            {/* main content */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {loading ? (
                      tableLoading()
                    ) : (
                      <div key="fragment3">
                        {/* spinner */}

                        {/* top search */}
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedCards.searched
                                    ? _t(t("Card List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  data-toggle="modal"
                                  data-target="#addCard"
                                  onClick={() =>
                                    setNewCard({
                                      ...newCard,
                                      edit: false,
                                      uploading: false,
                                    })
                                  }
                                >
                                  {_t(t("add new"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Discount"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("IBAN"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {!searchedCards.searched ?
                                (!cardList || !cardList.data || !cardList.data.length) ?
                                <tr className="align-middle">
                                  <td
                                    scope="row"
                                    colSpan="6"
                                    className="xsm-text align-middle text-center"
                                  >
                                    {_t(t("No data available"))}
                                  </td>
                                </tr>
                                :
                                cardList.data.map((card, cardIdx) => (
                                  <tr className="align-middle" key={cardIdx}>
                                    <th
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {cardIdx + 1 + (cardList.current_page - 1) * cardList.per_page}
                                    </th>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {card.bank_name}
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {card.discount}
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {card.iban_number}
                                    </td>
                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      <div className="dropdown">
                                        <button
                                          className="btn t-bg-clear t-text-dark--light-40"
                                          type="button"
                                          data-toggle="dropdown"
                                        >
                                          <i className="fa fa-ellipsis-h"></i>
                                        </button>
                                        <div className="dropdown-menu">
                                          <button
                                            className="dropdown-item sm-text text-capitalize"
                                            onClick={() =>
                                              handleSetEdit(card)
                                            }
                                            data-toggle="modal"
                                            data-target="#addCard"
                                          >
                                            <span className="t-mr-8">
                                              <i className="fa fa-pencil"></i>
                                            </span>
                                            {_t(t("Edit"))}
                                          </button>
                                          <button
                                            className="dropdown-item sm-text text-capitalize"
                                            onClick={() =>
                                              handleDeleteConfirmation(card)
                                            }
                                          >
                                            <span className="t-mr-8">
                                              <i className="fa fa-trash"></i>
                                            </span>
                                            {_t(t("Delete"))}
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              : (!searchedCards || !searchedCards.list || !searchedCards.list.length) ?
                              <tr className="align-middle">
                                <td
                                  scope="row"
                                  colSpan="6"
                                  className="xsm-text align-middle text-center"
                                >
                                  {_t(t("No data available"))}
                                </td>
                              </tr>
                              :
                              searchedCards.list.map((card, cardIdx) => (
                                <tr className="align-middle" key={cardIdx}>
                                  <th
                                    scope="row"
                                    className="xsm-text text-capitalize align-middle text-center"
                                  >
                                    {cardIdx + 1 + (cardList.current_page - 1) * cardList.per_page}
                                  </th>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {card.bank_name}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {card.discount}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {card.iban_number}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    <div className="dropdown">
                                      <button
                                        className="btn t-bg-clear t-text-dark--light-40"
                                        type="button"
                                        data-toggle="dropdown"
                                      >
                                        <i className="fa fa-ellipsis-h"></i>
                                      </button>
                                      <div className="dropdown-menu">
                                        <button
                                          className="dropdown-item sm-text text-capitalize"
                                          onClick={() =>
                                            handleSetEdit(card)
                                          }
                                          data-toggle="modal"
                                          data-target="#addCard"
                                        >
                                          <span className="t-mr-8">
                                            <i className="fa fa-pencil"></i>
                                          </span>
                                          {_t(t("Edit"))}
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {newCard?.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedCards.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(cardList, setPaginatedCard)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(cardList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm rounded"
                                  onClick={() => {}
                                    // setSearchedScheme({
                                    //   ...searchedScheme,
                                    //   searched: false,
                                    // })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {/* {searchedShowingData(
                                    searchedScheme,
                                    deptTagForSearch
                                  )} */}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CardsCrud;
