import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  getSystemSettings,
  currencySymbolRight,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import Moment from "react-moment";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

const ItemWise = () => {
  const { t } = useTranslation();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } =
    useContext(SettingsContext);
  let { branchForSearch } = useContext(RestaurantContext);
  const { partyForSearch, authUserInfo } = useContext(UserContext);
  // States hook here
  const [amountChart, setAmountChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [],
      },
      labels: { show: false },
    },
    series: [
      {
        name: _t(t("Amount")),
        data: [],
      },
    ],
  });

  //all data
  const [reportData, setReportData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [branch, setBranch] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [sumQuantity, setSumQuantity] = useState(0);
  const [sumPrice, setSumPrice] = useState(0);
  const [siteName, setSiteName] = useState("");
  const [discount, setDiscount] = useState(0);
  const [cancellation, setCancellation] = useState(0);
  const [refund, setRefund] = useState(0);
  const [saleTax, setSaleTax] = useState(0);
  const [DeliveryCharges, setDeliveryCharges] = useState(0);
  const [netSales, setNetSales] = useState(0);
  const [branchSearch, setBranchSearch] = useState(null);

  //useEffect == componentDidMount()
  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    if (authUserInfo.details.user_type === "staff") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  //Print Report
  const ReportPrint = useRef();

  const handlePrintBtn = useReactToPrint({
    content: () => ReportPrint.current,
  });

  const handleBranch = (branch) => {
    setBranch(branch);
  };

  //get ItemWise reports filter
  const getItemWiseSelected = () => {
    if (authUserInfo.details.user_type === "staff" && branch === undefined) {
      toast.error(`${_t(t("Please select Branch to generate report"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return;
    }

    if (startDate !== null && endDate !== null) {
      const sDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
      console.log("start date ", startDate, sDate);
      setLoading(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate =  moment(endDate).format("YYYY-MM-DD");
      
      console.log("start date ", fromDate);
      const url = BASE_URL + "/settings/get-food-item-report";
      let formData = {
        fromDate,
        toDate,
        branch,
      };
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          let formattedAmount = res.data[1].map((item) =>
            parseFloat(formatPrice(item))
          );
          console.log("result : ", res);
          setAmountChart({
            ...amountChart,
            options: {
              ...amountChart.options,
              xaxis: {
                ...amountChart.options.xaxis,
                categories: res.data[0],
                labels: { show: false },
              },
            },
            series: [
              { name: amountChart.series[0].name, data: formattedAmount },
            ],
          });
          setReportData(res.data[2]);
          setDiscount(res.data[4]);
          setSaleTax(res.data[5]);
          setDeliveryCharges(res.data[6]);
          setGeneratedReport(true);
          setRefund(res.data[7]);
          setCancellation(res.data[8]);
          //sum quantity for reporting
          const sum_qty = res.data[2].reduce(function (a, b) {
            return a + parseFloat(b.quantity);
          }, 0);
          setSumQuantity(sum_qty);

          //sum price for reporting
          // const sum_price = res.data[2].reduce(function (a, b) {
          //   return a + parseFloat(b.price);
          // }, 0);
          setSumPrice(res.data[9]);
          setNetSales(res.data[10]);
          setCancellation(res.data[11]);

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select all the field to generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  let total_cost = 0;
  let grand_total_cost = 0;
  let avg = 0;
  let tax_amount = 0;
  let total_sales = 0
  let sum_tax_amount = 0;
  let inclusive_vat = 0;
  let after_tax_price = 0;
  let sum_after_tax_price = 0;

  return (
    <>
      <Helmet>
        <title>{_t(t("Item Food Cost report"))}</title>
      </Helmet>

      {/* Print Button */}
      <div className="d-none">
        <div ref={ReportPrint}>
          <style type="text/css" media="print">
            {
              "\
          @page { size: landscape; }\
        "
            }
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="18" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {/* {siteName + " (" + branch.name + ")"} */}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Item Wise Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Group"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Variation"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Properties"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Qty"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Sale Price"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Sale Price Variation"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Sale Price Properties"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Sales Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Tax Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Sales Amount With Tax"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("% of Till"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Cost Price"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Cost Price variation"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Cost Price properties"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Total Cost"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("FC %age"))}
                                  </th>
                                  {/* <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th> */}
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here*/}
                                {Object.keys(reportData).map(
                                  (group_name, index) => {
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {group_name}
                                          </th>
                                        </tr>
                                        {reportData[group_name].map(
                                          (item, i) => {
                                             total_sales += parseFloat(item.price); 

                                            inclusive_vat =
                                              (parseFloat(item.tax) + 100) /
                                              100;
                                            after_tax_price =
                                              item.price / inclusive_vat;

                                            tax_amount =
                                              item.price - after_tax_price;

                                            sum_tax_amount += tax_amount;
                                            sum_after_tax_price +=
                                              after_tax_price;

                                            total_cost =
                                              (parseFloat(item.cost_price) +
                                                parseFloat(
                                                  item.cost_price_variation
                                                ) +
                                                parseFloat(
                                                  item.cost_price_properties
                                                )) *
                                              parseFloat(item.quantity);
                                            total_cost > 0 && item.price > 0
                                              ? (avg =
                                                  (parseFloat(total_cost) /
                                                    parseFloat(item.price)) *
                                                  100)
                                              : (avg = 0);
                                            grand_total_cost += total_cost;

                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index + 1}
                                                </th>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.food_item}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.food_group}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.variation !== null
                                                    ? item.variation
                                                    : "-"}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.property_items}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.quantity}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.sale_price_item}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.sale_price_variation}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.sale_price_properties}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {formatPrice(item.price)}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {formatPrice(tax_amount)}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {formatPrice(after_tax_price)}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {Number(
                                                    (formatPrice(
                                                      parseFloat(item.price)
                                                    ) /
                                                      formatPrice(
                                                        parseFloat(sumPrice)
                                                      )) *
                                                      100
                                                  ).toFixed(2)}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.cost_price !== null
                                                    ? item.cost_price
                                                    : 0}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.cost_price_variation !==
                                                  null
                                                    ? item.cost_price_variation
                                                    : 0}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.cost_price_properties !==
                                                  null
                                                    ? item.cost_price_properties
                                                    : 0}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {formatPrice(total_cost)}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {formatPrice(avg)}
                                                </td>
                                                {/* <td className="xsm-text align-middle text-center">
                                          <Moment format="LL">
                                            {item.created_at}
                                          </Moment>
                                        </td> */}
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr>
                                          <th colspan="4"></th>
                                          <td className="xsm-text align-middle text-left">
                                            Total
                                          </td>
                                          <td className="xsm-text align-middle text-left">
                                            {sumQuantity}
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td className="xsm-text align-middle text-left">
                                            {formatPrice(total_sales)}
                                          </td>
                                          <td className="xsm-text align-middle text-left">
                                            {formatPrice(sum_tax_amount)}
                                          </td>
                                          <td className="xsm-text align-middle text-left">
                                            {formatPrice(sum_after_tax_price)}
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td className="xsm-text align-middle text-left">
                                            {formatPrice(grand_total_cost)}
                                          </td>
                                          <td className="xsm-text align-middle text-left">
                                            {formatPrice(
                                              (grand_total_cost /
                                                sum_after_tax_price) *
                                                100
                                            )}
                                          </td>
                                        </tr>
                                      </div>
                                    );
                                  }
                                )}
                                {
                                  <>
                                    <tr>
                                      {/* <th colspan="4"></th>
                                      <td className="xsm-text align-middle text-left">
                                        Total
                                      </td>
                                      <td className="xsm-text align-middle text-left">
                                        {sumQuantity}
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="xsm-text align-middle text-left">
                                        {formatPrice(sumPrice)}
                                      </td>
                                      <td className="xsm-text align-middle text-left">
                                        {formatPrice(sum_tax_amount)}
                                      </td>
                                      <td className="xsm-text align-middle text-left">
                                        {formatPrice(sum_after_tax_price)}
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="xsm-text align-middle text-left">
                                        {formatPrice(grand_total_cost)}
                                      </td>
                                      <td className="xsm-text align-middle text-left">
                                        {formatPrice(
                                          (grand_total_cost /
                                            sum_after_tax_price) *
                                            100
                                        )}
                                      </td> */}
                                    </tr>
                                    {/* <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Gross Sales
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(sumPrice)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Discount
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(discount)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Cancel Order
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(cancellation)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Add Sales Tax
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(saleTax)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Delivery Charges
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(DeliveryCharges)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Net Sales Including Tax
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(netSales)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Refund
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(refund)}
                                      </td>
                                    </tr> */}
                                  </>
                                }
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Item Food Cost report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-12 col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-12 col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <Select
                            options={branchSearch && branchSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            value={branch}
                            classNamePrefix="select"
                            className="xsm-text col-md-4 d-none d-md-block "
                            onChange={handleBranch}
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select a branch")) + ".."}
                          />

                          <Select
                            options={branchSearch && branchSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            value={branch}
                            classNamePrefix="select"
                            className="xsm-text col-5 mb-2 mb-md-0 d-block d-md-none"
                            onChange={handleBranch}
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select a branch")) + ".."}
                          />
                          <div className="col-5 col-md-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={getItemWiseSelected}
                            >
                              {_t(t("Generate Report"))}
                            </button>
                          </div>

                          <div className="col-5 col-md-8 t-mb-15 mb-md-0 d-block d-md-none">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text rounded"
                              onClick={getItemWiseSelected}
                            >
                              {_t(t("Generate Report"))}
                            </button>
                          </div>
                        </div>
                        {generatedReport ? (
                          <>
                            <div className="row gx-2 justify-content-center t-pt-15">
                              <div className="col-12 mb-md-0">
                                <Chart
                                  options={amountChart.options}
                                  series={amountChart.series}
                                  type="line"
                                  width="100%"
                                  height="350px"
                                />
                              </div>
                            </div>
                            {reportData !== null &&
                              reportData !== undefined &&
                              generatedReport === true && (
                                <div className="row gx-2 justify-content-center t-pb-15 t-pt-15">
                                  {/* Action Button */}
                                  <div className="col-12">
                                    {/* <button
                                      className="float-right btn btn-info"
                                      onClick={handlePrintBtn}
                                    >
                                      Print Report
                                    </button> */}
                                    <ReactHTMLTableToExcel
                                      id="test-table-xls-button"
                                      className="download-table-xls-button btn btn-success float-right mr-3"
                                      table="table-to-xls"
                                      filename="Item Wise"
                                      sheet="sheet 1"
                                      buttonText="Download as XLS"
                                    />
                                  </div>
                                </div>
                              )}
                          </>
                        ) : (
                          <div className="row gx-2 justify-content-center t-pt-15">
                            <div className="col-8 mt-5 py-4 mb-md-0 card text-center text-uppercase sm-text">
                              {_t(
                                t("Generate report following the above field")
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default ItemWise;
