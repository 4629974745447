import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,
} from "../../../../functions/Functions";
import DatePicker from "react-datepicker";

import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const ExpenseList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  let {
    //party
    authUserInfo,
    partyForSearch,
    //pagination
    dataPaginating
  } = useContext(UserContext);

  let { 
    orderTypeForSearch,
    paymentTypeList,
    expenseForSearch,
    branchForSearch,
    expensePayment,
    setPaymentExpense,
    paymentExpenseForSearch,
    setPaymentExpenseForSearch,
    getPaginatedExpenseList,
  } = useContext(RestaurantContext);

  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };

    // cash cheque pay in payout
    const [cashDetails, setCashDetails] = useState({
      document_no: "",
      party_id: "",
      party_name: "",
      payment_type: "cash",
      document_date : null,
      cheque_no: 0,
      cheque_date: null,
      payment_status: null,
      expense_id: null,
      expense_name: "",
      remarks: "",
      amount: 0,
      branch_id: null,
      branch_name: null,
      edit: false,
      editSlug: null,
      uploading: false,
    });
    const [selectBox,setSelectBox] = useState({
      branch: null,
      expType: null,
      party: null
    })
   
      // pay in/out
      const [otherChequeFields, setOtherChequeFields] = useState(false);
  
      //  party
      const handleSetPartyFromCashModal = (party) => {
      setSelectBox({
        ...selectBox,
        party: party
      })
      setCashDetails({
        ...cashDetails,
        party_id: party.id,
        party_name: party.name,
      });
    };

    const handleSelectBranch = (branch) =>{
      setSelectBox({
        ...selectBox,
        branch: branch,
      })
      setCashDetails({
        ...cashDetails,
        branch_id: branch.id,
        branch_name: branch.name,
      })
    }

    const handleSetExpense = (expense) =>{
      setSelectBox({
        ...selectBox,
        expType: expense
      })
      setCashDetails({
        ...cashDetails,
        expense_name: expense.name,
        expense_id : expense.id
      })
    }

    useEffect(()=>{
    if(authUserInfo && authUserInfo?.details?.user_type === "staff"){
      const branch = branchForSearch && branchForSearch.find((branch) => {
        return parseInt(authUserInfo.details.branch_id) === branch.id;
      });
     setSelectBox({
      ...selectBox,
      branch: branch
    })
     setCashDetails({
      ...cashDetails,
      branch_id: branch.id,
      branch_name:branch.name
     })
    }
    },[branchForSearch,authUserInfo])
  
      //cash modal
   const handleSubmitCash = () => {
    setCashDetails({
        ...cashDetails,
        uploading: true,
    })
      
    if(cashDetails.document_date === null || cashDetails.document_date === ""){
      toast.error(
        `${_t(t("Please select date"))}`,
        toastObj
      );
      return false
    }   

      if(cashDetails.branch_id === null || cashDetails.branch_id === ""){
        toast.error(
          `${_t(t("Please select branch"))}`,
          toastObj
        );
        return false
      } 
      if(cashDetails.expense_id === null || cashDetails.expense_id === ""){
        toast.error(
          `${_t(t("Please select expense type"))}`,
          toastObj
        );
        return false
      } 
      
      if(cashDetails.payment_type === "cheque"){
        if((cashDetails.cheque_date === null || cashDetails.cheque_date === "") || (cashDetails.cheque_no === null || cashDetails.cheque_no === "")){
          toast.error(
            `${_t(t("Please select check date/cheque no."))}`,
            toastObj
          );
          return false
        }
      }
      
      if(cashDetails.amount === null || cashDetails.amount === 0){
        toast.error(
          `${_t(t("Please enter amount"))}`,
          toastObj
        );
        return false
      } 

      cashDetails.document_date = moment(cashDetails.document_date).format("YYYY-MM-DD");
      console.log("abcd4",cashDetails)
      let url = BASE_URL + "/settings/new-payment";
      setLoading(true);
      console.log("cash detail : ", cashDetails);
      if (!cashDetails.payment_type) {
        toast.error(`${_t(t("Please add payment type"))}`, toastObj);
        setLoading(false);
        return false;
      } else {
        let formData = cashDetails;
        formData.payment_status = "pay-out";
  
        axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            toast.success(
              `${_t(t("Record has been added successfully."))}`,
              toastObj
            );
            setPaymentExpense(res.data[0])
            setPaymentExpenseForSearch(res.data[1])
            setLoading(false);
            setCashDetails({
              ...cashDetails,
              branch_id: authUserInfo?.details?.user_type === "staff" ? cashDetails.branch_id :null,
              branch_name : authUserInfo?.details?.user_type === "staff" ? cashDetails.branch_name :null,
              document_no: "",
              party_id: "",
              party_name: "",
              payment_type: "cash",
              document_date : null,
              expense_id: null,
              expense_name: "",
              cheque_no: 0,
              cheque_date: null,
              payment_status: null,
              remarks: "",
              amount: 0,
              edit: false,
              editSlug: null,
              uploading: false,
            });
            setOtherChequeFields(false);
            setSelectBox({
              branch: authUserInfo?.details?.user_type === "staff" ? selectBox.branch :null,
              expType: null,
              party: null
            })
            document.getElementById('close-btn').click();
          })
          .catch(() => {
            setLoading(false);
            toast.error(`${_t(t("Please try again"))}`, toastObj);
          });
      }
    };

  console.log("order type List", orderTypeForSearch);
  // States hook here
  //new customer
  let [newParty, setNewParty] = useState({
    name: "",
    email: "",
    phn_no: "",
    address: "",
    associateWith: [],
    associateWithMenu: [],
    associateWithPaymentType: [],
    ref_min:0,
    ref_max: 0,
    for_cc: false,
    receivableAmount: null,
    showHeader: false,
    showRefNo: false,
    due: null,
    edit: false,
    editSlug: null,
    uploading: false,
  });


  //search result
  let [searchedPayExpense, setsearchedPayExpense] = useState({
    list: null,
    searched: false,
  });

  //set edit true & values
  const handleSetEdit = (slug) => {
    let payExpense = paymentExpenseForSearch.filter((item) => {
      return item.slug === slug;
    });
    setLoading(false);
    setCashDetails({
      document_no: payExpense.identity,
      party_id: payExpense.party_id,
      party_name: payExpense.party_name,
      branch_id: payExpense.branch_id,
      branch_name: payExpense.branch_name,
      expense_id: payExpense.expense_id,
      expense_name: payExpense.expense_name,
      payment_type: payExpense.party_type,
      document_date : payExpense.document_date,
      cheque_no: payExpense.party_id.cheque_no,
      cheque_date: payExpense.cheque_date,
      payment_status: payExpense.payment_status,
      remarks: payExpense.remarks,
      amount: payExpense.amount,
      edit: true,
      editSlug: payExpense.slug,
      uploading: false,
    });
    
  };


  //update customer
  const handleUpdateExpense = (e) => {
    e.preventDefault();
    setCashDetails({
      ...cashDetails,
      uploading: true,
  })
    const customerUrl = BASE_URL + `/settings/update-party`;
    let formData = cashDetails;
    formData.payment_status = "pay-out";
    return axios
      .post(customerUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaymentExpense(res.data[0])
        setPaymentExpenseForSearch(res.data[1])
        setLoading(false);
        setCashDetails({
          ...cashDetails,
          branch_id: authUserInfo?.details?.user_type === "staff" ? cashDetails.branch_id :null,
          branch_name : authUserInfo?.details?.user_type === "staff" ? cashDetails.branch_name :null,
          document_no: "",
          party_id: "",
          party_name: "",
          expense_id: null,
          expense_name: "",
          payment_type: "cash",
          document_date : null,
          cheque_no: 0,
          cheque_date: null,
          payment_status: null,
          remarks: "",
          amount: 0,
          edit: true,
          editSlug: null,
          uploading: false,
        });
        setsearchedPayExpense({
          ...searchedPayExpense,
          list: res.data[1],
        });
        setSelectBox({
          branch: authUserInfo?.details?.user_type === "staff" ? selectBox.branch :null,
          expType: null,
          party: null
        })
        setLoading(false);
        toast.success(`${_t(t("Party has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          `${_t(t("Please try again"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          })
     
      });
  };
  //search customers here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setsearchedPayExpense({ ...searchedPayExpense, searched: false });
    } else {
      
      let searchedList = paymentExpenseForSearch.filter((item) => {
        //name
        let lowerCaseBranchName = item.branch_name?.toLowerCase();

        //email
        let lowerCaseExpense =
          item.expense_name !== null && item.expense_name.toLowerCase();

        let lowerDocId = 
           item.document_identity !== null && item.document_identity.toLowerCase()


        // //show header
        // let lowerCaseItemShowHeader =
        //   item.showHeader !== null && item.showHeader.toLowerCase();

        return (
          lowerCaseBranchName.includes(searchInput) ||
          (lowerCaseExpense && lowerCaseExpense.includes(searchInput)) ||
          (lowerDocId && lowerDocId.includes(searchInput))
        );
      });
      setsearchedPayExpense({
        ...searchedPayExpense,
        list: searchedList,
        searched: true,
      });
    }
  };

  //delete confirmation modal of waiter
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to cancel this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteExpense(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, cancel it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete customer here
  const handleDeleteExpense = (slug) => {
    setLoading(true);
    const customerUrl = BASE_URL + `/settings/delete-party/${slug}`;
    return axios
      .get(customerUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaymentExpense(res.data[0])
        setPaymentExpenseForSearch(res.data[1])
        setsearchedPayExpense({
          ...searchedPayExpense,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Expense has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  const handleFormStateEmpty = () => {
    setCashDetails({
      document_no: "",
      party_id: "",
      party_name: "",
      payment_type: "cash",
      document_date : null,
      expense_id: null,
      expense_name: "",
      cheque_no: 0,
      cheque_date: null,
      payment_status: null,
      remarks: "",
      amount: 0,
      branch_id: authUserInfo?.details?.user_type === "staff" ? cashDetails.branch_id : null,
      branch_name: authUserInfo?.details?.user_type === "staff" ? cashDetails.branch_name :null,
      edit: false,
      editSlug: null,
      uploading: false,
    });
    setSelectBox({
      branch: authUserInfo?.details?.user_type === "staff" ? selectBox.branch : null,
      expType: null,
      party: null
    })
   
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Expense"))}</title>
      </Helmet>

      {/* Add modal */}
      <div className="modal fade" id="addExpenseList" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newParty.edit
                    ? _t(t("Add new Expense"))
                    : _t(t("Update Expense"))}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newParty.uploading === false ? (
                <div key="fragment-customer-1">
                  <form
                    onSubmit={
                      !newParty.edit
                        ? handleSubmitCash
                        : handleUpdateExpense
                    }
                  >
                    <div>
                    {/* <label htmlFor="name" className="form-label">
                    {_t(t("Amount"))}{" "}
                    <small className="text-primary">*</small>
                  </label> */}
                  <div className="addons-list__item mt-1 mx-1 row">
                  <div className="col-6">
                  <label htmlFor="name" className="form-label d-block">
                   {_t(t("Document No"))}{" "}
                  </label>
                   <input
                    readOnly
                    type="text"
                    name="payment_no"
                    className="form-control"
                    placeholder="Document No"
                    value={cashDetails.document_no}
                    
                   />
                  </div>
                  <div className="col-6">
                  <label htmlFor="name" className="form-label d-block">
                    {_t(t("Document Date"))}{" "}
                    <small className="text-danger">*</small>
                  </label>
                    <DatePicker
                    selected={cashDetails.document_date}
                    minDate={moment().toDate()}
                    onChange={(date) => {
                      setCashDetails({
                        ...cashDetails,
                        document_date: date,
                      });
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control py-1"
                    placeholderText={_t(t("Enter expense date"))}
                    shouldCloseOnSelect={true}
                    />
                  </div>
                 </div>
                    <div className="addons-list__item mt-1 mx-1 row">
                     <div className="col-6">
                     <label htmlFor="name" className="form-label d-block">
                     {_t(t("Select Branch"))}{" "}
                     <small className="text-danger">*</small>
                     </label>
                      <Select
                      isDisabled={authUserInfo?.details?.user_type === "staff"}
                      options={branchForSearch ? branchForSearch : []}
                      selected = {selectBox.branch}
                      value = {selectBox.branch}
                      components={makeAnimated()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      classNamePrefix="select"
                      className="xsm-text"
                      onChange={handleSelectBranch}
                      maxMenuHeight="150px"
                      placeholder={_t(t("Select branch")) + ".."}
                      />
                     </div>
                     <div className="col-6">
                      <label htmlFor="name" className="form-label d-block">
                        {_t(t("Select Expense Type"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <Select
                      options={expenseForSearch ? expenseForSearch : []}
                      selected = {selectBox.expType}
                      value = {selectBox.expType}
                      components={makeAnimated()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      classNamePrefix="select"
                      className="xsm-text"
                      onChange={handleSetExpense}
                      maxMenuHeight="150px"
                      placeholder={_t(t("Select expense type")) + ".."}
                      />
                     </div>
                    </div>

                    <div className="addons-list__item mt-1 mx-1 mb-1 fk-addons-table__body row">
                      <div className="col-6">
                        <label htmlFor="name" className="form-label d-block">
                        {_t(t("Select Party"))}{" "}
                        </label>
                        <Select
                          options={partyForSearch ? partyForSearch : []}
                          selected = {selectBox.party}
                          value = {selectBox.party}
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          classNamePrefix="select"
                          className="xsm-text"
                          onChange={handleSetPartyFromCashModal}
                          maxMenuHeight="150px"
                          placeholder={_t(t("Select Party")) + ".."}
                        />
                      </div>
                     <div className="col-6">
                      <label htmlFor="name" className="form-label d-block">
                        {_t(t("Payment Type"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <label htmlFor="cash" className="mx-checkbox d-inline-block mr-3">
                        <input
                          type="radio"
                          value="Cash"
                          id="cash"
                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                          name="payment_type"
                          checked={cashDetails.payment_type === "cash"}
                          onChange={() => {
                            setOtherChequeFields(false);
                            setCashDetails({
                              ...cashDetails,
                              payment_type: "cash",
                            });
                          }}
                        />
                        <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text">
                          Cash
                        </span>
                      </label>
                      <label htmlFor="cheque" className="mx-checkbox d-inline-block mr-3">
                        <input
                          type="radio"
                          value="Cheque"
                          id="cheque"
                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                          name="payment_type"
                          checked={cashDetails.payment_type === "cheque"}
                          onChange={() => {
                            setOtherChequeFields(true);
                            setCashDetails({
                              ...cashDetails,
                              payment_type: "cheque",
                            });
                          }}
                        />
                        <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text">
                          Cheque
                        </span>
                      </label>
                     </div> 
                    </div>
                    <div
                      className={`others-cheque-fields
                      ${otherChequeFields && "active"}
                      `}
                    >
                      <div className="addons-list__item mt-1 mx-1 row">
                        <div className="col-6">
                        <label htmlFor="name" className="form-label d-block">
                          {_t(t("Enter Cheque No."))}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cheque_no"
                          name="cheque_no"
                          placeholder="Enter Cheque No."
                          value={cashDetails.cheque_no}
                          onChange={(e) => {
                            setCashDetails({
                              ...cashDetails,
                              cheque_no: e.target.value,
                            });
                          }}
                        />
                       </div>
                    
                      <div className="col-6">
                        <label htmlFor="name" className="form-label d-block">
                          {_t(t("Enter issue cheque date"))}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <DatePicker
                          selected={cashDetails.cheque_date}
                          onChange={(date) => {
                            setCashDetails({
                              ...cashDetails,
                              cheque_date: date,
                            });
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control sm-text py-2 t-mb-15 mb-md-0"
                          placeholderText={_t(t("Enter issue cheque date"))}
                          shouldCloseOnSelect={true}
                        />
                      </div>
                     </div> 
                    </div>

                    <div className="addons-list__item mt-1 mx-1 row">
                      <div className="col-6">
                      <label htmlFor="amount" className="form-label d-block">
                        {_t(t("Expense Amount"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="amount"
                        name="amount"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        placeholder="Expense Amount"
                        value={cashDetails.amount > 0 ? cashDetails.amount : ""}
                        required
                        onChange={(e) => {
                          setCashDetails({
                            ...cashDetails,
                            amount: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                    <div className="addons-list__item mt-1 mx-1 row">
                    <div className="col-12">
                    <label htmlFor="remarks" className="form-label d-block">
                     {_t(t("Remarks"))}{" "}
                    </label>
                      <textarea
                        name="remarks"
                        id="remarks"
                        className="form-control"
                        placeholder="Remarks"
                        value={cashDetails.remarks}
                        onChange={(e) => {
                          setCashDetails({
                            ...cashDetails,
                            remarks: e.target.value,
                          });
                        }}
                      ></textarea>
                    </div>

                   </div> 
                  </div>

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        {/*<button
                          type="button"
                          className="btn btn-success font-weight-bold w-100 sm-text text-uppercase t-width-max"
                          onClick={(e) => handleSubmitCash("pay-in")}
                          data-dismiss={`${
                            cashDetails.payment_type ? "modal" : ""
                          }`}
                        >
                          {_t(t("Pay-in"))}
                        </button>*/}
                      </div>
                      <div className="col-6 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary font-weight-bold print-text text-uppercase ronded mr-3"
                          onClick={(e) => handleSubmitCash()}
                        >
                          {_t(t("Save"))}
                        </button>
                      </div>
                    </div>
                  </div>
                   
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row d-flex justify-content-end align-items-center">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newParty.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-12 col-xxl-12 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {newParty.uploading === true || loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${dataPaginating && "loading"}`}></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedPayExpense.searched
                                    ? _t(t("Expense List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search customer */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add customer modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  data-toggle="modal"
                                  data-target="#addExpenseList"
                                  onClick={() => {
                                    handleFormStateEmpty()
                                  }}
                                >
                                  {_t(t("add new"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Document Name"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Document Date"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Branch"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Expense Type"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Amount"))}
                                </th> 
                                {
                                  /*
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Action"))}
                                  </th>
                                  */
                                }
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedPayExpense.searched
                                ? [
                                    expensePayment && [
                                      expensePayment.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        expensePayment.data.map((item, index) => {
                                          console.log("List Item ", item);
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (expensePayment.current_page - 1) *
                                                    expensePayment.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.document_identity}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                <Moment format="LL">
                                                 {item.document_date}
                                                </Moment>
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.branch_name}
                                              </td>
                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.expense_name}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {currencySymbolLeft()}
                                                {formatPrice(item.amount)}
                                                {currencySymbolRight()}
                                              </td>
                                              {
                                                /*
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      title="cancel"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.slug
                                                        );
                                                      }}
                                                    >
                                                     <i style={{color:"red"}} class="fa fa-trash" aria-hidden="true"></i>
                                                    </button>
                                                   
                                                  </div>
                                                </td>
                                                */
                                              }
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedPayExpense && [
                                      searchedPayExpense.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedPayExpense.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (expensePayment.current_page -
                                                      1) *
                                                      expensePayment.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.document_identity}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                               <Moment format="LL">
                                                {item.document_date}
                                               </Moment>
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.branch_name}
                                              </td>
                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.expense_name}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {currencySymbolLeft()}
                                                {formatPrice(item.amount)}
                                                {currencySymbolRight()}
                                              </td>
                                               
                                              {
                                                /*
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                <div className="dropdown">
                                                <button
                                                  className="btn t-bg-clear t-text-dark--light-40"
                                                  type="button"
                                                  title="cancel"
                                                  onClick={() => {
                                                    handleDeleteConfirmation(
                                                      item.slug
                                                    );
                                                   }}
                                                  >
                                                  <i style={{color:"red"}} class="fa fa-trash" aria-hidden="true"></i>
                                                  </button>
                                                
                                                </div>
                                                </td>
                                                */
                                              }
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {newParty.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedPayExpense.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(expensePayment, getPaginatedExpenseList)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(expensePayment)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setsearchedPayExpense({
                                      ...searchedPayExpense,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedPayExpense,
                                    partyForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default ExpenseList;
