import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import Moment from "react-moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import { UserContext } from "../../../../contexts/User";

const StockLedgerReport = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } =
    useContext(SettingsContext);
  let { branchForSearch } = useContext(RestaurantContext);
  const { partyForSearch, authUserInfo } = useContext(UserContext);

  let [newStockLedgerReport, setNewStockLedgerReport] = useState({
    branch:null,
  });

  //all data
  const [DeliveryData, setDeliveryData] = useState(null);

  //all data
  const [reportData, setReportData] = useState(null);
  const [reportSummary, setReportSummary] = useState(null)

  const componentRef = useRef();
  //settle order
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [generatedSummary, setGeneratedSummary] = useState(false)
  const [siteName, setSiteName] = useState("");
  //useEffect == componentDidMount()
  const [branchSearch, setBranchSearch] = useState(null);

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    if (authUserInfo.details.user_type === "staff") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  //Print Report
  const ReportPrint = useRef();

  const handlePrintBtn = useReactToPrint({
    content: () => ReportPrint.current,
  });

  const handleBranch = (branch) => {
    // setDineInData(null);
    // setDeliveryData(null);
    // setTakeawayData(null);
    // setGrandTotalData(null);

    setNewStockLedgerReport({
      ...newStockLedgerReport,
      branch,
    });
    // setBranch(branch);
  };

  //get BranchWise reports filter
  const getBranchWiseSelected = () => {
    console.log("newStockLedgerReport?.branch?.id ", newStockLedgerReport?.branch?.id);
    if (newStockLedgerReport?.branch?.id !== null && newStockLedgerReport?.branch?.id !== undefined && startDate !== null) {
      setLoading(true);

      var fromDate = moment(startDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-stock-ledger-report";

      let formData = new FormData();
      formData.append("to_date", fromDate);
      formData.append("branch_id", newStockLedgerReport?.branch?.id);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("StockLedgerReportResult", res);
          setReportData(res.data[0]);

          setLoading(false);
          console.log("Component ", componentRef);
          setGeneratedReport(true);
          componentRef.current.handleDownload();
          setNewStockLedgerReport(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, branch"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  //get BranchWise summary filter
  const getBranchWiseSelectedSummary = () => {
    console.log("newStockLedgerReport?.branch?.id ", newStockLedgerReport?.branch?.id);
    if (newStockLedgerReport?.branch?.id !== null && newStockLedgerReport?.branch?.id !== undefined && startDate !== null) {
      setLoading(true);

      var fromDate = moment(startDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-stock-summary-report";

      let formData = new FormData();
      formData.append("to_date", fromDate);
      formData.append("branch_id", newStockLedgerReport?.branch?.id);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("StockLedgeSummaryResult", res);
          setReportSummary(res.data[0]);

          setLoading(false);
          setGeneratedSummary(true);
          setNewStockLedgerReport(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, branch"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  let stock_inn = 0;
  let stock_out = 0;
  let balance = 0;
  let amount = 0;

  return (
    <>
      <Helmet>
        <title>{_t(t("Stock Ledger Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Stock Ledger Report"
        sheet="sheet 1"
        buttonText="Sales Detail"
        ref={componentRef}
      />

      {/* Print Button */}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {
              "\
          @page { size: landscape; }\
        "
            }
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="12" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Stock Ledger Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                              {_t(t("Item Name"))}
                              </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Document Type"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("document No"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Branch Name"))}
                                  </th>
                                  {/*<th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Item Name"))}
                                  </th>*/}
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Unit"))}
                                  </th>

                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Stock Inn"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Stock Out"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Avg Rate"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Balance Qty"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {console.log("309 ", reportData)}
                                {/* loop here*/}
                                {
                                  Object.keys(reportData).map(
                                    (group_name, index) => {
                                      let balanceQty = 0; 
                                      stock_inn = 0;
                                      stock_out = 0;
                                      amount = 0;
                                      
                                      return(
                                      <div key={index}>
                                    
                                      <tr>
                                      <th
                                        scope="row"
                                        className="lg-text text-capitalize align-middle text-center"
                                      >
                                        {group_name}
                                      </th>
                                    </tr>
                                {reportData[group_name].map((item, i) => {
                                  balanceQty += parseFloat(item.balance_qty);
                                  stock_inn += parseFloat(item.stock_inn);
                                  stock_out += parseFloat(item.stock_out);
                                  amount += parseFloat(item.amount);
                                  
                                  return (
                                    <tr>
                                    <td></td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center "
                                      >
                                        {i + 1}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                      <Moment format="LL">

                                        {item.document_date}
                                        </Moment>
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.document_type}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.document_no}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.branch_name}
                                      </td>
                                     {/* <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.item_name}
                                      </td>  */}
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.unit_name}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {formatPrice(item.stock_inn)}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {formatPrice(item.stock_out)}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {formatPrice(item.avg_rate)}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {formatPrice(item.amount)}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {formatPrice(balanceQty)}
                                      </td>
                                    </tr>
                                  );
                                })}
                                 <tr>
                                  <th
                                    scope="row"
                                    className="xsm-text text-capitalize align-middle text-center"
                                  >
                                    Total
                                  </th>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td
                                  scope="row"
                                  className="xsm-text text-capitalize align-middle text-center"
                                >
                                  {formatPrice(stock_inn)}
                                </td>
                                <td
                                scope="row"
                                className="xsm-text text-capitalize align-middle text-center"
                                >
                                  {formatPrice(stock_out)}
                                </td>
                                <td></td>
                                <td
                                scope="row"
                                className="xsm-text text-capitalize align-middle text-center"
                                >
                                  {formatPrice(amount)}
                                </td>
                                 </tr>
                                </div>
                                );
                              }
                              )
                             }
                                {/*<tr>
                                  <th className="sm-text text-capitalize align-middle text-center border-1 border"></th>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    Total
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(stock_inn)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(stock_out)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(amount)}
                                  </td>
                               </tr>*/}
                              </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-2 mb-lg-0">
              <div className="t-bg-white p-1">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                     <>
                      <div key="smtp-form mt-2">
                        <div className="row gx-2 align-items-center t-pt-2 t-pb-2">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize mt-1">
                                  {_t(t("Stock Ledger Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-2 mb-md-0"
                              placeholderText={_t(t("Date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={branchSearch && branchSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              //value={branch}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleBranch}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a branch")) + ".."}
                            />
                          </div>
                          <div className="col-md-6 t-mb-15 mb-md-0 d-none d-md-block">
                            {/* Action Button */}
                           <div className="row ml-2">
                           <button
                              className="btn btn-primary text-uppercase xsm-text mr-1 col-md-3 px-2 rounded"
                              onClick={(e) => getBranchWiseSelected(e)}
                            >
                              Stock Report
                            </button>
                            <button
                              className="btn btn-primary text-uppercase xsm-text col-md-3 px-2 rounded"
                              onClick={(e) => getBranchWiseSelectedSummary(e)}
                            >
                              Stock Summary
                            </button>
                            {
                                reportSummary !== null &&
                                reportSummary !== undefined &&
                                reportSummary.length > 0 &&
                                 <ReactHTMLTableToExcel
                                 id="summaryTable"
                                 className="download-table-xls-button btn btn-success ml-1 col-md-3 xsm-text px-2"
                                 table="summaryTable-xls"
                                 filename="stock-summary-table"
                                 sheet="stock-summary-table"
                                 buttonText="Download as XLS" />
                            }
                           </div>
                          </div>
                        </div>
                      </div>

                          {/* summary  */}

                          {reportSummary !== null &&
                          reportSummary !== undefined &&
                          reportSummary.length > 0 && (
                            <div className="row gx-2 justify-content-center p-2 mt-2">
                              {/* Action Button */}
                              {/* <div className="col-12 mt-2">
                                <button
                                  className="btn btn-info"
                                  onClick={handlePrintBtn}
                                >
                                  Print Report
                                </button>
                                <ReactHTMLTableToExcel
                                  id="summaryTable"
                                  className="download-table-xls-button btn btn-success ml-3"
                                  table="summaryTable-xls"
                                  filename="stock-summary-table"
                                  sheet="stock-summary-table"
                                  buttonText="Download as XLS" />
                              </div> */}
                              <div className="col-12 t-mb-15 mb-md-0 table-responsive"  ref={ReportPrint}>
                                <table className="table table-bordered table-hover min-table-height mt-3" id="summaryTable-xls">
                                  <thead className="align-middle">
                                  <tr style={{ border: "0px" }}>
                                  <th colspan="7" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Stock Summary
                                        </h3>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                    <tr>
                                   
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("S/L"))}
                                      </th>

                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Item Name"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Unit Name"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Stock Quantity"))}
                                      </th>

                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Average Rate"))}
                                      </th>

                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Amount"))}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="align-middle">
                                    {/* loop here*/}
                                   
                                    {reportSummary.map((item, index) => {
                                      return (
                                        <tr
                                          className="align-middle"
                                          key={index}
                                        >
                                          <th
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {index + 1}
                                          </th>

                                          <td className="xsm-text text-capitalize align-middle text-secondary">
                                            {item.item_name}
                                          </td>

                                          <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.unit_name}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {item.stock_qty}
                                          </td>

                                          <td className="xsm-text align-middle text-right">
                                            {formatPrice(item.avg_rate)}
                                          </td>

                                          <td className="xsm-text align-middle text-right">
                                            {formatPrice(item.amount)}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}


                     </>
                    )}
                  </div>

                </div>
                  
              </div>
              
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default StockLedgerReport;
