import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

//functions
import { getCookie, deleteCookie } from "../functions/Functions";

//3rd party packages

// creating context api
const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const history = useHistory();

  // State hooks here
  //loading
  const [loading, setLoading] = useState(false);
  const [dataPaginating, setDataPaginating] = useState(false);

  // auth user
  const [authUserInfo, setAuthUserInfo] = useState({
    details: null,
    permissions: null,
  });

  //supplier
  const [supplierList, setSupplierList] = useState(null);
  const [supplierForSearch, setSupplierForSearch] = useState(null);

  //stock transfer
  const [stockTransferList, setStockTransferList] = useState(null);
  const [stockTransferForSearch, setStockTransferForSearch] = useState(null);

  //party
  const [partyList, setPartyList] = useState(null);
  const [partyForSearch, setPartyForSearch] = useState(null);

  //waiter
  const [waiterList, setWaiterList] = useState(null);
  const [waiterForSearch, setWaiterForSearch] = useState(null);

  // units
  const [units, setUnits] = useState([])

  //customer
  const [customerList, setCustomerList] = useState(null);
  const [customerForSearch, setCustomerForSearch] = useState(null);

  //customer online
  const [customerListOnline, setCustomerListOnline] = useState(null);
  const [customerOnlineForSearch, setCustomerOnlineForSearch] = useState(null);

  //adminStaff
  const [adminStaffList, setAdminStaffList] = useState(null);
  const [adminStaffForSearch, setAdminStaffListforSearch] = useState(null);
  //delivery
  const [deliveryList, setDeliveryList] = useState(null);
  const [deliveryForSearch, setDeliveryListforSearch] = useState(null);
  const [deliveryListReq, setDeliveryListReq] = useState(null);
  const [deliveryListReqForSearch, setDeliveryListReqForSearch] =
    useState(null);

  // localStorage helper
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  }

  // localStorage helper
  const getDataFromLocal = (url) =>{
    return JSON.parse(localStorage.getItem(url));
  }

  //check if offline helper
  const offline = (url) => {
    return !navigator.onLine && localStorage.getItem(url) != null;
  }

  //useEffect- to get data on render
  useEffect(() => {
    //call- unauthenticated
    
    //call if authenticated
    if (getCookie() !== undefined || offline(BASE_URL + "/auth/user")) {
      getAuthUser();
      getParty();
      getWaiter();
      getUnits();
      getCustomer();
      getAdminStaff();
      getDeliveryUser()
    }
  }, []);

  //Helper
  const setUserInfo = (res) => {
    if (res.data[0].is_banned === 0 || res.data[0].is_banned === "0") {
      setAuthUserInfo({
        ...authUserInfo,
        details: res.data[0],
        permissions: res.data[1],
      });
      setLoading(false);
    } else {
      deleteCookie();
    }
  }

  //get authenticated user // Priority -> offline
  const getAuthUser = () => {
    setLoading(true);
    const langUrl = BASE_URL + "/auth/user";
    return axios
    .get(langUrl, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
      .then((res) => {
        setUserInfo(res);
        localStorage.setItem(langUrl, JSON.stringify(res));
      })
      .catch((error) => {
        if(offline(langUrl)){
           setLoading(false);
          let parseRes = JSON.parse(localStorage.getItem(langUrl));
          setUserInfo(parseRes);
        }
      });
  };

  // Helper
  const setSuppliersList = (res) => {
    setSupplierList(res.data[0]);
    setSupplierForSearch(res.data[1]);
    setLoading(false);
  }

  //get supplier  // Priority -> 
  const getSupplier = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-supplier";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setSuppliersList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setSuppliersList(parseRes);
        }
      });
  };

  // Helper
  const setPaginatedSupplierList = (res) => {
    setSupplierList(res.data[0]);
    setSupplierForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated supplier // Priority -> 
  const setPaginatedSupplier = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-supplier?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedSupplierList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedSupplierList(parseRes);
        }
      });

  };

  const getUnits = () => {
    const url = BASE_URL + "/settings/unit";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(res => {
        if (res.data && res.data.length)
          setUnits(res.data[1]);
      })
      .catch(error => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setUnits(parseRes);
        }
      })
  }

  // Helper
  const setStockTransfersList = (res) => {
    setStockTransferList(res.data[0]);
    setStockTransferForSearch(res.data[1]);
    setLoading(false);
  }

  //get supplier  // Priority -> 
  const getStockTransfer = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/stockTransfer";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setStockTransfersList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setStockTransfersList(parseRes);
        }
      });
  };

  // Helper
  const setPaginatedStockTransferList = (res) => {
    setStockTransferList(res.data[0]);
    setStockTransferForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated supplier // Priority -> 
  const setPaginatedStockTransfer = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/stockTransfer?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedStockTransferList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedStockTransferList(parseRes);
        }
      });
  };

  // Helper
  const setPartiesList = (res) => {
    setPartyList(res.data[0]);
    setPartyForSearch(res.data[1]);
    setLoading(false);
  }

  //get party  // Priority -> 
  const getParty = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-party";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPartiesList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPartiesList(parseRes);
        }
      });
  };

  // Helper
  const setPaginatedPartyList = (res) => {
    setPartyList(res.data[0]);
    setPartyForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated party // Priority -> 
  const setPaginatedParty = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-party?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedPartyList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedPartyList(parseRes);
        }
      });

  };

  // Helper
  const setWaitersList = (res) => {
    setWaiterList(res.data[0]);
    setWaiterForSearch(res.data[1]);
    setLoading(false);
  }
  //get waiter  // Priority -> 
  const getWaiter = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-waiter";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setWaitersList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setWaitersList(parseRes);
        }
      });
  };

  // Helper
  const setPaginatedWaiterList = (res) => {
    setWaiterList(res.data[0]);
    setWaiterForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated waiter  // Priority -> 
  const setPaginatedWaiter = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-waiter?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedWaiterList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedWaiterList(parseRes);
        }
      });
  };

  // Helper
  const setCustomersList = (res, dontLoad=false) => {
    setCustomerList(res.data[0]);
    setCustomerForSearch(res.data[1]);
    if (!dontLoad) setLoading(false);
    else dontLoad(res.data[1])
  }

  //get customer  // Priority
  const getCustomer = (dontLoad=false) => {
    if (!dontLoad) setLoading(true);
    const url = BASE_URL + "/settings/get-customer";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        // console.log("Resss: ", res);
        setCustomersList(res, dontLoad);
        setDataToLocal(url, res);
        return res;
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setCustomersList(parseRes);
          return parseRes;
        }
      });
  };

  // Helper
  const setPaginatedCustomersList = (res) => {
    setCustomerList(res.data[0]);
    setCustomerForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated customer // Priority -> 
  const setPaginatedCustomer = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-customer?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedCustomersList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedCustomersList(parseRes);
        }
      });
  };

  // Helper
  const setCustomersListOnline = (res) => {
    setCustomerListOnline(res.data[0]);
    setCustomerOnlineForSearch(res.data[1]);
    setLoading(false);
  }

  //get customer online  // Priority ->
  const getCustomerOnline = () => {
    setLoading(true);
    const customerUrl = BASE_URL + "/settings/get-website-customer";
    return axios
      .get(customerUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setCustomersListOnline(res);
        setDataToLocal(customerUrl, res);
      }).catch((error) => {
        if(offline(customerUrl)){
          let parseRes = getDataFromLocal(customerUrl);
          setCustomersListOnline(parseRes);
        }
      });
  };
  
  // Helper
  const setPaginatedCustomerList = (res) => {
    setCustomerListOnline(res.data[0]);
    setCustomerOnlineForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated customer online  // Priority
  const setPaginatedCustomerOnline = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-website-customer?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedCustomerList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedCustomerList(parseRes);
        }
      });
  };

  // Helper
  const setAdminStaffsList = (res) => {
    setAdminStaffList(res.data[0]);
    setAdminStaffListforSearch(res.data[1]);
    setLoading(false);
  }

  //get adminStaff   // Priority -> 
  const getAdminStaff = () => {
    setLoading(true);
    const adminStaffUrl = BASE_URL + "/settings/get-admin-staff";
    return axios
      .get(adminStaffUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setAdminStaffsList(res);
        setDataToLocal(adminStaffUrl, res);
      }).catch((error) => {
        if(offline(adminStaffUrl)){
          let parseRes = getDataFromLocal(adminStaffUrl);
          setAdminStaffsList(parseRes);
        }
      });
  };

  // Helper
  const setPaginatedAdminStaffList = (res) => {
    setAdminStaffList(res.data[0]);
    setAdminStaffListforSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated adminStaff  // Priority -> 
  const setPaginatedAdminStaff = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-admin-staff?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDataToLocal(url, res);
        setPaginatedAdminStaffList(res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedAdminStaffList(parseRes);
        }
      });
  };

  //Helper
  const setDeliveriesList = (res) => {
    setDeliveryList(res.data[0]);
    setDeliveryListforSearch(res.data[1]);
    setDeliveryListReq(res.data[2]);
    setDeliveryListReqForSearch(res.data[3]);
    setLoading(false);
  }

  //get delivery user   // Priority -> 
  const getDeliveryUser = () => {
    setLoading(true);
    const adminStaffUrl = BASE_URL + "/settings/get-delivery-man";
    return axios
      .get(adminStaffUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDeliveriesList(res);
        setDataToLocal(adminStaffUrl, res);
      }).catch((error) => {
        if(offline(adminStaffUrl)){
          let parseRes = getDataFromLocal(adminStaffUrl);
          setDeliveriesList(parseRes);
        }
      });
  };

  //Helper
  const setPaginatedDeliveriesList = (res) => {
    setDeliveryList(res.data[0]);
    setDeliveryListforSearch(res.data[1]);
    setDeliveryListReq(res.data[2]);
    setDeliveryListReqForSearch(res.data[3]);
    setDataPaginating(false);
  }

  // get paginated delivery user // Priority -> 
  const setPaginatedDeliveryUser = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-delivery-man?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedDeliveriesList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedDeliveriesList(parseRes);
        }
      });
  };

  return (
    <UserContext.Provider
      value={{
        //auth user
        getAuthUser,
        setAuthUserInfo,
        authUserInfo,

        //supplier
        getSupplier,
        supplierList,
        setSupplierList,
        setPaginatedSupplier,
        supplierForSearch,
        setSupplierForSearch,

        //stock transger
        getStockTransfer,
        stockTransferList,
        setStockTransferList,
        setPaginatedStockTransfer,
        stockTransferForSearch,
        setStockTransferForSearch,

        units,

        //party
        getParty,
        partyList,
        setPartyList,
        setPaginatedParty,
        partyForSearch,
        setPartyForSearch,

        //waiter
        getWaiter,
        waiterList,
        setWaiterList,
        setPaginatedWaiter,
        waiterForSearch,
        setWaiterForSearch,

        //customer
        getCustomer,
        customerList,
        setCustomerList,
        setPaginatedCustomer,
        customerForSearch,
        setCustomerForSearch,

        //customer Online
        getCustomerOnline,
        customerListOnline,
        setCustomerListOnline,
        setPaginatedCustomerOnline,
        customerOnlineForSearch,
        setCustomerOnlineForSearch,

        //adminStaff
        getAdminStaff,
        adminStaffList,
        setAdminStaffList,
        setPaginatedAdminStaff,
        adminStaffForSearch,
        setAdminStaffListforSearch,

        //delivery user
        getDeliveryUser,
        deliveryList,
        setDeliveryList,
        setPaginatedDeliveryUser,
        deliveryForSearch,
        setDeliveryListforSearch,
        deliveryListReq,
        setDeliveryListReq,
        deliveryListReqForSearch,
        setDeliveryListReqForSearch,

        //pagination
        dataPaginating,
        setDataPaginating,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
