import React, { useEffect, useContext, useState } from "react";
import { ProgressBar } from "react-bootstrap"; 
import "./dashboard.css";
import { ReactComponent as Triangle } from "../../../public/caret-up.svg";


const ListWidget = ({data,heading}) => {

  const [loadAll,setLoadAll] = useState(false);
  const [data1,setData1] = useState([]);
  const [loading,setLoading] = useState(false);

  const handleLoad = () =>{
    if(loadAll === true){
      setLoading(true);
      setData1(data.tbody);
      setTimeout(() => {    
        setLoading(false);
      }, 300);
    }else{
      setLoading(true);
      setData1(data.tbody.slice(0, 5));
      setTimeout(() => {    
        setLoading(false);
      }, 300);
    }
  }

  useEffect(()=>{
    if(heading?.toLowerCase()  === "branch wise sales"){
      handleLoad()
    }else if(heading?.toLowerCase() === "item wise sales"){
      handleLoad()
    }else if(heading?.toLowerCase() === "category wise sales"){
      handleLoad()
    }else if(heading?.toLowerCase() === "brand wise sales"){
      handleLoad();
    }
 },[loadAll,data])

  return (
    <div className={`row dashboard__card_list mt-3 px-0 mt-1`} style={{width:"auto !important",margin:"0 0.3rem"}}>
    <div className="col-12">
    <div className="d-flex align-items-center justify-content-between px-1 pb-3">
     <div className="list-heading font-weight-bold">
      <h5 className="mt-1">{heading}</h5>
     </div>
     <div>
     {
      data.tbody?.length > 5 &&
      <button className="btn btn-primary btn-sm rounded px-3 py-1"
      disabled={loading}
      onClick={()=> setLoadAll(!loadAll)}
      >
      {
       loadAll ? "Load less" : "Load All"
      }
      
      </button>
     }
     </div>
    </div>
     <table className="table mb-0 table-borderless akash-table-for-print-padding">
       <thead>
         <tr>
           <th
             scope="col"
             className="list-head-text text-capitalize dashboard_border border-1 p-2"
           >
             {data.thead[0]}
           </th>
           <th
             scope="col"
             className="list-head-text text-capitalize dashboard_border border-1 p-2"
           >
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-capitalize">
                {data.thead[1]}
              </div>
              <div className="ml-2" onClick={()=>{
                setLoading(true)
                let Data =  [...data1].sort((a,b)=>{
                  return parseFloat(b[1]) - parseFloat(a[1])
                });
                setTimeout(() => {
                  setData1(Data);
                  setLoading(false)
                }, 300);
              }}>
              <Triangle className = "dashboard_card_up cursor-pointer"/>
              </div>
            </div>
           </th>
           <th
             scope="col"
             className=" list-head-text text-capitalize dashboard_border border-1 p-2"
           >
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-capitalize">
                {data.thead[2]}
              </div>
              <div className="ml-2" onClick={()=>{
                setLoading(true)
                let Data = [...data1].sort((a,b)=>{
                  return parseFloat(b[2]) - parseFloat(a[2])
                });
                setTimeout(() => {
                  setData1(Data);
                  setLoading(false)
                }, 300);
              }}>
              <Triangle className = "dashboard_card_up cursor-pointer"/>
              </div>
            </div>
           </th>
           <th
             scope="col"
             className=" list-head-text text-capitalize dashboard_border border-1 p-2"
           >
           {data.thead[3]}
           </th>
         </tr>
       </thead>
      {data1?.map((dataChild) => {
        let percent = ((parseFloat(dataChild[1]) / parseFloat(data.total))*100)?.toFixed(2);
        return (
            <tbody>
              <tr>
                <th
                  scope="col"
                  className=" list-text text-capitalize dashboard_border border-1 p-2"
                >
                  {dataChild[0]}
                </th>
                <th
                  scope="col"
                  className=" list-text text-capitalize dashboard_border border-1 p-2"
                >
                  {parseFloat(dataChild[1])?.toFixed(2)}
                </th>
                <th
                  scope="col"
                  className=" list-text text-capitalize dashboard_border border-1 p-2"
                >
                  {dataChild[2]}
                </th>
                <th
                scope="col"
                className=" list-text text-capitalize dashboard_border border-1 p-2"
              >
               <div className="d-flex align-items-center" style={{width:"100%"}}>
               <span className = "mr-2" style={{width:"42%",fontSize:"0.75rem"}}>
               {percent}%
               </span>
                <ProgressBar style={{width:"58%"}} now = {percent} />
               </div>
              </th>
              </tr>
            </tbody>
            );
        })}
        </table>
        </div>
        
    </div>
  );
};

export default ListWidget;
