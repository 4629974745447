import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import moment from "moment";

//functions
import {
  _t,
  getCookie,
  pageLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { NavLink } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

const WorkPeriod = () => {
  var weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //getting context values here
  const {
    //common
    loading,
    setLoading,
    dataPaginating,
    generalSettings,
    showManageStock,
    // setshowManageStock
  } = useContext(SettingsContext);

  const { authUserInfo, adminStaffForSearch } = useContext(UserContext);

  const {
     //item
     ingredientItemForSearch,
 
     //group
     getIngredientItem,

    //branch
    branchForSearch,
    //work period
    getWorkPeriod,
    workPeriodList,
    setWorkPeriodList,
    setPaginatedWorkPeriod,
    workPeriodForSearch,
    setWorkPeriodListForSearch,
    getWorkPeriodForBranch,
  } = useContext(RestaurantContext);

  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };
 
  const { t } = useTranslation();
  // order type
  let totalItemPrice = 0;
  let totalItemQty = 0;
  let totalGroupQty = 0;
  let totalGroupPrice = 0;
  let totalOrderTypeQty = 0;
  let totalOrderTypePrice = 0;

  // product mix
  let totalProductMix = 0;
  
  const [zReportData, setZReportData] = useState(null);
  const [zCatgData, setZCatgData] = useState(null);
  const [zOrderTypeData, setZOrderTypeData] = useState(null);
  const [zProductMix, setZProductMix] = useState(null);
  const [cashRegisterData, setCashRegisterData] = useState(null);
  //stock
  const [stockModal,setStockModal] = useState(false);
  const [stockDayEnd,setStockDayEnd] = useState([]);
  const [stockData,setStockData] = useState({
    branch_id: null,
    workperiod_id: null,
  })

  const [users,setUsers] = useState(null);
  const [print,setPrint] = useState(null);
  const [tillUser,setTillUser] = useState({
    id:null,
    user_name: null,
    workperiod_id: null
  });
  const cashInput = useRef();

  const [tillModal,setTillModal] =useState(false);

  //new unit
  let [newWorkPeriod, setNewWorkPeriod] = useState({
    user_type: null,
    branch_id: null,
    opening_cash: 0,
    uploading: false,
  });

  //search result
  let [searchedWorkPeriod, setSearchedWorkPeriod] = useState({
    list: null,
    searched: false,
  });

  const printType = [
    {
      id:1,
      name: "Z Report"
    },
    {
      id:2,
      name: "Order Type"
    },
    {
      id:3,
      name: "Category"
    }, 
    {
      id:4,
      name: "Product Mix"
    },
    {
      id:5,
      name: "View Cash"
    }
  ]

  const customStyle = {
    control: (provided) => ({
      ...provided,
      minHeight : '2rem',
      marginTop:"0",
      marginBottom:"0",
    
    }),
   
    singleValue: (provided) =>({
      ...provided,
      alignSelf: "center",
    }),
    input:(provided)=>({
      ...provided,
     margin:"0",
    }),
    valueContainer: (provided) => ({
      ...provided,
      marginTop:"0",
      padding:"0 0.5rem",
     
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding:"0 0.4rem",
      "svg": {
        height: '2rem',
      }
    }),
  }

  useEffect(()=>{
    if(authUserInfo.details?.user_type === "staff"){
      let userList = adminStaffForSearch?.length > 0 && adminStaffForSearch?.filter((list)=>{
        if(list?.branch_id){
          return list?.branch_id === authUserInfo.details?.branch_id && list?.id !== authUserInfo.details?.id
        }
      })
     setUsers(userList);
    }else{
      let userList = adminStaffForSearch?.length > 0 && adminStaffForSearch?.filter((list)=>{
          return list?.id !== authUserInfo.details?.id
      })
      setUsers(userList);
    }

    workPeriodList && workPeriodList?.data && 
    workPeriodList.data.map((item)=>{
      setPrint((curr)=>{
        const Data = {
          ...curr,
          [item.id] : printType[0]
        }
       return Data
      })
    })

    authUserInfo.details &&
    setNewWorkPeriod({
      ...newWorkPeriod,
      branch_id: authUserInfo.details.branch_id
        ? authUserInfo.details.branch_id
        : null,
      user_type: authUserInfo.details.user_type,
    });

  },[authUserInfo,adminStaffForSearch])
   
  //useEffect == componentDidMount
  useEffect(() => {
    getIngredientItem();
    console.log(" newWorkPeriod ", authUserInfo.details);

  }, []);

  let user = [];
  for (const keys in zReportData?.user) {
    user.push(
      <li>
        {keys}: {zReportData.user[keys]}
      </li>
    );
  }

  let salesDetails = [];
  for (const keys in zReportData?.sales_details) {
    salesDetails.push(
      <li>
        {keys}: {zReportData.sales_details[keys]}
      </li>
    );
  }

  let inSight = [];
  for (const keys in zReportData?.insights) {
    inSight.push(
      <li>
        {keys}: {zReportData.insights[keys]}
      </li>
    );
  }

  let categoryWiseSales = [];
  for (const keys in zReportData?.category_wise_sales) {
    categoryWiseSales.push(
      <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
        {/* {keys}: {zReportData.category_wise_sales[keys]} */}
        <span className="font-weight-bold">{keys}</span>
        <span className="float-right">
          {zReportData.category_wise_sales[keys]}
        </span>
      </p>
    );
  }

  // Order types
  let orderTypesSales = [];
  for (const keys in zReportData?.order_type_sales) {
    orderTypesSales.push(
      <p className="mb-0 xsm-text fk-print-text_pos text-capitalize d-flex w-100 justify-content-between">
        {/* {keys}: {zReportData.category_wise_sales[keys]} */}
        <span className="font-weight-bold w-50">{keys}</span>
        <span className="w-25">{zReportData.order_type_sales_num[keys]}</span>
        <span className="w-25 text-right">
          {zReportData.order_type_sales[keys]}
        </span>
      </p>
    );
  }

  // Party Wise
  let partyWiseSales = [];
  for (const keys in zReportData?.party_wise_sales) {
    partyWiseSales.push(
      <p className="mb-0 xsm-text fk-print-text_pos text-capitalize d-flex w-100 justify-content-between">
        {/* {keys}: {zReportData.category_wise_sales[keys]} */}
        <span className="font-weight-bold w-50">{keys}</span>
        <span className="w-25">{zReportData.party_wise_sales_no[keys]}</span>
        <span className="w-25 text-right">
          {zReportData.party_wise_sales[keys]}
        </span>
      </p>
    );
  }

  let departWiseSales = [];
  for (const keys in zReportData?.department_wise_sales) {
    departWiseSales.push(
      <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
        {/* {keys}: {zReportData.department_wise_sales[keys]} */}
        <span className="font-weight-bold">{keys}</span>
        <span className="float-right">
          {zReportData.department_wise_sales[keys]}
        </span>
      </p>
    );
  }

  let departPaymentSales = [];
  for (const keys in zReportData?.payment_sales) {
    departPaymentSales.push(
      <p className="mb-0 mx-2 xsm-text fk-print-text_pos text-capitalize">
        {/* {payment} : {zReportData?.department_payment_sales[keys][payment]} */}
        <span className="font-weight-bold"> {keys}</span>
        <span className="float-right">
          {Math.round(zReportData?.payment_sales[keys], 2)}
        </span>
      </p>
    );
  }

  // for (const keys in zReportData?.department_payment_sales) {
  //   departPaymentSales.push(
  //     <p className="mb-0 xsm-text fk-print-text_pos text-capitalize lg-text">
  //       {keys}
  //     </p>
  //   );
  //   for (const payment in zReportData?.department_payment_sales[keys]) {
  //     console.log(
  //       "Payment",
  //       payment,
  //       zReportData?.department_payment_sales[keys][payment]
  //     );
  //     departPaymentSales.push(
  //       <p className="mb-0 mx-2 xsm-text fk-print-text_pos text-capitalize">
  //         {/* {payment} : {zReportData?.department_payment_sales[keys][payment]} */}
  //         <span className="font-weight-bold"> {payment}</span>
  //         <span className="float-right">
  //           {zReportData?.department_payment_sales[keys][payment]}
  //         </span>
  //       </p>
  //     );
  //   }
  //   console.log("Keys", zReportData?.department_payment_sales[keys]);
  // }

  //set branch id
  const handleSetBranchId = (branch) => {
    setNewWorkPeriod({
      ...newWorkPeriod,
      branch_id: branch.id,
    });
  };

  //search work periods here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedWorkPeriod({ ...searchedWorkPeriod, searched: false });
    } else {
      let searchedList = workPeriodForSearch.filter((item) => {
        let lowerCaseItemBranchName = item.branch_name.toLowerCase();
        let lowerCaseItemUserName = item.started_by.toLowerCase();
        let lowerCaseItemDate = item.date.toLowerCase();
        let lowerCaseItemUserName2 =
          item.ended_by && item.ended_by.toLowerCase();
        return (
          lowerCaseItemBranchName.includes(searchInput) ||
          lowerCaseItemUserName.includes(searchInput) ||
          lowerCaseItemDate.includes(searchInput) ||
          (lowerCaseItemUserName2 &&
            lowerCaseItemUserName2.includes(searchInput))
        );
      });
      setSearchedWorkPeriod({
        ...searchedWorkPeriod,
        list: searchedList,
        searched: true,
      });
    }
  };

  const handleSetPrintType = (print_type,id) => {
    setPrint({...print,[id]:print_type})
  }

  const handlePrintReport = (itemId) =>{
    if (print === null){
      toast.error("Please select print type", toastObj)
      return false;
    }
    if (!print[itemId]){
      toast.error("Please select print Row", toastObj)
      return false;
    }
    if(print[itemId].id === 1){
      handleZReport(itemId,"shift-report/");
    }else if(print[itemId].id === 2){
      handleOrderTypeReport(itemId,"shift-report-ot/");
    }else if(print[itemId].id === 3){
      handleCatgoryReport(itemId,"shift-report-cw/");
    }else if(print[itemId].id === 4){
      handleProductReport(itemId,"shift-menu-mix/");
    }else if(print[itemId].id === 5){
      handleViewReprot(itemId)
    }

  }

  const handleBranchCheckOnModal = () => {
    if(cashInput.current){
      setTimeout(() => {
        cashInput.current.focus()
        
      }, 300);
    }
    if (newWorkPeriod.branch_id) {
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, toastObj);
    }
  };

  const handleStockSubmit = () =>{
    let formData = {
      branch_id : stockData.branch_id,
      branch_name: stockData.branch_name,
      workperiod_id: stockData.workperiod_id,
      stock : Object.values(stockDayEnd)
    }
    //stock item
    if(!stockDayEnd || Object.keys(stockDayEnd)?.length === 0){
      toast.error(`${_t(t("Please add stock before submit"))}`, toastObj);
    }else{
      setLoading(true);
      const url = BASE_URL + `/settings/update-closing-items`;
      if(stockData?.branch_id && stockData?.workperiod_id){
      return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setWorkPeriodList(res.data[0]);
        setWorkPeriodListForSearch(res.data[1]);
        getWorkPeriodForBranch();
        setStockDayEnd([]);
        setStockModal(false);
        setStockData({});
        setLoading(false);
        toast.success(`${_t(t("Closing stock has been updated"))}`, toastObj);
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, toastObj);
      });
     }else{
      toast.error(`${_t(t("Branch can't be empty"))}`, toastObj);
     }
    }

  }

  const handleStock = (qty,item) =>{
    //stock item
      setStockDayEnd((curr)=>{
        let newData;
        if(stockDayEnd[item.id] && qty){
          newData = {
            ...curr,
           [item.id] : {
            ...stockDayEnd[item?.id],
             qty: qty,
            },
         }
        }else if(qty){
           newData = {
            ...curr,
           [item.id] : {
             id: item.id,
             name: item.name,
             qty: qty,
             unit:item.unit,
             unit_id: item.unit_id
            },
           }
        }else{
          newData = {
            ...curr,
          }
          curr && delete curr[item?.id]
        }
        return newData
      })    
    
  }

   // multi user pop up
   const changeUser =(e)=>{
    setTillUser({
      ...tillUser,
      id: parseInt(e.target.id),
      user_name: e.target.value
    });
  }

  const handleOrderTranfer = () =>{
    if(tillUser?.user_name && tillUser?.id){
    setLoading(true)  
    const url = BASE_URL + `/settings/update-work-period`;
    let time = new Date().getTime();
    let formData = {
      id: tillUser.workperiod_id,
      user_id: tillUser?.id,
      till_user: tillUser?.user_name,
      ended_at : time,
    };

    return axios
    .post(url, formData, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((res) => {
      if(res.data === "work period not open"){
        setLoading(false);
        setTillModal(true);
        toast.error(`${_t(t(`Please start the workperiod of ${tillUser.user_name}`))}`, toastObj);
      }else{
        authUserInfo.details &&
        setNewWorkPeriod({
          branch_id: authUserInfo.details.branch_id
            ? authUserInfo.details.branch_id
            : null,
          user_type: authUserInfo.details.user_type,
          opening_cash: 0,
          uploading: false,
        });
      setWorkPeriodList(res.data[0]);
      setWorkPeriodListForSearch(res.data[1]);
      getWorkPeriodForBranch();
      setSearchedWorkPeriod({
        ...searchedWorkPeriod,
        list: res.data[1],
      });
      handleZReport(tillUser.workperiod_id,"shift-report-end/");
      localStorage.setItem("order_id", 0);
      toast.success(`${_t(t("Work period has been ended"))}`, toastObj);
      setTillModal(false);
      setLoading(false);
        setTillUser({
         id:null,
         user_name:null,
         workperiod_id: null,
        });
      }
    }).catch((err)=>{
      setLoading(false);
      setTillModal(true);
      setTillUser({
        id:null,
        user_name:null,
        workperiod_id: null
       })
      toast.error(`${_t(t("Please try again"))}`, toastObj);
    });
    }else{
      toast.error(
        `${_t(
          t("Please select user to tranfer your order")
        )}`,
        toastObj
      );
    }
    
  }

  //Save New work period
  const handleSaveNewWorkPeriod = (e) => {
    e.preventDefault();
    if (newWorkPeriod.branch_id) {
      setNewWorkPeriod({
        ...newWorkPeriod,
        uploading: true,
      });
      const url = BASE_URL + `/settings/new-work-period`;
      let date =
        weekday[new Date().getDay()] +
        ", " +
        new Date().getDate() +
        " " +
        month[new Date().getMonth()] +
        ", " +
        new Date().getFullYear();

      let time = new Date().getTime();

      let formData = {
        date: date,
        branch_id: newWorkPeriod.branch_id,
        started_at: time,
        opening_cash: newWorkPeriod.opening_cash,
       
      };
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data === "exist") {
            authUserInfo.details &&
              setNewWorkPeriod({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
                opening_cash: 0,
              });
            toast.error(
              `${_t(t("Please end the started work period first"))}`,
              toastObj
            );
          } else {
            authUserInfo.details &&
              setNewWorkPeriod({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
                opening_cash: 0,
              });
            setWorkPeriodList(res.data[0]);
            setWorkPeriodListForSearch(res.data[1]);
            getWorkPeriodForBranch();
            setSearchedWorkPeriod({
              ...searchedWorkPeriod,
              list: res.data[1],
            });
            setLoading(false);
            localStorage.setItem("order_id", 0);
            toast.success(`${_t(t("Work period has been started"))}`, toastObj);
            document.getElementById('close-btn').click();
          }
        })
        .catch(() => {
          setLoading(false);
          setNewWorkPeriod({
            ...newWorkPeriod,
            uploading: false,
          });
          toast.error(`${_t(t("Please try again"))}`, toastObj);
        });
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, toastObj);
    }
  };

  //milisec to hour min
  const millisToMinutesAndHours = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var hours = Math.floor(minutes / 60);
    minutes = Math.floor(minutes % 60);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${hours} ${_t(t("hr"))} - ${minutes} ${_t(t("min"))} - ${
      seconds < 10 ? "0" : ""
    }${seconds} ${_t(t("sec"))}`;
  };

  //end confirmation modal of workPeriod
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to end now?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleEndWorkPeriod(id);
                  onClose();
                }}
              >
                {_t(t("Yes, end work period!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const zReportRef = useRef();
  const catgReport = useRef()
  const cashRegisterRef = useRef();
  const orderTypeReport = useRef();
  const productMixReport = useRef();

  const handleProductMixReport = useReactToPrint({
    content: () => productMixReport.current,
  });

  const handleOdTCatgReport = useReactToPrint({
    content: () => orderTypeReport.current,
  });
 
  const handleCatgReport = useReactToPrint({
    content: () => catgReport.current,
  });

  const handlePrintZReport = useReactToPrint({
    content: () => zReportRef.current,
  });

  const handlePrintCashRegister = useReactToPrint({
    content: () => cashRegisterRef.current,
  });

  const handleOrderTypeReport = (id,url) => {
    const apiUrl = BASE_URL + `/settings/`+ url + id ;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((response) => {
        setZOrderTypeData(response.data);
        handleOdTCatgReport()
      });
  };

  const handleProductReport = (id,url)=>{
    const apiUrl = BASE_URL + `/settings/`+ url + id ;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log("abcd44",res.data)
        setZProductMix(res.data);
        handleProductMixReport()
      });
  }

  const handleCatgoryReport = (id,url) => {
    const apiUrl = BASE_URL + `/settings/`+ url + id ;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((response) => {
        setZCatgData(response.data);
        handleCatgReport()
      });
  };

  const handleZReport = (id,url) => {
    const apiUrl = BASE_URL + `/settings/`+ url + id ;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((response) => {
        console.log(response);
        setZReportData(response.data.data);
        handlePrintZReport();
      });
  };

  const handleDayEndWorkPeriod = () => {
    if (newWorkPeriod.branch_id) {
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, toastObj);
    }
    const apiUrl =
      BASE_URL + `/settings/shift-report-day-end/` + newWorkPeriod.branch_id;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((response) => {
        if (response.data !== "orderExist") {
          if (response.data !== "day_closed") {
            console.log("data response : ", response);
            setZReportData(response.data.data);
            handlePrintZReport();
          } else {
            toast.error(`${_t(t("Day Already Closed"))}`, toastObj);
          }
        } else {
          toast.error(`${_t(t("Please close work period first"))}`, toastObj);
        }
      });
  };

  const handleCashRegister = (id) => {
    const apiUrl = BASE_URL + `/settings/view-cash-register/` + id;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((response) => {
        console.log("Cash Register Data ", response);
        setCashRegisterData(response.data.data);
        handlePrintCashRegister();
      });
  };

  const handleViewReprot = (id) => {
    handleCashRegister(id);
  };

  //set name hook
  const handleSetOpeningCash = (e) => {
    console.log("work period", e.target.value);
    setNewWorkPeriod({
      ...newWorkPeriod,
      opening_cash: e.target.value,
    });
  };

  //free of cost order confirmation modal
  const handleWorkperiodEmail = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("You want email for this workperiod?"))}
            </p>
            <form 
            onSubmit={(e) => {
              e.preventDefault();
              if(e.target["passcode"].value === "4321"){
                handleCallApi(id,onClose);
              }else{
                toast.error("Please cordinate to your manager for admin use only",toastObj)
              }
            }}
            >
              <div className="form-group mb-4">
                <label
                  htmlFor="passcode"
                  className="font-weight-bold mr-4 lg-text"
                >
                  Enter passcode
                </label>
                <input
                  type="text"
                  className="form-control secure-password-field w-50 d-inline-block"
                  name="passcode"
                  id="passcode"
                  placeholder="Password"
                  required
                />
              </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                type="submit"
              >
                {_t(t("Yes"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
            </form>
            </div>
        );
      },
    });
  };
  
  //end here
  const handleCallApi = (id,onClose) =>{
    const url = BASE_URL + `/settings/shift-report-api/` + id;
    axios
    .get(url, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((response) => {
      console.log(response);
      onClose()
    })
    .catch((err)=>{
      console.log(err)
    })
   
  }

  // work period end
  const handleEndWorkPeriod = (id) => {
    setNewWorkPeriod({
      ...newWorkPeriod,
      uploading: true,
    });
    const url = BASE_URL + `/settings/update-work-period`;
    let time = new Date().getTime();
    let formData = {
      id: id,
      user_id: "",
      till_user: "",
      ended_at: time,
      show_stock : showManageStock ? 1 : 0,
      physical_stock : parseInt(getSystemSettings(generalSettings, "physical_stock")),
    };

    let orderSubmit = JSON.parse(localStorage.getItem("orderplaced"));
    let orderSettle = JSON.parse(localStorage.getItem("ordersettle"));
    if((orderSubmit && orderSubmit?.length > 0) || (orderSettle && orderSettle?.length > 0)){
      setNewWorkPeriod({
        ...newWorkPeriod,
        uploading: false,
      });
      toast.error((orderSubmit || orderSettle) && "You have " + ((orderSubmit ? orderSubmit?.length : "") + (orderSettle ? orderSettle?.length : "")) + " order to sync in submit/settle.")
    }else{
    
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "orderExist" && res.data !== "addClosing") {
          authUserInfo.details &&
            setNewWorkPeriod({
              branch_id: authUserInfo.details.branch_id
                ? authUserInfo.details.branch_id
                : null,
              user_type: authUserInfo.details.user_type,
              opening_cash: 0,
              uploading: false,
            });
          setWorkPeriodList(res.data[0]);
          setWorkPeriodListForSearch(res.data[1]);
          getWorkPeriodForBranch();
          setSearchedWorkPeriod({
            ...searchedWorkPeriod,
            list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Work period has been ended"))}`, toastObj);
          handleZReport(id,"shift-report-end/");
          localStorage.setItem("order_id", 0);

        } else {
          if (res.data === "addClosing") {
            toast.error(`${_t(t("Add closing stock of ingredients"))}`, toastObj);
          } else {           
            if(parseInt(getSystemSettings(generalSettings, "till_user")) === 1){
              setTillModal(true);
              setTillUser({
                ...tillUser,
                workperiod_id: id
              });

            }else{
              toast.error(
                `${_t(
                  t("All submitted orders need to be settled to end workperiod")
                )}`,
                toastObj
              );
            }
            setLoading(false);
            setNewWorkPeriod({
              ...newWorkPeriod,
              uploading: false,
            });
          }
            }
      })
      .catch(() => {
        setLoading(false);
        setNewWorkPeriod({
          ...newWorkPeriod,
          uploading: false,
        });
        toast.error(`${_t(t("Please try again"))}`, toastObj);
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Work Periods"))}</title>
      </Helmet>

      <div className="d-none">
      <div ref={productMixReport}>
        <div className="fk-print">
          <div className="container">
           <div className="row">
             <div className="col-12">
               <span className="mt-2 d-block xslg-text font-weight-bold text-uppercase text-center mb-2">
                 Product Mix Report
               </span>
          {/*product mix report */}
          <div className="col-12">
          {
            zProductMix &&
            zProductMix && (
              <div>
          <p className="mb-1 xsm-text table-text text-capitalize d-flex justify-content-between border border-dark py-1">
           <span className="font-weight-bold mx-2">
          {zProductMix &&
            zProductMix !== null &&
            "Item"}
            </span>

            <span className="font-weight-bold pr-1">
            {zProductMix &&
              zProductMix !== null &&
              "Qty"}
            </span>
          </p>
            </div>
            )}
         {
          zProductMix &&
          zProductMix !== null &&
            Object.keys(zProductMix)?.map((product)=>{
              let productQty = 0;
              return(
                <div>
                  <div className="w-100 col-12 mb-2">
                    <span className="mx-2 d-flex lg-text font-weight-bold justify-content-center text-decoration-underline text-capitalize lg-text">{product}</span>
                  </div>
                  {
                    Object.keys(zProductMix[product])?.map((group)=>{
                      productQty += zProductMix[product][group];
                      totalProductMix += zProductMix[product][group];
                     return(
                       <div className="mb-1 xsm-text print-text text-capitalize d-flex justify-content-between w-100">
                        <span className="mx-2 lg-text fk-print-text_pos--bold" >{group}</span>
                        <span className="mx-2 lg-text fk-print-text_pos--bold pr-1" style={{textAlign:"right"}}>{zProductMix[product][group]}</span>
                       </div>
                     )
                    })
                  }
                   <div className = "mb-1 xsm-text table-text fk-print-text--bold text-capitalize d-flex justify-content-between border border-2 p-1">
                      <span className="mx-2 ml-1 lg-text fk-print-text--bold" >Total</span>
                      <span className="float-right lg-text fk-print-text--bold" style={{textAlign:"right"}}>{productQty}</span>
                   </div>
                </div>  
              )
            })
           }
           {
            zProductMix !== null && zProductMix && (
              <div> 
                <div className = "mb-1 table-text fk-print-text--bold text-capitalize d-flex justify-content-between border border-2 p-1">
                  <span className="mx-2 ml-1" >Grand Total</span>
                  <span className="float-right" style={{textAlign:"right"}}>{totalProductMix}</span>
                </div>
              </div> 
            )
           }
            </div>
          </div> 
         </div> 
        </div> 
       </div> 
      </div> 
    </div> 

      <div className="d-none">
      <div ref={orderTypeReport}>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">
                    Order Type Wise Item Shift Report
                  </span>
          {/*ordertype catg wise report */}
          <div className="col-12">
          {
            zOrderTypeData &&
            zOrderTypeData && (
              <div>
          <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">Order Type Wise Item Details</span>
          <p className="mb-1 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark">
          <span className="font-weight-bold mx-2" style={{width:"18%"}}>
          {zOrderTypeData &&
            zOrderTypeData !== null &&
            "qty"}
            </span>
            
            <span className="font-weight-bold"  style={{width:"56%"}}>
            {zOrderTypeData &&
              zOrderTypeData !== null &&
              "item"}
            </span>

            <span className="font-weight-bold"style={{width:"25%",textAlign:"right"}}>
            {zOrderTypeData &&
              zOrderTypeData !== null &&
              "amount"}
            </span>
          </p>
            </div>
            )}
        {
          zOrderTypeData &&
          zOrderTypeData !== null &&
            Object.keys(zOrderTypeData)?.map((orderType)=>{
              let orderTypeQty = 0;
              let orderTypePrice = 0;
              return(
                <div>
                  <div className="w-100 col-12 ">
                   <span className="mx-2 d-flex fk-print-text_pos  font-weight-bold justify-content-center border-dark border-top border-bottom border-1 lg-text">{orderType}</span>
                  </div> 
                  {
                    Object.entries(zOrderTypeData[orderType])?.map((group)=>{
                     return(
                      <>
                      <div className="w-100 col-12">
                       <span className="mx-2 d-flex fk-print-text_pos font-weight-bold justify-content-center text-decoration-underline lg-text">{group[0]}</span>
                      </div>
                      {
                        group[1]?.map((item)=>{
                          orderTypeQty += parseInt(item.quantity);
                          orderTypePrice += parseFloat(item.price);
                          totalOrderTypeQty += parseInt(item.quantity);
                          totalOrderTypePrice += parseFloat(item.price);
                          return (
                            <div className="mb-0 xsm-text fk-print-text_pos  text-capitalize d-flex justify-content-between w-100">
                            <span className="mx-2"  style={{width:"18.2%"}}>{item.quantity}</span>
                            <span style={{width:"54.8%"}}>{item?.item_id}</span>
                            <span className="float-right mr-1" style={{width:"25%",textAlign:"right"}}>{(parseFloat(item.price))?.toFixed(2)}</span>
                            </div>
                          )
                        })
                       }
                      </>
                     )
                    })
                  }
                   <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-2">
                      <span className="mx-2 ml-1"  style={{width:"18%"}}>{orderTypeQty}</span>
                      <span style={{width:"55%"}}></span>
                      <span className="float-right" style={{width:"25%",textAlign:"right"}}>{orderTypePrice?.toFixed(2)}</span>
                   </div>
                </div>  
              )
            })
           }
           {
            zOrderTypeData !== null && zOrderTypeData && (
              <div> 
               <p className="mb-1 sm-text fk-print-text_pos fk-print-text--bold"> Grand Total </p>
                <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark">
                  <span className="mx-2 ml-1"  style={{width:"18%"}}>{totalOrderTypeQty}</span>
                  <span style={{width:"55%"}}></span>
                  <span className="float-right" style={{width:"25%",textAlign:"right"}}>{totalOrderTypePrice?.toFixed(2)}</span>
                </div>
              </div> 
            )
           }
            </div>
          </div> 
         </div> 
        </div> 
       </div> 
      </div> 
    </div> 

      <div className="d-none">
      <div ref={catgReport}>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">
                    Group Wise Item Shift Report
                  </span>
      {/*catg wise report */}
      <div className="col-12">
      {
        zCatgData &&
        zCatgData && (
          <div>
       <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">Group Wise Item Details</span>
       <p className="mb-1 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark">
       <span className="font-weight-bold mx-2" style={{width:"18%"}}>
       {zCatgData &&
        zCatgData !== null &&
        "qty"}
        </span>
        
        <span className="font-weight-bold"  style={{width:"56%"}}>
         {zCatgData &&
          zCatgData !== null &&
           "item"}
         </span>

         <span className="font-weight-bold"style={{width:"25%",textAlign:"right"}}>
         {zCatgData &&
          zCatgData !== null &&
          "amount"}
         </span>
       </p>
         </div>
        )}
        {
            zCatgData &&
            zCatgData !== null &&
            Object.keys(zCatgData)?.map((group)=>{
              let groupQty = 0;
              let groupPrice = 0;
              return(
                <div>
                  <div className="w-100 col-12">
                   <span className="mx-2 d-flex font-weight-bold fk-print-text_pos justify-content-center text-decoration-underline lg-text">{group}</span>
                  </div> 
                  {
                    zCatgData[group]?.map((item)=>{
                      groupQty += parseInt(item.quantity);
                      groupPrice += parseFloat(item.price);
                      totalGroupQty += parseInt(item.quantity);
                      totalGroupPrice += parseFloat(item.price);
                      return(
                      <div className="mb-0 xsm-text fk-print-text_pos  text-capitalize d-flex justify-content-between w-100">
                      <span className="mx-2"  style={{width:"18.2%"}}>{item.quantity}</span>
                      <span style={{width:"54.8%"}}>{item?.item_id}</span>
                      <span className="float-right mr-1" style={{width:"25%",textAlign:"right"}}>{(parseFloat(item.price))?.toFixed(2)}</span>
                      </div>
                      )
                    })
                  }
                   <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-2">
                      <span className="mx-2 ml-1"  style={{width:"18%"}}>{groupQty}</span>
                      <span style={{width:"55%"}}></span>
                      <span className="float-right" style={{width:"25%",textAlign:"right"}}>{groupPrice?.toFixed(2)}</span>
                   </div>
                </div>  
              )
            })
           }
           {
             zCatgData !== null && zCatgData && (
              <div> 
               <p className="mb-1 sm-text fk-print-text_pos fk-print-text--bold"> Grand Total </p>
                <div className = "mb-1 xsm-text fk-print-text_pos fk-print-text--bold text-capitalize d-flex justify-content-between border border-dark">
                  <span className="mx-2 ml-1"  style={{width:"18%"}}>{totalGroupQty}</span>
                  <span style={{width:"55%"}}></span>
                  <span className="float-right" style={{width:"25%",textAlign:"right"}}>{totalGroupPrice?.toFixed(2)}</span>
                </div>
              </div> 
            )
           }
            </div>
          </div> 
         </div> 
        </div> 
       </div> 
      </div> 
    </div> 

      {/* Print Cash Register Report Start */}
      <div className="d-none">
        <div ref={cashRegisterRef}>
          {cashRegisterData && (
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">
                      Cash Register Report
                    </span>
                    <span className="my-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">
                      {cashRegisterData &&
                        cashRegisterData.branch_name !== null &&
                        cashRegisterData.branch_name}
                    </span>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.report_date !== null &&
                          "Report Date"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.report_date !== null &&
                          cashRegisterData.report_date}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          "Shift Opened"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          cashRegisterData.workperiod_status.start}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          "Shift Close"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          cashRegisterData.workperiod_status.end}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.user !== null &&
                          "User"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.user !== null &&
                          cashRegisterData.user.name}
                      </span>
                    </p>
                    <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      Cash Drawer
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          "Starting Cash"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.workperiod_status !== null &&
                          cashRegisterData.workperiod_status.opening_cash}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Cash sales"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.net_sales}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Refund"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.total_refund}
                      </span>
                    </p>

                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Paid in"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.payments !== null &&
                          cashRegisterData?.payments.pay_in}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Paid out"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.payments !== null &&
                          cashRegisterData?.payments.pay_out}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Expected cash amount"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.expected_cash !== null &&
                          cashRegisterData?.expected_cash}
                      </span>
                    </p>

                    <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      Sales Summary
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Gross sales"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.gross_total}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Discount"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.discount}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          "Refund"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.sales_details !== null &&
                          cashRegisterData?.sales_details?.total_refund}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize"></p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {cashRegisterData &&
                          cashRegisterData.insights !== null &&
                          "Net sales"}
                      </span>
                      <span className="float-right">
                        {cashRegisterData &&
                          cashRegisterData.insights !== null &&
                          cashRegisterData?.insights?.total_sales}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Print Z Report Start */}
      <div className="d-none">
        <div ref={zReportRef}>
          {zReportData && (
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">
                      Shift Report
                    </span>
                    <span className="my-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">
                      {zReportData &&
                        zReportData.branch_name !== null &&
                        zReportData.branch_name}
                    </span>
                    <span className="my-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center md-text">
                      {zReportData &&
                        zReportData.day_status !== null &&
                        zReportData.day_status}
                    </span>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.report_date !== null &&
                          "Report Date"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.report_date !== null &&
                          zReportData.report_date}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize ">
                      {/* {zReportData &&
                        zReportData.branch_name !== null &&
                        "Terminal: " + zReportData.branch_name} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.branch_name !== null &&
                          "Terminal"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.branch_name !== null &&
                          zReportData.branch_name}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.workperiod_status !== null &&
                        "Open Date: " + zReportData.workperiod_status.start} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.workperiod_status !== null &&
                          "Open Date"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.workperiod_status !== null &&
                          zReportData.workperiod_status.start}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.workperiod_status !== null &&
                        "Close Date: " + zReportData.workperiod_status.end} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.workperiod_status !== null &&
                          "Close Date"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.workperiod_status !== null &&
                          zReportData.workperiod_status.end}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.user !== null &&
                        "User: " + zReportData.user.name} */}
                      <span className="font-weight-bold">
                        {zReportData && zReportData.user !== null && "User"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.user !== null &&
                          zReportData.user.name}
                      </span>
                    </p>

                    {/* <ul>
                      USER
                      { zReportData &&
                         user
                      }
                    </ul> */}
                    <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      SALES DETAILS
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Gross Total: " +
                          zReportData?.sales_details?.gross_total} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Sales Variance"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.sale_variance}
                      </span>
                    </p>

                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Gross Total: " +
                          zReportData?.sales_details?.gross_total} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Gross Sales"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.gross_total}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Free Of Cost"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.total_foc}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Discount: " + zReportData?.sales_details?.discount} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Discount"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.discount}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.cancelled !== null &&
                        "Discount: " + zReportData?.cancelled?.cancelled} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Cancelled"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.cancelled}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.cancelled !== null &&
                        "Discount: " + zReportData?.cancelled?.cancelled} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Refund"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.total_refund}
                      </span>
                    </p>

                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Net Sales: " + zReportData?.sales_details?.net_sales} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Net Sales"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.net_sales}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Tax Amount: " + zReportData?.sales_details?.tax_amount} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Tax Amount"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.tax_amount}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Taxable Total: " +
                          zReportData?.sales_details?.taxable_total} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Net Sales Including Tax"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.taxable_total}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Service Charge: " +
                          zReportData?.sales_details?.service_charge} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Delivery Charges"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.service_charge}
                      </span>
                    </p>

                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Service Charge: " +
                          zReportData?.sales_details?.service_charge} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Service Charges"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.service_amount}
                      </span>
                    </p>

                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Department Commission: " +
                          zReportData?.sales_details?.dept_commission} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Department Commission"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.dept_commission}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.sales_details !== null &&
                        "Total Collected: " +
                          zReportData?.sales_details?.total_collected} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Total Collected"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.total_collected}
                      </span>
                    </p>

                    <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      INSIGHT
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.insights !== null &&
                        "Number of Orders: " +
                          zReportData?.insights?.number_of_orders} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.insights !== null &&
                          "Number of Orders"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.insights !== null &&
                          zReportData?.insights?.number_of_orders}
                      </span>
                    </p>
                    <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
                      {/* {zReportData &&
                        zReportData.insights !== null &&
                        "Number of Customers: " +
                          zReportData?.insights?.number_of_customers} */}
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.insights !== null &&
                          "Number of Customers"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.insights !== null &&
                          zReportData?.insights?.number_of_customers}
                      </span>
                    </p>
                    {/* <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      CATEGORY WISE SALES
                    </p>
                    {zReportData && categoryWiseSales} */}

                    <div className="myBorder my-2"></div>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      PAYMENT WISE SALES
                    </p>
                    {zReportData && departPaymentSales}
                    <p className="mb-1 xsm-text fk-print-text_pos text-capitalize border border-dark">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Total"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.payment_type_total}
                      </span>
                    </p>

                    {/* <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      Total
                    </p>
                    {zReportData && departWiseSales} */}
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      ORDER TYPE SALES
                    </p>
                    {zReportData && orderTypesSales}
                    <p className="mb-1 xsm-text fk-print-text_pos text-capitalize border border-dark">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Total"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.order_type_total}
                      </span>
                    </p>
                    <p className="mb-2 sm-text fk-print-text_pos text-capitalize lg-text">
                      PARTY WISE SALES
                    </p>
                    {zReportData && partyWiseSales}
                    <p className="mb-1 xsm-text fk-print-text_pos text-capitalize border border-dark">
                      <span className="font-weight-bold">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          "Total"}
                      </span>
                      <span className="float-right">
                        {zReportData &&
                          zReportData.sales_details !== null &&
                          zReportData?.sales_details?.party_total}
                      </span>
                    </p>
                  </div>
                  <div className="col-12">
                  {
                    zReportData &&
                    zReportData?.order_details && (
                      <>
                   <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center lg-text">Sold Item Details</span>
                   <p className="mb-1 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark">
                    <span className="font-weight-bold"  style={{width:"60%"}}>
                     {zReportData &&
                       zReportData?.order_details !== null &&
                       "item"}
                     </span>

                     <span className="font-weight-bold mx-2" style={{width:"14%"}}>
                     {zReportData &&
                       zReportData?.order_details !== null &&
                       "qty"}
                     </span>

                     <span className="font-weight-bold"style={{width:"25%",textAlign:"right"}}>
                     {zReportData &&
                      zReportData?.order_details !== null &&
                      "amount"}
                     </span>
                   </p>
                   
                   {
                   zReportData &&
                   zReportData.order_details !== null &&
                   zReportData.order_details && zReportData.order_details?.map((food)=>{
                    totalItemPrice += parseFloat(food.price);
                    totalItemQty += parseInt(food.quantity);
                     return(
                        <p className="mb-0 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100">
                         <span style={{width:"60%"}}>{food.food_item}{(food.variation && food.variation !== "") && ` - ${food.variation}`}</span>
                         <span className="mx-2"  style={{width:"13%"}}>{food.quantity}</span>
                         <span className="float-right" style={{width:"25%",textAlign:"right"}}>{(parseFloat(food.price))?.toFixed(2)}</span>
                         </p>
                         )
                        })
                      }
                      <p className="mb-0 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark">
                      <span  style={{width:"60%"}}>Total</span>
                      <span className="mx-2"  style={{width:"13%"}}>{totalItemQty}</span>
                      <span className="float-right" style={{width:"25%",textAlign:"right"}}>{totalItemPrice?.toFixed(2)}</span>
                      </p>
                      </>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Print Z Report End */}
      <div className="modal fade" id="addOpeningCash" aria-hidden="true">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content"></div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form onSubmit={handleSaveNewWorkPeriod}>
                  <div>
                    <label htmlFor="name" className="form-label">
                      {_t(t("Opening Cash"))}{" "}
                      <small className="text-danger">*</small>
                    </label>
                    <input
                      ref={cashInput}
                      type="number"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="e.g. Opening Cash"
                      value={newWorkPeriod?.opening_cash}
                      required
                      onChange={(e) => {
                        handleSetOpeningCash(e);
                      }}
                    />
                  </div>

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          onClick={handleSaveNewWorkPeriod}
                          data-dismiss="modal"
                        >
                          {_t(t("Add"))}
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {newWorkPeriod.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {!searchedWorkPeriod.searched
                              ? _t(t("Work periods"))
                              : _t(t("Search Result"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-5">
                      <div className="input-group">
                        <button className="btn btn-primary rounded-left" type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="form-file">
                          <input
                            type="text"
                            className="form-control border-0 form-control--light-1 rounded-right"
                            placeholder={
                              _t(t("Search by branch, date, user")) + ".."
                            }
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-7 t-mb-15 mb-md-0 ">
                      <div className="row gx-2 align-items-center">
                        {authUserInfo.details !== null &&
                        authUserInfo.details.user_type !== "staff" ? (
                          <>
                            <div className="col-md-6 t-mb-15 mb-md-0 mt-3 mt-md-0">
                              <Select
                                options={branchForSearch && branchForSearch}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                classNamePrefix="select"
                                className="xsm-text"
                                onChange={handleSetBranchId}
                                maxMenuHeight="200px"
                                placeholder={
                                  _t(t("Please select a branch")) + ".."
                                }
                              />
                            </div>
                            <div className="col-md-3">
                              <ul className="t-list fk-sort align-items-center justify-content-center">
                                <li className="fk-sort__list mb-0 flex-grow-1">
                                  <button
                                    onClick={handleBranchCheckOnModal}
                                    data-toggle="modal"
                                    data-target={`${
                                      newWorkPeriod.branch_id
                                        ? "#addOpeningCash"
                                        : ""
                                    }`}
                                    className="w-100 btn btn-secondary sm-text text-uppercase rounded"
                                  >
                                    {_t(t("start work period"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-3">
                              <ul className="t-list fk-sort align-items-center justify-content-center">
                                <li className="fk-sort__list mb-0 flex-grow-1">
                                  <button
                                    onClick={handleDayEndWorkPeriod}
                                    className="w-100 btn btn-primary sm-text text-uppercase rounded"
                                  >
                                    {_t(t("Day End"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-12 col-md-5 ml-auto mt-2 mt-md-0">
                              <ul className="t-list fk-sort align-items-center justify-content-center">
                                <li className="fk-sort__list mb-0 flex-grow-1">
                                  <button
                                    onClick={handleBranchCheckOnModal}
                                    data-toggle="modal"
                                    data-target={`${
                                      newWorkPeriod.branch_id
                                        ? "#addOpeningCash"
                                        : ""
                                    }`}
                                    className="w-100 btn btn-secondary sm-text text-uppercase"
                                  >
                                    {_t(t("start work period"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-3">
                              <ul className="t-list fk-sort align-items-center justify-content-center">
                                <li className="fk-sort__list mb-0 flex-grow-1">
                                  <button
                                    onClick={handleDayEndWorkPeriod}
                                    className="w-100 btn btn-primary sm-text text-uppercase"
                                  >
                                    {_t(t("Day End"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Started by"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Date"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Started at"))}
                              </th>
                              {showManageStock && parseInt(getSystemSettings(generalSettings, "physical_stock"))
                                ? (
                                <th
                                  scope="col"
                                  className="sm-text align-middle text-center border-1 border"
                                >
                                  {_t(t("Stock"))}
                                </th>
                              ) : null}
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Ended at"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Ended by"))}
                              </th>
                              <th
                              scope="col"
                              className="sm-text align-middle text-center border-1 border"
                              style= {{minWidth:"15rem"}}
                              >
                              {_t(t("Sales Report"))}
                              </th>

                              {
                                /*
                                <th
                                  scope="col"
                                  className="sm-text align-middle text-center border-1 border"
                                >
                                  {_t(t("Cash Register"))}
                                </th>
                                */
                              }

                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Total time"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {!searchedWorkPeriod.searched
                              ? [
                                  workPeriodList && [
                                    workPeriodList.data?.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="8"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      workPeriodList.data?.map((item, index) => {
                                        const isFormat = moment(item.date, 'DD-MM-YYYY').isValid();
                                          let formattedDate = item.date;
                                          if(isFormat){
                                             formattedDate = moment(item.date, 'DD-MM-YYYY').format('dddd, DD MMM, YYYY');
                                          }
                                         
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (workPeriodList.current_page -
                                                  1) *
                                                  workPeriodList.per_page}
                                            </th>

                                            <td className="xsm-text align-middle text-center text-secondary">
                                              {item.branch_name}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.started_by}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                               {formattedDate}
                                            </td>

                                            <td className="xsm-text align-middle text-center text-green">
                                              <Moment format="LT">
                                                {new Date(item.created_at)}
                                              </Moment>
                                            </td>
                                            {/* show if manage stock is enable */}
                                            {showManageStock && parseInt(getSystemSettings(generalSettings, "physical_stock"))
                                               ? (
                                                item.is_stock ?
                                              <td className="xsm-text align-middle text-center">
                                                <button
                                                  className={`btn xsm-text btn-danger btn-sm p-1 px-2 rounded`}
                                                  onClick={()=> {
                                                    setStockData({
                                                      branch_id: parseInt(item.branch_id),
                                                      branch_name : item.branch_name,
                                                      workperiod_id: item.id
                                                    })
                                                    setStockModal(true)}}
                                                >
                                                  {_t(t("Closing Stock"))}
                                                </button>
                                              </td>
                                                 : 
                                                 <td className="xsm-text align-middle text-center"> - </td>
                                                ) : null}

                                            <td className="xsm-text align-middle text-center text-primary">
                                              {item.ended_at ? (
                                                <Moment format="LT">
                                                  {new Date(item.updated_at)}
                                                </Moment>
                                              ) : (
                                                "-"
                                              )}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.ended_at ? (
                                                item.ended_by
                                              ) : (
                                                <button
                                                  className="btn btn-primary btn-sm py-0 px-4 rounded"
                                                  onClick={() => {
                                                    handleDeleteConfirmation(
                                                      item.id
                                                    );
                                                  }}
                                                >
                                                  {_t(t("End"))}
                                                </button>
                                              )}
                                            </td>

                                            <td className="sm-text align-middle text-center">
                                            <div className="d-flex align-items-center justify-content-center">
                                              <Select
                                                menuPosition="fixed"
                                                options={printType && printType}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                value={print && print[item.id]}
                                                onChange={(print_type) => handleSetPrintType(print_type,item.id)}
                                                className="sm-text"
                                                placeholder="Report"
                                                styles={customStyle}
                                              />
                                              <button
                                              className="btn btn-primary btn-sm py-1 px-2 ml-2 rounded"
                                                onClick={() => {
                                                  handlePrintReport(item.id)
                                                 // handleZReport(item.id,"shift-report/");
                                                }}
                                              >
                                                {_t(t("Print"))}
                                              </button>
                                               {
                                                /*
                                              
                                                <button
                                                  className="btn btn-primary btn-sm py-0 px-2 mr-2 rounded"
                                                  onClick={() => {
                                                    handleOrderTypeReport(item.id,"shift-report-ot/");
                                                  }}
                                                >
                                                  {_t(t("Od.T"))}
                                                </button>
                                                <button
                                                className="btn btn-secondary btn-sm py-0 px-2 rounded"
                                                  onClick={() => {
                                                    handleCatgoryReport(item.id,"shift-report-cw/");
                                                  }}
                                                >
                                                  {_t(t("Catg."))}
                                                </button>
                                                */}
                                              </div>
                                            </td>
                                       
                                            {
                                              /*
                                              <td className="sm-text align-middle text-center">
                                                <button
                                                  className="btn btn-success btn-sm py-0 px-4 rounded"
                                                  onClick={() => handleViewReprot(item.id)}
                                                >
                                                  {_t(t("View"))}
                                                </button>
                                              </td>
                                              */
                                            }

                                            <td className="xsm-text align-middle text-center" onClick={()=>{
                                              if(getSystemSettings(generalSettings, "call_api") === "yes"){
                                                handleWorkperiodEmail(item.id)
                                              }
                                            }}>
                                              {item.ended_at
                                                ? millisToMinutesAndHours(
                                                    parseInt(
                                                      item.ended_at -
                                                        item.started_at
                                                    )
                                                  )
                                                : "-"}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ),
                                  ],
                                ]
                              : [
                                  /* searched data, logic === haveData*/
                                  searchedWorkPeriod && [
                                    searchedWorkPeriod.list?.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="9"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searchedWorkPeriod.list?.map(
                                        (item, index) => {
                                          const isFormat = moment(item.date, 'DD-MM-YYYY').isValid();
                                          let formattedDate = item.date;
                                          if(isFormat){
                                             formattedDate = moment(item.date, 'DD-MM-YYYY').format('dddd, DD MMM, YYYY');
                                          }
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (workPeriodList.current_page -
                                                    1) *
                                                    workPeriodList.per_page}
                                              </th>

                                              <td className="xsm-text align-middle text-center text-secondary">
                                                {item.branch_name}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.started_by}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {formattedDate}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                <Moment format="LT">
                                                  {new Date(item.created_at)}
                                                </Moment>
                                              </td>
                                              
                                              {showManageStock &&parseInt(getSystemSettings(generalSettings, "physical_stock"))
                                                ? (
                                                item.is_stock ?
                                             <td className="xsm-text align-middle text-center">
                                              <button
                                              className={`btn xsm-text btn-danger btn-sm p-1 px-2 rounded`}
                                              onClick={()=> {
                                                setStockData({
                                                  branch_id: parseInt(item.branch_id),
                                                  branch_name : item.branch_name,
                                                  workperiod_id: item.id
                                                })
                                                setStockModal(true)}}
                                              >
                                                {_t(t("Closing Stock"))}
                                              </button>
                                            </td>
                                             : 
                                             <td className="xsm-text align-middle text-center"> - </td>
                                            ) : null }
                                            <td className="xsm-text align-middle text-center">

                                                {item.ended_at ? (
                                                  <Moment format="LT">
                                                    {new Date(item.updated_at)}
                                                  </Moment>
                                                ) : (
                                                  "-"
                                                )}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.ended_at ? (
                                                  item.ended_by
                                                ) : (
                                                  <button
                                                    className="btn btn-primary btn-sm py-0 px-4 rounded"
                                                    onClick={() => {
                                                      handleDeleteConfirmation(
                                                        item.id
                                                      );
                                                    }}
                                                  >
                                                    {_t(t("End"))}
                                                  </button>
                                                )}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                              <div className="d-flex align-items-center justify-content-center">
                                              <Select
                                                menuPosition="fixed"
                                                options={printType && printType}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                value={print && print[item.id]}
                                                onChange={(print_type) => handleSetPrintType(print_type,item.id)}
                                                className="sm-text"
                                                placeholder="Report"
                                                styles={customStyle}
                                              />
                                              <button
                                              className="btn btn-primary btn-sm py-1 px-2 ml-2 rounded"
                                                onClick={() => {
                                                  handlePrintReport(item.id)
                                                  //handleZReport(item.id,"shift-report/");
                                                }}
                                              >
                                                {_t(t("Print"))}
                                              </button>

                                               {
                                                /*
                                                <button
                                                  className="btn btn-primary btn-sm py-0 px-2 mr-2 rounded"
                                                  onClick={() => {
                                                    handleOrderTypeReport(item.id,"shift-report-ot/");
                                                  }}
                                                >
                                                  {_t(t("Od.T"))}
                                                </button>
                                                <button
                                                className="btn btn-secondary btn-sm py-0 px-2 rounded"
                                                  onClick={() => {
                                                    handleCatgoryReport(item.id,"shift-report-cw/");
                                                  }}
                                                >
                                                  {_t(t("Catg."))}
                                                </button>
                                                */
                                               }
                                              </div>
                                              </td>

                                              {
                                                /*
                                                <td className="sm-text align-middle text-center">
                                                  <button
                                                    className="btn btn-success btn-sm py-0 px-4 rounded"
                                                    onClick={() => handleViewReprot(item.id)}
                                                  >
                                                    {_t(t("View"))}
                                                  </button>
                                                </td>
                                                */
                                              }


                                              <td className="xsm-text align-middle text-center" onClick={()=>{
                                                if(getSystemSettings(generalSettings, "call_api") === "yes"){
                                                  handleWorkperiodEmail(item.id)
                                                }
                                               }}>
                                                {item.ended_at
                                                  ? millisToMinutesAndHours(
                                                      parseInt(
                                                        item.ended_at -
                                                          item.started_at
                                                      )
                                                    )
                                                  : "-"}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* pagination loading effect */}
              {newWorkPeriod.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedWorkPeriod.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(
                                workPeriodList,
                                setPaginatedWorkPeriod
                              )}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(workPeriodList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedWorkPeriod({
                                      ...searchedWorkPeriod,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedWorkPeriod,
                                    workPeriodForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
          </div>
        </div>
      </main>
      {/*User list Modal*/}
      <div
        className={`modal fade ${
          tillModal !== false ? "showing" : ""
        }`}
        id="customerAddress"
        aria-hidden="true"
        style={{
          zIndex:14999
        }}
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header align-items-center py-2">
              <div className="fk-sm-card__content" style={{fontSize:"1.2rem",fontWeight:"600"}}>
              User List 
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setTillModal(false);
                  setTillUser({
                    id:null,
                    user_name:null,
                    workperiod_id: null,
                   });
                  toast.error(
                    `${_t(
                      t("All submitted orders need to be settled to end workperiod")
                    )}`,
                    toastObj
                  );
                }}
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form onSubmit={(e) =>{
                  e.preventDefault();
                  handleOrderTranfer()
                }}>
                <div className="mb-1 px-1">
                <span style={{fontSize:"0.88rem",fontWeight:"400"}}>Please cash out all submitted order or transfer all sumbited orders.</span>
                </div>
                  <div className="modal-scroll">
                    {
                      users && users?.map((user,index)=>{
                        return(
                          <label key={index} className="d-flex align-items-center add-container cursor-pointer">
                          <input
                          type="radio"
                          id={user.id}
                          name="user"
                          value={user.name}
                          checked={user.id === tillUser.id}
                          // defaultChecked={index === 0}
                          onChange={changeUser}
                          />
                          <span className="ml-2 d-flex align-items-center">
                          {user.name}
                          </span>
                          </label>
                        )
                      })
                    }
                  </div>

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="submit"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"                         
                        >
                          Transfer Order
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                          onClick={(e) => {
                            setTillModal(false);
                            setTillUser({
                              id:null,
                              user_name:null,
                              workperiod_id: null,
                             });
                            toast.error(
                              `${_t(
                                t("All submitted orders need to be settled to end workperiod")
                              )}`,
                              toastObj
                            );
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
      className={`modal fade ${
        stockModal !== false ? "showing" : ""
      }`}
      id="stock"
      aria-hidden="true"
      style={{
        zIndex:14999
      }}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header align-items-center py-2">
            <div className="fk-sm-card__content" style={{fontSize:"1.2rem",fontWeight:"600"}}>
             Physical Stock
            </div>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                setStockModal(false);
                setStockDayEnd([]);
                setStockData({})
              }}
            ></button>
          </div>
          <div className="modal-body">
            {/* show form or show saving loading */}
            <div key="fragment-food-group-1">
              <form onSubmit={(e) =>{
                e.preventDefault();
                handleStockSubmit()
              }}>
              <div className="mb-1">
              <span style={{fontSize:"0.88rem",fontWeight:"400"}}>Stock Items.</span>
              </div>
              <div style={{fontWeight:"500"}}  className="lg-text d-flex justify-content-between align-items-center my-2">
                <div className="mr-3" style={{flex:2}}>
                  Item Name
                </div >
                <div className="mr-3 pl-1" style={{flex:1}}>
                  Qty
                </div >
                <div className="mr-3" style={{flex:1}}>
                  Unit
                </div>
              </div>
                <div className="modal-scroll">
                  {
                    ingredientItemForSearch && ingredientItemForSearch?.map((item,index)=>{
                      return(
                        <div key={index} className="d-flex justify-content-between align-items-center my-1">
                         <div className="mr-3" style={{flex:2}}>
                         <label>
                            {item.name}
                         </label>
                         </div>
                         <div className="mr-3 " style={{flex:1}}>
                          <input
                           type="number"
                           onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                           name="qty"
                           className="form-control"
                           value={stockDayEnd[item?.id]?.qty || ""}
                           onChange={(e)=>handleStock(e.target.value,item)}
                           placeholder="qty"
                          />
                          </div >
                          <div className="mr-3" style={{flex:1}}>
                            {item.unit}
                          </div>
                         </div>
                      )
                    })
                  }
                </div>

                <div className="mt-4">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                        data-dismiss="modal"                         
                      >
                        Stock Submit
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="button"
                        className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                        data-dismiss="modal"
                        onClick={(e) => {
                          setStockModal(false);
                          setStockDayEnd([]);
                          setStockData({})
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default WorkPeriod;
