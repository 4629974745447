import React, { useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "./BaseUrl";

//routes
import RestaurantRoute from "./routes/RestaurantRoute";
import CustomerRoute from "./routes/CustomerRoute";

//functions
import {
  // consolee,
  checkPermission,
  getSystemSettings,
} from "./functions/Functions";

//3rd party packages
import { ToastContainer } from "react-toastify";

//pages & includes
import {
  //installation
  Welcome,
  InstallPermission,
  DbSetup,
  ImportDb,
  InstallationUser,
  InstallationCongratulation,
  //landing
  RestaurantLanding,
  CustomerProfile,
  MyOrders,

  //common
  Refresh,
  Login,
  SignUp,
  BeDeliveryMan,
  NotFound,
  ForgetPw,
  SetNewPw,
  NoPermission,

  //dashboard
  RestaurantHome,
  UpdateProfile,
  WorkPeriod,
  OpeningClosingStock,

  //delivery
  AssignedOrders,
  DeliveredOrders,

  //pos
  Pos,
  Submitted,
  FoodPanda,
  Settled,
  OnlineOrders,
  EditSubmittedOrder,
  //customers
  Customers,
  OnlineCustomerList,
  //
  OnlineOrderHistories,
  OrderHistories,
  Kitchen,
  KitchenOnline,
  //Reports
  Dashboard,
  ConsolitedData,
  ConsolitedReport,
  Daily,
  Monthly,
  // Yearly,
  ItemWise,
  GroupWise,
  BranchWise,
  UserWise,
  DeptWise,
  ServiceChargeWise,
  DiscountWise,
  StockDashboard,
  FoodStockReport,
  IngredientStockReport,
  OpeningClosingStockIngredientReport,
  OpeningClosingStockFoodReport,
  WorkPeriodReport,
  SalesReport,
  SalesSummaryReport,
  SourceWiseReport,
  CustomerOrderHistoryReport,
  FoodPandaReport,
  CancelOrderReport,
  CancelItemReport,
  MeezanReport,
  HourlySalesReport,
  IncomeStatementReport,
  DailyCashSalesReport,
  ExpenseReport,
  //manage->food
  GroupCrud,
  // UnitCrud,
  VariationCrud,
  PropertyCrud,
  PropertyItemCrud,
  FoodItemCrud,
  AllItemList,
  //manage->website
  HeroSection,
  Promotions,
  //manage->settings
  Currency,
  Lang,
  Translation,
  Smtp,
  PosScreen,
  General,
  UpdateSystem,

  //manage->stock
  CreateRecipe,
  ViewRecipe,
  RecipeEdit,
  FoodPurchase,
  FoodPurchaseEdit,
  FoodPurchaseHistory,
  FoodReturn,
  IngredientGroup,
  IngredientUnit,
  IngredientItem,
  IngredientPurchase,
  IngredientPurchaseEdit,
  IngredientPurchaseHistory,
  IngredientUsage,
  IngredientReturn,
  ManageSupplier,
  SupplierHistory,
  StockOutFood,
  StockOutIngredient,

  //manage->user
  AdminStaffCrud,
  DeliveryMen,
  NewDeliveryMen,
  Waiter,
  Permissions,
  CustomerCrud,

  //manage->restaurantDetails
  BranchCrud,
  OrderTypeCrud,
  TableFloor,
  TableCrud,
  MenuTagCrud,
  PaymentTypeCrud,
  KotCrud,
  Expense,
} from "./imports/Pages";
import { Navbar, Footer } from "./imports/Components";
import ExpenseList from "./resources/restaurant/auth/expense/ExpenseList";

//context consumer
import { SettingsContext } from "./contexts/Settings";
import { UserContext } from "./contexts/User";
import Showdeliveryman from "./resources/restaurant/auth/manage/settings/ShowDeliveryman";
import UpdateSettings from "./resources/restaurant/auth/manage/settings/UpdateSettings";
import Party from "./resources/restaurant/auth/manage/restaurantDetails/PartyCrud";
import PartyHistory from "./resources/restaurant/auth/manage/restaurantDetails/PartyHistory";
import SchemesCrud from "./resources/restaurant/auth/manage/food/SchemesCrud";
import CardsCrud from "./resources/restaurant/auth/manage/food/CardsCrud";
import AddonRestrictionsCrud from "./resources/restaurant/auth/manage/food/AddonRestrictionsCrud";
import StockTransfer from "./resources/restaurant/auth/manage/stock/StockTransfer";
import StockLedgerReport from "./resources/restaurant/auth/reports/StockLedgerReport";
import StockConsumption from "./resources/restaurant/auth/reports/StockConsumption";
import EmailTemplate from './resources/restaurant/auth/manage/settings/Email';
import TopBarOffline from "./TopBarOffline";
import CallCenterReport from "./resources/restaurant/auth/reports/CallCenterReport";

function App() {
  //getting context values here
  const { loading, setLoading, generalSettings } = useContext(SettingsContext);
  let { authUserInfo } = useContext(UserContext);

  //state hooks here
  const [credentials, setCredentials] = useState({
    install_no: false,
  });

  // localStorage helper
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  };

  // localStorage helper
  const getDataFromLocal = (url) => {
    return JSON.parse(localStorage.getItem(url));
  };

  //check if offline helper
  const offline = (url) => {
    return !navigator.onLine && localStorage.getItem(url) != null;
  };

  const setCredential = (res) => {
    if (res.data === "NO") {
      setCredentials({
        ...credentials,
        install_no: true,
      });
    }
  };

  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Set the initial online status based on the window.navigator.onLine property.
      setIsOnline(window.navigator.onLine);

      // Add online/offline event listeners.
      const onlineHandler = () => {
        setIsOnline(true);
      };

      const offlineHandler = () => {
        setIsOnline(false);
      };

      window.addEventListener("online", onlineHandler);
      window.addEventListener("offline", offlineHandler);

      return () => {
        // Remove event listeners when the component unmounts.
        window.removeEventListener("online", onlineHandler);
        window.removeEventListener("offline", offlineHandler);
      };
    }
  }, []);

  //useEffect == componentDidMount()
  useEffect(() => {
    // (async () => {
    //   setLoading(false);
    //   const url = BASE_URL + "/check-install";
    //   return axios
    //     .get(url)
    //     .then((res) => {
    //       setDataToLocal(url, res);
    //       setCredential(res);
    //     })
    //     .catch((error) => {
    //       if (offline(url)) {
    //         let parseRes = getDataFromLocal(url);
    //         setCredential(parseRes);
    //       }
    //     });
    // })();


    if (generalSettings) {
      const favicon = document.getElementById("favicon");
      favicon.href = BASE_URL + getSystemSettings(generalSettings, "favicon");
    }
    // consolee();
  }, [authUserInfo]);
  return (
    <>
      <ToastContainer />
      <TopBarOffline online={isOnline}/>
      {/* <Router basename="/khalaf"> */}
      {/* "homepage": "http://localhost/khadyo" */}
      
      <Router>
        <Navbar />
        <Switch>
          {/* installation */}
          {credentials.install_no && (
            <Route path="/installation" exact>
              <Welcome />
            </Route>
          )}

          {credentials.install_no && (
            <Route path="/installation/permission-chcek" exact>
              <InstallPermission />
            </Route>
          )}

          {credentials.install_no && (
            <Route path="/installation/database-setup" exact>
              <DbSetup />
            </Route>
          )}

          {credentials.install_no && (
            <Route path="/installation/import-database" exact>
              <ImportDb />
            </Route>
          )}

          {credentials.install_no && (
            <Route path="/installation/add-admin-user" exact>
              <InstallationUser />
            </Route>
          )}

          {credentials.install_no && (
            <Route path="/installation/congratulation" exact>
              <InstallationCongratulation />
            </Route>
          )}

          {/* common */}
          <Route path="/refresh" exact>
            <Refresh />
          </Route>

          <Route path="/login" exact>
            <Login />
          </Route>

          <Route path="/sign-up" exact>
            <SignUp />
          </Route>

          <Route path="/delivery-man-registration" exact>
            <BeDeliveryMan />
          </Route>

          <Route path="/reset-password" exact>
            <ForgetPw />
          </Route>

          <Route path="/set-new-password/:token" exact>
            <SetNewPw />
          </Route>
          <Route path="/" exact>
            <Login />
          </Route>
          {/* {credentials.install_no ? (
            
          ) : (
            <Route path="/" exact>
              <RestaurantLanding />
            </Route>
          )} */}

          {/* Customer routes */}
          <CustomerRoute path="/profile" exact>
            <CustomerProfile />
          </CustomerRoute>
          <CustomerRoute path="/my-orders" exact>
            <MyOrders />
          </CustomerRoute>

          {/* restaurant dashboard pages */}
          <RestaurantRoute path="/dashboard" exact>
            <RestaurantHome />
          </RestaurantRoute>

          <RestaurantRoute path="/update-user-profile" exact>
            <UpdateProfile />
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/work-periods" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Work period") ? (
              <WorkPeriod />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/closing-stock/:started_at" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Work period") ? (
              <OpeningClosingStock />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/pos/edit" exact>
            {console.log(authUserInfo.details.user_type)}
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "POS") ? (
              <Pos />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* <RestaurantRoute path="/dashboard/epos/{:editId}" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "POS") ? (
              <EditSubmittedOrder />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute> */}

          {/* jtd */}
          {/* <RestaurantRoute path="/dashboard/edit-submit-order/:editId" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "POS") ? (
              <EditSubmittedOrder />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute> */}

          <RestaurantRoute path="/dashboard/pos/submitted" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "POS") ? (
              <Submitted />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/pos/foodpanda" exact>
          {authUserInfo.permissions !== null &&
          checkPermission(authUserInfo.permissions, "POS") ? (
            <FoodPanda />
          ) : (
            <NoPermission />
          )}
        </RestaurantRoute>

          <RestaurantRoute path="/dashboard/pos/settled" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "POS") ? (
              <Settled />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/pos/online-orders" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "POS") ? (
              <OnlineOrders />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/orders" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Order history") ? (
              <OrderHistories />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/online-orders" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Order history") ? (
              <OnlineOrderHistories />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/customers" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Customer") ? (
              <Customers />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/online-customers" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Customer") ? (
              <OnlineCustomerList />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/kitchen" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Kitchen") ? (
              <Kitchen />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/kitchen/online" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Kitchen") ? (
              <KitchenOnline />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Dashboard />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/consolited-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(
              authUserInfo.permissions,
              "Report"
            ) ? (
              <ConsolitedData />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/consolidate-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(
              authUserInfo.permissions,
              "Report"
            ) ? (
              <ConsolitedReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/daily-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Daily />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/monthly-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <Monthly />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* <RestaurantRoute path="/dashboard/yearly-reports" exact>
       {authUserInfo.permissions !== null &&
       checkPermission(authUserInfo.permissions, "Report") ? (
         <Yearly />
       ) : (
         <NoPermission />
       )}
     </RestaurantRoute> */}

          <RestaurantRoute path="/dashboard/food-items-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <ItemWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/food-group-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <GroupWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/branch-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <BranchWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/work-period-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <WorkPeriodReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/sales-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <SalesReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/sales-summary-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <SalesSummaryReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/call-center-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <CallCenterReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/source-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <SourceWiseReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/customer-order-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <CustomerOrderHistoryReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/food-panda-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <FoodPandaReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/expense-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <ExpenseReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/meezan-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <MeezanReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/cancel-order-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <CancelOrderReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute path="/dashboard/cancel-made-unmade-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <CancelItemReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/hourly-sales-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <HourlySalesReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/income-statement-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <IncomeStatementReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/daily-cash-sales-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <DailyCashSalesReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/pos-user-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <UserWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/dept-tag-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <DeptWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/service-charge-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <ServiceChargeWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/discount-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <DiscountWise />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/stock-ledger-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <StockLedgerReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          
          <RestaurantRoute path="/dashboard/stock-consumption-reports" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <StockConsumption />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/stock" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <StockDashboard />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/food-stock" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <FoodStockReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/ingredient-stock" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <IngredientStockReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/food-stock/:started_at" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <OpeningClosingStockFoodReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/ingredient-stock/:started_at" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Report") ? (
              <OpeningClosingStockIngredientReport />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* Manage routes */}
          {/* food */}
          <RestaurantRoute path="/dashboard/manage/food/groups" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <GroupCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* <RestaurantRoute path="/dashboard/manage/food/units" exact>
       {authUserInfo.permissions !== null &&
       checkPermission(authUserInfo.permissions, "Manage") ? (
         <UnitCrud />
       ) : (
         <NoPermission />
       )}
     </RestaurantRoute> */}

          <RestaurantRoute path="/dashboard/manage/food/variations" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <VariationCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/properties" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <PropertyCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/properties-item" exact>
            {/* <RestaurantRoute path="/dashboard/manage/food/properties/:slug" exact> */}
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <PropertyItemCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/add-new" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <FoodItemCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/addon-restriction" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AddonRestrictionsCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/all-items" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AllItemList />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* Stock */}
          <RestaurantRoute path="/dashboard/manage/stock/food-purchase" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <FoodPurchase />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/stock/view-recipe" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <ViewRecipe />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/stock/create-recipe" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <ViewRecipe />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/purchase-history-food"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <FoodPurchaseHistory />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/purchase-history-food-edit/:id"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <FoodPurchaseEdit />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/stock/food-return" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <FoodReturn />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/ingredient-group"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <IngredientGroup />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/ingredient-unit"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <IngredientUnit />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/stock/ingredient-item" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <IngredientItem />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/ingredient-purchase"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <IngredientPurchase />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/purchase-history-ingredient"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <IngredientPurchaseHistory />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/purchase-history-ingredient-edit/:id"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <IngredientPurchaseEdit />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/recipe-edit/:id"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <RecipeEdit />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/ingredient-return"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <IngredientReturn />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/ingredient-usage"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <IngredientUsage />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/stock/manage-supplier" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <ManageSupplier />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/stock/stock-transfer" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <StockTransfer />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/supplier-history"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <SupplierHistory />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/stock/stock-out-food" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <StockOutFood />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/stock/stock-out-ingredient"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <StockOutIngredient />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* Users */}
          <RestaurantRoute path="/dashboard/manage/user/customers" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <CustomerCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/user/admin-staff" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AdminStaffCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/user/delivery-men" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <DeliveryMen />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/user/delivery-request" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <NewDeliveryMen />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/user/waiters" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Waiter />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/roles-and-permissions" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Permissions />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* Restaurant */}
          <RestaurantRoute path="/dashboard/manage/restaurant/branches" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <BranchCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/restaurant/order-types"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <OrderTypeCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/restaurant/table-floor" exact>
          {authUserInfo.permissions !== null &&
          checkPermission(authUserInfo.permissions, "Manage") ? (
            <TableFloor />
          ) : (
            <NoPermission />
          )}
        </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/restaurant/tables" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <TableCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/add-menu" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <MenuTagCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/add-schemes" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <SchemesCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/food/add-cards" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <CardsCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* <RestaurantRoute path="/dashboard/manage/restaurant/dept-tags" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <DeptTagCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute> */}

          <RestaurantRoute
            path="/dashboard/manage/restaurant/payment-type"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <PaymentTypeCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/restaurant/manage-party"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Party />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          
          <RestaurantRoute
            path="/dashboard/expense"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <ExpenseList />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/restaurant/manage-expense"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Expense />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/restaurant/party-history"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <PartyHistory />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/restaurant/manage-kot" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <KotCrud />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* Website */}
          <RestaurantRoute path="/dashboard/manage/website/hero-section" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <HeroSection />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/website/promotions" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Promotions />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* Settings */}
          <RestaurantRoute path="/dashboard/manage/settings/currencies" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Currency />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/settings/languages" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Lang />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/settings/languages/:code"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Translation />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/settings/smtp-settings"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Smtp />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/settings/email-settings"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <EmailTemplate />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/manage/settings/pos-screen" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <PosScreen />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/settings/general-settings"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <General />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/settings/show-delivery-menu"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <Showdeliveryman />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute
          path="/dashboard/manage/settings/show-delivery-menu"
          exact
        >
          {authUserInfo.permissions !== null &&
          checkPermission(authUserInfo.permissions, "Manage") ? (
            <UpdateSettings />
          ) : (
            <NoPermission />
          )}
        </RestaurantRoute>

          <RestaurantRoute
            path="/dashboard/manage/settings/update-system"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <UpdateSystem />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/delivery/assigned-orders" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Delivery") ? (
              <AssignedOrders />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          <RestaurantRoute path="/dashboard/delivery/delivered-orders" exact>
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Delivery") ? (
              <DeliveredOrders />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* Error Routing */}
          <Route component={NotFound} />
          {/* Error Routing */}
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
