import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import DatePicker from "react-datepicker";

const toastObj = {
  position: "bottom-center",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  className: "text-center toast-notification",
}

const StockTransfer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  const {
    branchForSearch,
    ingredientItemForSearch,
    getIngredientItem,
    ingredientItemStock,
  } = useContext(RestaurantContext)

  let {
    //stock transfer
    getStockTransfer,
    stockTransferList,
    setStockTransferList,
    setPaginatedStockTransfer,
    stockTransferForSearch,
    setStockTransferForSearch,
    // units
    units,
    //pagination
    dataPaginating,
  } = useContext(UserContext);

  // States hook here
  //new customer
  let [newStockTransfer, setNewStockTransfer] = useState({
    transfer_no: "",
    transfer_date: null,
    branch: null,
    remarks: '',
    ingredients: {},
    edit: false,
    uploading: false,
  });

  const [date, setDate] = useState(null);

  //search result
  let [searchedStockTransfer, setSearchedStockTransfer] = useState({
    list: null,
    searched: false,
  });

  const [cachedIngredients, setCachedIngredients] = useState({})
  const [branchNamesObj, setBranchNamesObj] = useState({})
  const [unitNamesObj, setUnitNamesObj] = useState({})

  //useEffect == componentDidMount
  useEffect(() => {
    getIngredientItem()
  }, []);

  useEffect(() => {
    if (branchForSearch && branchForSearch.length) {
      const obj = {}
      branchForSearch.forEach(branch => {
        obj[branch.id] = branch
      })
      setBranchNamesObj(obj)
    }
  }, [branchForSearch])

  useEffect(() => {
    if (units && units.length) {
      const obj = {}
      units.forEach(unit => {
        obj[unit.id] = unit.code
      })
      setUnitNamesObj(obj)
    }
  }, [units])

  //set name, phn no hook
  const handleSetNewStockTransfer = (e) => {
    setNewStockTransfer({ ...newStockTransfer, [e.target.name]: e.target.value });
  };

  const handleSetBranch = (branch) => {
    setNewStockTransfer({ ...newStockTransfer, branch: branch, ingredients: {} });
  }

  const handleDeleteIngredient = (id) => {
    const newObj = {...newStockTransfer.ingredients}
    delete newObj[id]
    setNewStockTransfer(curr => ({
      ...curr,
      ingredients: newObj
    }))
  }

  const handleSetIngredients = (ingredient) => {
    console.log('ar-log ingredient',ingredient)
    const newObj = {...newStockTransfer.ingredients}
    const toFetch = []
    if (!newObj[ingredient.id]) {
      newObj[ingredient.id] = ingredient
      toFetch.push(ingredient.id)
    }
    setNewStockTransfer(curr => ({
      ...curr,
      ingredients: newObj
    }))
    toFetch.forEach(ingredientId => getIngredientInfo(ingredientId))
  }

  const handleSetIngredientBranch = (branch, id) => {
    setNewStockTransfer(curr => ({
      ...curr,
      ingredients: {
        ...curr.ingredients,
        [id]: {
          ...curr.ingredients[id],
          branch: branch
        }
      }
    }))
  }

  useEffect(() => console.log('ar-log newStockTransfer', newStockTransfer), [newStockTransfer])

  const getIngredientInfo = (ingredientId) => {
    // if (cachedIngredients[ingredientId]) {
      // setNewStockTransfer(curr => ({
      //   ...curr,
      //   ingredients: {
      //     ...curr.ingredients,
      //     [ingredientId]: {
      //       ...curr.ingredients[ingredientId],
      //       max_qty: parseInt(cachedIngredients[ingredientId].stock_qty),
      //       fetched_price: parseFloat(cachedIngredients[ingredientId].avg_rate)
      //     }handleCloseModal
      //   }
      // }))
    // } else {
      const url = BASE_URL + '/settings/get-qty-avgrate';
      let formData = new FormData()
      formData.append("branch_id", newStockTransfer.branch.id)
      formData.append("item_id", ingredientId)
      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` }
        })
        .then(res => {
          if (res.data && res.data.stock_qty) {
            setNewStockTransfer(curr => ({
              ...curr,
              ingredients: {
                ...curr.ingredients,
                [ingredientId]: {
                  ...curr.ingredients[ingredientId],
                  max_qty: parseInt(res.data.stock_qty),
                  fetched_price: parseFloat(res.data.avg_rate)
                }
              }
            }))
            // setCachedIngredients(curr => ({
            //   ...curr,
            //   [ingredientId]: res.data
            // }))
          }
        })
        .catch(err => {
          console.log(err)
        });
    // }
  }

  const handleCloseModal = (e) => {
    setNewStockTransfer({
      transfer_no: "",
      transfer_date: null,
      branch: null,
      remarks: '',
      ingredients: {},
      edit: false,
      uploading: false,
    })
  }

  const validateAndGetFormData = (edit) => {
    const formData = {};
    if (!newStockTransfer.transfer_date) {
      toast.error('Please select a date', toastObj);
      return false;
    } else {
      let dateObj = newStockTransfer.transfer_date
      let date =
        `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)}-${dateObj.getDate()}`;
      formData.transfer_date = date
    }
    if (!newStockTransfer.branch || !newStockTransfer.branch.id) {
      toast.error('Please select a branch', toastObj);
      return false;
    } else {
      formData.from_branch_id = newStockTransfer.branch.id
    }
    if (!newStockTransfer.ingredients || !Object.keys(newStockTransfer.ingredients).length) {
      toast.error('Please select ingredients', toastObj);
      return false;
    } else {
      const stock_transfer_details = [];
      for (const ingredientId in newStockTransfer.ingredients) {
        const ingredient = newStockTransfer.ingredients[ingredientId]
        if (!ingredient.branch || !ingredient.branch.id) {
          toast.error(`Please select the branch for ${ingredient.name}.`, toastObj);
          return false;
        } else if (!ingredient.qty) {
          toast.error(`Please enter the qty for ${ingredient.name}.`, toastObj);
          return false;
        } else {
          stock_transfer_details.push({
            to_branch_id: ingredient.branch.id,
            item_id: ingredient.id,
            item_name: ingredient.name,
            item_code: ingredient.item_code,
            unit_id: ingredient.unit_id,
            qty: ingredient.qty,
            price: ingredient.fetched_price,
          })
        }
      }
      formData.stock_transfer_details = stock_transfer_details
    }
    if (edit) formData.transfer_no = newStockTransfer.transfer_no
    formData.remarks = newStockTransfer.remarks
    return formData;
  }

  const onSuccessSave = (res) => {
    setNewStockTransfer({
      transfer_no: "",
      transfer_date: null,
      branch: null,
      remarks: '',
      ingredients: {},
      edit: false,
      uploading: false,
    });
    setStockTransferList(res.data[0]);
    setStockTransferForSearch(res.data[1]);
    setLoading(false);
    toast.success(`${_t(t("Stock transfer has been added."))}`, toastObj);
  }

  const onFailedSave = (error) => {
    setLoading(false);
    setNewStockTransfer({
      ...newStockTransfer,
      uploading: false,
    });
    toast.error(
      `${_t(t("Please try again."))}`,
      toastObj
    );
  }

  //Save New customer
  const handleSaveNewCustomer = (e, edit) => {
    e.preventDefault();
    const id = newStockTransfer.id
    const formData = validateAndGetFormData(edit)
    if (!formData)
      return;
    const url = BASE_URL + `/settings/stockTransfer${edit ? '/'+id : ''}`;
    console.log('ar-log formData', formData)
    if (!edit)
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(onSuccessSave)
      .catch(onFailedSave);
    else
      return axios
      .put(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(onSuccessSave)
      .catch(onFailedSave);
  };

  //set edit true & values
  const handleSetEdit = (id) => {
    const url = BASE_URL + `/settings/stockTransfer/${id}`
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` }
      })
      .then(res => {
        console.log(res.data)
        if (res.data) {
          const dateToSet = new Date(res.data.stock.transfer_date)
          const newStockObj = {
            id: res.data.stock.id,
            transfer_no: res.data.stock.transfer_no,
            transfer_date: dateToSet,
            remarks: res.data.stock.remarks,
            edit: true,
            uploading: false,
          }
          newStockObj.branch = branchNamesObj[res.data.stock.from_branch_id];
          const ingredientObj = {}
          res.data.stockDetails.forEach(ingredient => {
            console.log('ar-log',unitNamesObj, ingredient.unit_id, unitNamesObj[ingredient.unit_id])
            ingredientObj[ingredient.item_id] = {
              amount: ingredient.amount,
              id: ingredient.item_id,
              item_code: ingredient.item_code,
              price: ingredient.price,
              qty: ingredient.qty,
              name: ingredient.item_name,
              fetched_price: parseFloat(ingredient.avg_rate),
              max_qty: parseInt(ingredient.stock_qty),
              branch: branchNamesObj[ingredient.to_branch_id],
              unit: unitNamesObj[ingredient.unit_id],
              unit_id: ingredient.unit_id,
            }
          })
          newStockObj.ingredients = ingredientObj
          setNewStockTransfer(newStockObj)
          setDate(dateToSet)
        }
      })
      .catch(error => {
      })
  };

  //search customers here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedStockTransfer({ ...searchedStockTransfer, searched: false });
    } else {
      let searchedList = stockTransferForSearch.filter((item) => {
        let lowerCaseTransferNo = item.transfer_no.toLowerCase();
        let lowerCaseTransferDate = item.transfer_date.toLowerCase();
        let lowerCaseBranch = (branchNamesObj[item.from_branch_id])?.name?.toLowerCase();

        return (
          lowerCaseTransferNo.includes(searchInput) ||
          lowerCaseTransferDate.includes(searchInput) ||
          lowerCaseBranch.includes(searchInput)
        );
      });
      setSearchedStockTransfer({
        ...searchedStockTransfer,
        list: searchedList,
        searched: true,
      });
    }
  };

  //delete confirmation modal of waiter
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteCustomer(id);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete customer here
  const handleDeleteCustomer = (id) => {
    setLoading(true);
    const customerUrl = BASE_URL + `/settings/stockTransfer/${id}`;
    return axios
      .delete(customerUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setStockTransferList(res.data[0]);
        setStockTransferForSearch(res.data[1]);
        setSearchedStockTransfer({
          ...searchedStockTransfer,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Stock Transfer has been deleted successfully"))}`, toastObj);
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, toastObj);
      });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Stock Transfer"))}</title>
      </Helmet>

      {/* Add modal */}
      <div className="modal fade add-transfer-modal" id="addTransfer" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content" style={{maxHeight: 'calc(100vh - 1.75rem - 1.75rem)'}}>
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newStockTransfer.edit
                    ? _t(t("Add new Stock Transfer"))
                    : _t(t("Update Stock Transfer"))}
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body" data-simplebar>
              {/* show form or show saving loading */}
              {newStockTransfer.uploading === false ? (
                <div key="fragment-customer-1">
                  <form
                    onSubmit={
                      (e) =>
                        handleSaveNewCustomer(e, newStockTransfer.edit)
                    }
                  >
                    <div className="row m-0">
                      <div className="col-6">
                        <label htmlFor="transfer_no" className="form-label">
                          {_t(t("Transfer Number"))}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="transfer_no"
                          name="transfer_no"
                          placeholder="Transfer Number will be automatically set"
                          value={newStockTransfer.transfer_no || ""}
                          disabled
                          onChange={handleSetNewStockTransfer}
                        />
                      </div>

                      <div className="col-6">
                        <label htmlFor="branch" className="form-label">
                        {_t(t("Branch"))}
                        <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={branchForSearch}
                          value={newStockTransfer.branch || null}
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          classNamePrefix="select"
                          onChange={handleSetBranch}
                          maxMenuHeight="200px"
                          placeholder={
                            _t(t("Please select a branch")) + ".."
                          }
                        />
                      </div>
                    </div>

                    <div className="row mt-3 m-0">
                      <div>
                        <label
                          htmlFor="transfer_date"
                          className="control-label"
                        >
                          {_t(t("Transfer Date"))}
                          <span className="text-danger">*</span>
                        </label>
                        <DatePicker
                          selected={date}
                          onChange={(date) => {
                            setDate(date)
                            setNewStockTransfer({
                              ...newStockTransfer,
                              transfer_date: date
                            })
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control"
                          placeholderText={_t(t("Transfer Date"))}
                        />
                      </div>
                    </div>

                    <div className="row m-0 mt-3">
                      <div>
                        <label htmlFor="remarks" className="form-label">
                          {_t(t("Remarks"))}{" "}
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="remarks"
                          name="remarks"
                          placeholder="Remarks"
                          value={newStockTransfer.remarks || ""}
                          onChange={handleSetNewStockTransfer}
                        />
                      </div>
                    </div>

                    <div className="row m-0 mt-3">
                      <div>
                        <label htmlFor="ingredients" className="form-label">
                          {_t(t("Ingredients"))}{" "}
                        </label>
                        <Select
                          options={ingredientItemForSearch}
                          // value={Object.values(newStockTransfer.ingredients)}
                          // isMulti
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          classNamePrefix="select"
                          onChange={handleSetIngredients}
                          maxMenuHeight="200px"
                          menuPlacement="top"
                          placeholder={
                            _t(t("Please select ingredients")) + ".."
                          }
                          isDisabled={!newStockTransfer.branch}
                        />
                      </div>

                      {newStockTransfer.ingredients && Object.values(newStockTransfer.ingredients).length ?
                        <div className="mt-3">
                        <table className="table table-bordered table-hover">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Code"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Name"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("To Branch"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Unit"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Qty"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Stock Qty"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Price"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Amount"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Action"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {Object.values(newStockTransfer.ingredients).map(
                              (ingredient, index) => (
                                <tr
                                  className="align-middle"
                                  key={index}
                                >
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {ingredient.item_code}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {ingredient.name}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    <Select
                                      options={branchForSearch.filter(branch => branch.id !== newStockTransfer.branch.id)}
                                      value={ingredient.branch || null}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      classNamePrefix="select"
                                      onChange={(branch) => handleSetIngredientBranch(branch, ingredient.id)}
                                      maxMenuHeight="200px"
                                      menuPlacement="top"
                                      placeholder={
                                        _t(t("Branch")) + ".."
                                      }
                                    />
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {ingredient.unit}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    <input
                                      type="number"
                                      name="qty"
                                      className="form-control"
                                      min={0}
                                      value={ingredient.qty}
                                      placeholder={(ingredient.max_qty || ingredient.max_qty==0) ? 'Enter Qty' : 'Loading..'}
                                      disabled={!(ingredient.max_qty || ingredient.max_qty == 0)}
                                      onChange={(e) => {
                                        const newQty = (
                                          e.target.value
                                          ? parseInt(e.target.value)
                                          : 0
                                        )
                                        console.log('ar-log newQty', newQty, 'max_qty', ingredient.max_qty)
                                        if (newQty <= ingredient.max_qty) {
                                          setNewStockTransfer(curr => ({
                                            ...curr,
                                            ingredients: {
                                              ...curr.ingredients,
                                              [ingredient.id]: {
                                                ...curr.ingredients[ingredient.id],
                                                qty: newQty
                                              }
                                            },
                                          }))
                                        } else {
                                          toast.error('Please select a quantity lower than stock quantity of the From Branch', toastObj)
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {(ingredient.max_qty || ingredient.max_qty == 0)
                                    ? ingredient.max_qty
                                    : 'Loading..'}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {(ingredient.fetched_price || ingredient.fetched_price==0)
                                    ? ingredient.fetched_price
                                    : 'Loading..'}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {
                                    (ingredient.fetched_price && ingredient.qty)
                                    ? ingredient.fetched_price * ingredient.qty
                                    : 0
                                    }
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    <button className="btn btn-danger" onClick={() => {handleDeleteIngredient(ingredient.id)}}>
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                        : null
                      }
                    </div>

                    <div className="mt-4">
                      <div className="row d-flex justify-content-end align-items-center">
                        <div className="col-3">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          >
                            {!newStockTransfer.edit
                              ? _t(t("Save"))
                              : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                            data-dismiss="modal"
                            onClick={handleCloseModal}
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row d-flex justify-content-end align-items-center">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newStockTransfer.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${dataPaginating && "loading"}`}></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedStockTransfer.searched
                                    ? _t(t("Stock Transfer List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search customer */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add customer modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  data-toggle="modal"
                                  data-target="#addTransfer"
                                  onClick={() => {
                                    setNewStockTransfer({
                                      ...newStockTransfer,
                                      edit: false,
                                      uploading: false,
                                    });
                                  }}
                                >
                                  {_t(t("add new"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Transfer Number"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Transfer Date"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Branch"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedStockTransfer.searched
                                ? [
                                    stockTransferList && [
                                      stockTransferList.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        stockTransferList.data.map((item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (stockTransferList.current_page -
                                                    1) *
                                                    stockTransferList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.transfer_no}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.transfer_date}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {branchNamesObj[item.from_branch_id] && branchNamesObj[item.from_branch_id].name
                                                ? branchNamesObj[item.from_branch_id].name
                                                : ''}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleSetEdit(
                                                          item.id
                                                        );
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#addTransfer"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Edit"))}
                                                    </button>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.id
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedStockTransfer && [
                                      searchedStockTransfer.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedStockTransfer.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (stockTransferList.current_page -
                                                      1) *
                                                      stockTransferList.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.transfer_date}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.transfer_date}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {branchNamesObj[item.from_branch_id] && branchNamesObj[item.from_branch_id].name
                                                  ? branchNamesObj[item.from_branch_id].name
                                                  : ''}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handleSetEdit(
                                                            item.id
                                                          );
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#addTransfer"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("Edit"))}
                                                      </button>

                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handleDeleteConfirmation(
                                                            item.id
                                                          );
                                                        }}
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                        {_t(t("Delete"))}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedStockTransfer.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(stockTransferList, setPaginatedStockTransfer)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(stockTransferList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedStockTransfer({
                                      ...searchedStockTransfer,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedStockTransfer,
                                    stockTransferForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default StockTransfer;
