import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { FoodContext } from "../../../../../contexts/Food";

const multiSelectOptions = [
  {value: 'multiple', label: 'Multiple'}
]

const AddonRestrictionsCrud = () => {
  const { t } = useTranslation();
  const history = useHistory();
  
  // CONTEXT STATES
  const {
    loading,
    setLoading,
    dataPaginating
  } = useContext(SettingsContext);

  const {
    propertyGroupForSearch,
    propertyItemList,
    getPropertyItem,
  } = useContext(FoodContext);

  // ALL STATES
  const [item, setItem] = useState(null)
  const [propertyGroups, setPropertyGroups] = useState([])
  const [allPropertiesByGroup, setAllPropertiesByGroup] = useState({})
  const [selectedProperties, setSelectedProperties] = useState({})


  // COMPONENT DID MOUNT
  useEffect(() => {
    // This useEffect sets the item from location state passed
    // by previous page.
    if (history.location.state && history.location.state.item) {
      setItem(history.location.state.item)
    }
  }, [history.location])

  useEffect(() => {
    // This useEffect sets all the properties accoridng to their
    // property group ids.
    if (propertyItemList &&  propertyItemList.length) {
      const propertiesByGroups = {}
      propertyItemList.forEach(property => {
        JSON.parse(property.property_group_id).forEach(groupId => {
          if (propertiesByGroups[groupId] && propertiesByGroups[groupId].length)
            propertiesByGroups[groupId].push(property);
          else
            propertiesByGroups[groupId] = [property];
        })
      })
      setAllPropertiesByGroup(propertiesByGroups)
      setSelectedProperties(propertiesByGroups)
    } else {
      getPropertyItem();
    }
  }, [propertyItemList])

  useEffect(() => {
    // this useEffect sets all the property groups associated with the item
    // once item has been set
    if (item && propertyGroupForSearch && propertyGroupForSearch.length) {
      const tempPropertyGroups = [];
      propertyGroupForSearch.forEach(propertyGroup => {
        if (item.property_groups.includes(propertyGroup.id))
          tempPropertyGroups.push(propertyGroup);
      })
      setPropertyGroups(tempPropertyGroups);
    }
  }, [item, propertyGroupForSearch])


  // POST REQUEST
  const propertyValidationAxios = async (id, payload) => {
    setLoading(true);
    const url = BASE_URL + "/settings/food-item-property_validation/" + id;
    return axios
      .post(url, payload, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLoading(false);
        toast.success(`${_t(t("Property validations has been set"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        history.push('/dashboard/manage/food/all-items', {from: 'restrictions'})
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  //send to server
  const handleSubmit = (e) => {
    e.preventDefault();
    const allPropertyGroups = (
      e.target['property-groups'].length
      ? e.target['property-groups']
      : [e.target['property-groups']]
    );
    const payload = [];
    allPropertyGroups.forEach(propertyGroup => {
      payload.push({
        property_group_id: (
          isNaN(parseInt(propertyGroup.value))
          ? null
          : parseInt(propertyGroup.value)
        ),
        required: (
          propertyGroup.checked
          ? 1
          : 0
        ),
        multi_select: e.target[`multi_select-${propertyGroup.id}`].value,
        limit: parseInt(e.target[`limit-${propertyGroup.id}`].value),
        property_item_ids: (
          selectedProperties[propertyGroup.value]?.length ?
          selectedProperties[propertyGroup.value].map(
            property => property.id
          ).join(',')
          : ""
        ),
      })
    })
    propertyValidationAxios(item.id, {
      payload: payload
    });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Property Validation"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true
                      ? tableLoading()
                      : (
                          // start here
                          <div key="smtp-form">
                            <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                              <div className="col t-mb-15 mb-md-0">
                                <ul className="t-list fk-breadcrumb">
                                  <li className="fk-breadcrumb__list">
                                    <span className="t-link fk-breadcrumb__link text-capitalize d-inline-block">
                                      {_t(t("Property Validation"))}
                                    </span>
                                    {item ?
                                      <span className="t-link fk-breadcrumb__link text-capitalize d-inline-block">
                                        {" - "}{item.name}
                                      </span>
                                      : null
                                    }
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-6 col-lg-7">
                                <div className="row gx-3 align-items-center"></div>
                              </div>
                            </div>

                            {/* Form starts here */}
                            <form
                              className="row card p-2 mx-3 mb-5 sm-text"
                              onSubmit={handleSubmit}
                            >
                              <div className="col-12">
                                {
                                item
                                ? <h5>{_t(t("Select the Property Groups for"))}{" "}{item?.name}</h5>
                                : null
                                }
                                {(item && propertyGroups && propertyGroups.length) ? (
                                    propertyGroups.map(propertyGroup => (
                                      <div className="mb-4">
                                        <div className="form-group mb-2" key={`property-group-${propertyGroup.id}`}>
                                          <input
                                            name="property-groups"
                                            id={propertyGroup.id}
                                            value={propertyGroup.id}
                                            type="checkbox"
                                            className="form-check-input flex-0"
                                            defaultChecked
                                          />
                                          <label
                                            htmlFor={propertyGroup.id}
                                            className="form-check-label ml-2 col-4 lg-text font-weight-bold"
                                          >
                                            {propertyGroup.name}
                                          </label>
                                        </div>
                                        <div className="row m-0 mb-2 align-items-center justify-content-between">
                                          <label className="form-label col-4">
                                            {_t(t("Select Quantity Option"))}{":"}
                                          </label>
                                          <Select
                                            options={multiSelectOptions}
                                            name={`multi_select-${propertyGroup.id}`}
                                            className="col-4"
                                            defaultValue={multiSelectOptions[0]}
                                            components={makeAnimated()}
                                          />
                                          <input
                                            type="number"
                                            name={`limit-${propertyGroup.id}`}
                                            className="form-control col-3 w-25"
                                            placeholder="Limit e.g. 2"
                                            defaultValue="0"
                                          />
                                        </div>
                                        <div className="row m-0 align-items-center">
                                          <label className="col-3 form-label">
                                            {_t(t("Select Properties"))}{":"}
                                          </label>
                                          <Select
                                            isMulti
                                            className="col-9 pr-0"
                                            options={allPropertiesByGroup[propertyGroup.id] || []}
                                            getOptionLabel={op => op.name}
                                            getOptionValue={op => op.id}
                                            value={selectedProperties[propertyGroup.id] || null}
                                            onChange={(newStuff) => {
                                              setSelectedProperties(curr => {
                                                return {
                                                  ...curr,
                                                  [propertyGroup.id]: newStuff
                                                }
                                              })
                                            }}
                                            components={makeAnimated()}
                                          />
                                        </div>
                                      </div>
                                    ))
                                  )
                                  : null
                                }
                                <div className="form-group mt-5 pb-2">
                                  <div className="col-lg-12">
                                    <button
                                      className="btn btn-primary px-5"
                                      type="submit"
                                    >
                                      {_t(t("Save"))}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          // end here
                        )
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default AddonRestrictionsCrud;
